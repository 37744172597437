import { HashLocationStrategy, LocationStrategy } from '@angular/common';
import { HttpClient, provideHttpClient, withJsonpSupport } from '@angular/common/http';
import { ApplicationConfig, LOCALE_ID, importProvidersFrom } from '@angular/core';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideRouter } from '@angular/router';
import player from 'lottie-web';
import { AnimationLoader, provideLottieOptions } from 'ngx-lottie';
import { routes } from './app-routing.module';
import { LabelPipe } from './_shared-modules/label-pipe-module/label.pipe';
import { TranslateLoader, TranslateModule, TranslateService } from '@ngx-translate/core';
import { JWT_OPTIONS, JwtHelperService } from '@auth0/angular-jwt';
import { CustomTranslateLoader } from './_services/custom-translate-loader.service';
import { KeycloakService } from 'keycloak-angular';

/**
   * This factory function returns a function that returns a promise which resolves when the `onLangChange` event is emitted.
   * The `onLangChange` event is emitted when the language is changed using the `use` method of the `TranslateService`.
   *
   * The promise returned by this function is used with Angular's `APP_INITIALIZER` token to delay the application start until the promise resolves.
   * This ensures that the translations are loaded before the application starts, which can solve the issue of the translations not showing up immediately when the page is refreshed.
   *
   * @param {TranslateService} translate - An instance of the `TranslateService`.
   * @param {TranslateLoader} translateLoader - An instance of the `TranslateLoader`.
   *
   * @returns {Function} A function that returns a promise which resolves when the `onLangChange` event is emitted.
   */
export interface Translations {
  [key: string]: string | Translations;
}

export function appInitializerFactory(
  translate: TranslateService,
  translateLoader: TranslateLoader
): () => void {
  return () =>
    new Promise<void>((resolve) => {
      translate.onLangChange.subscribe({
        next: () => {
          resolve();
        },
      });

      translateLoader.getTranslation("pt").subscribe({
        next: (translations: Translations) => {
          translate.setTranslation("pt", translations, true);
          translate.use("pt");
        },
      });
    });
}

export function playerFactory() {
  return player;
}

export const appConfig: ApplicationConfig = {
  providers: [
    { provide: LOCALE_ID, useValue: 'pt-PT' },
    AnimationLoader,
    importProvidersFrom(
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpLoaderFactory,
          deps: [HttpClient]
        }
      }
    )),
    provideLottieOptions({
      player: () => player,
    }),
    provideAnimations(),
    provideHttpClient(
      withJsonpSupport()
    ),
    // provideRouter(routes, withViewTransitions()), //FIXME Enable this when safari and firefox support is added
    provideRouter(routes),
    { provide: LocationStrategy, useClass: HashLocationStrategy },
    LabelPipe,
    JwtHelperService,
    { provide: JWT_OPTIONS, useValue: JWT_OPTIONS },
    KeycloakService
  ]
};

export function HttpLoaderFactory(http: HttpClient) {
  return new CustomTranslateLoader(http);
}
