import { animate, state, style, transition, trigger } from '@angular/animations';

import { Component, DestroyRef, ElementRef, inject, Input, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { take } from 'rxjs';
import { Accordion } from '../__models/accordion/accordion.model';
import { LibraryService } from '../__services/library.service';
import { AccordionBaseComponent } from '../_extended-components/accordion-base-component/accordion-base.component';
import { NgClass } from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-accordion',
  templateUrl: './accordion.component.html',
  styleUrls: [
    '../_base-component/base.component.sass',
    '../_extended-components/accordion-base-component/accordion-base.component.sass',
    './accordion.component.sass'
  ],
  animations: [
    trigger('slideAnimation', [
      state('hide', style({
        height: '0px'
      })),
      state('show', style({
        height: '*'
      })),
      transition('show <=> hide', animate('300ms ease-in-out'))
    ])
  ],
  imports: [
    AccordionBaseComponent,
    NgClass
  ],
  providers: [LibraryService]
})
export class AccordionComponent extends AccordionBaseComponent implements OnInit {

  @Input() public accordions: Array<Accordion> = [];

  private destroyRef: DestroyRef = inject(DestroyRef);

  constructor(
    public override host: ElementRef,
    public override libraryService: LibraryService) {
    super(host, libraryService);
  }

  ngOnInit(): void {
    super.setCSSVars();
    super.setSpecificCSSVars();
  }

  public toggleAccordion(accordionItem: Accordion): void {
    super.oldAsyncFunction(() => {
      this.accordions.forEach(item => {
        if (item != accordionItem) {
          item.animationState = 'hide';
        }
      });
      accordionItem.animationState = accordionItem.animationState === 'hide' ? 'show' : 'hide';
    }, 300).pipe(take(1), takeUntilDestroyed(this.destroyRef)).subscribe();
  }
}
