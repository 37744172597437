export interface ListItem {
  id: string;
  info: Array<Info>;
  type?: string;
  selected?: boolean;
  notClickable?: boolean;
  borderStyle?: string;
  checkBoxEnabled?: boolean;
  actionDisabled?: boolean;
  expand?: boolean;

  infoExpandable?: Array<ExpandableListItem>;
}

export interface ExpandableListItem extends ListItem {
  action?: Actions;
}

export interface Type {
  id: number;
  name: string;
  imageUrl: string;
}

export interface Icon {
  iconClass?: string;
  iconUrl?: string;
}

export interface Info {
  imageUrl?: string;
  text?: string;
  number?: number;
  date?: string;
  progressBar?: ProgressBarInfo;
  icon?: Icon;
  state?: State;
  actions?: Array<Actions>;
  expandButton?: boolean;

  sizes?: Sizes;
  styles?: Styles;
}

export interface ProgressBarInfo {
  total: number;
  currentValue: number;
}

export interface State {
  label: string;
  class: string;
}

export interface Actions {
  bootstrapIconClass?: string;
  imageUrl?: string;
  tooltipText: string;
  actionId: ActionId;
  backgroundColor?: string;
  borderRadius?: string;
  disabled?: boolean;
  buttonHeight?: string;
  buttonWidth?: string;
  fileUrl?: string;

  textValue?: string;
  fontColor?: string;
  fontSize?: string;
  fontWeight?: string;
}

export interface Sizes {
  size: number;
  sizeSmall?: number;
}

export interface Styles {
  fontSize?: string;
  fontColor?: string;
  fontWeight?: string;
  textAlign?: string;
  padding?: string;
  whiteSpace?: string;
  maxAmountTextLines?: number;
  justify?: string;
}

export enum ActionId {
  publish,
  unpublish,
  qrcode,
  visibility,
  redeem,
  delete,
  goTo,

  edit,
  show,
  download
}

export enum ItemState {
  noState,

  // General
  pending, // yellow
  active, // green
  disabled, // red
  hidden, // TODO grey?

  // Vouchers
  outofstock, // red

  // Users
  confirmed, // green
  blacklisted, // red

  // CategoryBadges
  configured, // green
  notConfigured, // red

  // Suggestions
  accepted,

  archived
}
