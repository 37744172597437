export const environment = {
  showVersion: true,
  production: false,

  defaultLanguage: 'pt',
  showMockDataPopup: false,
  apiBaseUrl: 'https://dev.api.givin.io/',
  translation_files_url: '',

  tenantName: 'GIVIN',
  tenantId: '5ec48614-f9fa-416f-889c-521fb2fa8e04',
  applicationId: '6d5fdb3a-c684-42b9-98c4-0c8776c618b1',


  tenantLocalAssetsPath: 'GIVIN',

  // General Application Information
  pageTitle: '[DEV] GIVIN',

  //Wallet Information
  useWallet: false,
  web3ClientId: 'BKloJT7k_Q-GKTVTmsZQIez9fT9-Zu6FjpL6jxYFQnxysBPzG74awHIucT89K0pAj8yWXj0L8nfm5NBK8v2dIDY',


  // Keycloak configurations
  useKeycloak: true,
  keycloakConfig: {
    url: 'https://dev.auth.givin.io/',
    realm: 'GIVIN',
    clientId: 'givin'
  },

  //disable scroll animation
  useScrollAnimation: true,

  // custom loading animation
  customLoading: true,
  customLoadingAnimationType: '',

  //CSS Variables
  brandStyleVariables: {
    brandDarkerColor: '#19635f',
    brandLighterColor: '#239C95',
    mainBrandColor: '#269F98',
    mainBrandColorLight: '#269f9866',
    mainBrandColorLighter: '#269f9833',
    mainBrandColorLightest: '#269f981A',
    pseudoElementHighlightColor: '#0AADAF',
    mainErrorColor: '#ff3e3e',
    mainWarningColor: '#ffb84d',
    mainBackgroundColor: 'white',
    mainFontColor: '#333333',
    secondaryFontColor: '#828282',
    mainBorderColor: '#8692A6',
    brandBackgroundColor: '#269f98',
    inputBackgroundColor: '#f0f1f2',
    inputSecondaryBackgroundColor: '#fafbff',
    imageDisabledColor: '#00000066',
    footerBackgroundColor: '#27282b',
    footerTextColor: '#ffffff',
    footerButtonsColor: '#343538',
    listShadowBrandColor: 'rgba(189, 194, 208, 1)'
  },

  firebaseConfig: {
    apiKey: "AIzaSyDsDdt6m24he2N1hUQ7LK-dp1NsJ5LxOh8",
    authDomain: "givin-89ac1.firebaseapp.com",
    projectId: "givin-89ac1",
    storageBucket: "givin-89ac1.appspot.com",
    messagingSenderId: "356995805010",
    appId: "1:356995805010:web:05a64627bd9e5b3265548b",
    measurementId: "G-QGFWLM9MM2"
  },

  homeRequiredRoles: [],

  // Contact Us
  contactUsFormAlignLeft: true,
  contactUsFormBackgroundImage: 'assets/mock/contact-us/form-nft.png',
  contactUsFormEnvironmentIcon: '',
  showPhoneInput: true,

  // Footer
  showTermsAndConditions: true,
  showPrivacyPolicy: true,

  // User Info
  showUserPointsInBanners: false,

  // ############################################ Animations ############################################
  animations: {
    activityMultiStep: {
      type: 'IMAGE',
      url: 'assets/imgs/environments/' + 'GIVIN' + '/animations/activity-multi-step-image.png'
    }
  },

  // ############################################ Redirect Routes ############################################
  routeRedirect: {
    landingPage: {
      shouldRedirectIfLoggedIn: false,
      pathToRedirect: '/'
    }
    // TODO Add other routes if needed
  },

  // ############################################ Mocked Services ############################################
  allServicesMocked: false,
  useMockedData: {
    // ######## General ########
    generalGetInfo: true,
    generalGetLanguages: false,
    generalGetLPBanners: false,
    generalGetUserDetails: false,
    generalUpdateUserDetails: false,
    generalGetMenus: false,
    generalRegisterNewsletter: false,

    // ######## Labels ########
    labelsGetList: false,

    // about us
    aboutUsGetInfo: true,

    // blog
    blogGetFilters: true,

    // contact us
    contactUsSubmit: false,
    contactUsGetFAQS: true,

    //marketplace
    marketplaceGetTiers: false,
    marketplaceGetFilters: false,


    // marketplace - Order Entry
    marketplaceOrderEntryCreate: false,
    marketplaceOrderEntryGet: false,
    marketplaceOrderEntryGetList: false,

    // marketplace - products
    marketplaceProductsGet: false,
    marketplaceProductsGetList: false,
    marketplaceProductsGetRelatedList: false,

    // onboarding
    // TODO

    // our products
    ourProductsGetList: true,

    // partners
    partnersGetList: false,
    partnersGetCategories: false,
    partnersGetDetails: false,

    // profile
    profileGetTransactionsListHeaders: true,

    // terms & Conditions
    termsConditionsGet: true,

    // tutorial
    tutorialGetInfo: true,

    // user goals
    userGoalGetOptionsList: true,

    // history
    historyGetProductsList: true,
    historyGetTransactionList: false,

    // home
    homeActivityHistoryGetList: false,
    homeActivityPotencialRewards: false,

    // activities
    activitiesGetList: true,
    activitiesGet: true,
    activitiesInputAddUserInput: true,
    activitiesInputEditUserInput: true,
    activitiesInputDeleteUserInput: true,
  }
};
