<div class="tier-list">
  @for (tier of tierList; track tier) {
    <div class="tier-item" (click)="tierAction(tier)">
      <div class="tier-card">
        <div class="card-background-container">
          @if (tier.imageUrl) {
            <img class="image-background" [src]="tier.imageUrl" [alt]="tier.title">
          }
        </div>
        <p class="tier-title">{{tier.title}}</p>
        <p class="tier-description">Click here</p>
      </div>
    </div>
  }
</div>
