<app-page-banner
  [title]="mainBannerInfo.title ? mainBannerInfo.title : ''"
  [description]="mainBannerInfo.description ? mainBannerInfo.description : ''"
  [backgroundImageUrl]="mainBannerInfo.backgroundImageUrl ? mainBannerInfo.backgroundImageUrl : ''"
  [imageUrl]="mainBannerInfo.itemImageUrl ? mainBannerInfo.itemImageUrl : ''"
  [useGradient]="mainBannerInfo.useGradient"
  [pseudoElementColor]="environment.brandStyleVariables.pseudoElementHighlightColor"
  [containerHeight]="'36vh'" [imageSize]="'35%'">
</app-page-banner>

<div class="main-content-container">
  @if (aboutUsDescription) {
    <div class="description-container mb-3 mt-3" [innerHTML]="aboutUsDescription | safehtml"></div>
  }

  @if (!(generalService.isMobile | async) && animation && animation.show) {
    <div class="animation-container">
      @if (animation) {
        <ng-lottie #lottieTimelineAnimation
          [width]="'100%'"
          [height]="'100%'"
          [options]="lottieOptions"
          (animationCreated)="lottieAnimationItem = $event; animationCreated('lottie');"
          >
        </ng-lottie>
      }
      @if (animation.type === 'rive') {
        <canvas #canvasRive>
        </canvas>
      }
    </div>
  }

  @if ((generalService.isMobile | async) && animationMobile && animationMobile.show) {
    <div class="animation-container">
      <ng-lottie #lottieTimelineAnimation
        [width]="'100%'"
        [height]="'100%'"
        [options]="lottieOptionsMobile"
        (animationCreated)="lottieAnimationItem = $event; animationCreated('lottie');"
        >
      </ng-lottie>
      @if (animation && animation.type === 'rive') {
        <canvas #canvasRive>
        </canvas>
      }
    </div>
  }

  @if (showOurTeam) {
    <div class="our-team-container mb-3">
      <!-- TODO -->
      <p class="banner-title mt-3">{{ 'Our Team' }}</p>
      @if (ourTeamImageUrl) {
        <img class="team-image" [src]="ourTeamImageUrl">
      }
    </div>
  }
</div>
