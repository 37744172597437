export default {
  "name": "EIP-1153",
  "number": 1153,
  "comment": "Transient Storage",
  "url": "https://eips.ethereum.org/EIPS/eip-1153",
  "status": "Review",
  "minimumHardfork": "chainstart",
  "requiredEIPs": [],
  "gasConfig": {},
  "gasPrices": {
    "tstore": {
      "v": 100,
      "d": "Base fee of the TSTORE opcode"
    },
    "tload": {
      "v": 100,
      "d": "Base fee of the TLOAD opcode"
    }
  },
  "vm": {},
  "pow": {}
};
