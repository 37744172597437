<app-small-page-banner [title]="labelService.contactusGeneralInfoBannerTitle | label"
  [description]="labelService.contactusGeneralInfoBannerDescription | label" [imageSrc]="'assets/mock/contact-us/general-info-small-banner.png'"
  [titleFontSizePx]="'var(--page-title-font-size)'" [descFontSizePx]="'var(--section-description-font-size)'">
</app-small-page-banner>

<div class="faq-container">
  <div class="faq-title">{{labelService.contactusGeneralInfoFAQTitle | label}}</div>
  <app-accordion [accordions]="accordions" [fontSize]="'var(--section-description-font-size)'"
    [numberFontSize]="'var(--page-title-font-size)'" [titleFontSize]="'var(--large-section-title-font-size)'">
  </app-accordion>
</div>

<div class="form-area-container">
  <div class="form-container form-shadow">
    <p class="form-title">{{labelService.contactusGeneralInfoFormTitle | label}}</p>
    <p class="form-description">{{labelService.contactusGeneralInfoFormDesc | label}}</p>
    <form [formGroup]="form" (ngSubmit)="submitForm()">
      <div class="contact-us-form-control">
        <app-single-line-text-input
          [control]="generalInfoNameControl"
          [formControlName]="'generalInfoName'"
          [label]="labelService.contactusGeneralInfoNameTitle | label"
          [inputHeight]="'64px'"
          [fontSize]="'var(--main-info-font-size)'"
          [roundBorders]="true"
          [borderRadius]="'calc(var(--main-info-font-size) * 3)'"
          [labelMargin]="'1rem'"
          [backgroundColor]="environment.brandStyleVariables.inputSecondaryBackgroundColor"
          [brandColor]="environment.brandStyleVariables.mainBrandColor"
          [placeholderLabel]="labelService.contactusGeneralInfoNamePlaceholder | label">
        </app-single-line-text-input>

        @if ((form.get('generalInfoName')?.touched
          && (form.get('generalInfoName')?.hasError('required')
          || form.get('generalInfoName')?.hasError('minlength')))) {
          <span class="input-error"
            >
            <img src="assets/imgs/error-icon.svg">
            {{labelService.myAccountDetailsErrorField | label}}
          </span>
        }
        @if ((form.get('generalInfoName')?.touched
          && form.get('generalInfoName')?.hasError('pattern'))) {
          <span class="input-error"
            >
            <img src="assets/imgs/error-icon.svg">
            {{labelService.myAccountDetailsErrorTextField | label}}
          </span>
        }
      </div>

      <div class="contact-us-form-control">
        <app-generic-phone-input
          [control]="phoneInputControl"
          [formControlName]="'phoneInput'"
          [selectedCountryISO]="CountryISO.Portugal"
          [label]="labelService.contactusGeneralInfoPhoneTitle | label" [placeholderLabel]="labelService.contactusGeneralInfoPhonePlaceholder | label"
          [inputHeight]="'64px'" [fontSize]="'var(--main-info-font-size)'" [borderRadius]="'calc(var(--main-info-font-size) * 3)'" [fontColor]="environment.brandStyleVariables.mainFontColor"
          [errorColor]="environment.brandStyleVariables.mainErrorColor" [backgroundColor]="environment.brandStyleVariables.inputSecondaryBackgroundColor"
          [roundBorders]="true">
        </app-generic-phone-input>

        @if ((form.get('phoneInput')?.touched
          && (form.get('phoneInput')?.hasError('required')))) {
          <span class="input-error"
            >
            <img src="assets/imgs/error-icon.svg">
            {{labelService.myAccountDetailsErrorField | label}}
          </span>
        }

        @if ((form.get('phoneInput')?.touched
          && form.get('phoneInput')?.hasError('validatePhoneNumber'))) {
          <span class="input-error"
            >
            <img src="assets/imgs/error-icon.svg">
            {{labelService.myAccountDetailsErrorTextField | label}}
          </span>
        }
      </div>

      <div class="contact-us-form-control">
        <app-single-line-text-input
          [control]="generalInfoEmailControl"
          [formControlName]="'generalInfoEmail'"
          [label]="labelService.contactusGeneralInfoEmailTitle | label"
          [inputHeight]="'64px'"
          [fontSize]="'var(--main-info-font-size)'"
          [roundBorders]="true"
          [borderRadius]="'calc(var(--main-info-font-size) * 3)'"
          [labelMargin]="'1rem'"
          [backgroundColor]="environment.brandStyleVariables.inputSecondaryBackgroundColor"
          [brandColor]="environment.brandStyleVariables.mainBrandColor"
          [placeholderLabel]="labelService.contactusGeneralInfoEmailPlaceholder | label">
        </app-single-line-text-input>

        @if ((form.get('generalInfoEmail')?.touched
          && (form.get('generalInfoEmail')?.hasError('required')
          || form.get('generalInfoEmail')?.hasError('minlength')))) {
          <span class="input-error"
            >
            <img src="assets/imgs/error-icon.svg">
            {{labelService.myAccountDetailsErrorField | label}}
          </span>
        }
        @if ((form.get('generalInfoEmail')?.touched
          && form.get('generalInfoEmail')?.hasError('pattern'))) {
          <span class="input-error"
            >
            <img src="assets/imgs/error-icon.svg">
            {{labelService.myAccountDetailsErrorTextField | label}}
          </span>
        }
      </div>

      <div class="contact-us-form-control">
        <app-textarea-input
          [control]="generalInfoMessageControl"
          [formControlName]="'generalInfoMessage'"
          [label]="labelService.contactusGeneralInfoMessageTitle | label"
          [labelMargin]="'1rem'"
          [borderRadius]="'33px'"
          [shouldGrow]="true"
          [charLength]="150"
          [rowSize]="3"
          [showCharacterCount]="false"
          [maxCharactersLabel]="''"
          [backgroundColor]="environment.brandStyleVariables.inputSecondaryBackgroundColor"
          [brandColor]="environment.brandStyleVariables.mainBrandColor"
          [placeholder]="labelService.contactusGeneralInfoMessagePlaceholder | label">
        </app-textarea-input>

        @if ((form.get('generalInfoMessage')?.touched
          && (form.get('generalInfoMessage')?.hasError('required')
          || form.get('generalInfoMessage')?.hasError('minlength')))) {
          <span class="input-error"
            >
            <img src="assets/imgs/error-icon.svg">
            {{labelService.myAccountDetailsErrorField | label}}
          </span>
        }
        @if ((form.get('generalInfoMessage')?.touched
          && form.get('generalInfoMessage')?.hasError('pattern'))) {
          <span class="input-error"
            >
            <img src="assets/imgs/error-icon.svg">
            {{labelService.myAccountDetailsErrorTextField | label}}
          </span>
        }
      </div>

      <app-generic-button [buttonName]="labelService.contactusGeneralInfoSubmitButton | label" [minHeight]="'64px'"
        [backgroundColor]="environment.brandStyleVariables.mainBrandColor"
        [brandColor]="environment.brandStyleVariables.mainBrandColor" [fontColor]="environment.brandStyleVariables.mainBackgroundColor"
        [buttonSize]="'244px'" [borderRadius]="'66px'" [fontSize]="'var(--small-font-size)'" [fontWeight]="'700'"
        [disabledBackgroundColor]="environment.brandStyleVariables.mainBrandColorLight" [isDisabled]="!form.valid"
        submit>
      </app-generic-button>
    </form>
  </div>

  <div class="form-image">
    <img [src]="'assets/mock/contact-us/contact-us-general-form-nft.png'" alt="form-nft">
  </div>
</div>

