<div class="table-header"
  [ngStyle]="{ 'padding': padding }">
  @for (header of headerList; track $index) {
    <div class="header-container" aria-hidden="true"
      [ngStyle]="{
        'width': fullSize ? header.sizes?.size + '%' : header.sizes?.sizeSmall + '%',
        'justify-content': header.styles?.textAlign === 'left' ? 'flex-start' : (header.styles?.textAlign === 'right' ? 'flex-end' : 'center'),
        'cursor': clickable? 'pointer': 'auto'
      }" (click)="clickHeader(header.id)">
      @if (fullSize || header.sizes!.sizeSmall) {
        @if (header.checkBoxEnabled && $index === 0 && detailsClosed) {
          <div class="checkbox-container">
            <form [formGroup]="itemForm">
              <app-checkbox [brandColor]="brandColor" [disabled]="disableCheckbox" [control]="itemCheckControl"  [disabledBackgroundColor]="disabledCheckboxColor"
                [formControlName]="'itemCheck'" [gapSize]="''" [flexDirection]="''" [activeCheckboxColor]="activeCheckboxColor" [minHeight]="'var(--smallest-font-size)'">
              </app-checkbox>
            </form>
          </div>
        }
        <span [ngStyle]="{
          'font-size': header.styles?.fontSize ? header.styles?.fontSize : 'var(--font-size)',
          'color': header.styles?.fontColor,
          'font-weight': header.styles?.fontWeight,
          'text-align': header.styles?.textAlign,
          'padding': header.styles?.padding
        }">{{header.name }}</span>
        @if (showArrows && header.name !== '') {
          <div class="sort-arrows-container"
            [ngStyle]="{ 'font-size': header.styles?.fontSize ? header.styles?.fontSize : 'var(--font-size)' }">
            <div class="up-arrow" [ngClass]="{'toggle': header.id === sortFilter}"></div>
            <div class="down-arrow" [ngClass]="{'toggle': header.id === -sortFilter}"></div>
          </div>
        }
      }
    </div>
  }
</div>
