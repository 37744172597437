<div class="transactions-container">
  <!-- <div class="transaction-charts-container mb-5" *ngIf="charts">
  <div class="transaction-charts-labels-container mb-5">
    <div class="title">
      <p>{{ labelService.transactionsDashboardTitle | label }}</p>
    </div>
    <div class="description">
      <p>{{ labelService.transactionDashboardDescription | label }}</p>
    </div>
  </div>

  <app-dashboard-container [charts]="charts">
  </app-dashboard-container>
</div> -->

<div class="transaction-list-wrapper">
  <div class="transaction-list-labels-container">
    <div class="title">
      <p>{{ labelService.transactionsListTitle | label }}</p>
    </div>
    <div class="description">
      <p>{{ labelService.transactionListDescription | label }}</p>
    </div>
  </div>

  @if (transactionsList.length > 0 || isListLoading) {
    <div class="mt-5" class="transaction-list-container">
      @if (headerListItems.length > 0 && !(generalService.isMobile | async)!) {
        <app-list-header [fullSize]="!(generalService.isMobile | async)!"
          [fontSize]="'var(--header-font-size)'" [fontColor]="environment.brandStyleVariables.mainBorderColor" [headerList]="headerListItems"
        [showArrows]="false"></app-list-header>
      }
      <div class="transaction-list"
        infiniteScroll
        [infiniteScrollDistance]="listPercentageFromBottom"
        [infiniteScrollThrottle]="50"
        [immediateCheck]="true"
        [scrollWindow]="false"
        [infiniteScrollContainer]="mainComponent.scrollableContainerRef"
        (scrolled)="onScroll()"
        #transactionListContainer>
        @if (transactionsList.length > 0 || isListLoading) {
          @for (item of transactionsList; track item) {
            <app-list-item-expandable [fontSize]="'var(--header-font-size)'" [expandableFontSize]="'var(--small-font-size)'" [item]="item" [expandableItem]="item.infoExpandable!"
              [expandableHeader]="expandableListHeaderItems" [headerFontWeight]="'600'" [backgroundOpacity]="'1'" [fullSize]="!(generalService.isMobile | async)!"
              [isMobile]="(generalService.isMobile | async)!"[expandableBackgroundOpacity]="'0%'" [backgroundOpacity]="'10%'" [stateActiveColor]="'#5ED788'" [statePendingColor]="'#ff3e3e'" [stateDisabledColor]="'#FFB84D'"
              [borderRadius]="'var(--smallest-font-size)'" [showId]="false">
            </app-list-item-expandable>
          }
        }
        @if (isListLoading) {
          <!-- TODO Skeleton -->
          <!-- <ng-container *ngFor="let item of loadingListItems">
          <app-loading-list-item class="loading-item">
            <ng-template>
              <app-product-loading-content></app-product-loading-content>
            </ng-template>
          </app-loading-list-item>
        </ng-container> -->
      }
    </div>
  </div>
} @else {
  <div class="not-items-found">
    <app-no-items [image]="'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/empty-list-image.png'" [title]="labelService.transactionListNoItems | label"></app-no-items>
  </div>
}
</div>
</div>
