<!-- ##################### Product Description Banner ##################### -->
<div class="product-description-banner mt-5" [ngStyle]="{'flex-flow' : flexFlow}">
  <div class="product-info-container" [ngStyle]="{'width': productInfo.mainProductImageUrl ? '45%' : '100%'}">
    @if (productInfo.mainProductLogoUrl) {
      <img class="main-product-logo" [src]="productInfo.mainProductLogoUrl">
    }

    <p class="product-title mt-5">{{ productInfo.mainProductTitle }}</p>
    @if (productInfo.mainProductDescriptionHTML) {
      <div class="product-description" [innerHTML]="productInfo.mainProductDescriptionHTML | safehtml"></div>
    }

    @if (productInfo.productCertificationsImageUrl) {
      <img class="product-certifications mt-5" [src]="productInfo.productCertificationsImageUrl">
    }
  </div>

  @if (productInfo.mainProductImageUrl) {
    <img class="main-product-image" [src]="productInfo.mainProductImageUrl">
  }
</div>

<!-- ##################### Product Preview Banner ##################### -->
@if (productInfo.productPreviewBannerImageUrl) {
  <img class="product-preview-banner" [src]="productInfo.productPreviewBannerImageUrl">
}
