<div class="input-container w-100">
  <div class="d-flex align-items-center">
    @if (label) {
      <label [for]="control"
        [ngClass]="{'mb-1': !labelMargin, 'error': control.touched && !control.valid}"
        [ngStyle]="{'margin-bottom': labelMargin}">{{ label }}</label>
    }
    @if (hasTooltip) {
      <app-tooltip class="ms-2" [textContent]="toolTipText" [ngStyle]="{'margin-bottom': labelMargin}"/>
    }
  </div>
  <div class="position-relative w-100">
    @if (hasPrefix) {
      <div class="prefix">
        @if (prefixClassName) {
          <i class="prefix-icon" [ngClass]="prefixClassName"></i>
        }@else {
          <img class="prefix-image" loading="lazy" alt="" [src]="prefixImageSrc">
        }
      </div>
    }
    <input
      #textInput class="w-100"
      [type]="!isPassword ? 'text' : hideValue ? 'password' : 'text'"
      [ngClass]="{
        'error': control.touched && !control.valid,
        'password': isPassword, 'ps-5': alignLeft, 'disabled': isDisabled,
      }" [placeholder]="placeholderLabel? placeholderLabel : ''"
      [formControl]="control!" [maxlength]="maxChars"
      [value]="inputValue" (input)="onInput($event)" (keydown)="checkKeyPressed($event)" (blur)="onFocusOut(); onTouched()">

    @if (hasSuffix) {
      <div class="suffix" aria-hidden="true" [ngClass]="{'alignLeft': alignLeft, 'active': suffixToggle && !isSuffixIcon}" (click)="suffixToggled()">
        @if (isSuffixIcon) {
          <i class="suffix-image" [ngClass]="suffixClassName"></i>
        } @else {
          <span>{{ suffixToggle ? inactiveSuffixLabel : activeSuffixLabel }}</span>
        }
      </div>
    }
  </div>
</div>
