import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, DestroyRef, InputSignal, OnInit, OutputEmitterRef, WritableSignal, effect, inject, input, output, signal } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, ReactiveFormsModule, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import * as lpn from 'google-libphonenumber';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { FormInputItemStatus, FormInputListItem } from 'src/app/pages/activities/activities.model';
import { environment } from 'src/environments/environment';
import { DynamicFormModel, DynamicInput, InputType, Option, WidthOptions } from '../activity-type-input.model';
import { CommonModule } from '@angular/common';
import { LabelPipe } from 'src/app/_shared-modules/label-pipe-module/label.pipe';
import { GenericButtonComponent } from 'src/app/_generic-components-lib/inputs/buttons/generic-button/generic-button.component';
import { noWhiteSpaceValidator } from 'src/app/_shared-modules/form-validators-module/validators/noWhiteSpace_validators';
import { phoneValidator } from 'src/app/_generic-components-lib/inputs/generic-phone-input/validators/generic-phone-input.validator';
import { SingleLineTextInputComponent } from 'src/app/_generic-components-lib/inputs/text-inputs/single-line-text-input/single-line-text-input.component';
import { TextareaInputComponent } from 'src/app/_generic-components-lib/inputs/textarea-input/textarea-input.component';
import { NumericInputComponent } from 'src/app/_generic-components-lib/inputs/numeric-inputs/numeric-input/numeric-input.component';
import { GenericPhoneInputComponent } from 'src/app/_generic-components-lib/inputs/generic-phone-input/generic-phone-input.component';
import { CheckboxComponent } from 'src/app/_generic-components-lib/inputs/checkbox-input/checkbox.component';
import { asapScheduler, take } from 'rxjs';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'app-activity-input-form-popup',
  templateUrl: './activity-input-form-popup.component.html',
  styleUrls: ['./activity-input-form-popup.component.sass'],
  animations: [
    trigger('ZoomIn', [
      state('hide', style({
        opacity: '0',
        transform: 'translate(-50%, -50%) scale(0.75, 0.75)'
      })),
      state('show', style({
        opacity: '1',
        transform: 'translate(-50%, -50%) scale(1, 1)'
      })),
      transition('show => hide',  animate('300ms ease-in-out')),
      transition('hide => show',  animate('300ms ease-in-out'))
    ]),
    trigger('SlideIn', [
      state('hide', style({
        opacity: 0,
        transform: 'translate(-50%, 100%)'
      })),
      state('show', style({
        opacity: 1,
        transform: 'translate(-50%, -50%)'
      })),
      transition('show => hide',  animate('300ms ease-in-out')),
      transition('hide => show',  animate('300ms ease-in-out'))
    ]),
    trigger('BackdropFadeIn', [
      state('hide', style({
        opacity: '0',
      })),
      state('show', style({
        opacity: '0.32',
      })),
      transition('hide <=> show',  animate('300ms ease-in-out')),
    ]),
  ],
  standalone: true,
  imports: [
    CommonModule,
    LabelPipe,
    ReactiveFormsModule,
    SingleLineTextInputComponent,
    TextareaInputComponent,
    NumericInputComponent,
    GenericPhoneInputComponent,
    CheckboxComponent,
    GenericButtonComponent
  ]
})
export class ActivityInputFormPopupComponent implements OnInit {
  // #region - Injections
    private destroyRef = inject(DestroyRef);
    public labelService: LabelsService = inject(LabelsService);
    private fb: FormBuilder = inject(FormBuilder);
    public generalService: GeneralService = inject(GeneralService);
  // #endregion - Injections


  // #region - Inputs
    public form: InputSignal<FormInputListItem> = input.required<FormInputListItem>();
  // #endregion - Inputs


  // #region - Outputs
    public popUpClosed: OutputEmitterRef<boolean> = output<boolean>();
    public formSubmited: OutputEmitterRef<{id?: string | null, form: FormGroup}> = output<{id?: string | null, form: FormGroup}>();
    public userInputRemoved: OutputEmitterRef<string> = output<string>();
  // #endregion - Outputs

  public editing: WritableSignal<boolean> = signal<boolean>(false);
  public isLoaded: WritableSignal<boolean> = signal<boolean>(false);
  public isMobile: WritableSignal<boolean> = signal<boolean>(false);

  public animationState: WritableSignal<string> = signal<string>('hide');
  public mobileAnimationState: WritableSignal<string> = signal<string>('hide');

  public dynamicForm: WritableSignal<FormGroup<DynamicFormModel>> = signal<FormGroup<DynamicFormModel>>(new FormGroup({}));

  public environment = environment;
  private phoneUtil = lpn.PhoneNumberUtil.getInstance();

  public widthOptions = WidthOptions;
  public inputType = InputType;
  public formInputItemStatus = FormInputItemStatus;


  constructor() {
    this.generalService.isMobile.subscribe((resp: boolean) => { this.isMobile.set(resp)});

    effect(() => {
      if (this.form()) {
        this.form().inputs.forEach(input => {
          this.setOptionChecked(input);
        });
      }
    });
  }

  ngOnInit(): void {
    if (this.form().status === this.formInputItemStatus.CREATING) {
      this.editing.set(true);

      this.initForm();
    }

    this.isLoaded.set(true);

    asapScheduler.schedule(() => {
      if (this.isMobile()) {
        this.mobileAnimationState.set('show');
      } else {
        this.animationState.set('show');
      }
    });
  }

  /**
   * Function to handle the popup's close animations.
   */
  public closePopUp(): void {
    if(this.isMobile()) {
      this.mobileAnimationState.set('hide');
    } else {
      this.animationState.set('hide');
    }

    this.generalService.asyncFunction(
      () => {
        this.popUpClosed.emit(true);
      }, 300)
      .pipe(take(1), takeUntilDestroyed(this.destroyRef))
      .subscribe();
  }

  /**
   * Inits form inputs and validators
   */
  private initForm(): void {
    let formGroup: DynamicFormModel = {};

    this.form().inputs?.forEach((input) => {
      // add to form group each input
      const control: DynamicFormModel = this.getFormControlFromInput(input);

      // since the control already has its string index, we just use the same index and get the control from it
      formGroup[input.order] = control[input.order];
    });

    this.dynamicForm.set(this.fb.group(formGroup));
  }

  /**
   * This function receives an input that can be one of many types (string, number, date, phone etc)
   * and creates either a FormControl or a FormGroup with FormControls inside depending on whats needed.
   * Each FormControl should be complete with its validators.
   *
   * @param { DynamicInput } input - This object contains information about the input field such as type, value,
   * maxLength, required, and options for radio buttons or checkboxes.
   * @returns { DynamicFormModel } - Can be either a FormControl or a FormGroup depending on the input type
   */
  private getFormControlFromInput(input: DynamicInput): DynamicFormModel {
    const validators: Array<ValidatorFn> = [];
    let control: FormControl<unknown> = new FormControl();

    // first, set common validators
    if (input.required) {
      validators.push(Validators.required);
      validators.push(noWhiteSpaceValidator());
    }

    if (input.maxLength) {
      validators.push(Validators.maxLength(input.maxLength));
    }

    switch (input.type) {
      case InputType.SINGLE_LINE:
        control = new FormControl<string | null>((input.value as string) || null, { validators: validators });
        break;

      case InputType.TEXT_AREA:
        control = new FormControl<string | null>((input.value as string) || null, { validators: validators });
        break;

      case InputType.NUMBER:
        control = new FormControl<number | null>((input.value as number) || null, { validators: validators });
        break;

      case InputType.PHONE_NUMBER:
        let parsedNumber;

        if (input.value) {
          try {
            parsedNumber = this.phoneUtil.parseAndKeepRawInput(input.value as string);
          } catch {
            input.value = '+351' + input.value;
            parsedNumber = this.phoneUtil.parseAndKeepRawInput(input.value);
          }
        }

        const countryCode = parsedNumber?.getCountryCode() ? '+' + parsedNumber.getCountryCode() : '+351';

        // remove country code from phone number
        const phoneNumber = input.value ? (input.value as string).split(countryCode)[1].trim() : null;

        const countryCodeFC = new FormControl<string | null>(countryCode, []);
        validators.push(phoneValidator(countryCodeFC));

        const phoneInputFC = new FormControl<string | null>(phoneNumber, { validators: validators });

        // since its going to return something other than a FormControl, it returns directly inside the switch case
        return {
          [input.order]: new FormGroup<any>({
            'phoneInput': phoneInputFC,
            'countryCode': countryCodeFC
          })
        };
        break;

      case InputType.EMAIL:
        validators.push(Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+([.][a-zA-Z]{2,})+$"));
        control = new FormControl<string | null>((input.value as string) || null, { validators: validators });
        break;

      case InputType.RADIO_GROUP:
        // TODO
        break;

      case InputType.CHECKBOX:
        control = new FormControl<string | null>((input.value as string) || null, { validators: validators });

        const selectedOption = input.options?.find(opt => opt.id === input.value);
        if (selectedOption) {
          selectedOption.isChecked = true;
        }
        break;

      case InputType.TOGGLE:
        // TODO
        break;
    }

    return { [input.order]: control };
  }

  /**
   * Checks if input is of type CHECKBOX and selects the correct value
   * @param { DynamicInput } input
   */
  private setOptionChecked(input: DynamicInput): void {
    if (input.type === this.inputType.CHECKBOX) {
      const selectedOption = input.options?.find(opt => opt.id === input.value);
      if (selectedOption)
        selectedOption.isChecked = true;
    }
  }

  /**
   * This function, takes the list of available options and sets the selected option checked state
   * to the correct one (based on the selectedOption checked state).
   *
   * @param { Option } selectedOption - Option that the user seleceted (or deselected)
   * @param { DynamicInput } input - This object contains information about the input field such as type, value,
   * maxLength, required, and options for radio buttons or checkboxes.
   * @param { Array<Option> } options - list of all available options
   */
  public addCheckboxValueToOptions(selectedOption: Option, input: DynamicInput, options: Array<Option>): void {
    const inputToUse = this.dynamicForm().controls[input.order];

    options.forEach(opt => {
      if (opt.id !== selectedOption.id) {
        opt.isChecked = false;
      }
    });

    selectedOption.isChecked = !selectedOption.isChecked;
    inputToUse.patchValue(!selectedOption.isChecked ? null : selectedOption.id);
  }

  /**
   * Emit submited form so the parent component can use it
   */
  public saveForm(): void {
    this.formSubmited.emit({
      id: this.form().userInputId,
      form: this.dynamicForm()
    });
  }

  /**
   * Emit the id of the form (userInputId) to the parent component to use it in a API call
   */
  public removeUserInput(): void {
    this.userInputRemoved.emit(this.form().userInputId!);
  }

  public startEditing(): void {
    this.editing.set(true);

    this.initForm();
  }
}
