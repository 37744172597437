<div class="outside-dropdown" aria-hidden="true" (click)="clickOutside($event);"></div>

<div class="dropdown aux" id="dropdownContainer"></div>
@if (dropdownOpen) {
  <div #dropdownContainer aria-hidden="true" class="dropdown custom-scroll" [@simpleFadeAnimation]="'in'"
    [ngClass]="{'show-from-above' : showDropdownAbove}" (click)="$event.stopPropagation()">

    @if (dropdownOptions.length !== 0) {
      @for (option of dropdownOptions; track $index) {
        <div>
          @if (option.name!.toLowerCase()) {
            <div class="option-item"
              [ngClass]="{'selected': option.selected, 'justify-content-center': !checkIcon, 'no-icon': !checkIcon }" (click)="toggleOptionSelected(option.id!)">
              <span class="d-flex align-items-center" [ngClass]="!checkIcon? 'title': 'name'">{{option.name}}</span>
              @if (option.selected && !activeText && checkIcon) {
                <i class="check-icon bi bi-check-lg"></i>
              }

              @if (activeText && option.active) {
                <span class="branding-font-color active-text">{{ 'Active' }}</span>
              }

              @if (activeText && !option.active) {
                <span class="inactive-text">{{ 'Inactive' }}</span>
              }
            </div>
          }
        </div>
      }
    } @else {
      <div class="option-item">
        <span class="title">{{ noResultsLabel }}</span>
      </div>
    }
  </div>
}
