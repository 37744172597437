import { AbstractControl, FormControl, ValidatorFn } from '@angular/forms';
import * as lpn from 'google-libphonenumber';

export function phoneValidator(countryCodeControl: FormControl): ValidatorFn {
  return (phoneControl: AbstractControl): Record<string, unknown> | null => {
    if (!phoneControl && !countryCodeControl) {
      return null;
    }

    const phoneUtil: lpn.PhoneNumberUtil = lpn.PhoneNumberUtil.getInstance();
    let countryCode: string;
    let phoneNumber: string;

    phoneNumber = phoneControl ? phoneControl?.value : null;
    countryCode = countryCodeControl ? countryCodeControl?.value.replace('+', '') : null;

    if (phoneNumber) {
      let parsedNumber: lpn.PhoneNumber;

      try {
        parsedNumber = phoneUtil.parseAndKeepRawInput('+' + countryCode + phoneNumber);
      } catch (error) {
        console.error(error);
        // Return null if there is an error parsing the number
        return { validatePhoneNumber: { valid: false } };
      }

      countryCode = phoneUtil.getRegionCodeForNumber(parsedNumber) ? phoneUtil.getRegionCodeForNumber(parsedNumber) + '' : 'PT' ;
    }

    if (!countryCode || !phoneNumber) {
      return null;
    }

    let parsedNumber: lpn.PhoneNumber;

    try {
      parsedNumber = phoneUtil.parseAndKeepRawInput(phoneNumber, countryCode);
    } catch (error) {
      console.error(error);
      // Return null if there is an error parsing the number
      return { validatePhoneNumber: { valid: false } };
    }

    const nationalSignificantNumber: string = phoneUtil.getNationalSignificantNumber(parsedNumber);

    // Check if input starts with the selected country code, and remove it if it does
    if (nationalSignificantNumber.startsWith(countryCode)) {
      phoneNumber = nationalSignificantNumber.slice(countryCode.length);
    }

    try {
      const isValidNumber: boolean = phoneUtil.isValidNumberForRegion(parsedNumber, countryCode);

      if (isValidNumber) {
        return null;
      }
    } catch (error) {
      if ((error as Error).name === 'PhoneNumberError') {
        return { validatePhoneNumber: { valid: false } };
      } else {
        // Re-throw the error if it's not a PhoneNumberError
        throw error;
      }
    }

    return { validatePhoneNumber: { valid: false } };
  };
}
