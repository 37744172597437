import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { ProductInfo } from '../our-products.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { environment } from 'src/environments/environment';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { CommonModule } from '@angular/common';
import { LabelPipe } from '../../../_shared-modules/label-pipe-module/label.pipe';

@Component({
  selector: 'app-known-for-banner',
  templateUrl: './known-for-banner.component.html',
  styleUrls: ['./known-for-banner.component.sass'],
  standalone: true,
  imports: [
    CommonModule,
    LabelPipe
  ]
})
export class KnownForBannerComponent implements OnInit {

  @Input() products: Array<ProductInfo>;

  constructor(
    private generalService: GeneralService,
    public labelsService: LabelsService,
    private host: ElementRef
  ) { }

  ngOnInit(): void {
    this.setCSSVars();
  }

  public scrollToProdcut(productName: string): void {
    this.generalService.autoScrollTop(productName, true);
  }

  private setCSSVars(): void {
    this.host.nativeElement.style.setProperty('--pseudo-element-color', environment.brandStyleVariables.pseudoElementHighlightColor);
  }
}
