import { CommonModule } from '@angular/common';
import { Component, ElementRef, Inject } from '@angular/core';
import { MAT_SNACK_BAR_DATA, MatSnackBarModule, MatSnackBarRef } from '@angular/material/snack-bar';
import { CustomSnackBarData } from '../__models/snackbar/snackbar.model';
import { LibraryService } from '../__services/library.service';
import { SnackbarBaseComponent } from '../_extended-components/snackbar-base-component/snackbar-base.component';

@Component({
  standalone: true,
  selector: 'app-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: [
    '../_base-component/base.component.sass',
    '../_extended-components/snackbar-base-component/snackbar-base.component.sass',
    './snackbar.component.sass'
  ],
  imports: [
    CommonModule,
    MatSnackBarModule
  ]
})
export class SnackbarComponent extends SnackbarBaseComponent {

  public showPrefixIcon: boolean = false;
  public prefixIconUrl: string;

  public message: string;
  public showCloseIcon: boolean;

  public showAction: boolean = false;
  public actionText: string;

  public snackbarClass: string;

  constructor(
    @Inject(MAT_SNACK_BAR_DATA) public data: CustomSnackBarData,
    public snackBarRef: MatSnackBarRef<SnackbarComponent>,
    public override host: ElementRef,
    public override libraryService: LibraryService) {
    super(host, libraryService);
    super.setCSSVars();
    this.snackbarClass = data.snackbarClass;

    this.showPrefixIcon = data.prefixIconUrl != null && data.prefixIconUrl !== '';
    this.prefixIconUrl = data.prefixIconUrl;

    this.message = data.message;
    this.showCloseIcon = data.showCloseIcon;
    if (data.action) {
      this.showAction = true;
      this.actionText = data.action;
    }
  }

  public closeSnackbar(): void {
    this.snackBarRef.dismiss();
  }

  public ActionClicked(): void {
    // TODO
    this.closeSnackbar();
  }

}
