
<div class="main-page-container">
  @if (isLoaded) {
    <!-- ####### NAVBAR & MENU ####### -->
    <header>
      <app-navbar></app-navbar>
    </header>
    @if (showRoute) {
      <div class="page-router-container custom-scroll" id="scrollable-container" #ScrollableContainer>
        <!-- ####### Main router ####### -->
        @if (openNavigation) {
          <router-outlet></router-outlet>
        }
        <!-- ####### Footer ####### -->
        <footer>
          <app-footer></app-footer>
        </footer>
      </div>
    }
    @if (hasPopups && popupToShow) {
      <app-generic-popup
        [closeClickOutside]="popupToShow.closeClickOutside!"
        [preImgTitle]="popupToShow.preImgTitle!"
        [image]="popupToShow.image!"
        [title]="popupToShow.title!"
        [descriptions]="popupToShow.descriptions!"
        [buttons]="popupToShow.buttons!"
        [enableXButton]="popupToShow.enableXButton ?? false"
        [buttonsVertical]="popupToShow.buttonsVertical!"
        (popUpClosed)="popupClosed()"
        (buttonClicked)="popupActionClicked($event)">
      </app-generic-popup>
    }
    @if (showOnboarding) {
      <div class="onboarding-wrapper">
        <app-onboarding-container (close)="closeOnboarding()"></app-onboarding-container>
      </div>
    }
  }

  @if (!isLoaded && errorLoading) {
    <!-- TODO Check what to do in this case -->
    {{errorToShow}}
  }

  <!-- ####### TutorialPopup ####### -->
  @if (showTutorial) {
    <app-tutorial-popup (close)="showTutorial = false"></app-tutorial-popup>
  }


  <!-- ####### Loading ####### -->
  @if (isLoading) {
    <app-main-loading class="loading"></app-main-loading>
  }

</div>
