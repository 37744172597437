
import {
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { BorderStyleRadius } from '../../__models/_base/base-component.model';
import { DropdownOption } from '../../__models/dropdown/dropdown.model';
import { LibraryService } from '../../__services/library.service';
import { BaseComponent } from '../../_base-component/base.component';

@Component({
  standalone: true,
  selector: 'app-dropdown-base-component',
  template: '',
  styleUrls: [
    '../../_base-component/base.component.sass',
    './dropdown-base.component.sass',
  ],
  imports: [ReactiveFormsModule, BaseComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DropdownBaseComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: DropdownBaseComponent,
    },
    LibraryService,
  ],
})
export class DropdownBaseComponent extends BaseComponent implements ControlValueAccessor, Validator
{
  @HostListener('wheel', ['$event'])
  public handleWheelEvent(event: WheelEvent) {
    event.stopPropagation();
  }

  @Input() public suffixSize: string;
  @Input() public suffixPadding: string;
  @Input() public suffixColor: string;
  @Input() public suffixBackground: string;

  @Input() public optionToSelect?: unknown;

  @Input() public borderStyleRadius: BorderStyleRadius;

  @Input() public dropdownHeight: string;
  @Input() public labelWeight: number;
  @Input() public labelMargin: string;

  @Input() public checkIcon: boolean;
  @Input() public disabled: boolean;

  @Input() public control: FormControl;

  @Input() public inputPlaceholder: string;
  @Input() public noResultsLabel: string = 'No results found';

  @Input() public clearSelected: boolean = false;
  @Input() public dropdownOptions: Array<DropdownOption>;

  @Output() public optionSelected: EventEmitter<DropdownOption> =
    new EventEmitter<DropdownOption>();
  @Output() public optionsSelected: EventEmitter<Array<unknown>> =
    new EventEmitter<Array<unknown>>();
  @Output() public closeDropdown: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  public inputValue?: string = '';

  public onChange: <T>(option: T) => void;
  public onTouched: () => void;
  public onValidatorChange: () => void;

  constructor(
    public override host: ElementRef,
    public override libraryService: LibraryService
  ) {
    super(host, libraryService);
    super.setCSSVars();
    this.setSpecificCSSVars();
  }

  public writeValue<T>(value: T): void {
    this.optionToSelect = value;
  }

  public registerOnChange(onChange: () => void): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars, @typescript-eslint/no-empty-function
  public setDisabledState(isDisabled: boolean): void {}

  public registerOnValidatorChange(onValidatorChange: () => void): void {
    this.onValidatorChange = onValidatorChange;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public validate(control: AbstractControl): ValidationErrors | null {
    const errors: ValidationErrors = {};

    return errors;
  }

  public setSpecificCSSVars(): void {
    if (
      this.dropdownHeight ||
      this.labelWeight ||
      this.labelMargin ||
      this.suffixSize ||
      this.suffixPadding ||
      this.suffixColor ||
      this.suffixBackground ||
      this.borderStyleRadius ||
      this.placeholderStyle
    ) {
      //set variables
      if (this.labelWeight)
        this.host.nativeElement.style.setProperty(
          '--placeholder-weight',
          this.labelWeight
        );
      if (this.dropdownHeight)
        this.host.nativeElement.style.setProperty(
          '--dropdown-height',
          this.dropdownHeight
        );
      if (this.labelMargin)
        this.host.nativeElement.style.setProperty(
          '--label-margin',
          this.labelMargin
        );
      if (this.suffixSize)
        this.host.nativeElement.style.setProperty(
          '--suffix-size',
          this.suffixSize
        );
      if (this.suffixPadding)
        this.host.nativeElement.style.setProperty(
          '--suffix-padding',
          this.suffixPadding
        );
      if (this.suffixColor)
        this.host.nativeElement.style.setProperty(
          '--suffix-color',
          this.suffixColor
        );
      if (this.suffixBackground)
        this.host.nativeElement.style.setProperty(
          '--suffix-background',
          this.suffixBackground
        );
      if (this.borderStyleRadius) this.setBorderRadiusStyles();
      if (this.placeholderStyle) this.setPlaceholderStyles();
    }
  }

  private setBorderRadiusStyles(): void {
    if (this.borderStyleRadius.borderTopLeftRadius)
      this.host.nativeElement.style.setProperty(
        '--border-top-left-radius',
        this.borderStyleRadius.borderTopLeftRadius
      );
    if (this.borderStyleRadius.borderTopRightRadius)
      this.host.nativeElement.style.setProperty(
        '--border-top-right-radius',
        this.borderStyleRadius.borderTopRightRadius
      );
    if (this.borderStyleRadius.borderBottomLeftRadius)
      this.host.nativeElement.style.setProperty(
        '--border-bottom-left-radius',
        this.borderStyleRadius.borderBottomLeftRadius
      );
    if (this.borderStyleRadius.borderBottomRightRadius)
      this.host.nativeElement.style.setProperty(
        '--border-bottom-right-radius',
        this.borderStyleRadius.borderBottomRightRadius
      );
  }

  private setPlaceholderStyles(): void {
    if (this.placeholderStyle.fontSize)
      this.host.nativeElement.style.setProperty(
        '--placeholder-font-size',
        this.placeholderStyle.fontSize
      );
    if (this.placeholderStyle.fontStyle)
      this.host.nativeElement.style.setProperty(
        '--placeholder-font-style',
        this.placeholderStyle.fontStyle
      );
    if (this.placeholderStyle.fontWeight)
      this.host.nativeElement.style.setProperty(
        '--placeholder-font-weight',
        this.placeholderStyle.fontWeight
      );
    if (this.placeholderStyle.color)
      this.host.nativeElement.style.setProperty(
        '--placeholder-color',
        this.libraryService.convertToHexColor(
          this.host,
          this.placeholderStyle.color
        )
      );
  }
}
