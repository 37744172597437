@if (showLabel) {
  <div class="steps-label">{{ labelsService.onboardingStepsLabel | label: { currentStep: currentStep + 1, totalSteps: totalSteps } }}</div>
}

<div class="steps-container">
  @for (step of stepsList; track step; let index = $index) {
    @if (index > 0) {
      <span class="pre-step-line" [ngClass]="{'active': index <= currentStep }"></span>
    }
    <div class="step" [ngClass]="{'active': index <= currentStep }">
      {{ index + 1 }}
    </div>
  }
</div>
