import { DestroyRef, Directive, HostListener, Input, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Observable, mergeMap, of, take, timer } from 'rxjs';

@Directive({
  selector: '[dirSingleClick]',
  standalone: true
})
export class SingleClickDirective {

  @Input() public delayTime: number;

  private destroyRef = inject(DestroyRef);

  private isClicked = false;

  @HostListener('click', ['$event'])
  @HostListener('touch', ['$event'])
  public onClick(event: Event) {
    if (!this.isClicked) {
      this.isClicked = true;

      this.oldAsyncFunction(() => {
        this.isClicked = false;
      }, this.delayTime).pipe(take(1), takeUntilDestroyed(this.destroyRef)).subscribe();

    } else {
      event.preventDefault();
      event.stopPropagation();
    }
  }

  private oldAsyncFunction(delayedFn: () => unknown, delay: number = 0): Observable<unknown> {
    const delayObservable: Observable<number> = timer(delay);

    // Use mergeMap to call the function when the delay is over
    const resultObservable: Observable<unknown> = delayObservable.pipe(
      mergeMap(() => {
        const result: unknown = delayedFn();

        // Return the result as the next value in the observable
        return of(result);
      })
    );

    // Subscribe to start the observable
    return resultObservable;
  }
}
