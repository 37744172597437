import myLocaleEN from '@angular/common/locales/en';
import myLocalePT from '@angular/common/locales/pt';
import { enableProdMode, Provider, APP_INITIALIZER, ApplicationConfig } from '@angular/core';

import { environment } from './environments/environment';
import { bootstrapApplication } from '@angular/platform-browser';
import { AuthGuardKeycloak } from './app/_guards/AuthKeycloak.guard';
import { AuthGuard } from './app/_guards/auth.guard';
import { KeycloakService, KeycloakEventType } from 'keycloak-angular';
import { AppComponent } from './app/app.component';
import { appConfig } from './app/app.config';
import { registerLocaleData } from '@angular/common';

function initializeKeycloak(keycloak: KeycloakService) {
  return async () => {
    try {
      keycloak.keycloakEvents$.subscribe({
        next: (e) => {
          if (e.type == KeycloakEventType.OnTokenExpired) {
            console.log('token Expired, trying to revalidate..');
            keycloak.updateToken(30).then(resp => {
              console.log('Token revalidation:', resp ? 'success revalidating!' : 'failed revalidation');

              if (!resp) {
                console.log('clearing token');
                localStorage.removeItem(environment.keycloakConfig.clientId + '-jwt');
                keycloak.clearToken();
                window.location.reload();
              }
            }).catch(err => {
              console.log('Failed to refresh the token - ', err);
            });
          }
        }
      });

      await keycloak.init({
        config: environment.keycloakConfig,
        loadUserProfileAtStartUp: true,
        initOptions: {
          onLoad: 'check-sso',
          checkLoginIframe: false,
          silentCheckSsoRedirectUri:
            window.location.origin + '/assets/keycloak/silent-check-sso.html',
          enableLogging: true
        }
      });

      if (keycloak.getKeycloakInstance().token) {
        localStorage.setItem(environment.keycloakConfig.clientId + '-jwt', keycloak.getKeycloakInstance().token!);
      }


    } catch (error) {
      console.log(error);
    }
  }
}

let providers: Array<Provider> = [
  environment.useKeycloak ? AuthGuardKeycloak : AuthGuard
];

if (environment.useKeycloak) {
  providers.push([
    KeycloakService,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    }
  ]);
}


if (environment.production) {
  enableProdMode();
}

document.getElementById('appTitle')!.innerHTML = environment.pageTitle;

registerLocaleData(myLocalePT);
registerLocaleData(myLocaleEN);

const applicationConfig: ApplicationConfig = appConfig;

applicationConfig.providers.push(providers);

bootstrapApplication(AppComponent, appConfig)
  .catch((err) => console.error(err));


/* bootstrapApplication(AppComponent, {
  providers: [
    ...providers,
    provideLottieOptions({
      player: () => player,
    }),
    importProvidersFrom(
      AppRoutingModule,
      BrowserModule,
      SwiperModule,
      ButtonsModule,
      RadioButtonsInputModule,
      CheckboxInputModule,
      SingleLineTextInputModule,
      SnackbarsModule,
      GenericPopupModule,
      FormsModule,
      DropdownsModule,
      ProfileModule,
      TermsAndConditionsModule,
      OurProductsModule,
      JwtModule.forRoot({
        config: {
          tokenGetter: () => localStorage.getItem(environment.keycloakConfig.clientId + '-jwt')
        }
      }),
      TranslateModule.forRoot({
        loader: {
            provide: TranslateLoader,
            useFactory: HttpLoaderFactory,
            deps: [HttpClient]
        },
        isolate: true
      })
    ),
    provideHttpClient(withInterceptorsFromDi()),
    provideAnimations()
  ]
}).catch(err => console.error(err));
 */
