import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { PopupService } from 'src/app/_services/popup-service.service';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { WalletConnectionService } from '../wallet-services/connections/wallet-connection.service';
import { environment } from 'src/environments/environment';

import { LabelPipe } from '../../../../_shared-modules/label-pipe-module/label.pipe';
import { GenericPopupFromBottomComponent } from 'src/app/_generic-components-lib/popups/generic-popup-from-bottom/generic-popup-from-bottom.component';

@Component({
  selector: 'app-wallet-sidebar',
  templateUrl: './wallet-sidebar.component.html',
  styleUrls: ['./wallet-sidebar.component.sass'],
  animations: [
    trigger('SideBarSlide', [
      state('hide', style({
        transform: 'translateX(100%)',
      })),
      state('show', style({
        transform: 'translateX(0)'
      })),
      transition('hide <=> show', animate('600ms ease-in')),
    ]),
  ],
  standalone: true,
  imports: [
    GenericPopupFromBottomComponent,
    LabelPipe
]
})
export class WalletSidebarComponent implements OnInit, AfterViewInit {

  @Input() close: boolean;

  @Input() profilePicture: string;

  @Output() isClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

  animationState: string = 'hide';

  openPrompt: boolean;

  constructor(
    private host: ElementRef,
    public walletService: WalletConnectionService,
    private generalService: GeneralService,
    public labelService: LabelsService,
    private popUpService: PopupService
    ) { }

  ngOnInit(): void {
    this.setCSSVars();
    this.generalService.oldAsyncFunction(() => {this.animationState = 'show'}, 0);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['close']) {
      if (changes['close'].currentValue) {
        this.animationState = 'hide';
        this.generalService.oldAsyncFunction(() => this.isClosed.emit(true), 600);
      }
    }
  }

  ngAfterViewInit(): void {
    let w3AuthModalContainer = document.getElementsByClassName('w3a-parent-container')[0];
    let walletListContainer = document.getElementsByClassName('walletconnect-container')[0];

    walletListContainer?.appendChild(w3AuthModalContainer);
  }

  openWeb3Auth(): void {
    this.walletService.walletLogin();
  }

  closeSideBar(): void {
    this.animationState = 'hide';
    let walletListContainer = document.getElementsByClassName('walletconnect-container')[0];
    if(walletListContainer.getElementsByClassName('w3a-header__button').length > 0){
      (walletListContainer.getElementsByClassName('w3a-header__button')[0] as HTMLElement).click();
    }

    this.generalService.oldAsyncFunction(() => {
      document.getElementsByTagName('body')[0]?.appendChild(walletListContainer);
      this.isClosed.emit(true);
    }, 600);
  }

  public openDisconnectPrompt(): void {
    this.openPrompt = true;
  }

  public disconnectWallet(): void {
    this.popUpService.setPopupToOpen(
      {
        text: ''
      },
      'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/popup-warning-image.png',
      {text: 'Logout'},
      [this.popUpService.getSimpleDescription("Are you sure you wan't to logout?")],
      [{
        text: 'Logout',
        isCloseBtn: false,
        actionText: 'walletLogout',
        style: {
          backgroundColor: 'var(--main-brand-color)',
          fontColor: '#ffffff'
        }
      },{
        text: 'Cancel',
        isCloseBtn: true,
        actionText: '',
        style: {
          backgroundColor: 'rgba(38, 159, 152, 0.15)',
          fontColor: 'var(--main-brand-color)'
        }
      }]
    );
  }

  setCSSVars(): void {
    if(this.profilePicture)
      this.host.nativeElement.style.setProperty('--picture-placeholder', `url("${this.profilePicture}")`);
  }
}
