import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Rive } from "@rive-app/canvas";
import { take } from 'rxjs';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { environment } from 'src/environments/environment';
import { TutorialService } from '../_services/tutorial.service';
import { ImageType, TutorialStep } from '../tutorial.model';
import { CommonModule } from '@angular/common';
import { LabelPipe } from 'src/app/_shared-modules/label-pipe-module/label.pipe';
import { LottieComponent } from 'ngx-lottie';
import { GenericButtonComponent } from 'src/app/_generic-components-lib/inputs/buttons/generic-button/generic-button.component';


@Component({
  selector: 'app-tutorial-popup',
  templateUrl: './tutorial-popup.component.html',
  styleUrls: ['./tutorial-popup.component.sass'],
  animations: [
    trigger('ZoomIn', [
      state('hide', style({
        opacity: '0',
        transform: 'translate(-50%, -50%) scale(0.75, 0.75)'
      })),
      state('show', style({
        opacity: '1',
        transform: 'translate(-50%, -50%) scale(1, 1)'
      })),
      transition('show => hide',  animate('300ms ease-in-out')),
      transition('hide => show',  animate('300ms ease-in-out'))
    ]),
    trigger('BackdropFadeIn', [
      state('hide', style({
        opacity: '0',
      })),
      state('show', style({
        opacity: '1',
      })),
      transition('hide <=> show',  animate('300ms ease-in-out')),
    ]),
  ],
  standalone: true,
  imports: [
    CommonModule,
    LabelPipe,
    GenericButtonComponent,
    LottieComponent
  ]
})
export class TutorialPopupComponent implements OnInit {
  @ViewChild('canvasRive', { static: false }) public canvasRive: ElementRef;

  @Output() private close: EventEmitter<boolean> = new EventEmitter<boolean>();

  public environment = environment;

  public tutorialSteps: Array<TutorialStep>;
  public currentStep: number = 0;
  public totalSteps: number = 0;

  public loaded: boolean = false;

  public popupAnimationState: string = 'hide';
  public imageType = ImageType;

  constructor(
    private tutorialService: TutorialService,
    public generalService: GeneralService,
    public labelService: LabelsService,
  ) {
  }


  ngOnInit(): void {
    this.tutorialService.getTutorialInfo().pipe(take(1)).subscribe(tutorialSteps => {
      this.tutorialSteps = tutorialSteps;
      this.tutorialSteps[0].selectedStep = true;

      this.totalSteps = tutorialSteps.length - 1;

      this.changeStep(0);
      this.loaded = true;
      this.generalService.oldAsyncFunction(() => {
        this.popupAnimationState = 'show';
      }, 0);
    });
  }

  public closeTutorial(): void {
    this.popupAnimationState = 'hide';

    this.generalService.oldAsyncFunction(() => {
      this.close.emit(true);
    }, 300);
  }

  public changeStep(increment: number): void {
    this.currentStep += increment;
    this.currentStep = Math.min(this.currentStep, this.totalSteps);

    this.tutorialSteps.forEach((step, index) => step.selectedStep = index === this.currentStep);

    if (this.tutorialSteps[this.currentStep].image.type === this.imageType.RIVE) {
      this.initiateRiveAnimation();
    }
  }

  private initiateRiveAnimation(): void {
    this.generalService.oldAsyncFunction(() => {
      const r = new Rive({
        src: this.tutorialSteps[this.currentStep].image.url,
        canvas: this.canvasRive.nativeElement,
        autoplay: true,
        onLoad: () => {
          r.resizeDrawingSurfaceToCanvas();
        },
      });

      window.addEventListener('resize', () => {
        r.resizeDrawingSurfaceToCanvas();
      });
    }, 0);
  }

  public skipTutorial(): void {
    this.closeTutorial();
  }
}
