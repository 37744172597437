import { Injectable } from "@angular/core";
import { NativeDateAdapter } from "@angular/material/core";

@Injectable({
  providedIn: 'root',
})
export class CustomDateAdapter extends NativeDateAdapter {

  public override createDate(year: number, month: number, date: number): Date {
    const newDate: Date = new Date(year, month, date);
    return super.parse(newDate)!;
  }

  public override addCalendarYears(date: Date, years: number): Date {
    return this.addCalendarMonths(date, years * 12);
  }

  public override addCalendarMonths(date: Date, months: number): Date {
    let newDate: Date = this.createDateWithOverflow(this.getYear(date), this.getMonth(date) + months, this.getDate(date));

    // It's possible to wind up in the wrong month if the original month has more days than the new
    // month. In this case we want to go to the last day of the desired month.
    // Note: the additional + 12 % 12 ensures we end up with a positive number, since JS % doesn't
    // guarantee this.
    if (this.getMonth(newDate) !== ((this.getMonth(date) + months) % 12 + 12) % 12) {
      newDate = this.createDateWithOverflow(this.getYear(newDate), this.getMonth(newDate), 0);
    }

    return newDate;
  }

  private createDateWithOverflow(year: number, month: number, date: number) {
    const result: Date = new Date(year, month, date);

    // We need to correct for the fact that JS native Date treats years in range [0, 99] as
    // abbreviations for 19xx.
    if (year >= 0 && year < 100) {
      result.setFullYear(this.getYear(result) - 1900);
    }
    return result;
  }

  public override getYear(date: Date): number {
    return super.getYear(date);
  }
}
