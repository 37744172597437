export default {
  "name": "EIP-3675",
  "number": 3675,
  "comment": "Upgrade consensus to Proof-of-Stake",
  "url": "https://eips.ethereum.org/EIPS/eip-3675",
  "status": "Final",
  "minimumHardfork": "london",
  "requiredEIPs": [],
  "gasConfig": {},
  "gasPrices": {},
  "vm": {},
  "pow": {}
};
