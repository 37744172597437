<div class="carousel-container">
  @if (content.smallCarouselContent && content.mainCarouselContent.length > 0) {
    <div class="carousel-main-container swiper-container">

      <swiper-container class="carousel-swiper" #swiperRef swiperJS init="false" [config]="swiperConfig">
        @for (mainContentItem of content.mainCarouselContent; track mainContentItem; let index = $index) {
          <swiper-slide class="carousel-item-container">
            <div class="carousel-item-wrapper">
              <img [src]="mainContentItem.imageUrl">
              <div class="carousel-item-info-container">
                <div class="carousel-item-info">
                  @if (mainContentItem.type) {
                    <div class="item-type">
                      @switch (mainContentItem.type) {
                        @case (CarouselType.COLLECTIONS) {
                          <p class="swiper-no-swiping">{{'Collections' | translate}}</p>
                        }
                        @case (CarouselType.NEWS) {
                          <p class="swiper-no-swiping">{{'News' | translate}}</p>
                        }
                        @case (CarouselType.NFT) {
                          <p class="swiper-no-swiping">{{'NFT' | translate}}</p>
                        }
                        @case (CarouselType.BLOG) {
                          <p class="swiper-no-swiping">{{'Blog' | translate}}</p>
                        }
                      }
                    </div>
                  }
                  <div class="carousel-item-title swiper-no-swiping">
                    <p>{{mainContentItem.title}}</p>
                  </div>
                </div>
                <div class="carousel-item-button-container">
                  <div class="carousel-item-button" (click)="carouselSlideAction(mainContentItem.type!, mainContentItem.id)">
                    <img [src]="mainContentItem.iconUrl">
                  </div>
                </div>
              </div>
              @if (mainContentItem.badgeIconUrl) {
                <div class="carousel-top-right-icon-container">
                  <img [src]="mainContentItem.badgeIconUrl">
                </div>
              }
            </div>
          </swiper-slide>
        }
      </swiper-container>

      <!-- ########################## OLD SWIPER ########################## -->
      <!-- <swiper #swiperRef class="carousel-swiper"
        [slidesPerView]="'auto'"
        [spaceBetween]="30"
        [loop]="content.mainCarouselContent.length > 1 ? true : false"
        [loopedSlides]="content.mainCarouselContent.length > 1 ? 2 : 0"
        [navigation]="false"
        [pagination]="false"
        [centeredSlides]="true"
      [breakpoints]="{
        '320': {
          spaceBetween: 18
        },
        '760': {
          spaceBetween: 30
        },
        '1200': {
          initialSlide: 1
        }
      }"
      [autoplay]="{
        delay: 5000,
        disableOnInteraction: false,
        pauseOnMouseEnter: true,
        stopOnLastSlide: false
      }"
      >
      @for (mainContentItem of content.mainCarouselContent; track mainContentItem; let index = $index) {
        <ng-template swiperSlide class="carousel-item-container">
          <div class="carousel-item-wrapper">
            <img [src]="mainContentItem.imageUrl">
            <div class="carousel-item-info-container">
              <div class="carousel-item-info">
                @if (mainContentItem.type) {
                  <div class="item-type">
                    @switch (mainContentItem.type) {
                      @case (CarouselType.COLLECTIONS) {
                        <p class="swiper-no-swiping">{{'Collections' | translate}}</p>
                      }
                      @case (CarouselType.NEWS) {
                        <p class="swiper-no-swiping">{{'News' | translate}}</p>
                      }
                      @case (CarouselType.NFT) {
                        <p class="swiper-no-swiping">{{'NFT' | translate}}</p>
                      }
                      @case (CarouselType.BLOG) {
                        <p class="swiper-no-swiping">{{'Blog' | translate}}</p>
                      }
                    }
                  </div>
                }
                <div class="carousel-item-title swiper-no-swiping">
                  <p>{{mainContentItem.title}}</p>
                </div>
              </div>
              <div class="carousel-item-button-container">
                <div class="carousel-item-button" (click)="carouselSlideAction(mainContentItem.type!, mainContentItem.id)">
                  <img [src]="mainContentItem.iconUrl">
                </div>
              </div>
            </div>
            @if (mainContentItem.badgeIconUrl) {
              <div class="carousel-top-right-icon-container">
                <img [src]="mainContentItem.badgeIconUrl">
              </div>
            }
          </div>
        </ng-template>
      }
    </swiper> -->
  </div>
}

@if (content.smallCarouselContent && content.smallCarouselContent.length > 0 && environment.tenantName === 'GAMIFYOU') {
  <div class="carousel-small-container swiper-container">

    <swiper-container class="carousel-swiper" #swiperRef swiperJS init="false" [config]="gamifyouSwiperConfig">
      @for (smallContentItem of content.smallCarouselContent; track smallContentItem; let index = $index) {
        <swiper-slide class="carousel-item-container">
          <div class="carousel-item-wrapper">
            <img [src]="smallContentItem.imageUrl">
            <div class="carousel-item-info-container">
              <div class="carousel-item-info">
                <div class="carousel-item-title swiper-no-swiping">
                  <p>{{smallContentItem.title}}</p>
                </div>
              </div>
              <div class="carousel-item-button-container">
                <div class="carousel-item-button" (click)="carouselSlideAction(smallContentItem.type!, smallContentItem.id)">
                  <img [src]="smallContentItem.iconUrl">
                </div>
              </div>
            </div>
          </div>
        </swiper-slide>
      }
    </swiper-container>

    <!-- ########################## OLD SWIPER ########################## -->
    <!-- <swiper #swiperRef class="carousel-swiper"
      [centeredSlides]="true"
      [loop]="content.smallCarouselContent.length > 3 ? true : false"
      [loopedSlides]="content.smallCarouselContent.length > 3 ? 2 : 0"
      [speed]="500"
      [grabCursor]="true"
      [breakpoints]="{
        '320': {
          spaceBetween: 10
        },
        '760': {
          spaceBetween: 30
        },
        '1920': {
          initialSlide: 0
        }
      }"
    >
    @for (smallContentItem of content.smallCarouselContent; track smallContentItem; let index = $index) {
      <ng-template swiperSlide class="carousel-item-container">
        <div class="carousel-item-wrapper">
          <img [src]="smallContentItem.imageUrl">
          <div class="carousel-item-info-container">
            <div class="carousel-item-info">
              <div class="carousel-item-title swiper-no-swiping">
                <p>{{smallContentItem.title}}</p>
              </div>
            </div>
            <div class="carousel-item-button-container">
              <div class="carousel-item-button" (click)="carouselSlideAction(smallContentItem.type!, smallContentItem.id)">
                <img [src]="smallContentItem.iconUrl">
              </div>
            </div>
          </div>
        </div>
      </ng-template>
    }
    </swiper> -->
  </div>
}
</div>
