<app-page-banner
  [title]="mainBannerInfo.title ? mainBannerInfo.title : ''"
  [description]="mainBannerInfo.description ? mainBannerInfo.description : ''"
  [backgroundImageUrl]="mainBannerInfo.backgroundImageUrl ? mainBannerInfo.backgroundImageUrl : ''"
  [imageUrl]="mainBannerInfo.itemImageUrl ? mainBannerInfo.itemImageUrl : ''"
  [useGradient]="mainBannerInfo.useGradient"
  [pseudoElementColor]="environment.brandStyleVariables.pseudoElementHighlightColor"
  [containerHeight]="'36vh'" [imageSize]="'35%'">
  <!-- [backgroundImageUrl]="'assets/mock/contact-us/contact-us-hero.png'" [title]="labelService.contactusBannerTitle | label" [description]="labelService.contactusBannerDescription | label" -->
</app-page-banner>

<div class="content-wrapper">
  @if (subMenuList && subMenuList.length > 0) {
    <div class="menu-area-container">
      @for (menu of subMenuList; track menu) {
        <a class="contact-us-menu" [routerLink]="currentMenuUrl + menu.viewUrl" [routerLinkActive]="'active'" (click)="menuClicked()">
          <span>{{menu.name}}</span>
        </a>
      }
    </div>
  }

  <router-outlet></router-outlet>
</div>

@if (!(generalService.isMobile | async) && brandInfo && (brandInfo.brandHQAddress || brandInfo.brandHQMapImageUrl)) {
  <div class="address-banner">
    @if (brandInfo.brandHQAddress) {
      <div class="address-info-container">
        <div class="address-title">{{ labelService.contactUsHeadquartersAddressTitle | label }}</div> <!-- TODO -->
        <div class="address-info">
          @if (brandInfo.brandHQAddress.addressLine1) {
            <p>{{ brandInfo.brandHQAddress.addressLine1 }}</p>
          }
          @if (brandInfo.brandHQAddress.addressLine2) {
            <p>{{ brandInfo.brandHQAddress.addressLine2 }}</p>
          }
          @if (brandInfo.brandHQAddress.postalCode) {
            <p>
              {{
              brandInfo.brandHQAddress.postalCode
              + ( brandInfo.brandHQAddress.city ? ', ' + brandInfo.brandHQAddress.city : '' )
              + ( brandInfo.brandHQAddress.country ? ', ' + brandInfo.brandHQAddress.country : '' )
              }}
            </p>
          }
          @if (brandInfo.phoneContact) {
            <p>{{ brandInfo.phoneContact }}</p>
          }
          @if (brandInfo.emailContact) {
            <p>{{ brandInfo.emailContact }}</p>
          }
        </div>
      </div>
    }
    @if (brandInfo.brandHQMapImageUrl) {
      <img class="map-container" [src]="brandInfo.brandHQMapImageUrl" alt="headquarters location map">
    }
  </div>
}

