import { CommonModule } from '@angular/common';
import { } from '@angular/common/http';
import { Component, ElementRef, Input, OnChanges, OnInit, ViewChild } from '@angular/core';
import { FormGroupDirective, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { ToolTipComponent } from 'src/app/_generic-components-lib/tooltip/tooltip.component';
import { LibraryService } from '../../../__services/library.service';
import { InputBaseComponent } from '../../../_extended-components/input-base-component/input-base.component';

@Component({
  standalone: true,
  selector: 'app-single-line-text-input',
  templateUrl: './single-line-text-input.component.html',
  styleUrls: [
    '../../../_base-component/base.component.sass',
    '../../../_extended-components/input-base-component/input-base.component.sass',
    './single-line-text-input.component.sass'
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ToolTipComponent
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: SingleLineTextInputComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: SingleLineTextInputComponent
    },
    FormGroupDirective
  ]
})
export class SingleLineTextInputComponent extends InputBaseComponent implements OnInit, OnChanges {

  @ViewChild('textInput') public textInputComponent: ElementRef;

  @Input() public isPassword: boolean = false;
  @Input() public hasSuffix: boolean = false;
  @Input() public hasPrefix: boolean = false;
  @Input() public shouldHidePrefix: boolean = false;
  @Input() public isSuffixIcon: boolean; // Determines if suffix is icon or text
  @Input() public alignLeft: boolean;
  @Input() public prefixClassName: string;
  @Input() public prefixImageSrc: string;

  @Input() public inactiveSuffixLabel: string;
  @Input() public activeSuffixLabel: string;

  @Input() public activeSuffix: string;
  @Input() public inactiveSuffix: string;
  @Input() public maxChars: number = 999999;

  @Input() public hasTooltip: boolean = false;
  @Input() public toolTipText: string;

  public hideValue: boolean = false;
  public suffixToggle: boolean = false;

  public suffixClassName: string;

  constructor(
    public override host: ElementRef,
    public override libraryService: LibraryService
  ) {
    super(host, libraryService);
  }

  ngOnInit(): void {
    this.hideValue = this.isPassword;
    super.setCSSVars();
    super.setSpecificCSSVars();

    this.updateIcon();
  }

  ngOnChanges(): void {
    this.updateIcon();
  }

  //update icon class name with what comes from @input
  private updateIcon(): string {
    return this.suffixClassName = this.suffixToggle ? this.activeSuffix : this.inactiveSuffix;
  }

  public suffixToggled(): void {
    this.suffixToggle = !this.suffixToggle;

    if (this.isPassword)
      this.hideValue = !this.hideValue;

    this.updateIcon();
    this.suffixClicked.emit(this.suffixToggle);
  }

  public onInput(event: Event): void {
    if (event.target instanceof HTMLInputElement) {
      this.inputValue = event.target.value;
      this.onChange(this.inputValue);
    }
  }
}
