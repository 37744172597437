@for (accordionItem of accordions; track $index) {
  <div class="accordion-wrapper">
    <div class="accordion-header-container" aria-hidden="true" role="button" (click)="toggleAccordion(accordionItem)">
      <div class="id-container w-100">
        {{ $index < 10 ? "0" + ($index + 1) : $index + 1 }}
      </div>
      <div class="accordion w-100">
        <div class="title w-100">
          {{accordionItem.title}}
        </div>
        <div class="button-container" [ngClass]="{'active': accordionItem.animationState === 'show'}">
          <i class="bi bi-plus-lg"></i>
        </div>
      </div>
    </div>

    <div class="accordion-container" [@slideAnimation]="accordionItem.animationState">
      <div class="accordion-description" [innerHTML]="accordionItem.description"></div>
    </div>
  </div>
}
