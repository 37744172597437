<div class="side-menu-container" [@MenuSlide]="animationState">
  @if (generalService.isUserLoggedIn | async) {
    <div class="user-info-container"> <!-- (click)="goTo('my-account')"> -->
      <div class="user-info-wrapper">
        <div class="user-info-image-container" [ngClass]="{'active': isRouteActive}">
          <img [src]="(generalService.userDetails | async)?.images!.profilePictureImage?.href ? (generalService.userDetails | async)?.images!.profilePictureImage!.href : 'assets/imgs/environments/'+ environment.tenantLocalAssetsPath +'/default-images/default-profile-image.svg'" alt="user-profile-picture">
        </div>
        <div class="user-info-name-container">
          <p>{{(generalService.userDetails | async)?.firstName}} {{(generalService.userDetails | async)?.lastName}}</p>
        </div>
      </div>
      <!-- <div class="user-btn-container">
      <span></span>
    </div> -->
  </div>
}

<div class="menu-list-container">
  @for (menu of generalService.menuList; track menu) {
    @if (!menu.isHidden) {
      <a class="menu-container" [ngClass]="{'is-active': (generalService.instituionActive | async) && menu.viewUrl === '/institutions'}" [routerLink]="menu.viewUrl" [routerLinkActive]="'is-active'" (click)="goTo()">
        <p class="menu-name">{{menu.name}}</p>
        <!-- <div class="menu-arrow-container">
        <span class="menu-arrow"></span>
      </div> -->
    </a>
  }
}
</div>

@if (!(generalService.isUserLoggedIn | async) && environment.useKeycloak) {
  <div class="user-interaction-container">
    <app-generic-button
      [minHeight]="'unset'"
      [buttonName]="labelService.loginButton | label" [brandColor]="environment.brandStyleVariables.mainBrandColor" [backgroundColor]="environment.brandStyleVariables.mainBrandColor"
      [borderRadius]="'calc(var(--small-font-size) * 3)'" [buttonSpacing]="'3.55vw 1.5vw'" [fontWeight]="'800'" [fontSize]="'var(--smallest-font-size)'"
      [fontColor]="environment.brandStyleVariables.mainBackgroundColor" (buttonAction)="goToLogin()">
    </app-generic-button>
  </div>
}
@if ((generalService.isUserLoggedIn | async) && environment.useKeycloak) {
  <div class="user-interaction-container">
    <app-generic-button
      [minHeight]="'unset'"
      [buttonName]="labelService.logoutButton | label" [brandColor]="environment.brandStyleVariables.mainBrandColor" [backgroundColor]="environment.brandStyleVariables.mainBrandColor"
      [borderRadius]="'calc(var(--small-font-size) * 3)'" [buttonSpacing]="'3.55vw 1.5vw'" [fontWeight]="'800'" [fontSize]="'var(--smallest-font-size)'"
      [fontColor]="environment.brandStyleVariables.mainBackgroundColor" (buttonAction)="goToLogout()">
    </app-generic-button>
  </div>
}
</div>

<div class="backdrop-container" [@BackdropFadeIn]="animationState"></div>
