import _defineProperty from '@babel/runtime/helpers/defineProperty';
import _objectSpread from '@babel/runtime/helpers/objectSpread2';
import { SafeEventEmitter, WEB3AUTH_NETWORK } from '@web3auth/auth';
export { UX_MODE, WEB3AUTH_NETWORK } from '@web3auth/auth';
import { getChainConfig } from '../chain/config.js';
import { CHAIN_NAMESPACES } from '../chain/IChainInterface.js';
import { WalletInitializationError, WalletLoginError, WalletOperationsError } from '../errors/index.js';
import { WALLET_ADAPTERS } from '../wallet/index.js';
const ADAPTER_CATEGORY = {
  EXTERNAL: "external",
  IN_APP: "in_app"
};
const ADAPTER_STATUS = {
  NOT_READY: "not_ready",
  READY: "ready",
  CONNECTING: "connecting",
  CONNECTED: "connected",
  DISCONNECTED: "disconnected",
  ERRORED: "errored"
};
const ADAPTER_EVENTS = _objectSpread(_objectSpread({}, ADAPTER_STATUS), {}, {
  ADAPTER_DATA_UPDATED: "adapter_data_updated",
  CACHE_CLEAR: "cache_clear"
});
class BaseAdapter extends SafeEventEmitter {
  constructor(options = {}) {
    super();
    _defineProperty(this, "adapterData", {});
    _defineProperty(this, "sessionTime", 86400);
    _defineProperty(this, "clientId", void 0);
    _defineProperty(this, "web3AuthNetwork", WEB3AUTH_NETWORK.MAINNET);
    _defineProperty(this, "useCoreKitKey", undefined);
    _defineProperty(this, "rehydrated", false);
    // should be added in constructor or from setAdapterSettings function
    // before calling init function.
    _defineProperty(this, "chainConfig", null);
    _defineProperty(this, "knownChainConfigs", {});
    _defineProperty(this, "adapterNamespace", void 0);
    _defineProperty(this, "currentChainNamespace", void 0);
    _defineProperty(this, "type", void 0);
    _defineProperty(this, "name", void 0);
    _defineProperty(this, "status", void 0);
    this.setAdapterSettings(options);
  }
  get chainConfigProxy() {
    return this.chainConfig ? _objectSpread({}, this.chainConfig) : null;
  }
  get connnected() {
    return this.status === ADAPTER_STATUS.CONNECTED;
  }
  setAdapterSettings(options) {
    if (this.status === ADAPTER_STATUS.READY) return;
    if (options !== null && options !== void 0 && options.sessionTime) {
      this.sessionTime = options.sessionTime;
    }
    if (options !== null && options !== void 0 && options.clientId) {
      this.clientId = options.clientId;
    }
    if (options !== null && options !== void 0 && options.web3AuthNetwork) {
      this.web3AuthNetwork = options.web3AuthNetwork;
    }
    if ((options === null || options === void 0 ? void 0 : options.useCoreKitKey) !== undefined) {
      this.useCoreKitKey = options.useCoreKitKey;
    }
    const customChainConfig = options.chainConfig;
    if (customChainConfig) {
      if (!customChainConfig.chainNamespace) throw WalletInitializationError.notReady("ChainNamespace is required while setting chainConfig");
      this.currentChainNamespace = customChainConfig.chainNamespace;
      // chainId is optional in this function.
      // we go with mainnet chainId by default.
      const defaultChainConfig = getChainConfig(customChainConfig.chainNamespace, customChainConfig.chainId);
      // NOTE: It is being forced casted to CustomChainConfig to handle OTHER Chainnamespace
      // where chainConfig is not required.
      const finalChainConfig = _objectSpread(_objectSpread({}, defaultChainConfig || {}), customChainConfig);
      this.chainConfig = finalChainConfig;
      this.addChainConfig(finalChainConfig);
    }
  }
  checkConnectionRequirements() {
    // we reconnect without killing existing wallet connect session on calling connect again.
    if (this.name === WALLET_ADAPTERS.WALLET_CONNECT_V2 && this.status === ADAPTER_STATUS.CONNECTING) return;else if (this.status === ADAPTER_STATUS.CONNECTING) throw WalletInitializationError.notReady("Already connecting");
    if (this.status === ADAPTER_STATUS.CONNECTED) throw WalletLoginError.connectionError("Already connected");
    if (this.status !== ADAPTER_STATUS.READY) throw WalletLoginError.connectionError("Wallet adapter is not ready yet, Please wait for init function to resolve before calling connect/connectTo function");
  }
  checkInitializationRequirements() {
    if (!this.clientId) throw WalletInitializationError.invalidParams("Please initialize Web3Auth with a valid clientId in constructor");
    if (!this.chainConfig) throw WalletInitializationError.invalidParams("rpcTarget is required in chainConfig");
    if (!this.chainConfig.rpcTarget && this.chainConfig.chainNamespace !== CHAIN_NAMESPACES.OTHER) {
      throw WalletInitializationError.invalidParams("rpcTarget is required in chainConfig");
    }
    if (!this.chainConfig.chainId && this.chainConfig.chainNamespace !== CHAIN_NAMESPACES.OTHER) {
      throw WalletInitializationError.invalidParams("chainID is required in chainConfig");
    }
    if (this.status === ADAPTER_STATUS.NOT_READY) return;
    if (this.status === ADAPTER_STATUS.CONNECTED) throw WalletInitializationError.notReady("Already connected");
    if (this.status === ADAPTER_STATUS.READY) throw WalletInitializationError.notReady("Adapter is already initialized");
  }
  checkDisconnectionRequirements() {
    if (this.status !== ADAPTER_STATUS.CONNECTED) throw WalletLoginError.disconnectionError("Not connected with wallet");
  }
  checkAddChainRequirements(chainConfig, init = false) {
    if (!init && !this.provider) throw WalletLoginError.notConnectedError("Not connected with wallet.");
    if (this.currentChainNamespace !== chainConfig.chainNamespace) {
      throw WalletOperationsError.chainNamespaceNotAllowed("This adapter doesn't support this chainNamespace");
    }
  }
  checkSwitchChainRequirements({
    chainId
  }, init = false) {
    if (!init && !this.provider) throw WalletLoginError.notConnectedError("Not connected with wallet.");
    if (!this.knownChainConfigs[chainId]) throw WalletLoginError.chainConfigNotAdded("Invalid chainId");
  }
  updateAdapterData(data) {
    this.adapterData = data;
    this.emit(ADAPTER_EVENTS.ADAPTER_DATA_UPDATED, {
      adapterName: this.name,
      data
    });
  }
  addChainConfig(chainConfig) {
    const currentConfig = this.knownChainConfigs[chainConfig.chainId];
    this.knownChainConfigs[chainConfig.chainId] = _objectSpread(_objectSpread({}, currentConfig || {}), chainConfig);
  }
  getChainConfig(chainId) {
    return this.knownChainConfigs[chainId] || null;
  }
}
export { ADAPTER_CATEGORY, ADAPTER_EVENTS, ADAPTER_STATUS, BaseAdapter };