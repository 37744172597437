<div class="input-container textarea-container">
  @if (label) {
    <label [for]="control" [ngClass]="{
      'mb-1': !labelMargin,
      'error': control.touched && !control.valid
    }"[ngStyle]="{'margin-bottom': labelMargin}">{{ label }}</label>
  }

  <div class="textarea-border" [ngClass]="{'error': control.touched && !control.valid}">
    <textarea #textareaInput type="text" class="w-100 custom-scroll" [ngClass]="{'expand': shouldGrow}"
      [value]="inputValue" [placeholder]="placeholder" [rows]="rowSize" [maxLength]="charLength" [formControl]="control!"
      (input)="onInput($event)" (blur)="onFocusOut(); onTouched()"></textarea>
  </div>
  @if (showCharacterCount && charLength !== undefined) {
    <div class="character-counter">
      <p>{{ maxCharactersLabel }} <span>{{ inputValue.length }} / {{textareaInput.getAttribute('maxLength') }}</span></p>
    </div>
  }
</div>
