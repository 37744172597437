<!-- <div class="main-page-wrapper">
  <div class="main-page-container">
    <img [src]="'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/404-image.png'" alt="" class="main-page-image">

    <div class="text-container">
      <div class="error-number">{{ this.labelService.errorPageCode | label }}</div>

      <p class="page-title">{{ this.labelService.errorPageTitle | label }}</p>

      <p class="page-description mt-4">{{ this.labelService.errorPageDescription | label }}</p>
    </div>
  </div>
</div> -->


<div class="main-page-container">
  <div class="error-number-container">
    <p class="error-number">404</p>
    <img [src]="'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/404-image.png'" alt="" class="main-page-image">
  </div>
  <p class="page-title">{{ this.labelService.errorPageTitle | label }}</p>
  <p class="page-description mb-5">{{ this.labelService.errorPageDescription | label }}</p>
</div>
