import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { GenericErrorReply } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { ApiService } from 'src/app/_services/api.service';
import { ContactUsDTO, FaqDTO } from '../contact-us.model';
import { FormGroup } from '@angular/forms';
import { environment } from 'src/environments/environment';
import { Accordion } from 'src/app/_generic-components-lib/__models/accordion/accordion.model';

@Injectable({
  providedIn: 'root'
})
export class ContactUsService {

  constructor(
    private apiService: ApiService,
    private generalService: GeneralService) { }

  getFAQs(): Observable<Array<Accordion>> {
    return this.apiService.get(`contacts/faqs`, {}, '1.0', false, environment.useMockedData.contactUsGetFAQS).pipe(
      catchError(
        (error: HttpErrorResponse) => {
          this.generalService.isValidServerReply(error['error']);
          throw error['message'];
        }
      ),
      map((response: Array<FaqDTO> | GenericErrorReply) => {
        if(this.generalService.isValidServerReply(response)) {
          response = response as Array<FaqDTO>;

          const accordionDTO = response;
          const accordions = this.getFAQsFromDTO(accordionDTO);
          return accordions;
        } else {
          throw response;
        }
      })
    )
  }

  submitContactUsForm(form: FormGroup): Observable<any> {
    const input = this.getSubmitContactUsDTOFromForm(form);

    return this.apiService.post(`general/contact-us`, input, '1.0', true, environment.useMockedData.contactUsSubmit).pipe(
      catchError(
        (error: HttpErrorResponse) => {
          this.generalService.isValidServerReply(error['error']);
          throw error['message'];
        }
      ),
      map((response: null | GenericErrorReply) => {
        if(!response || this.generalService.isValidServerReply(response)) {
          return 'Success';
        } else {
          throw response;
        }
      })
    )
  }

  getSubmitContactUsDTOFromForm(form: FormGroup): ContactUsDTO {
    let dto: ContactUsDTO = {
      name: form.get('form')?.get('name')?.value,
      emailAddress: form.get('form')?.get('email')?.value,
      phoneNumber: form.get('form')?.get('phoneInput')?.value,
      message: form.get('form')?.get('message')?.value
    };

    return dto;
  }

  getFAQsFromDTO(dto: Array<FaqDTO>): Array<Accordion> {
    let accordionList: Array<Accordion> = [];

    dto.forEach((faqDto: FaqDTO) => {
      accordionList.push({
        id: faqDto.id,
        title: faqDto.title,
        description: faqDto.description,
        animationState: 'hide'
      });
    });

    return accordionList;
  }
}
