import { DOCUMENT } from '@angular/common';
import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, Inject, OnInit, ViewChild } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Observable, Subject, combineLatest, take } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GeneralService } from './_services/_general-service/general.service';
import { LabelsService } from './_services/_labels/labels.service';
import { ApiService } from './_services/api.service';
import { FirebaseAnalyticsService } from './_services/firebase/analytics/firebase-analytics.service';
import { PopupService } from './_services/popup-service.service';
import { WalletConnectionService } from './pages/_shared-components/wallet-connect-module/wallet-services/connections/wallet-connection.service';
import { NavbarComponent } from "./pages/_shared-components/navbar/navbar.component";
import { RouterModule } from '@angular/router';
import { FooterComponent } from "./pages/_shared-components/footer/footer.component";
import { OnboardingContainerComponent } from "./pages/onboarding/onboarding-container/onboarding-container.component";
import { TutorialPopupComponent } from "./pages/tutorial/tutorial-popup/tutorial-popup.component";
import { GenericPopupComponent } from './_generic-components-lib/popups/generic-popup/generic-popup.component';
import { GenericPopUp, PopupButtonAction } from './_generic-components-lib/__models/popups/popups.model';
import { MainLoadingComponent } from "./_loading/main-loading/main-loading.component";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.sass'],
  standalone: true,
  imports: [
    NavbarComponent,
    RouterModule,
    FooterComponent,
    GenericPopupComponent,
    OnboardingContainerComponent,
    TutorialPopupComponent,
    MainLoadingComponent
]
})
export class AppComponent implements OnInit, AfterViewInit {
  @ViewChild('ScrollableContainer', { static: false }) public scrollableContainerRef: ElementRef;

  public isLoaded: boolean = false;
  public errorLoading: boolean = false;
  public errorToShow: string = '';

  public isLoading: boolean;
  public openNavigation: boolean = false;

  public hasPopups: boolean = false;
  public popupToShow: GenericPopUp;

  public showRoute: boolean = false;
  public showOnboarding: boolean = false;
  public showTutorial: boolean = false;

  public environment = environment;

  constructor(
    private apiService: ApiService,
    private generalService: GeneralService,
    private labelService: LabelsService,
    private changeDetector : ChangeDetectorRef,
    private popupService: PopupService,
    private walletService: WalletConnectionService,
    private host: ElementRef,
    private firebaseAnalyticsService: FirebaseAnalyticsService,
    @Inject(DOCUMENT) private document: Document,
    private translateService: TranslateService
  ) {

    const sessionLanguage = localStorage.getItem('SessionLanguageCode');

    this.translateService.currentLang = environment.defaultLanguage;
    this.translateService.setDefaultLang(environment.defaultLanguage);
    this.translateService.use(environment.defaultLanguage);

    // Gets the current number of requests active and shows loading if there is any active
    apiService.numberRequests.subscribe(value => {
      this.isLoading = value > 0;
    });

    // When the displayed page changes, log it to firebase
    this.generalService.currentScreen.subscribe(change => {
      this.firebaseAnalyticsService.logEvents('page-' + (change === '' || change == null ? 'landing-page': change));
    });

    popupService.hasPopups.subscribe(value => {
      this.hasPopups = value;
      if (value && popupService.popUpsToShow.length > 0)
        this.popupToShow = popupService.popUpsToShow[0];
    });

    // Popup of warning for when mocked data is being used
    if (environment.showMockDataPopup) {
      this.popupService.setPopupToOpen(
        {
          text: 'WARNING'
        },
        'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/popup-warning-image.png',
        this.popupService.getTitle('Mocked data is being used!'),
        [this.popupService.getSimpleDescription('All data presented is mocked and should not be taken seriously.', )],
        [{
          text: 'OK',
          isCloseBtn: true,
          actionText: '',
          style: {
            backgroundColor: environment.brandStyleVariables.mainBrandColor,
            fontColor: environment.brandStyleVariables.mainBackgroundColor
          }
        }]
      );
    }
  }

  ngOnInit() {
    this.setCSSVars();
    if (environment.useKeycloak) {
      this.generalService.isUserLoggedIn$.next(this.generalService.keycloakService.isLoggedIn());

      this.initialSetup();
    } else {
      this.initialSetup();
    }

    document.addEventListener('visibilitychange', () => {
      if(!document.hidden) {
        this.refreshTokenOnTabChange();
      }
    });
  }

  ngAfterViewInit(): void {
    if(environment.useWallet) {
      this.walletService.initModal();
      this.walletService.checkMetamaskExtension();
    }
  }

  private initialSetup(): void {
    this.firebaseAnalyticsService.initializeFirebaseApp();

    this.getGeneralInfo().pipe(take(1)).subscribe({
      next: () => {
        this.isLoaded = true;
        this.generalService.oldAsyncFunction(() => {
          this.generalService.scrollableContainerRef = this.scrollableContainerRef;
          this.openNavigation = true;
          this.changeDetector.detectChanges();
        }, 0);

          const pendingTransactionId = localStorage.getItem('pendingTransactionId');

          if (pendingTransactionId) {
            const productId = localStorage.getItem('pendingTransactionProductId');

            const url = `/marketplace/product/${productId}/payment-page`;
            this.generalService.navigateTo(url);
          }
      }, error: (err) => {
        this.errorLoading = true;
        this.errorToShow = err;
      }
    });
  }

  private getGeneralInfo(): Observable<boolean> {
    const main$: Subject<boolean> = new Subject<boolean>();

    const languages$: Subject<boolean> = new Subject<boolean>();
    const labels$: Subject<boolean> = new Subject<boolean>();
    const generalInfo$: Subject<boolean> = new Subject<boolean>();
    const menuList$: Subject<boolean> = new Subject<boolean>();

    this.generalService.getLanguages().pipe(take(1)).subscribe(() => {
      languages$.next(true);
      this.labelService.getLabelsList().pipe(take(1)).subscribe(() => {
        labels$.next(true);
      }, err => {
        // TODO
        labels$.next(true);
      });
    });


    this.generalService.getGeneralInfo().pipe(take(1)).subscribe(() => {
      if(environment.useKeycloak && this.generalService.isUserLoggedIn$.value) {
        this.generalService.getUserDetails().pipe(take(1)).subscribe(userInfo => {

          // show tutorial popup based on user flag
          this.showTutorial = userInfo.showTutorial!;
          this.showOnboarding = userInfo.showOnboarding!;

          this.showRoute = !this.showOnboarding;

          generalInfo$.next(true);
        });
      } else {
        this.showRoute = true;
        generalInfo$.next(true);
      }
    });

    this.generalService.getMenus().pipe(take(1)).subscribe(() => {
      menuList$.next(true);
    });

    combineLatest([languages$.asObservable(), labels$.asObservable(), generalInfo$.asObservable(), menuList$.asObservable()]).subscribe(
      ([languages, labels, generalInfo, menuList]) => {
        main$.next(languages && labels && generalInfo && menuList);
    });

    return main$.asObservable();
  }

  public closeOnboarding(): void {
    this.showRoute = true;
    this.generalService.oldAsyncFunction(() => {
      this.showOnboarding = false;
    }, 1500);
  }

  public popupClosed(): void {
    this.popupService.removePopup();
  }

  public popupActionClicked(action: PopupButtonAction): void {
    // TODO
    switch(action.actionText) {
      case 'breakingError':
        window.location.reload();
        break;

      case 'newsLetterRegistered':
        (<HTMLInputElement>document.getElementById('newsletter-input')).value = "";
        this.popupClosed();
        break;

      case 'walletLogout':
        if(this.walletService.currentWallet === 'WalletConnect') {
          this.walletService.walletLogout();
        } else if (this.walletService.currentWallet === 'Metamask') {
          this.walletService.disconnectMetamask();
        }
        this.popupClosed();
        break;

      case 'transactions':
        this.generalService.navigateTo('/my-account/my-transactions');
        break;

      case 'goToVoucher':
        this.generalService.navigateTo(`/marketplace/product/${action.actionValue}`);
        break;

      case 'voucherHistory':
        this.generalService.navigateTo(`history/products/${action.actionValue}`);
        break;

      case 'login':
        this.generalService.userLogin();
        break;

      case 'cancel':
        this.popupClosed();
        break;

      case 'close':
        this.popupClosed();
        break;

      case 'logout':
        // in order to keep in the same page when loggedOut if not restricted
        // const currentNavigation = this.route.snapshot.firstChild?.data['restricted'];
        this.firebaseAnalyticsService.logEvents('logout-btn-clicked');
        // this.generalService.userLogout(currentNavigation ? window.location.origin : window.location.href);
        this.generalService.userLogout(window.location.origin);
        this.popupClosed();
        break;

      default:
        break;
    }
  }

  private refreshTokenOnTabChange(): void {
    if (this.generalService.keycloakService) {
      const isTokenExpired = this.generalService.keycloakService.isTokenExpired();

      if(isTokenExpired) {
        this.generalService.keycloakService.updateToken(30).then(resp => {
          console.log('Token revalidation:', resp ? 'success revalidating!' : 'failed revalidation');

          if (!resp) {
            console.log('clearing token');
            localStorage.removeItem(environment.keycloakConfig.clientId + '-jwt');
            this.generalService.keycloakService.clearToken();
            window.location.reload();
          }
        }).catch(err => {
          console.log('On tab focus failed to refresh the token - ', err);
        });
      }
    }
  }

  private setCSSVars(): void {
    this.host.nativeElement.style.setProperty('--brand-darker-color', environment.brandStyleVariables.brandDarkerColor);
    this.host.nativeElement.style.setProperty('--brand-lighter-color', environment.brandStyleVariables.brandLighterColor);
    this.host.nativeElement.style.setProperty('--main-brand-color', environment.brandStyleVariables.mainBrandColor);
    this.host.nativeElement.style.setProperty('--main-brand-color-light', environment.brandStyleVariables.mainBrandColorLight);
    this.host.nativeElement.style.setProperty('--main-brand-color-lighter', environment.brandStyleVariables.mainBrandColorLighter);
    this.host.nativeElement.style.setProperty('--main-brand-color-lightest', environment.brandStyleVariables.mainBrandColorLightest);
    this.host.nativeElement.style.setProperty('--main-error-color', environment.brandStyleVariables.mainErrorColor);
    this.host.nativeElement.style.setProperty('--main-warning-color', environment.brandStyleVariables.mainWarningColor);
    this.host.nativeElement.style.setProperty('--main-background-color', environment.brandStyleVariables.mainBackgroundColor);
    this.host.nativeElement.style.setProperty('--brand-background-color', environment.brandStyleVariables.brandBackgroundColor);
    this.host.nativeElement.style.setProperty('--footer-background-color', environment.brandStyleVariables.footerBackgroundColor);
    this.host.nativeElement.style.setProperty('--footer-button-background-color', environment.brandStyleVariables.footerButtonsColor);
    this.host.nativeElement.style.setProperty('--footer-main-font-color', environment.brandStyleVariables.footerTextColor);

    this.host.nativeElement.style.setProperty('--main-font-color', environment.brandStyleVariables.mainFontColor);
    this.host.nativeElement.style.setProperty('--secondary-font-color', environment.brandStyleVariables.secondaryFontColor);
  }
}
