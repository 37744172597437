import { CommonModule } from '@angular/common';
import { } from '@angular/common/http';
import { Component, DestroyRef, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, forwardRef, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MAT_RADIO_DEFAULT_OPTIONS, MatRadioModule } from '@angular/material/radio';
import { RadioButton } from '../../__models/buttons/buttons.model';
import { LibraryService } from '../../__services/library.service';
import { ButtonBaseComponent } from '../../_extended-components/button-base-component/button-base.component';
import { ToolTipComponent } from '../../tooltip/tooltip.component';

@Component({
  standalone: true,
  selector: 'app-radio-group',
  templateUrl: './radio-group.component.html',
  styleUrls: [
    '../../_base-component/base.component.sass',
    '../../_extended-components/button-base-component/button-base.component.sass',
    './radio-group.component.sass'
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    MatRadioModule,
    FormsModule,
    ReactiveFormsModule,
    ToolTipComponent,
  ],
  providers: [
    {
      provide: MAT_RADIO_DEFAULT_OPTIONS,
      useValue: { color: 'accent' },
    },
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => RadioGroupComponent)
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => RadioGroupComponent)
    },
  LibraryService
  ]
})
export class RadioGroupComponent extends ButtonBaseComponent<number> implements OnInit, OnChanges {
  private destroyRef = inject(DestroyRef);

  @Input({required: true}) public vertical: boolean;
  @Input({required: true}) public labelFirst: boolean;
  @Input() public control: FormControl;

  @Input({required: true}) public radioButtons: Array<RadioButton>;

  @Output() public valueChanged: EventEmitter<number> = new EventEmitter<number>();

  public selectedValue: number | undefined;

  public form = this.fb.group({
    option: new FormControl(null, [])
  });

  public override onChange: (inputValue: number) => void = (value: number) => {
    this.valueChanged.emit(value);
  };

  constructor(
    public override host: ElementRef,
    public override libraryService: LibraryService,
    private fb: FormBuilder
    ) {
    super(host, libraryService);

    this.form.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (form) => {
        this.selectedValue = form.option ? form.option : undefined;
        this.valueChanged.emit(this.selectedValue);
      }
    });
  }

  public override writeValue(value: string | number | undefined): void {
    this.optionControl.patchValue(value, {emitEvent: false});
  }

  ngOnInit(): void {
    const selectedOption: number | undefined = this.radioButtons.find(button => button.selected)?.value;
    this.writeValue(selectedOption);

    super.setCSSVars();
    super.setSpecificCSSVars();

    if (this.isDisabled) {
      this.control.disable();
    }
  }

  ngOnChanges(): void {
    if (this.isDisabled) {
      this.control.disable();
    } else {
      this.control.enable();
    }

    const selectedOption: number | undefined = this.radioButtons.find(button => button.selected)?.value;
    this.writeValue(selectedOption);
  }

  public get optionControl(): FormControl {
    return this.form.controls['option'];
  }

  public onValueChange(input: number): void {
    this.onChange(input);
  }
}
