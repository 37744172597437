import { animate, state, style, transition, trigger } from '@angular/animations';

import { Component, DestroyRef, ElementRef, inject, Input, OnChanges, OnInit, SimpleChanges, ViewChild } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroupDirective, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { mergeMap, Observable, of, take, timer } from 'rxjs';
import { DropdownOption } from '../../../__models/dropdown/dropdown.model';
import { LibraryService } from '../../../__services/library.service';
import { DropdownBaseComponent } from '../../../_extended-components/dropdown-base-component/dropdown-base.component';
import { NgClass } from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-generic-dropdown',
  templateUrl: './generic-dropdown.component.html',
  styleUrls: [
    '../../../_base-component/base.component.sass',
    './generic-dropdown.component.sass'
  ],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate(150)
      ]),
      transition(':leave',
        animate(150, style({ opacity: 0 })))
    ])
  ],
  imports: [
    NgClass,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: GenericDropdownComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: GenericDropdownComponent
    },
    FormGroupDirective
  ]
})
export class GenericDropdownComponent extends DropdownBaseComponent implements OnInit, OnChanges {
  private destroyRef = inject(DestroyRef);

  @ViewChild('dropdownContainer') public dropdownContainer: ElementRef;

  @Input() public isMultipleSelection: boolean = false;

  @Input() public activeText: boolean = false;

  @Input() public retrieveId: boolean = true; // This will allow for the option Id or name to be emitted based on the value of this

  public dropdownOpen: boolean = false;
  public showDropdownAbove: boolean = false; // TODO automatic turn on

  public selectedOption?: DropdownOption | Array<DropdownOption>;

  constructor(
    public override host: ElementRef,
    public override libraryService: LibraryService
    ) {
      super(host, libraryService);
    }

  ngOnInit(): void {
    super.setCSSVars();
    super.setSpecificCSSVars();

    this.dropdownOpen = true;

    const delayObservable: Observable<number> = timer(0);

    // Use mergeMap to call the function when the delay is over
    delayObservable.pipe(
      mergeMap(() => {
        const result: void = this.checkDropdownPosition();

        // Return the result as the next value in the observable
        return of(result);
      })
    ).pipe(takeUntilDestroyed(this.destroyRef)).subscribe();
  }

  public override writeValue<T>(value: T): void {
    if (this.isMultipleSelection) {
      if ((value as Array<DropdownOption>).length > 0) {
        (value as Array<DropdownOption>).forEach(selectedOption => {
          const option: number = this.dropdownOptions.findIndex(d => d.id === selectedOption.id);
          this.dropdownOptions[option].selected = true;
        });
      }
    } else if (value !== '') {
      this.dropdownOptions.forEach(op => op.selected = false);

      const option: number = this.dropdownOptions.findIndex(d => d.id === value);
      this.dropdownOptions[option].selected = true;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['optionToSelect']?.previousValue !== changes['optionToSelect']?.currentValue && changes['optionToSelect']?.previousValue !== undefined) {
      if (this.optionToSelect) {
        this.toggleOptionSelected((this.optionToSelect as DropdownOption).id);
      } else {
        this.clearSelection();
      }
    }
  }

  public toggleOptionSelected(optionId: number | string): void {
    if (this.isMultipleSelection) {
      this.selectedOption = [];

      this.dropdownOptions.forEach(option => {
        if (option.id === optionId) {
          option.selected = !option.selected;
        }

        if (option.selected) {
          (this.selectedOption as Array<DropdownOption>).push(option);
        }
      });

      this.optionsSelected.emit(this.selectedOption);

    } else {
      this.dropdownOptions.forEach(option => {
        if (option.id === optionId) {
          option.selected = true;
          this.selectedOption = option;
        } else {
          option.selected = false;
        }
      });

      this.optionSelected.emit(this.selectedOption as DropdownOption);
    }

  }

  public clickOutside(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.dropdownOpen = false;

    super.oldAsyncFunction(() => {
      this.closeDropdown.emit(true);
    }, 150).pipe(take(1), takeUntilDestroyed(this.destroyRef)).subscribe();
  }

  public clearSelection(): void {
    this.dropdownOptions.forEach((option: DropdownOption) => {
      option.selected = false;
    });
    this.selectedOption = undefined;
  }

  private checkDropdownPosition(): void {
    const dropdownRect: DOMRect = this.dropdownContainer.nativeElement.getBoundingClientRect();
    const isOnTop: boolean = dropdownRect.height >= window.innerHeight - dropdownRect.top - 15;
    this.showDropdownAbove = isOnTop;
  }
}
