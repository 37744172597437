<div class="banner-container" [ngClass]="{ 'small-banner-container': smallBanner && !(environment.showUserPointsInBanners && (generalService.isUserLoggedIn | async)),
  'points-banner': environment.showUserPointsInBanners && (generalService.isUserLoggedIn | async) }" [ngStyle]="{ 'min-height': containerHeight }">
  @if (useGradient) {
    <div class="banner-gradient"></div>
  }
  @if (backgroundImageUrl) {
    <img class="banner-background-image" [src]="backgroundImageUrl">
  }
  <div class="text-container">
    @if (title && !smallBanner) {
      <p class="banner-title">{{ title }}</p>
    }
    @if (title && smallBanner) {
      <span class="banner-title">{{ title }}</span>
    }
    @if (description && !(environment.showUserPointsInBanners && (generalService.isUserLoggedIn | async))) {
      <p class="banner-description">{{ description }}</p>
    }

    @if (environment.showUserPointsInBanners && (generalService.isUserLoggedIn | async)) {
      <div class="balance-container">
        <img [src]="'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/icons/balance-icon.svg'">
        <span class="points">{{ generalService.userDefaultWallet ? generalService.userDefaultWallet.currentValue : 0 }}</span>
        <span class="points-label">{{ labelsService.bannersBalanceLabel | label }}</span>
      </div>
    }
  </div>

  <div class="banner-image-container" [ngClass]="{ 'no-bottom-margin' : alignImageBottom }" [ngStyle]="{ 'width': imageSize }">
    @if (imageUrl) {
      <img [src]="imageUrl" [alt]="title">
    }
  </div>
</div>
