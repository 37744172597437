import { Directive, Input, Output, EventEmitter, OnInit, OnDestroy } from '@angular/core';
import { NgControl } from '@angular/forms';
import { debounceTime, distinctUntilChanged, filter } from 'rxjs/operators';
import { Subscription } from 'rxjs';

@Directive({
    selector: '[ngModel][onDebounce]',
    standalone: true,
})
export class DebounceDirective implements OnInit, OnDestroy {
  @Input() public debounceTime: number = 0;
  @Output() public onDebounce = new EventEmitter<any>();

  private subscription: Subscription;

  constructor(public model: NgControl) { }

  ngOnInit() {
    this.subscription =
    this.model.valueChanges!.pipe(
      filter(value => value != null),
      debounceTime(this.debounceTime),
      distinctUntilChanged())
        .subscribe(modelValue => {
          this.onDebounce.emit(modelValue);
        });
  }

  ngOnDestroy() {
    this.subscription.unsubscribe();
  }

}
