
import { Component, ElementRef, Input } from '@angular/core';
import { LibraryService } from '../../__services/library.service';
import { BaseComponent } from '../../_base-component/base.component';

@Component({
  standalone: true,
  selector: 'app-tabs-base-component',
  template: '',
  styleUrls: ['./tabs-base.component.sass'],
  imports: [BaseComponent],
  providers: [LibraryService]
})
export class TabsBaseComponent extends BaseComponent {

  @Input() public defaultBackground: string;
  @Input() public defaultLightBackground: string;
  @Input() public activeColor: string;

  @Input() public stepHeight: string;

  constructor(
    public override host: ElementRef,
    public override libraryService: LibraryService) {
    super(host, libraryService);
  }

  public setSpecificCSSVars(): void {
    if (this.defaultBackground || this.defaultLightBackground || this.stepHeight || this.activeColor) {
      if (this.defaultBackground)
        this.host.nativeElement.style.setProperty('--default-color', this.libraryService.convertToHexColor(this.host, this.defaultBackground));
      if (this.defaultLightBackground)
        this.host.nativeElement.style.setProperty('--default-color-lighter', this.libraryService.convertToHexColor(this.host,this.defaultLightBackground));
      if (this.stepHeight)
        this.host.nativeElement.style.setProperty('--step-height', this.stepHeight);
      if (this.activeColor)
        this.host.nativeElement.style.setProperty('--active-font-color', this.libraryService.convertToHexColor(this.host, this.activeColor));
    }
  }
}
