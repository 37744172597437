
<div class="sub-tasks-progress-container d-flex flex-column mb-3">
  @if (labelsService.activityMultiStepTitle | label) {
    <p class="title mb-2">{{ labelsService.activityMultiStepTitle | label }}</p>
  }
  @if (labelsService.activityMultiStepSubtitle | label) {
    <p class="subtitle mb-4">{{ labelsService.activityMultiStepSubtitle | label }}</p>
  }

  <div class="progress-wrapper">
    <div class="info-container">
      <p class="tasks-progress">
        {{ completedTasks + '/' + totalTasks + ' ' + ( labelsService.activityMultiStepTaskName | label)}}
      </p>

      @if (labelsService.activityMultiStepProgressBarTitle | label) {
        <p class="progress-bar-title">
          {{ labelsService.activityMultiStepProgressBarTitle | label }}
        </p>
      }

      <div class="progress-bar-container">
        <ng-progress
          #completedTasksProgressBar
          [id]="activity.activityId"
          [direction]="'ltr+'"
          [speed]="800"
          [debounceTime]="0"
          [spinner]="false"
          [min]="0"
          [max]="100">
        </ng-progress>

        <span> {{ activity.totalCompletedSubActivityPercentage }}%</span>
      </div>
    </div>

    @if (!isMobile) {
      <div class="animation-container">
        @switch (environment.animations.activityMultiStep.type) {
          <!-- Rive -->
          @case ('RIVE') {
            <canvas #canvasRive class="w-100">
            </canvas>
          }
          <!-- Lottie -->
          @case ('LOTTIE') {
            <ng-lottie #lottieAnimation
              class="w-100"
              [width]="'100%'"
              [height]="'100%'"
              [options]="lottieOptions"
              (animationCreated)="lottieAnimationItem = $event;">
            </ng-lottie>
          }
          <!-- GIF -->
          @case ('GIF') {
            <img class="w-100" [src]="environment.animations.activityMultiStep.url" alt="loading...">
          }
          <!-- Img -->
          @case ('IMAGE') {
            <img class="w-100" [src]="environment.animations.activityMultiStep.url" alt="">
          }
        }
      </div>
    }

  </div>

  <div class="check-tasks-button align-self-end">
    <app-generic-button
      [minHeight]="'unset'"
      [buttonName]="completedTasks > 0 ? (labelsService.activityMultiStepCheckTasksBtn | label) : (labelsService.activityMultiStepStartTasksBtn | label)" [borderRadius]="'50px'"
      [backgroundColor]="environment.brandStyleVariables.mainBrandColor"
      [brandColor]="'var(--main-brand-color)'" [fontColor]="environment.brandStyleVariables.mainBackgroundColor" [fontSize]="'var(--main-info-font-size)'"
      [buttonSpacing]="'calc(var(--main-info-font-size) * 1.1) calc(var(--main-info-font-size) * 3)'"
      [fontWeight]="'700'" (click)="checkTasks()">
    </app-generic-button>
  </div>
</div>
