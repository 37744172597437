import { animate, state, style, transition, trigger } from '@angular/animations';

import { Component, DestroyRef, ElementRef, EventEmitter, inject, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroupDirective, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { take } from 'rxjs';
import { Country } from '../../../__models/phone/phone-number.model';
import { LibraryService } from '../../../__services/library.service';
import { DropdownBaseComponent } from '../../../_extended-components/dropdown-base-component/dropdown-base.component';
import { FilterPipe } from 'src/app/_shared-modules/filter-pipe-module/filter.pipe';
import { NgClass } from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-country-dropdown',
  templateUrl: './country-dropdown.component.html',
  styleUrls: ['./country-dropdown.component.sass'],
  animations: [
    trigger('simpleFadeAnimation', [
      state('in', style({ opacity: 1 })),
      transition(':enter', [
        style({ opacity: 0 }),
        animate(150)
      ]),
      transition(':leave',
        animate(150, style({ opacity: 0 })))
    ])
  ],
  imports: [
    NgClass,
    FormsModule,
    ReactiveFormsModule,
    FilterPipe
  ],
  providers: [FormGroupDirective]
})
export class CountryDropdownComponent extends DropdownBaseComponent implements OnInit, OnChanges {

  @Input() public checkIconUrl: string;

  @Input() public countryList: Array<Country>;
  @Input() public countryToSelected: Country;

  @Input() public placeholder: string;

  @Input() public retrieveISO: boolean = true; // This will allow for the option Id or name to be emitted based on the value of this

  @Output() public countrySelected: EventEmitter<string> = new EventEmitter<string>();

  public dropdownOpen: boolean = false;
  public showDropdownAbove: boolean = false; // TODO automatic turn on

  public searchQuery: string = '';

  public selectedOption?: string;

  private destroyRef = inject(DestroyRef);

  public override onChange: <T>(option: T) => void;

  constructor(
    public override host: ElementRef,
    public override libraryService: LibraryService
  ) {
    super(host, libraryService);
  }

  ngOnInit(): void {
    super.setCSSVars();
    super.setSpecificCSSVars();

    this.dropdownOpen = true;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['countryToSelected']?.previousValue !== changes['countryToSelected']?.currentValue && changes['countryToSelected']?.previousValue !== undefined) {
      if (this.countryToSelected) {
        this.toggleOptionSelected(this.countryToSelected.iso2);
      } else {
        this.clearSelection();
      }
    }
  }

  public toggleOptionSelected(optionISO: string): void {
    this.countryList.forEach(option => {
      if (option.iso2 === optionISO) {
          option.selected = true;
          this.selectedOption = option.iso2;
      } else {
        option.selected = false;
      }
    });

    this.countrySelected.emit(this.selectedOption);
  }

  public clickOutside(event: Event) {
    event.preventDefault();
    event.stopPropagation();
    this.dropdownOpen = false;

    this.oldAsyncFunction(() => {
      this.closeDropdown.emit(true);
    }, 150).pipe(take(1), takeUntilDestroyed(this.destroyRef)).subscribe();
  }

  public clearSelection(): void {
    this.countryList.forEach((option: Country) => {
      option.selected = false;
    });
    this.selectedOption = undefined;
  }
}
