
import { CUSTOM_ELEMENTS_SCHEMA, Component, Input, OnInit, ViewChild, ViewEncapsulation } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperDirective } from 'src/app/_directives/swiper/swiper.directive';
import { CarouselBanner, CarouselType } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { environment } from 'src/environments/environment';
import { SwiperOptions } from 'swiper/types';

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  encapsulation: ViewEncapsulation.None,
  styleUrls: ['./carousel.component.sass'],
  standalone: true,
  imports: [
    TranslateModule,
    SwiperDirective
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CarouselComponent implements OnInit {
  @Input() content: CarouselBanner;

  public CarouselType = CarouselType;

  public environment = environment;

  public swiperConfig: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 30,
    navigation: false,
    pagination: false,
    centeredSlides: true,
    breakpoints: {
      '320': {
        spaceBetween: 18
      },
      '760': {
        spaceBetween: 30
      },
      '1200': {
        initialSlide: 1
      }
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: false,
      pauseOnMouseEnter: true,
      stopOnLastSlide: false
    }
  };

  public gamifyouSwiperConfig: SwiperOptions = {
    slidesPerView: 'auto',
    spaceBetween: 30,
    navigation: false,
    pagination: false,
    centeredSlides: true,
    speed: 500,
    grabCursor: true,
    breakpoints: {
      '320': {
        spaceBetween: 10
      },
      '760': {
        spaceBetween: 30
      },
      '1200': {
        initialSlide: 0
      }
    }
  };

  constructor(private generalService: GeneralService) { }

  ngOnInit(): void {
    this.swiperConfig.loop = this.content.mainCarouselContent.length > 1 ? true : false;
    this.gamifyouSwiperConfig.loop = this.content.mainCarouselContent.length > 3 ? true : false;
  }

  public carouselSlideAction(carouselType: CarouselType, slideId: string): void {
    switch(carouselType) {
      case CarouselType.BLOG:
        this.generalService.navigateTo(`blog/${slideId}`);
        this.generalService.autoScrollTop('page-router-container');
        break;

      case CarouselType.COLLECTIONS:
        this.generalService.navigateTo(`marketplace/product/${slideId}`);
        this.generalService.autoScrollTop('page-router-container');
        break;

      case CarouselType.INSTITUTIONS:
        this.generalService.navigateTo(`/${slideId}`);
        this.generalService.autoScrollTop('page-router-container');
        break;
    }
  }
}
