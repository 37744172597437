import { CommonModule } from '@angular/common';
import { } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroupDirective, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import * as lpn from 'google-libphonenumber';
import { Country, CountryISO } from '../../__models/phone/phone-number.model';
import { LibraryService } from '../../__services/library.service';
import { InputBaseComponent } from '../../_extended-components/input-base-component/input-base.component';
import { CountryDropdownComponent } from '../dropdowns/country-dropdown/country-dropdown.component';
import { GenericPhoneInputService } from './_services/generic-phone-input.service';
import { CountryCode } from './data/country-code-data';

@Component({
  standalone: true,
  selector: 'app-generic-phone-input',
  templateUrl: './generic-phone-input.component.html',
  styleUrls: [
    '../../_base-component/base.component.sass',
    '../../_extended-components/input-base-component/input-base.component.sass',
    './generic-phone-input.component.sass'
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    CountryDropdownComponent,
    InputBaseComponent,
  ],
  providers: [
    CountryCode,
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: GenericPhoneInputComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: GenericPhoneInputComponent
    },
    FormGroupDirective,
    GenericPhoneInputService
  ]
})
export class GenericPhoneInputComponent extends InputBaseComponent implements OnInit {
  @Input() public noResultsLabel: string;

  @Input() public selectedCountryISO: CountryISO;

  @Input() public allowDropdown: boolean = false;
  @Input() public dropdownBorderRadius: string;

  @Input() public countryCodeControl: FormControl;

  @Output() public isInputValid: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public optionSelected: EventEmitter<string> = new EventEmitter<string>();

  public allCountries: Array<Country> = [];

  public dropdownOpen: boolean = false;

  public selectedCountry: Country = {
    areaCodes: undefined,
    dialCode: '',
    htmlId: '',
    iso2: '',
    flagClass: '',
    name: '',
    placeHolder: '',
    priority: 0
  };

  public selectedOption?: string;

  public hasError: boolean = false;

  private phoneUtil = lpn.PhoneNumberUtil.getInstance();

  constructor(
    public override host: ElementRef,
    public override libraryService: LibraryService,
    private phoneService: GenericPhoneInputService,
  ) {
    super(host, libraryService);
  }

  ngOnInit(): void {
    this.allCountries = this.phoneService.allCountries;
    let parsedNumber: lpn.PhoneNumber;
    let countryCode: string;
    if (this.control.value !== '') {
      parsedNumber = this.phoneUtil.parseAndKeepRawInput(this.countryCodeControl.value + this.control.value);
      countryCode = this.phoneUtil.getRegionCodeForNumber(parsedNumber) + '';
    } else {
      countryCode = 'pt';
    }

    this.selectedCountryISO = this.allCountries.find(country => country.iso2 === countryCode.toLowerCase())!.iso2 as CountryISO;
    this.emitOptionSelected(this.selectedCountryISO);

    super.setCSSVars();
    super.setSpecificCSSVars();
  }

  public setSelectedCountry(iso: string = ''): void {
    const selectedISO: string = iso ? iso : this.selectedCountryISO;
    this.selectedCountry = this.allCountries.find(country => country.iso2 === selectedISO)!;

    this.countryCodeControl.patchValue(`+${this.selectedCountry.dialCode}`);
  }

  public openDropdown(): void {
    this.dropdownOpen = true;
  }

  public countrySelect(): void {
    this.dropdownOpen = false;

    this.control.updateValueAndValidity({emitEvent: false});
  }

  public emitOptionSelected(option: string): void {
    this.selectedOption = option;
    this.dropdownOpen = false;
    this.setSelectedCountry(this.selectedOption);
    this.countrySelect();

    this.optionSelected.emit(option);
  }

  public clickOutside(event: Event): void {
    event.preventDefault();
    event.stopPropagation();

    this.dropdownOpen = false;
  }
}
