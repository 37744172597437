import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { MenuBanner, Wallet } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { ProductsService } from 'src/app/pages/marketplace/_services/_products/products.service';
import { Product, ProductPartner, ProductTypeEnum } from 'src/app/pages/marketplace/_services/_products/products.service.model';
import { environment } from 'src/environments/environment';
import { ProductCode } from '../../../_services/products/product-history.model';
import { ProductsHistoryService } from '../../../_services/products/products-history.service';
import { PageBannerComponent } from "../../../../_shared-components/page-banner-module/page-banner/page-banner.component";
import { ItemImageSliderComponent } from "../../../../_shared-components/item-image-slider-module/item-image-slider/item-image-slider.component";
import { CommonModule } from '@angular/common';
import { VoucherDetailsComponent } from 'src/app/pages/marketplace/product-details/voucher-details/voucher-details.component';
import { NftDetailsComponent } from 'src/app/pages/marketplace/product-details/nft-details/nft-details.component';
import { ProductCodeListItemComponent } from "../product-code-list-item/product-code-list-item.component";
import { LabelPipe } from 'src/app/_shared-modules/label-pipe-module/label.pipe';
import { LoadingListItemComponent } from "../../../../_shared-components/loading-list-module/loading-list-item/loading-list-item.component";


@Component({
  selector: 'app-history-product-details',
  templateUrl: './history-product-details.component.html',
  styleUrls: ['./history-product-details.component.sass'],
  animations: [
    trigger('FadeIn', [
      state('hide', style({
        opacity: '0',
      })),
      state('show', style({
        opacity: '1',
      })),
      transition('hide <=> show', animate('600ms ease-in-out')),
    ])
  ],
  standalone: true,
  imports: [
    CommonModule,
    PageBannerComponent,
    ItemImageSliderComponent,
    ProductCodeListItemComponent,
    LabelPipe,
    VoucherDetailsComponent,
    NftDetailsComponent,
    LoadingListItemComponent
]
})
export class HistoryProductDetailsComponent implements OnInit, AfterViewInit, OnDestroy {

  private destroy: Subject<boolean> = new Subject<boolean>();

  public animationState: string = 'hide';

  public loadingListItems: Array<number> = [1,2,3];

  public isLoaded: boolean = false;
  public isListLoading: boolean = false;

  public mainBannerInfo: MenuBanner;

  public product: Product;
  private productId: string;

  public partner: ProductPartner | undefined;

  public productCodeList: Array<ProductCode> = [];

  public environment = environment;
  public ProductTypeEnum = ProductTypeEnum;

  public userDefaultWalletValue: number;

  constructor(
    public generalService: GeneralService,
    private productService: ProductsService,
    public labelService: LabelsService,
    private route: ActivatedRoute,
    public translateService: TranslateService,
    public productsHistoryService: ProductsHistoryService
    ) {
    const sessionLanguage = localStorage.getItem('SessionLanguageCode');

    this.translateService.currentLang = environment.defaultLanguage;
    this.translateService.setDefaultLang(environment.defaultLanguage);
    this.translateService.use(environment.defaultLanguage);

    this.generalService.userDetails.pipe(takeUntil(this.destroy)).subscribe(() => {
      const defaultWallet: Wallet | undefined = this.generalService.userDetails$.value.wallets![0];
      this.userDefaultWalletValue = defaultWallet ? defaultWallet.currentValue: 0;
    });
  }

  ngOnInit(): void {
    const currentMenu = this.generalService.menuList.find(menu => menu.viewUrl === '/marketplace');
    this.mainBannerInfo = currentMenu?.bannerContent!;

    this.route.params.subscribe(params => {
      this.productId = params['id'];

      this.isListLoading = true;
      this.productsHistoryService.getProductCodes(this.productId).subscribe({
        next: (resp: Array<ProductCode>) => {
          this.productCodeList = resp;
          this.isListLoading = false;
        }
      });

      this.productService.getProductDetail(this.productId).subscribe({
        next: product => {
          this.product = product;

          this.generalService.oldAsyncFunction(() => {this.animationState = 'show'});

          let relatedListQueryParameters: Params = {};
          if (this.product.collection) {
            relatedListQueryParameters = {
              ...relatedListQueryParameters,
              'collection': this.product.collection.id
            };
          }

          // TODO if needed uncomment
          /* if(this.product.partner) {
            this.partnerService.getPartnerDetails(this.product.partner.id).subscribe((resp) => {
              this.partner = resp;
              this.isLoaded = true;
            });
          } else {
            this.isLoaded = true;
          } */

          this.partner = product.partner;
          this.isLoaded = true;
        },
        error: err => {
          this.generalService.navigateTo('/404');
        }
      });
    });
  }

  ngAfterViewInit(): void {
    this.generalService.currentScreen$.next('product-details-' + this.productId);
  }

  public productItemClicked(productId: string): void {
    this.generalService.navigateTo(`marketplace/product/${productId}`);

    this.generalService.autoScrollTop('page-router-container');
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.complete();
  }
}
