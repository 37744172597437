import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { catchError, map, Observable } from 'rxjs';
import { RadioButton } from 'src/app/_generic-components-lib/__models/buttons/buttons.model';
import { ApiService } from 'src/app/_services/api.service';
import { GenericErrorReply } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { environment } from 'src/environments/environment';
import { FilterGroup, FilterGroupDTO, FilterItem, FilterItemDTO, FilterType, FilterTypeEnum } from '../../_shared-components/filters-list-module/filters-list/filter.model';
import { TierItemDTO } from '../marketplace.model';
import { TierItem } from '../marketplace-list-container/tiers-filter/tier-filter.model';

@Injectable({
  providedIn: 'root'
})
export class MarketplaceService {
  infiniteScrollActive: any;

  constructor(
    private apiService: ApiService,
    private generalService: GeneralService
  ) { }

  public getTiers(): Observable<Array<TierItem>> {
    return this.apiService.get(`tenant-management/tenant/${environment.tenantId}/application/${environment.applicationId}/highlight`, {}, '1.0', true, environment.useMockedData.marketplaceGetTiers).pipe(
      catchError(
        (error: HttpErrorResponse) => {
            this.generalService.isValidServerReply(error['error']);
            throw error['message'];
          }
        ),
      map((response: Array<TierItemDTO> | GenericErrorReply) => {
        if (this.generalService.isValidServerReply(response)) {
          const tiersList: Array<TierItem> = this.getTiersListFromDTO(response as Array<TierItemDTO>);

          return tiersList;
        } else {
          throw response;
        }
      }));
  }

  public getFilters(params: Params = {}): Observable<Array<FilterGroup>> {
    return this.apiService.get('product-catalog/public/product-filter', params, '1.0', true, environment.useMockedData.marketplaceGetFilters).pipe(
      catchError(
        (error: HttpErrorResponse) => {
            this.generalService.isValidServerReply(error['error']);
            throw error['message'];
          }
        ),
      map((response: Array<FilterGroupDTO> | GenericErrorReply) => {
        if (this.generalService.isValidServerReply(response)) {
          const filtersReply = response as Array<FilterGroupDTO>;
          const filtersList: Array<FilterGroup> = this.getFiltersListFromDTO(filtersReply);

          return filtersList;
        } else {
          throw response;
        }
      }));
  }

  private getFiltersListFromDTO(filtersReply: Array<FilterGroupDTO>): Array<FilterGroup>{
    const filtersList: Array<FilterGroup> = [];

    filtersReply.forEach(dto => {
      filtersList.push({
        queryParameter: dto.queryParameter,
        name: dto.name,
        type: FilterTypeEnum[dto.type as FilterType],
        values: this.getFilterItemFromDTO(dto.values, FilterTypeEnum[dto.type as FilterType]),
        isOpen: true
      });
    });

    return filtersList;
  }

  private getFilterItemFromDTO(dtoList: Array<FilterItemDTO>, filterType: FilterType = FilterTypeEnum.Checkbox): Array<FilterItem | RadioButton> {
    const filterList: Array<FilterItem | RadioButton> = [];

    dtoList.forEach(dto => {
      switch (filterType) {
        case FilterTypeEnum.Checkbox:
          filterList.push({
            id: dto.id,
            description: dto.description,
            selected: false
          });
          break;

        case FilterTypeEnum.Radio:
          filterList.push({
            label: dto.description,
            value: dto.id,
            selected: false
          });
          break;

        case FilterTypeEnum.Range:
          // TODO
          break;

        default:
          break;
      }
    });

    return filterList;
  }

  private getTiersListFromDTO(tiersReply: Array<TierItemDTO>): Array<TierItem>{
    const tierList: Array<TierItem> = [];

    tiersReply.forEach(dto => {
      tierList.push({
        id: dto.id,
        imageUrl: dto.imageUrl,
        title: dto.title
      });
    });

    return tierList;
  }
}
