import { AfterViewInit, Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormControl, Validators, FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { EmailAddress, FiscalAddress, UserDetails } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { environment } from 'src/environments/environment';
import { DatePipe, CommonModule } from '@angular/common';
import { SingleLineTextInputComponent } from 'src/app/_generic-components-lib/inputs/text-inputs/single-line-text-input/single-line-text-input.component';
import { LabelPipe } from '../../../_shared-modules/label-pipe-module/label.pipe';
import { DateInputComponent } from 'src/app/_generic-components-lib/inputs/date-inputs/date-input/date-input.component';

@Component({
  selector: 'app-account-details',
  templateUrl: './account-details.component.html',
  styleUrls: ['./account-details.component.sass'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SingleLineTextInputComponent,
    DateInputComponent,
    DatePipe,
    LabelPipe
  ]
})

export class AccountDetailsComponent implements OnInit, OnChanges, AfterViewInit {

  @Input() userDetails: UserDetails;
  @Input() editMode: boolean;
  @Input() resetForm: boolean;
  @Output() buttonClick: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() updateUserDetails: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Output() formIsReset: EventEmitter<boolean> = new EventEmitter<boolean>();

  public accountDetailsForm: FormGroup;
  private auxFormValues: FormGroup;

  public minDate: Date = new Date('01/01/1900');

  public environment = environment;

  constructor(
    private fb: FormBuilder,
    public labelService: LabelsService,
    public generalService: GeneralService,
    private translateService: TranslateService
  ) {
    const sessionLanguage = localStorage.getItem('SessionLanguageCode');

    this.translateService.currentLang = environment.defaultLanguage;
    this.translateService.setDefaultLang(environment.defaultLanguage);
    this.translateService.use(environment.defaultLanguage);
  }

  // #region - Form Controls
    public get firstNameControl(): FormControl {
      return this.accountDetailsForm.get('firstname') as FormControl
    }

    public get lastNameControl(): FormControl {
      return this.accountDetailsForm.get('lastName') as FormControl
    }

    public get birthDateControl(): FormControl {
      return this.accountDetailsForm.get('birthdate') as FormControl
    }

    public get emailAddressControl(): FormControl {
      return this.accountDetailsForm.get('emailaddress') as FormControl
    }

    public get fiscalNumberControl(): FormControl {
      return this.accountDetailsForm.get('fiscalnumber') as FormControl
    }

    public get addressLine1Control(): FormControl {
      return this.accountDetailsForm.get('addressline1') as FormControl
    }

    public get addressLine2Control(): FormControl {
      return this.accountDetailsForm.get('addressline2') as FormControl
    }

    public get postalCodeControl(): FormControl {
      return this.accountDetailsForm.get('postalcode') as FormControl
    }

    public get cityControl(): FormControl {
      return this.accountDetailsForm.get('city') as FormControl
    }

    public get countryControl(): FormControl {
      return this.accountDetailsForm.get('country') as FormControl
    }
  // #endregion - Form Controls

  ngOnInit(): void {
    this.accountDetailsForm = this.fb.group({
      firstname: new UntypedFormControl(this.userDetails.firstName, [
        Validators.pattern("^[a-zA-Z]+$"),
        Validators.required
      ]),
      lastname: new UntypedFormControl(this.userDetails.lastName, [
        Validators.pattern("^[a-zA-Z]+$"),
        Validators.required
      ]),
      birthdate: new UntypedFormControl(this.userDetails.birthdate, []),
      emailaddress: new UntypedFormControl((this.userDetails.emailAddress?.emailAddress as EmailAddress)?.emailAddress, [
        Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+([.][a-zA-Z]{2,})+$"),
        Validators.required
      ]),
      fiscalnumber: new UntypedFormControl(this.userDetails.fiscalNumber, [
        Validators.maxLength(9),
        Validators.minLength(9),
        Validators.required
      ]),
      addressline1: new UntypedFormControl(this.userDetails.fiscalAddress ? (this.userDetails.fiscalAddress?.fiscalAddress as FiscalAddress)?.addressLine1 : '', []),
      addressline2: new UntypedFormControl(this.userDetails.fiscalAddress ? (this.userDetails.fiscalAddress?.fiscalAddress as FiscalAddress)?.addressLine2 : '', []),
      postalcode: new UntypedFormControl(this.userDetails.fiscalAddress ? (this.userDetails.fiscalAddress?.fiscalAddress as FiscalAddress)?.postalCode : '', []),
      city: new UntypedFormControl(this.userDetails.fiscalAddress ? (this.userDetails.fiscalAddress?.fiscalAddress as FiscalAddress)?.city : '', []),
      country: new UntypedFormControl(this.userDetails.fiscalAddress ? (this.userDetails.fiscalAddress?.fiscalAddress as FiscalAddress)?.country : '', [])
    });

    this.copyForm();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if(changes['resetForm']) {
      if(changes['resetForm'].currentValue){
        this.accountDetailsForm.reset();
        Object.keys(this.accountDetailsForm.controls).forEach(key => {
          this.accountDetailsForm.get(key)?.setErrors(null);
          this.accountDetailsForm.get(key)?.setValue(this.auxFormValues.get(key)?.value);
        });
        this.formIsReset.emit(false);
      }
    }
  }

  ngAfterViewInit(): void {
    this.generalService.currentScreen$.next('account-details');
  }

  private copyForm(): void {
    this.auxFormValues = this.fb.group(this.accountDetailsForm.getRawValue());
  }

  closeDetails(): void {
    this.buttonClick.emit(false);
  }

  saveDetails(): void {
    this.accountDetailsForm.markAllAsTouched();
    this.updateUserDetails.emit(this.accountDetailsForm);
  }
}
