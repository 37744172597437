<div class="descriptions-container">
  @for (descItem of content.descriptionList; track descItem) {
    <div class="desc-item" [ngClass]="{'justify-content-center text-center': (!descItem.title && !descItem.subtitle) || !descItem.imageUrl && !descItem.animation}">
      @if (descItem.title || descItem.subtitle) {
        <div class="text-container">
          @if (descItem.title) {
            <p class="desc-title">{{descItem.title}}</p>
          }
          @if (descItem.subtitle) {
            <p class="desc-subtitle">{{descItem.subtitle}}</p>
          }
        </div>
      }
      @if (descItem.animation) {
        @switch (descItem.animation.type) {
          <!-- Lottie -->
          @case ('LOTTIE') {
            <ng-lottie
              [width]="(generalService.isMobile | async) ? '40vw' : '20vw'"
              [height]="(generalService.isMobile | async) ? '40vw' : '20vw'"
          [options]="{
            autoplay: true,
            loop: true,
            path: descItem.animation.url
          }">
            </ng-lottie>
          }
          <!-- Rive -->
          @case ('RIVE') {
            <div class="rive-animation-container"
        [ngStyle]="{
          'width': (generalService.isMobile | async) ? '40vw' : '20vw',
          'height': (generalService.isMobile | async) ? '40vw' : '20vw'
        }" >
              <app-rive-animation-component
                [loop]="true"
              [animationFileUrl]="descItem.animation.url"></app-rive-animation-component>
            </div>
          }
        }
      } @else {
        @if (descItem.imageUrl) {
          <img [src]="descItem.imageUrl">
        }
      }
      <!-- Simple Image -->
    </div>
  }
</div>
