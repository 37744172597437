<div class="product-info mt-4 mb-5">
  <div class="product-info-title-container">
    <span>{{ product.name }}</span>
  </div>

  <div class="product-info-table">
    <div class="product-detail-container">
      <p class="info-table-header">{{labelService.productDetailsInfoTitle | label}}</p>

      @if (product.quantity && product.quantity > 0 && !isHistory) {
        <div class="quantity-container">
          <div class="value" [ngClass]="{'is-blocked': isBlocked}">
            @if (isBlocked) {
              <img [src]="'assets/imgs/lock-icon.png'" class="me-2">
              } {{ (product.quantity | i18nPlural: generalService.getPluralKey('product_quantity')) | translate: {n: product.quantity} }}
            </div>
          </div>
        }

        @if (product.totalPurchased && product.totalPurchased > 0 && isHistory) {
          <div class="quantity-container">
            <div class="value" [ngClass]="{'is-blocked': isBlocked}">
              @if (isBlocked) {
                <img [src]="'assets/imgs/lock-icon.png'" class="me-2">
                } {{ (product.totalPurchased | i18nPlural: generalService.getPluralKey('product_purchased')) | translate: {n: product.totalPurchased} }}
              </div>
            </div>
          }

          @if (product.endDate && !isHistory) {
            <div class="date-container">
              {{ (labelService.productDetailsDate | label) + (product.endDate | date: 'dd MMMM, yyyy': translateService.currentLang: translateService.currentLang | titlecase)}}
            </div>
          }
        </div>

        <div class="product-price-container">
          <div class="product-price">
            {{ 'marketplace_listitem_price' | label: { price: product.prices[0].amount, unit: getCurrencySymbol(currency.type, 'narrow') | label } | lowercase }}
          </div>
        </div>
      </div>

      @if (partner || (product.locations && product.locations.length > 0)) {
        <div class="product-info-table mt-5">
          @if (product.locations && product.locations.length > 0) {
            <div class="addresses-container">
              <swiper-container class="item-address-info-swiper" #swiperRef swiperJS init="false" [config]="addressSwiperConfig">
                @for (location of product.locations; track $index) {
                  <swiper-slide class="item-info-item-container">
                    <img [src]="'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/icons/maps-pin-icon.png'">
                    <div class="address-info ms-2">
                      <p class="address-line">{{ (location.addressLine1 ? location.addressLine1 + ', ' : '') + (location.addressLine2 ? location.addressLine2 + ', ' : '') + location.city }}</p>
                      @if (location.distance) {
                        <p class="user-distance-from-address">{{ labelService.productDetailsUserDistance | label: { distanceKm : location.distance / 1000 | number : '1.2-2' } }}</p>
                      }
                      <a target="_blank" class="maps-redirect"
                        href="https://www.google.pt/maps/search/?api=1&query={{location.gpsCoords!.x}}%2C{{location.gpsCoords!.y}}">
                        {{ labelService.productDetailsSeeAddress | label }} <i class="bi bi-arrow-right-short"></i>
                      </a>
                    </div>
                  </swiper-slide>
                }
              </swiper-container>
              <div class="swiper-pagination"></div>
            </div>
          }
          @if (partner) {
            <div class="partner-container">
              <p class="info-table-header">{{labelService.productDetailsInstitution | label}}</p>
              <div class="partner-info-container">
                <img [src]="partner.image" [alt]="partner.name" class="partner-img">
                <div class="partner-name">{{ partner.name }}</div>
              </div>
            </div>
          }
        </div>
      }

      @if (isHistory && product.redeemSteps && product.redeemSteps.length > 0) {
        <div class="redeem-steps mt-5">
          <div class="step-container">
            @for (step of product.redeemSteps; track step) {
              <div class="step">
                <div class="step-index-container" [ngClass]="{ 'only-step': product.redeemSteps.length === 1 }">
                  {{ $index + 1 }}
                </div>
                <p class="step-description">
                  {{ step.description }}
                </p>
              </div>
            }
          </div>
          <div class="animation-container">
            <!-- Rive -->
            <div class="rive-animation-container">
              <app-rive-animation-component
                [loop]="true"
                [animationFileUrl]="'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/products/redeem-steps-animation.riv'">
              </app-rive-animation-component>
            </div>
          </div>
        </div>
      }

      @if (product.description) {
        <div class="product-info-text w-100 mt-5">
          @if (labelService.productDetailsDescription | label) {
            <span class="title">{{ labelService.productDetailsDescription | label }}</span>
          }
          <div class="description">{{ product.description }}</div>
        </div>
      }

      @if (product.traits && product.traits.length > 0) {
        <div class="product-properties-wrapper w-100 mt-4">
          <p class="title">{{labelService.productDetailsProperties | label}}</p>
          <div class="properties-list-container">
            @for (property of product.traits; track property) {
              <div class="property-container">
                <span class="property-name">{{ property.name }}</span>
                <br>
                  <span class="property-value">{{ property.value }}</span>
                </div>
              }
            </div>
          </div>
        }

        <!-- <div class="product-tags w-100 mt-4" *ngIf="product.categories">
        <p class="title">Tags</p>
        <span class="tags-container" *ngFor="let tag of product.categories">
          {{ tag.name }}
        </span>
      </div> -->
    </div>

    @if (!isHistory && product.quantity! > 0) {
      <div class="product-info-button-container">
        @if (!isBlocked) {
          <app-generic-button [buttonName]="labelService.productDetailsBuyButton | label" [borderRadius]="'50px'"
            [backgroundColor]="environment.brandStyleVariables.mainBrandColor"
            [brandColor]="'var(--main-brand-color)'" [fontColor]="environment.brandStyleVariables.mainBackgroundColor" [fontSize]="'var(--main-info-font-size)'"
            [buttonSpacing]="'calc(var(--main-info-font-size) * 1.2) calc(var(--main-info-font-size) * 2)'"
            [minHeight]="'unset'"
            [fontWeight]="'700'" (click)="openPurchasePopup()">
          </app-generic-button>
        } @else {
          <app-generic-button [buttonName]="labelService.productDetailsRedirectToMissionsButton | label : { currency : getCurrencySymbol(currency.type, 'narrow') | label }" [borderRadius]="'50px'"
            [backgroundColor]="environment.brandStyleVariables.mainBrandColor"
            [brandColor]="'var(--main-brand-color)'" [fontColor]="environment.brandStyleVariables.mainBackgroundColor" [fontSize]="'var(--main-info-font-size)'"
            [buttonSpacing]="'calc(var(--main-info-font-size) * 1.2) calc(var(--main-info-font-size) * 2)'"
            [minHeight]="'unset'"
            [fontWeight]="'700'" (click)="redirectToMission()">
          </app-generic-button>
        }
      </div>
    }

    @if (showPurchasePopup) {
      <app-purchase-popup
        [product]="product"
        [paymentType]="selectedPaymentType"
        (popUpClosed)="showPurchasePopup = false">
      </app-purchase-popup>
    }

    <!-- <div class="product-info-button-container" *ngIf="product.quantity! > 0">
    <div class="purchase-button button-shadow" role="button" (click)="goToPaymentPage()">{{labelService.productDetailsBuyButton | label}}</div>
  </div> -->
