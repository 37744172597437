
import { Component, ElementRef, Input } from '@angular/core';
import { mergeMap, Observable, of, timer } from 'rxjs';
import { PlaceholderStyle } from '../__models/_base/base-component.model';
import { LibraryService } from '../__services/library.service';

@Component({
  standalone: true,
  selector: 'app-base-component',
  template: '',
  styleUrls: ['./base.component.sass'],
  imports: [],
  providers: [LibraryService]
})
export class BaseComponent {

  @Input() public brandColor: string;
  @Input() public darkBrandColor: string;
  @Input() public lightBrandColor: string;
  @Input() public fontColor: string;
  @Input() public errorColor: string;
  @Input() public borderColor: string;
  @Input() public placeholderStyle: PlaceholderStyle;
  @Input() public backgroundColor: string;

  @Input() public fontFamily: string;
  @Input() public fontSize: string;
  @Input() public fontStyle: string;
  @Input() public fontWeight: string;
  @Input() public label: string;
  @Input() public labelFontSize: string;
  @Input() public labelFontWeight: string;
  @Input() public inputHeight: string;
  @Input() public borderRadius: string;
  @Input() public borderWidth: string;

  @Input() public toggleOnFtColor: string;

  @Input() public searchPlaceholderLabel: string;

  @Input() public isMobile: boolean = false;

  constructor(public host: ElementRef, public libraryService: LibraryService) {
    this.setCSSVars();
  }

  public oldAsyncFunction<T>(delayedFn: () => T, delay: number = 0): Observable<unknown> {
    const delayObservable: Observable<number> = timer(delay);

    // Use mergeMap to call the function when the delay is over
    const resultObservable:Observable<T> = delayObservable.pipe(
      mergeMap(() => {
        const result: T = delayedFn();

        // Return the result as the next value in the observable
        return of(result);
      })
    );

    // Subscribe to start the observable
    return resultObservable;
  }

  public setCSSVars(): void {
    if (this.brandColor || this.lightBrandColor || this.fontFamily || this.fontColor || this.errorColor || this.fontSize || this.fontWeight || this.borderRadius || this.fontStyle
        || this.borderColor || this.borderWidth || this.inputHeight || this.labelFontWeight || this.backgroundColor || this.toggleOnFtColor) {
      //set variables

      if (this.brandColor)
        this.host.nativeElement.style.setProperty('--brand-color', this.libraryService.convertToHexColor(this.host, this.brandColor));
      if (this.darkBrandColor)
        this.host.nativeElement.style.setProperty('--brand-darker', this.libraryService.convertToHexColor(this.host, this.darkBrandColor));
      if (this.lightBrandColor)
        this.host.nativeElement.style.setProperty('--brand-lighter', this.libraryService.convertToHexColor(this.host, this.lightBrandColor));
      if (this.fontFamily)
        this.host.nativeElement.style.setProperty('--font-family', this.fontFamily);
      if (this.fontColor)
        this.host.nativeElement.style.setProperty('--font-color', this.libraryService.convertToHexColor(this.host, this.fontColor));
      if (this.errorColor)
        this.host.nativeElement.style.setProperty('--error-color', this.libraryService.convertToHexColor(this.host, this.errorColor));
      if (this.fontSize)
        this.host.nativeElement.style.setProperty('--font-size', this.fontSize);
      if (this.fontStyle)
        this.host.nativeElement.style.setProperty('--font-style', this.fontStyle);
      if (this.fontWeight)
        this.host.nativeElement.style.setProperty('--font-weight', this.fontWeight);
      if (this.borderRadius)
        this.host.nativeElement.style.setProperty('--border-radius', this.borderRadius);
      if (this.borderColor)
        this.host.nativeElement.style.setProperty('--border-color', this.libraryService.convertToHexColor(this.host,this.borderColor));
      if (this.borderWidth)
        this.host.nativeElement.style.setProperty('--border-width', this.borderWidth);
      if (this.inputHeight)
        this.host.nativeElement.style.setProperty('--input-height', this.inputHeight);
      if (this.labelFontSize)
        this.host.nativeElement.style.setProperty('--label-font-size', this.labelFontSize);
      if (this.labelFontWeight)
        this.host.nativeElement.style.setProperty('--label-font-weight', this.labelFontWeight);
      if (this.backgroundColor)
        this.host.nativeElement.style.setProperty('--background-color', this.libraryService.convertToHexColor(this.host, this.backgroundColor));
      if (this.toggleOnFtColor)
        this.host.nativeElement.style.setProperty('--active-font-color', this.libraryService.convertToHexColor(this.host, this.toggleOnFtColor));
    }
  }
}
