import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormControl, Validators, FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import * as lpn from 'google-libphonenumber';
import { Subscription, take } from 'rxjs';
import { phoneValidator } from 'src/app/_generic-components-lib/inputs/generic-phone-input/validators/generic-phone-input.validator';
import { CountryISO } from 'src/app/_generic-components-lib/__models/phone/phone-number.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { PopupService } from 'src/app/_services/popup-service.service';
import { environment } from 'src/environments/GIVIN/environment';
import { ContactUsService } from '../_services/contact-us.service';
import { SmallPageBannerComponent } from '../../_shared-components/small-page-banner-module/small-page-banner/small-page-banner.component';
import { SingleLineTextInputComponent } from 'src/app/_generic-components-lib/inputs/text-inputs/single-line-text-input/single-line-text-input.component';

import { GenericButtonComponent } from 'src/app/_generic-components-lib/inputs/buttons/generic-button/generic-button.component';
import { LabelPipe } from '../../../_shared-modules/label-pipe-module/label.pipe';
import { AccordionComponent } from 'src/app/_generic-components-lib/accordion/accordion.component';
import { GenericPhoneInputComponent } from 'src/app/_generic-components-lib/inputs/generic-phone-input/generic-phone-input.component';
import { TextareaInputComponent } from 'src/app/_generic-components-lib/inputs/textarea-input/textarea-input.component';
import { Accordion } from 'src/app/_generic-components-lib/__models/accordion/accordion.model';
@Component({
  selector: 'app-general-info',
  templateUrl: './general-info.component.html',
  styleUrls: ['./general-info.component.sass'],
  standalone: true,
  imports: [
    SmallPageBannerComponent,
    AccordionComponent,
    FormsModule,
    ReactiveFormsModule,
    SingleLineTextInputComponent,
    GenericPhoneInputComponent,
    TextareaInputComponent,
    GenericButtonComponent,
    LabelPipe
]
})
export class GeneralInfoComponent implements OnInit, OnDestroy {

  public form = this.fb.group({
    generalInfoName: new UntypedFormControl('', [
      Validators.pattern("^[A-Za-zÀ-ÿ\\s]*$"),
      Validators.required
    ]),
    phoneInput: new UntypedFormControl('', [
      Validators.required
    ]),
    countryCode: new UntypedFormControl('', [
      Validators.required
    ]),
    generalInfoEmail: new UntypedFormControl('', [
      Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+([.][a-zA-Z]{2,})+$"),
      Validators.required
    ]),
    generalInfoMessage: new UntypedFormControl('', [
      Validators.pattern("^(?! )[^\s].*$"),
      Validators.required
    ])
  });

  public environment = environment;

  public CountryISO = CountryISO;

  public accordions: Array<Accordion>;

  private subscription: Subscription;

  constructor(
    private fb: FormBuilder,
    public labelService: LabelsService,
    private generalService: GeneralService,
    private popupService: PopupService,
    private contactusService: ContactUsService
  ) { }

  // #region - Form Controls
    public get generalInfoNameControl(): FormControl {
      return this.form.controls['generalInfoName'];
    }

    public get phoneInputControl(): FormControl {
      return this.form.controls['phoneInput'];
    }

    public get countryCodeControl(): FormControl {
      return this.form.controls['countryCode'];
    }

    public get generalInfoEmailControl(): FormControl {
      return this.form.controls['generalInfoEmail'];
    }

    public get generalInfoMessageControl(): FormControl {
      return this.form.controls['generalInfoMessage'];
    }
  // #endregion - Form Controls

  ngOnInit(): void {
    this.form.controls['phoneInput'].addValidators(
      phoneValidator(this.form.get('countryCode')?.value));

    this.subscription = this.form.get('phoneInput')?.valueChanges.subscribe((value: string) => {
      const phoneUtil = lpn.PhoneNumberUtil.getInstance();
      const countryCode = this.form.get('countryCode')?.value;
      const regionCode = phoneUtil.getCountryCodeForRegion(countryCode);
      const isoCode = phoneUtil.getRegionCodeForCountryCode(phoneUtil.getCountryCodeForRegion(countryCode));
      const phoneNumberLength = phoneUtil.getExampleNumberForType(isoCode, lpn.PhoneNumberType.FIXED_LINE_OR_MOBILE).getNationalNumber()?.toString().length;

      if(countryCode && typeof value === 'string' && value.length === phoneNumberLength && !value.startsWith(`+${regionCode}`)) {
        this.form.get('phoneInput')?.patchValue(
          `+${regionCode}${value}`,
        );
      }
    })!;

    this.contactusService.getFAQs().pipe(take(1)).subscribe((list: Array<Accordion>) => {
      this.accordions = list;
    });
  }

  public menuClicked(url: string): void {
    this.generalService.navigateTo(`contact-us/${url}`);
  }

  public submitForm(): void {
    this.form.markAllAsTouched();
    if(this.form.valid) {
      // Add a way to submit the information to BE
      this.popupService.setPopupToOpen(
        {text:''},
        'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/popup-success-image.png',
        { text: 'Obrigado'},
        [
          {text: 'Request sent successfully.\nA member of our team will get back to you shortly.'},
        ],
        [{
          text: 'Ok',
          actionText: 'close',
          isCloseBtn: true,
          style: {
            backgroundColor: this.environment.brandStyleVariables.mainBrandColor,
            fontColor: this.environment.brandStyleVariables.mainBackgroundColor,
            marginTop: '17px',
            fontSize: '14px',
            textAlign: 'center',
            fontWeight: '700',
            borderRadius: '66px'
          }
        }]
      );

      this.popupService.hasPopups.subscribe(haspopUp => {
        if(!haspopUp) {
          this.form.reset();
        }
      });
    }
  }

  ngOnDestroy(): void  {
    this.subscription.unsubscribe();
  }
}
