@if (isLoaded) {
  <div class="backdrop-container" [@BackdropFadeIn]="animationState"></div>
  <div [@ZoomIn]="animationState" class="popup-container card-shadow">
    <div class="close-button" (click)="closePopUp()">
      <i class="bi bi-x"></i>
    </div>
    <p class="subtitle">{{ labelsService.editGoalPopupSubtitle | label }}</p>
    <p class="title">{{ labelsService.editGoalPopupTitle | label }}</p>
    <div class="card-content custom-scroll">
      <!-- ################### OPTIONS ################### -->
      @if (possibleGoalOptionDTOs && possibleGoalOptionDTOs.length > 0) {
        <div class="options-container">
          @for (option of possibleGoalOptionDTOs; track option; let index = $index) {
            @if (index < 6) {
              <div class="option"
                (click)="optionSelected(option)"
                [ngClass]="{ selected: option.selected }"
                [ngStyle]="{ 'background-image': 'url(' + option.img + ')' }">
                @if (option.selected) {
                  <i class="selected-icon bi bi-check-circle-fill"></i>
                }
                @if (option.title) {
                  <div class="option-title">
                    <span>{{ option.title }}</span>
                  </div>
                }
              </div>
            }
          }
        </div>
      }
      <app-generic-button class="action-button" [borderRadius]="'60px'" [fontWeight]="'700'" [brandColor]="environment.brandStyleVariables.mainBrandColor"
        [backgroundColor]="environment.brandStyleVariables.mainBrandColor" [fontSize]="'var(' + ((generalService.isMobile | async) ? '--smallest-font-size' : '--small-font-size') + ')'"
        [hasIcon]="isAddingGoal" [iconClass]="isAddingGoal ? 'bi bi-plus-lg' : ''" [iconColor]="'white'" [fontColor]="'white'"
        [buttonName]="isAddingGoal ? ( labelsService.editGoalPopupAddButtonLabel | label ) : ( labelsService.editGoalPopupEditButtonLabel | label )"
        [buttonSize]="'min(160px, 17.5vw)'" [isDisabled]="!selectedGoalOptionDTO" [disabledBackgroundColor]="environment.brandStyleVariables.mainBrandColorLight" [minHeight]="'48px'" (click)="btnAction()">
      </app-generic-button>
    </div>
  </div>
}
