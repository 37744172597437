<div class="wallet-container" [@SideBarSlide]="animationState">
  <div class="wallet-wrapper">
    <div class="wallet-header">
      <div class="wallet-user-container">
        <div class="user-profile-pic">
          <div></div>
        </div>
        <span>{{ labelService.sidebarMyWallet | label }}</span>
      </div>
      <div class="close-button-container">
        <div class="close-button" (click)="closeSideBar()">
          <span></span>
          <span></span>
        </div>
      </div>
    </div>
    <hr>
      <div class="wallet-info-container">
        <p>{{labelService.sidebarPrimaryInfo | label}}</p>
      </div>

      <div class="wallet-list-container">
        <div class="wallet-list-item">
          <div class="list-item-info" (click)="(walletService.isWalletConnected ? openDisconnectPrompt() : walletService.hasExtension ? walletService.connectMetaMask() : walletService.openExtensionPopUp())">
            <div class="list-item-icon">
              <img src="/assets/imgs/metamask-icon.svg">
            </div>

            <div class="list-item-title">
              <span>{{ labelService.sidebarMetamask | label }}</span>
            </div>

            @if (walletService.currentWallet === 'Metamask') {
              <div class="list-item-connection ms-auto">
                <div class="item-connection">
                  <span>{{ labelService.sidebarCurrentWalletConnected | label }}</span>
                </div>
              </div>
            }
          </div>

        </div>

        <div class="wallet-list-item">
          <div class="list-item-info" (click)="walletService.isWalletConnected ? openDisconnectPrompt() : openWeb3Auth()">
            <div class="list-item-icon">
              <img src="/assets/imgs/walletconnect-icon.svg">
            </div>

            <div class="list-item-title">
              <span>{{ labelService.sidebarWalletConnect | label }}</span>
            </div>

            @if (walletService.currentWallet === 'WalletConnect') {
              <div class="list-item-connection ms-auto">
                <div class="item-connection">
                  <span>{{ labelService.sidebarCurrentWalletConnected | label }}</span>
                </div>
              </div>
            }
          </div>
          @if (walletService.isModalLoaded) {
            <div class="walletconnect-container w-100"></div>
          }
        </div>
      </div>



      <div class="wallet-footer">
        <p>{{labelService.sidebarFooterInfo | label}}</p>
      </div>
    </div>
    @if (openPrompt) {
      <app-generic-popup-from-bottom
    [preImgTitle]="{
      text: labelService.sidebarPopUpWalletTitle | label
    }"
    [title]="{
      text: labelService.sidebarPopUpWalletDesc | label
    }"
        [image]="'assets/imgs/metamask-icon.svg'"
        [walletLabel]="walletService.currentWallet"
        [walletName]="'1x28b-eacAGke'"
    [buttons]="[{
      text: 'Disconnect',
      style: {
        backgroundColor: '#FF3E3E',
        fontColor: '#ffffff'
      },
      actionText: 'disconnect',
      isCloseBtn: false
    },
    {
      text: 'Cancel',
      style: {
        backgroundColor: '#323232',
        fontColor: '#ffffff'
      },
      actionText: 'close',
      isCloseBtn: true
    }]"
        (popUpClosed)="openPrompt = false"
        >
      </app-generic-popup-from-bottom>
    }
  </div>
