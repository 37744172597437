<div class="activity-info mt-4 mb-5">
  <div class="activity-info-title-container">
    <span>{{ activity.name }}</span>
  </div>

  <div class="activity-info-table">
    <div class="activity-detail-container">
      <p class="info-table-header">{{labelService.activityDetailsInfoTitle | label}}</p>

      @if (activity.subActivities && activity.subActivities.length > 0) {
        <div class="quantity-container">
          <div class="value">
            {{ activity.subActivities.length === 1 ?
            (labelService.activityListItemSubtaskSingular | label : { quantity : activity.subActivities.length }) : labelService.activityListItemSubtaskPlural | label : { quantity : activity.subActivities.length }
            }}
          </div>
        </div>
      }

      @if (activity.endDate) {
        <div class="date-container">
          {{ labelService.activityListItemDateLabel | label: { date: activity.endDate | date: 'dd MMMM, yyyy': translateService.currentLang: translateService.currentLang | titlecase } }}
        </div>
      }
    </div>

    <div class="activity-prize-container">
      <div class="activity-prize">
        {{ labelService.activityListItemPrizeLabel | label: { amount: activity.totalReward, unit: getCurrencySymbol('pinkcoins', 'narrow') | label } }}
      </div>
    </div>
  </div>

  @if (activity.partner || (activity.addresses && activity.addresses.length > 0)) {
    <div class="activity-info-table mt-3">
      @if (activity.addresses && activity.addresses.length > 0) {
        <div class="addresses-container">
          <swiper-container class="item-address-info-swiper" #swiperRef swiperJS init="false" [config]="addressSwiperConfig">
            @for (address of activity.addresses; track address; let index = $index) {
              <swiper-slide class="item-info-item-container">
                <img [src]="'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/icons/maps-pin-icon.png'">
                <div class="address-info ms-2">
                  <p class="address-line">{{ address.addressLine1 + ', ' + (address.addressLine2 ? address.addressLine2 + ', ' : '') + address.city }}</p>
                  @if (address.distance) {
                    <p class="user-distance-from-address">{{ labelService.activityDetailsUserDistance | label: { distanceKm : address.distance / 1000 | number : '1.2-2' } }}</p>
                  }
                  <a target="_blank" class="maps-redirect"
                    href="https://www.google.pt/maps/search/?api=1&query={{address.gpsCoords!.x}}%2C{{address.gpsCoords!.y}}">
                    {{ labelService.activityDetailsSeeAddress | label }} <i class="bi bi-arrow-right-short"></i>
                  </a>
                </div>
              </swiper-slide>
            }
          </swiper-container>
          <div class="swiper-pagination"></div>
        </div>
      }
      @if (activity.partner) {
        <div class="partner-container">
          <p class="info-table-header">{{labelService.activityDetailsPartnerLabel | label}}</p>
          <div class="partner-info-container">
            <img [src]="activity.partner.images ? activity.partner.images.profilePictureImage.href : 'assets/imgs/environments/'+ environment.tenantLocalAssetsPath +'/default-images/default-image-square.svg'" [alt]="activity.partner.name" class="partner-img">
            <div class="partner-name">{{ activity.partner.name }}</div>
          </div>
        </div>
      }
    </div>
  }

  @if (activity.description) {
    <div class="activity-info-text w-100 mt-3">
      <span class="title">{{ labelService.activityDetailsDescriptionLabel | label }}</span>
      <div class="description">{{ activity.description }}</div>
    </div>
  }
</div>

@switch (activity.type) {
  @case (ActivityTypes.INPUT) {
    <app-activity-type-input [activity]="activity" (triggerGetActivityInfo)="getActivityInfo($event)"></app-activity-type-input>
  }
  @case (ActivityTypes.MULTI_STEP) {
    <app-activity-type-multi-step [activity]="activity"></app-activity-type-multi-step>
  }
  @default {
  }
}
