<div class="list-item-content-container w-100 ps-3 pe-3 mt-auto">
  @if (prices && prices.length > 0) {
    <div class="points-container">
      {{ 'marketplace_listitem_price' | label: { price: prices[0].amount, unit: getCurrencySymbol(currency.type, 'narrow') | label } }}
    </div>
  }

  @if (quantity && !isHistoryItem) {
    <div class="quantity-container right-top-column">
      @if (quantityWarningNumber) {
        @if (quantity <= quantityWarningNumber) {
          <span class="quantity-waning-msg" [ngStyle]="{ 'color': isBlocked ? 'var(--main-font-color)' : 'var(--msg-color)'}"
          >{{ quantity | i18nPlural: generalService.getPluralKey('product_quantity_warning') | translate: {n: quantity} }}</span>
        }
        @if (quantity > quantityWarningNumber) {
          <span class="quantity-normal-msg"
          >{{ quantity | i18nPlural: generalService.getPluralKey('product_quantity') | translate: {n: quantity} }}</span>
        }
      } @else {
        @if (quantity) {
          <span class="quantity-normal-msg">
            {{ quantity | i18nPlural: generalService.getPluralKey('product_quantity') | translate: {n: quantity} }}
          </span>
        }
      }
    </div>
  }

  @if (quantity && isHistoryItem) {
    <div class="history-item-quantity-container">
      {{ (quantity > 1 ? labelsService.productHistoryListQuantityPluralLabel : labelsService.productHistoryListQuantityLabel) | label : { quantity: quantity } }}
    </div>
  }

  <!--     <div class="lowest-price-message" *ngIf="lowestPriceValue">
  <span class="lowest-price-msg">{{ 'marketplace_listitem_lowestask' | label: { price: lowestPriceValue, unit: '€' } }}</span>
</div> -->

<div class="left-bottom-column">
  @if (isBlocked) {
    <img [src]="'assets/imgs/lock-icon.png'" class="me-2">
  }
  @if (serial) {
    <div class="serials-container ms-1">
      <span>{{ 'marketplace_listitem_serial' | label }} {{ serial }}</span>
    </div>
  }
</div>

@if (endDate) {
  <div class="date-container right-bottom-column">
    <ng-container>
      @if (dateWarning) {
        <img class="warning me-2" [src]="'assets/imgs/red-warning-icon.svg'">
      }
      @if (endDate) {
        <span [ngClass]="{'warning-color': dateWarning}">{{ 'marketplace_listitem_date' | label: { date: endDate | date: 'dd MMMM, yyyy': translateService.currentLang: translateService.currentLang } }}</span>
      }
    </ng-container>
  </div>
}
</div>
