import { CommonModule } from '@angular/common';
import {
  AfterContentInit,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import { MatIconRegistry } from '@angular/material/icon';
import { MatTabGroup, MatTabsModule } from '@angular/material/tabs';
import { DomSanitizer } from '@angular/platform-browser';
import { asapScheduler } from 'rxjs';
import { TabItem } from '../../__models/tabs/generic-tabs-model';
import { LibraryService } from '../../__services/library.service';
import { TabsBaseComponent } from '../../_extended-components/tabs-base-component/tabs-base.component';
import { SafeHtmlPipe } from 'src/app/_shared-modules/safe-html-pipe-module/safehtml.pipe';

@Component({
  standalone: true,
  selector: 'app-generic-tabs',
  templateUrl: './generic-tabs.component.html',
  styleUrls: [
    '../../_base-component/base.component.sass',
    '../../_extended-components/tabs-base-component/tabs-base.component.sass',
    './generic-tabs.component.sass',
  ],
  imports: [
    CommonModule,
    MatTabsModule,
    SafeHtmlPipe
  ]
})
export class GenericTabsComponent
  extends TabsBaseComponent
  implements OnInit, AfterContentInit
{
  @ContentChildren(TemplateRef) public tmpls: QueryList<TemplateRef<unknown>>;
  @ViewChild('tabGroup', { static: false }) public tabGroup:
    | MatTabGroup
    | undefined;

  @Input({ required: true }) public hasUnderLineActive: boolean;
  @Input({ required: true }) public hasActiveState: boolean;
  @Input() public grow: boolean;

  @Input() public tabsBody: Array<string> = [];

  @Input({ required: true }) public defaultTab: number = 0;

  @Input({ required: true }) public tabsList: Array<TabItem>;

  @Output() public clickedTab: EventEmitter<string> =
    new EventEmitter<string>();

  public tmplsArray: Array<TemplateRef<unknown>> = [];

  constructor(
    public matIconRegistry: MatIconRegistry,
    public domSanitizer: DomSanitizer,
    public override host: ElementRef,
    public override libraryService: LibraryService
  ) {
    super(host, libraryService);
  }

  ngOnInit(): void {
    super.setCSSVars();
    super.setSpecificCSSVars();
  }

  ngAfterContentInit(): void {
    if (this.tabsBody.length === 0) {
      this.tmplsArray = this.tmpls.toArray();
    }
  }

  public emitClickedTab(event: number): void {
    this.clickedTab.emit(this.tabsList[event].id);
  }

  public selectTab(index: number): void {
    asapScheduler.schedule(() => {
      if (this.tabGroup !== undefined && this.tabGroup !== null) {
        this.tabGroup.selectedIndex = index;
      }
    });
  }
}
