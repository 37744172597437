<app-page-banner [title]="labelsService.newsBannerTitle | label" [backgroundImageUrl]="'assets/mock/environments/Gamifyou/blog/unsplash_ln5drpv_ImI.png'"
  [imageUrl]="'assets/mock/environments/Gamifyou/blog/gamifas_pc.png'" [containerHeight]="'36vh'" [pseudoElementColor]="environment.brandStyleVariables.pseudoElementHighlightColor"
  [alignImageBottom]="true" [imageSize]="'35%'">
</app-page-banner>

<div class="article-container">
  <p class="main-page-title">{{ labelsService.blogArticleDetailsPageTitle | label }}</p>

  @if (article.image.coverImage.href) {
    <div class="article-image-container mb-5">
      <img class="image" [src]="article.image.coverImage.href">
      <!-- <div class="image-texts">
      <p class="image-title" *ngIf="article.title">
        {{ article.title }}
      </p>
      <p class="image-description" *ngIf="article.description">
        {{ article.description }}
      </p>
    </div> -->
  </div>
}

<div class="article" [innerHTML]="article.articleContent | safehtml"></div>
</div>
