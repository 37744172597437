<mat-tab-group #tabGroup class="h-100" [(selectedIndex)]="defaultTab" [ngClass]="{'enable-line': hasUnderLineActive, 'enable-state': hasActiveState, 'grow': grow }" (selectedTabChange)="emitClickedTab($event.index)">
  @for (tab of tabsList; track $index) {
    <mat-tab [bodyClass]="tabsBody.length > 0 ? 'small-space' : ''">
      <ng-template mat-tab-label>
        @if (tab.hasIcon) {
          <i [class]="tab.icon"></i>
        }
          <span>{{tab.name}}</span>
      </ng-template>

      @if (tabsBody.length > 0) {
        <div id="tab-body-content" [innerHTML]="tabsBody[$index] | safehtml"></div>
      } @else {
        <ng-content *ngTemplateOutlet="tmplsArray[$index]"></ng-content>
      }
    </mat-tab>
  }
</mat-tab-group>
