import { Component, OnInit, ViewChild, ElementRef, Input, EventEmitter, Output, } from '@angular/core';
import { Rive } from "@rive-app/canvas";
import { GeneralService } from 'src/app/_services/_general-service/general.service';

@Component({
  selector: 'app-rive-animation-component',
  templateUrl: './rive-animation-component.component.html',
  styleUrls: ['./rive-animation-component.component.sass'],
  standalone: true
})
export class RiveAnimationComponent implements OnInit {
  @ViewChild('canvasRive', { static: false }) public canvasRive: ElementRef;

  @Input() animationFileUrl: string;
  @Input() loop: boolean = true;

  @Output() onLoad: EventEmitter<Rive> = new EventEmitter<Rive>()

  constructor(
    private generalService: GeneralService
  ) { }

  ngOnInit(): void {
    this.initiateRiveAnimation()
  }

  private initiateRiveAnimation(): void {
    this.generalService.oldAsyncFunction(() => {
      const r = new Rive({
        src: this.animationFileUrl,
        canvas: this.canvasRive.nativeElement,
        autoplay: true,
        onLoop: () => {
          if (this.loop)
            r.play();
          else
            r.pause()
        },
        onLoad: () => {
          r.resizeDrawingSurfaceToCanvas();
          this.onLoad.emit(r);
        },
      });

      window.addEventListener('resize', () => {
        r.resizeDrawingSurfaceToCanvas();
      });
    }, 0);
  }

}
