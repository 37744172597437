import { Injectable } from '@angular/core';
import { ADAPTER_EVENTS, CHAIN_NAMESPACES, CONNECTED_EVENT_DATA, SafeEventEmitterProvider, WALLET_ADAPTERS } from "@web3auth/base";
import { Web3Auth } from "@web3auth/modal";
import { PopupService } from 'src/app/_services/popup-service.service';
import { environment } from 'src/environments/environment';
import Web3 from "web3";
import RPC from "../../../../marketplace/rpc/ethersRPC";

@Injectable({
  providedIn: 'root'
})
export class WalletConnectionService {

  private web3auth: Web3Auth | null;
  private provider: SafeEventEmitterProvider | null;

  public hasExtension: boolean;
  public isModalLoaded: boolean = false;
  public isWalletConnected: boolean = false;
  public isLoginActive: boolean = false;

  public currentWallet: string = '';
  public walletAddress: string = '';

  constructor(
    private popUpService: PopupService
    ) { }

  public async initModal(): Promise<void> {
    /* const clientId = environment.web3ClientId; // get from https://dashboard.web3auth.io
    this.web3auth = new Web3Auth({
      clientId,
      chainConfig: {
        chainNamespace: CHAIN_NAMESPACES.EIP155,
        chainId: "0x13881", // hex of 80001, polygon testnet
        rpcTarget: "https://rpc.ankr.com/polygon_mumbai",
        // Avoid using public rpcTarget in production.
        // Use services like Infura, Quicknode etc
        displayName: "Polygon Testnet",
        blockExplorer: "https://mumbai.polygonscan.com/",
        ticker: "MATIC",
        tickerName: "Matic",
      },
      uiConfig: {
        theme: "light",
      }
    });

    const web3auth = this.web3auth;

    this.subscribeAuthEvents(web3auth!);
    await web3auth!.initModal({
      modalConfig: {
        [WALLET_ADAPTERS.METAMASK]: {
          label: 'metamask',
          showOnModal: false,
          showOnDesktop: false,
          showOnMobile: false,
        },
        [WALLET_ADAPTERS.WALLET_CONNECT_V2]: {
          label: 'walletconnect',
          showOnModal: true,
          showOnDesktop: true,
          showOnMobile: true
        },
        [WALLET_ADAPTERS.OPENLOGIN]: {
          label: 'openlogin',
          showOnModal: false
        },
        [WALLET_ADAPTERS.TORUS_EVM]: {
          label: 'torus',
          showOnModal: false
        }
      }
    });
    if (web3auth.provider) {
      this.provider = web3auth.provider;
    }
    this.isModalLoaded = true; */
  }

    /**
   * Takes a web3auth instance and subscribes to multiple events.
   *
   * @param web3auth - This param is the web3auth instance used to create the walletConnect QRCode.
   */

  private subscribeAuthEvents(web3auth: Web3Auth): void {
    web3auth.on(ADAPTER_EVENTS.CONNECTED, (data: CONNECTED_EVENT_DATA) => {
      this.isWalletConnected = true;
      this.currentWallet = 'WalletConnect';
      this.getAccounts().then(acc => {
        this.walletAddress = acc as string
      });
      // web3auth.provider will be available here after user is connected
    });
    web3auth.on(ADAPTER_EVENTS.CONNECTING, () => {
      // while user is connecting
    });
    web3auth.on(ADAPTER_EVENTS.DISCONNECTED, () => {
      // when user disconnects
      this.isLoginActive = false;
      this.currentWallet = '';
      this.walletAddress = '';
    });
    web3auth.on(ADAPTER_EVENTS.ERRORED, () => {
      // when an error occurs
      const error: string = "Request rejected by the user";
      this.openErrorPopUp(error);
      const headerBtn = document.getElementsByClassName('w3a-header__button')[0] as HTMLElement;
      headerBtn.click();
    });
    web3auth.on(ADAPTER_EVENTS.READY, () => {
      // when the adapter is ready.
      this.isLoginActive = true;
    });
  }

  public async walletLogin(): Promise<void> {
    if (!this.web3auth) {
      this.openErrorPopUp('web3auth not initialized yet');
      return;
    }
    const web3auth = this.web3auth;
    this.provider = await web3auth.connect();
  }

  public async getUserInfo(): Promise<void> {
    if (!this.web3auth) {
      console.log("web3auth not initialized yet");
      return;
    }
    const user = await this.web3auth.getUserInfo();
    console.log(user);
  }

  public async walletLogout(): Promise<void> {
    if (!this.web3auth) {
      console.log("web3auth not initialized yet");
      return;
    }
    await this.web3auth.logout();
    this.provider = null;
    this.isWalletConnected = false;
  }

  public async getChainId(): Promise<void> {
    /* if (!this.provider) {
      console.log("provider not initialized yet");
      return;
    }
    const rpc = new RPC(this.provider);
    const chainId = await rpc.getChainId(); */
  }

  public async getAccounts(): Promise<string | void> {
    /* if (!this.provider) {
      console.log("provider not initialized yet");
      return;
    }
    const rpc = new RPC(this.provider);
    const address = await rpc.getAccounts();
    return address; */
  }

  public async getBalance(): Promise<void> {
    /* if (!this.provider) {
      console.log("provider not initialized yet");
      return;
    }
    const rpc = new RPC(this.provider);
    const balance = await rpc.getBalance(); */
  }

  public async sendTransaction(): Promise<void> {
    /* if (!this.provider) {
      console.log("provider not initialized yet");
      return;
    }
    const rpc = new RPC(this.provider);
    const receipt = await rpc.sendTransaction(); */
  }

  public async signMessage(): Promise<void> {
    /* if (!this.provider) {
      console.log("provider not initialized yet");
      return;
    }
    const rpc = new RPC(this.provider);
    const signedMessage = await rpc.signMessage(); */
  }

  public async getPrivateKey(): Promise<void> {
    /* if (!this.provider) {
      console.log("provider not initialized yet");
      return;
    }
    const rpc = new RPC(this.provider);
    const privateKey = await rpc.getPrivateKey(); */
  }

  // Metamask

  public async checkMetamaskExtension(): Promise<void> {
    if(typeof window.ethereum === 'undefined') {
      this.hasExtension = false;
    } else {
      this.hasExtension = true;
    }
  }

  public async connectMetaMask(): Promise<void> {
    const accounts = await window.ethereum.request({ method: 'eth_requestAccounts' });

    if(accounts && Array.isArray(accounts)) {
      const account = accounts[0];
      if(account) {
        this.isWalletConnected = true;
        this.currentWallet = 'Metamask'
        this.walletAddress = account;
      }
    }
  }

  public openExtensionPopUp(): void {
    this.popUpService.setPopupToOpen(
      {
        text: ''
      },
      'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/popup-error-image.png',
      {text: 'Metamask Extension Missing!'},
      [this.popUpService.getSimpleDescription('You have to install the metamask extension in order use metamask.')],
      [{
        text: 'Close',
        isCloseBtn: true,
        actionText: '',
        style: {
          backgroundColor: 'var(--main-brand-color)',
          fontColor: '#ffffff'
        }
      }]
    );
  }

  public async checkAccConnection(): Promise<void> {
    let web3: any;
    if(window.ethereum) {
      web3 = new Web3(window.ethereum);
    } else if (window.web3) {
      web3 = new Web3(window.web3.currentProvider);
    }

    web3.eth.getAccounts().then(async (addr: string) => {
      this.isWalletConnected = true;
      this.currentWallet = 'Metamask';
      this.walletAddress = addr;
    });
  }

  public disconnectMetamask(): void {
    this.isWalletConnected = false;
    this.currentWallet = ''
    this.walletAddress = '';
  }

  // Error popUp for any errors that occur this can be for metamask or walletConnect
  /**
 * Takes an error and displays it on a popUp using the popUp setPopupToOpen inside the popupService.
 *
 * @param error - Error string with the error information to be displayed in the popUp.
 */
  private openErrorPopUp(error: string): void {
    this.popUpService.setPopupToOpen(
      {
        text: ''
      },
      'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/popup-error-image.png',
      {text: 'An error occurred'},
      [this.popUpService.getSimpleDescription(error)],
      [{
        text: 'Close',
        isCloseBtn: true,
        actionText: '',
        style: {
          backgroundColor: 'var(--main-brand-color)',
          fontColor: '#ffffff'
        }
      }]
    );
  }
}
