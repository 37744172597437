import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DeepCopy {

  public deepCopy<T>(obj: T): T {
    return Array.isArray(obj) // check if obj is of type Array
      ? obj.map(item => this.deepCopy(item))
      : obj instanceof Date
      ? new Date(obj.getTime())
      : obj && typeof obj === 'object'
        ? Object.getOwnPropertyNames(obj).reduce((o, prop) => {
            Object.defineProperty(o, prop, Object.getOwnPropertyDescriptor(obj, prop)!);
            o[prop] = this.deepCopy((obj as { [key: string]: any }) [prop]);
            return o;
        }, Object.create(Object.getPrototypeOf(obj))) : obj as T;
  }

}
