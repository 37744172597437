@if (loaded) {
  <div class="backdrop-container" [@BackdropFadeIn]="popupAnimationState"></div>
  <div class="tutorial-popup-container" [@ZoomIn]="popupAnimationState">
    <!-- ===================== LEFT SIDE ===================== -->
    <div class="tutorial-explanation-container">
      @if (!(generalService.isMobile | async)) {
        <img [src]="'assets/imgs/environments/'+ environment.tenantLocalAssetsPath +'/negative-logo.svg'" class="brand-logo">
      }
      <div class="text-container">
        @if (!(generalService.isMobile | async) || ((generalService.isMobile | async) && tutorialSteps[currentStep].subtitle)) {
          <p class="sub-title">{{ tutorialSteps[currentStep].subtitle }}</p>
        }
        @if (!(generalService.isMobile | async) || ((generalService.isMobile | async) && tutorialSteps[currentStep].title)) {
          <p class="title">{{ tutorialSteps[currentStep].title }}</p>
        }
        @if (!(generalService.isMobile | async) || ((generalService.isMobile | async) && !tutorialSteps[currentStep].subtitle && tutorialSteps[currentStep].description)) {
          <p class="description">{{ tutorialSteps[currentStep].description }}</p>
        }
      </div>
      @if (!(generalService.isMobile | async) && currentStep !== totalSteps) {
        <app-generic-button class="jump-tutorial-button"
          [minHeight]="'unset'"
          [buttonName]="labelService.tutorialSkipTutorialLabel | label"
          [hasIcon]="true"
          [iconSize]="'var(--large-section-title-font-size)'"
          [iconClass]="'bi bi-arrow-right-short'"
          [fontColor]="'white'"
          [fontSize]="'var(--header-font-size)'"
          [fontWeight]="'600'"
          [iconColor]="'white'"
          [backgroundColor]="'transparent'"
          [brandColor]="'transparent'"
          [buttonSpacing]="'0px'"
          (buttonAction)="skipTutorial()">
        </app-generic-button>
      }
    </div>
    <!-- ===================== RIGHT SIDE ===================== -->
    <div class="tutorial-image-container">
      @switch (tutorialSteps[currentStep].image.type) {
        @case (imageType.IMAGE) {
          <img [src]="tutorialSteps[currentStep].image.url">
        }
        @case (imageType.LOTTIE) {
          <ng-lottie
            [width]="'100%'"
            [height]="'100%'"
          [options]="{
            autoplay: true,
            loop: false,
            path: tutorialSteps[currentStep].image.url
          }">
          </ng-lottie>
        }
        @case (imageType.RIVE) {
          <canvas #canvasRive>
          </canvas>
        }
      }
      <div class="steps-container">
        @if (!(generalService.isMobile | async) && currentStep !== 0) {
          <div class="prev-step-btn">
            <app-generic-button [borderRadius]="'60px'" [fontWeight]="'700'" [brandColor]="'#323232'"
              [backgroundColor]="'#323232'"
              [disabledBackgroundColor]="environment.brandStyleVariables.mainBrandColorLight" [fontSize]="'var(--small-font-size)'" [fontColor]="'white'" [buttonName]="labelService.tutorialPrevStepButtonLabel | label"
              [buttonSize]="'min(160px, 17.5vw)'" [minHeight]="'48px'" (click)="changeStep(-1)">
            </app-generic-button>
          </div>
        }
        <div class="step-counter-container">
          @for (step of tutorialSteps; track step) {
            <div class="step-circle" [ngStyle]="{'background-color': step.selectedStep ? 'var(--main-brand-color)': '#EDEFF2' }"></div>
          }
        </div>
        @if (!(generalService.isMobile | async)) {
          <div class="next-step-btn" [ngClass]="{'first-step-btn' : currentStep === 0}">
            <app-generic-button [borderRadius]="'60px'" [fontWeight]="'700'" [brandColor]="environment.brandStyleVariables.mainBrandColor"
              [backgroundColor]="environment.brandStyleVariables.mainBrandColor"
              [disabledBackgroundColor]="environment.brandStyleVariables.mainBrandColorLight" [fontSize]="'var(--small-font-size)'" [fontColor]="'white'" [buttonName]="currentStep !== 0 ? (currentStep !== totalSteps ? (labelService.tutorialNextStepButtonLabel | label) : (labelService.tutorialLastStepButtonLabel | label)) : labelService.tutorialFirstStepButtonLabel | label"
              [buttonSize]="'min(160px, 17.5vw)'" [minHeight]="'48px'" (click)="currentStep !== totalSteps ? changeStep(1) : closeTutorial()">
            </app-generic-button>
          </div>
        }
      </div>
      @if ((generalService.isMobile | async)) {
        <div class="mobile-steps-container mt-3">
          @if (currentStep === 0) {
            <app-generic-button class="jump-tutorial-button"
              [buttonName]="labelService.tutorialSkipTutorialLabel | label"
              [hasIcon]="true"
              [minHeight]="'100%'"
              [iconSize]="'var(--large-section-title-font-size)'"
              [iconClass]="'bi bi-arrow-right-short'"
              [fontColor]="'var(--main-brand-color)'"
              [fontSize]="'var(--main-info-font-size)'"
              [fontWeight]="'600'"
              [iconColor]="'var(--main-brand-color)'"
              [backgroundColor]="'transparent'"
              [brandColor]="'transparent'"
              [buttonSpacing]="'0px'"
              [buttonSize]="'min(125px, 35vw)'"
              (buttonAction)="skipTutorial()">
            </app-generic-button>
          }
          @if (currentStep !== 0) {
            <div class="prev-step-btn">
              <app-generic-button [borderRadius]="'60px'" [fontWeight]="'700'" [brandColor]="'#323232'"
                [backgroundColor]="'#323232'"
                [disabledBackgroundColor]="environment.brandStyleVariables.mainBrandColorLight" [fontSize]="'var(--main-info-font-size)'" [fontColor]="'white'" [buttonName]="labelService.tutorialPrevStepButtonLabel | label"
                [buttonSize]="'min(125px, 35vw)'" [minHeight]="'48px'" (click)="changeStep(-1)">
              </app-generic-button>
            </div>
          }
          <div class="next-step-btn">
            <app-generic-button [borderRadius]="'60px'" [fontWeight]="'700'" [brandColor]="environment.brandStyleVariables.mainBrandColor"
              [backgroundColor]="environment.brandStyleVariables.mainBrandColor"
              [disabledBackgroundColor]="environment.brandStyleVariables.mainBrandColorLight" [fontSize]="'var(--main-info-font-size)'" [fontColor]="'white'"
              [buttonName]="currentStep !== 0 ? (currentStep !== totalSteps ? (labelService.tutorialNextStepButtonLabel | label) : (labelService.tutorialLastStepButtonLabel | label)) : labelService.tutorialFirstStepButtonLabel | label"
              [buttonSize]="'min(125px, 35vw)'" [minHeight]="'48px'" (click)="currentStep !== totalSteps ? changeStep(1) : closeTutorial()">
            </app-generic-button>
          </div>
        </div>
      }
    </div>
  </div>
}
