export default {
  "name": "EIP-3855",
  "number": 3855,
  "comment": "PUSH0 instruction",
  "url": "https://eips.ethereum.org/EIPS/eip-3855",
  "status": "Review",
  "minimumHardfork": "chainstart",
  "requiredEIPs": [],
  "gasConfig": {},
  "gasPrices": {
    "push0": {
      "v": 2,
      "d": "Base fee of the PUSH0 opcode"
    }
  },
  "vm": {},
  "pow": {}
};
