export default {
  "name": "EIP-4399",
  "number": 4399,
  "comment": "Supplant DIFFICULTY opcode with PREVRANDAO",
  "url": "https://eips.ethereum.org/EIPS/eip-4399",
  "status": "Review",
  "minimumHardfork": "london",
  "requiredEIPs": [],
  "gasConfig": {},
  "gasPrices": {},
  "vm": {},
  "pow": {}
};
