import { animate, state, style, transition, trigger } from '@angular/animations';
import { Component, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import { take } from 'rxjs';
import { BrandingInfo, UserDetails } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { environment } from 'src/environments/environment';
import { OnboardingService } from '../_services/onboarding.service';
import { GoalDefinitionContent, OnboardingStepInfo, OnboardingStepLightInfo, OnboardingStepLogicType, OnboardingStepType, OnboardingSubmitContent, Option, OptionStep } from '../onboarding.model';
import { CommonModule } from '@angular/common';
import { CircularStepsComponent } from '../../_shared-components/steps-module/circular-steps/circular-steps.component';
import { LabelPipe } from '../../../_shared-modules/label-pipe-module/label.pipe';
import { GenericButtonComponent } from 'src/app/_generic-components-lib/inputs/buttons/generic-button/generic-button.component';

@Component({
  selector: 'app-onboarding-container',
  templateUrl: './onboarding-container.component.html',
  styleUrls: ['./onboarding-container.component.sass'],
  animations: [
    trigger('slideIn', [
      state('hide', style({
        transform: 'translateY(-100%)'
      })),
      state('show', style({
        transform: 'translateY(0%)'
      })),
      transition('show => hide', animate('1000ms ease-in-out'))
    ]),
    trigger('fadeIn', [
      state('hide', style({
        opacity: 0
      })),
      state('show', style({
        opacity: 1
      })),
      transition('hide <=> show', animate('400ms ease-in-out'))
    ]),
  ],
  standalone: true,
  imports: [
    CommonModule,
    CircularStepsComponent,
    GenericButtonComponent,
    LabelPipe
  ]
})
export class OnboardingContainerComponent implements OnInit {
  @Output() close: EventEmitter<boolean> = new EventEmitter<boolean>();

  public onBoardingSlideAnimationState: string = 'hide';
  public onBoardingStepFadeAnimationState: string = 'hide';

  public brandInfo: BrandingInfo;

  public currentStep: number = 0;
  public totalSteps: number = 3;

  public environment = environment;

  public stepInfo: OnboardingStepInfo;
  public selectedOption: Option | undefined;

  public onboardingStepType = OnboardingStepType;

  public onboardingStepList: Array<OnboardingStepLightInfo>;

  constructor(
    public generalService: GeneralService,
    public labelsService: LabelsService,
    private onboardingService: OnboardingService,
    private host: ElementRef
  ) {

  }

  ngOnInit(): void {
    this.setCssVars();
    this.brandInfo = this.generalService.generalInfo.brandingInfo;
  }

  ngAfterViewInit(): void {
    this.onboardingInit();
  }

  private onboardingInit(): void {
    this.onboardingService.getOnboardingList().pipe(take(1)).subscribe((stepList: Array<OnboardingStepLightInfo>) => {
      this.onboardingStepList = stepList;

      this.onboardingService.getOnboardingStep(stepList[0].onboardingId).pipe(take(1)).subscribe((resp: OnboardingStepInfo) => {
        this.stepInfo = resp;

        this.generalService.oldAsyncFunction(() => {
          this.onBoardingSlideAnimationState = 'show';
          this.onBoardingStepFadeAnimationState = 'show';
        });
      });
    });
  }

  public prevButtonPressed(): void {
    this.updateCurrentStep(-1);
  }

  public nextButtonPressed(): void {
    const currentStepInfo = this.onboardingStepList[this.currentStep];
    switch(currentStepInfo.type) {

      case OnboardingStepType.MULTIPLE_CHOICE:
        const body: OnboardingSubmitContent = {
          onboardingId: currentStepInfo.onboardingId,
          content: this.getBodyContentBasedOnLogicType(currentStepInfo.logicType)
        };
        this.onboardingService.submitStep(body).subscribe(() => {
          this.selectedOption = undefined;
          this.updateCurrentStep(1);
        });
        break;

      default:
        this.updateCurrentStep(1);
        break;
    }
  }

  /**
   * This function returns the content with the correct model based on the logicType of the step
   *
   * @param logicType
   * @returns
   */
  private getBodyContentBasedOnLogicType(logicType: OnboardingStepLogicType): GoalDefinitionContent | undefined {
    let content: GoalDefinitionContent | undefined;

    switch(logicType) {
      case OnboardingStepLogicType.GOAL_DEFINITION:
        content = ({
          productId: this.selectedOption?.itemId
        } as GoalDefinitionContent)
        break;

      // whenever we want to add another logic type, we need to add it like the following
      /* case OnboardingStepLogicType.ANOTHER_LOGIC_TYPE:
        content = ({
          requiredObjectStructure
        } as AnotherLogicTypeContent);
        break; */
      default:
        break;
    }

    return content;
  }

  /**
   * Updates the current step and closes the onboarding if the current step is the last.
   * This needs to be async so that we can wait for the userDetails update on the last step in order to turn the onboarding and tutorial flags of the user to false.
   *
   * @param increment
   * @returns
   */
  private async updateCurrentStep(increment: number): Promise<void> {

    if (this.currentStep + increment >= this.totalSteps) {
      this.closeOnboarding();
      return;
    } else if (this.currentStep + increment === this.totalSteps - 1) {
      const updatedUserDetails: UserDetails = {
        showTutorial: false,
        showOnboarding: false
      };

      await this.generalService.updateUserDetails(updatedUserDetails).pipe(take(1)).subscribe(() => {
        this.generalService.getUserDetails().pipe(take(1)).subscribe();
      });
    }

    this.currentStep += increment;

    this.currentStep = Math.max(this.currentStep, 0);
    this.currentStep = Math.min(this.currentStep, this.totalSteps);

    this.onBoardingStepFadeAnimationState = 'hide';
    this.onboardingService.getOnboardingStep(this.onboardingStepList[this.currentStep].onboardingId).pipe(take(1)).subscribe((resp: OnboardingStepInfo) => {
      this.onBoardingStepFadeAnimationState = 'show';
      this.stepInfo = resp;
    });
  }

  public closeOnboarding(): void {
    this.onBoardingSlideAnimationState = 'hide';
    this.close.emit(true);
  }

  public optionSelected(option: Option): void {
    (this.stepInfo.content as OptionStep).options?.forEach(c => c.selected = false);
    option.selected = true;

    this.selectedOption = option;
  }

  private setCssVars(): void {
    this.host.nativeElement.style.setProperty('--step-card-background-color', environment.brandStyleVariables.onboardingStepCardBackground);
    this.host.nativeElement.style.setProperty('--top-pseudo-element', `url('../../../../assets/imgs/environments/${environment.tenantName}/onboarding/top-pseudo-element.svg')`);
    this.host.nativeElement.style.setProperty('--bottom-pseudo-element', `url('../../../../assets/imgs/environments/${environment.tenantName}/onboarding/bottom-pseudo-element.svg')`);
  }
}
