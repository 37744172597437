import { CommonModule } from '@angular/common';
import { Component, DestroyRef, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HeaderItem } from '../__models/list/list-header.model';
import { LibraryService } from '../__services/library.service';
import { ListBaseComponent } from '../_extended-components/list-base-component/list-base.component';
import { CheckboxComponent } from '../inputs/checkbox-input/checkbox.component';

@Component({
  standalone: true,
  selector: 'app-list-header',
  templateUrl: './list-header.component.html',
  styleUrls: ['./list-header.component.sass'],
  imports: [
    CommonModule,
    CheckboxComponent,
    ReactiveFormsModule,
    FormsModule,
  ]
})
export class ListHeaderComponent extends ListBaseComponent implements OnInit, OnChanges {
  private destroyRef = inject(DestroyRef);

  @Input({required: true}) public fullSize: boolean;

  @Input({required: true}) public headerList: Array<HeaderItem>;
  @Input() public sortFilter: number = 0;
  @Input() public showArrows: boolean = true;
  @Input() public clickable: boolean = true;
  @Input() public padding: string;
  @Input() public disableCheckbox: boolean = false;
  @Input() public detailsClosed: boolean = false;
  @Input() public firstItemHasImg: boolean = false;

  @Input() public activeCheckboxColor: string;

  @Output() public headerEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() public itemCheckboxSelected: EventEmitter<boolean> = new EventEmitter<boolean>();

  public itemForm = this.fb.group({
    itemCheck: new FormControl<boolean>(false, [])
  });

  constructor(
    public override host: ElementRef,
    public override libraryService: LibraryService,
    private fb: FormBuilder
  ) {
    super(host, libraryService);
  }

  ngOnInit(): void {
    this.itemCheckControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: (value: boolean) => {
        this.checkBoxClicked(value);
      }
    });

    super.setCSSVars();
    super.setSpecificCSSVars();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['disableCheckbox']) {
      if (this.disableCheckbox) {
        this.itemCheckControl.disable();
      } else {
        this.itemCheckControl.enable();
      }
    }
  }

  public get itemCheckControl(): FormControl {
    return this.itemForm.controls['itemCheck'];
  }

  private checkBoxClicked(event: boolean): void {
    this.itemCheckboxSelected.emit(event);
  }

  public clickHeader(headerId: number): void {
    if (this.clickable) {
      this.headerEvent.emit(headerId);
    }
  }
}
