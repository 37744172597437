<div class="footer-container">
  <!-- ########################## Brand Info ########################## -->
  <div class="brand-info-container">
    <img [src]="brandInfo.brandImageUrl" class="brand-image">
    @if (labelsService.footerBrandDescription | label) {
      <p class="brand-description mt-3">
        {{ labelsService.footerBrandDescription | label }}
      </p>
    }

    <div class="contacts-container mt-5">
      <!-- <a class="contact-container" [href]="'tel:'+brandInfo.phoneContact">
      <div class="icon-container" [ngStyle]="{'background-color': 'var(--footer-button-background-color)'}">
        <img [src]="'assets/imgs/phone-icon.svg'">
      </div>

      <div class="contact-info ms-2">
        <span class="contact-label" *ngIf="labelsService.footerContactsPhoneTitle | label">{{ labelsService.footerContactsPhoneTitle | label }}</span>
        <span class="contact">{{ brandInfo.phoneContact }}</span>
      </div>
    </a> -->

    <a class="contact-container" [href]="'mailto:'+brandInfo.emailContact">
      <div class="icon-container" [ngStyle]="{'background-color': 'var(--footer-button-background-color)'}">
        <img [src]="'assets/imgs/email-icon.svg'">
      </div>

      <div class="contact-info ms-2">
        @if (labelsService.footerContactsEmailTitle | label) {
          <span class="contact-label">{{ labelsService.footerContactsEmailTitle | label }}</span>
        }
        <span class="contact">{{ brandInfo.emailContact }}</span>
      </div>
    </a>

  </div>

  <div class="navigation-container mt-5">
    @for (item of urlList; track item) {
      @if (item.enabled) {
        <a [routerLink]="item.url" class="me-3">
          {{ item.name | label }}
        </a>
      }
    }
  </div>
</div>

<!-- ########################## Newsletter ########################## -->
<div class="newsletter-container">
  @if (labelsService.footerNewsletterTitle | label) {
    <p class="newsletter-title">{{ labelsService.footerNewsletterTitle | label }}</p>
  }

  @if (labelsService.footerNewsletterDescription | label) {
    <p class="newsletter-description mt-2">
      {{ labelsService.footerNewsletterDescription | label }}
    </p>
  }

  <div class="newsletter-subscription-form mt-3">
    <img class="email-icon" src="assets/imgs/email-icon.svg">
    <input type="text" id="newsletter-input" [placeholder]="labelsService.footerNewsletterPlaceholder | label" [(ngModel)]="newsletterEmail">
    <app-generic-button
      [minHeight]="'unset'"
      [brandColor]="environment.brandStyleVariables.mainBrandColor" [backgroundColor]="environment.brandStyleVariables.mainBrandColor" [fontColor]="environment.brandStyleVariables.mainBackgroundColor"
      [borderRadius]="'20vw'" [fontWeight]="'500'"
      [buttonName]="labelsService.footerNewsletterSubmitBtn | label" [fontSize]="'var(--small-font-size)'" [buttonSize]="'123px'" [buttonSpacing]="'max(0.6vw, 9.45px) max(1.5vw, 30px)'" (click)="submitNewsletter()"></app-generic-button>
  </div>

  @if (brandInfo.socialMediaList && brandInfo.socialMediaList.length > 0) {
    <div class="social-media-list mt-4">
      @for (sm of brandInfo.socialMediaList; track sm) {
        <div class="social-media-item me-3" (click)="navigateOutside(sm.redirectUrl)">
          <div class="social-media-icon" [ngStyle]="{'mask-image' : 'url(' + sm.iconUrl + ')', '-webkit-mask-image': 'url(' + sm.iconUrl + ')' }"></div>
        </div>
      }
    </div>
  }

  @if (labelsService.footerAllRightsReserved | label) {
    <div class="copyrights-container mt-4">
      {{ labelsService.footerAllRightsReserved | label }}
    </div>
  }
</div>

<!-- ########################## Navigation & Copy Rights ########################## -->
<div class="navigation-copyrights-container mt-3">
  <div class="navigation-container mt-3">
    @for (item of urlList; track item) {
      @if (item.enabled) {
        <a [href]="item.url" class="me-3">
          {{ item.name | label }}
        </a>
      }
    }
  </div>

  @if (labelsService.footerAllRightsReserved | label) {
    <div class="copyrights-container mt-4">
      {{ labelsService.footerAllRightsReserved | label }}
    </div>
  }
</div>

@if (environment.showVersion) {
  <div class="app-version-container">
    {{appVersion}}v
  </div>
}
</div>
