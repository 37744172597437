<div class="main-profile-container">
  <div class="profile-banner">
    <img [src]="userDetails.images!.coverImage && userDetails.images!.coverImage.href ? userDetails.images!.coverImage.href : 'assets/mock/my-account-banner.png'">
    <!--     <div class="profile-banner-edit-btn" *ngIf="isDetailsOpen">
    <app-generic-button *ngIf="!(generalService.isMobile | async)" [buttonName]="labelService.myAccountEditCoverButton | label" [borderRadius]="'30'"
      [brandColor]="environment.brandStyleVariables.mainBackgroundColor" [fontColor]="environment.brandStyleVariables.mainBrandColor" [fontSizePx]="'12'" (click)="updateBannerPicture()">
    </app-generic-button>
  </div> -->
  <div class="profile-picture-wrapper">
    <!-- <div class="profile-picture-edit-btn" *ngIf="isDetailsOpen" (click)="updateProfilePicture()">
    <div class="profile-picture-edit-icon"></div>
  </div> -->
  <div class="profile-picture-container">
    <img [src]="userDetails.images!.profilePictureImage && userDetails.images!.profilePictureImage.href ? userDetails.images!.profilePictureImage.href : 'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/default-images/default-profile-image.svg'">
  </div>
</div>
</div>

<div class="profile-user-info-wrapper">
  <div class="profile-user-info-container">
    <div class="profile-user-name">
      <p>{{userDetails.firstName}} {{userDetails.lastName}}</p>
    </div>

    <div class="profile-button-wrapper">
      <!-- <div class="profile-button-logout-container" *ngIf="!isDetailsOpen">
      <app-generic-button *ngIf="!isDetailsOpen" [buttonName]="labelService.myAccountLogoutButton | label" [borderRadius]="'30'"
        [backgroundColor]="environment.brandStyleVariables.mainErrorColor"
        [brandColor]="environment.brandStyleVariables.mainErrorColor" [fontColor]="environment.brandStyleVariables.mainBackgroundColor" [fontSizePx]="'var(--small-font-size)'" (click)="logOut()">
      </app-generic-button>
    </div> -->

    @if (isDetailsOpen) {
      <div class="profile-button-edit-container">
        @if (!iseditModeOn) {
          <app-generic-button [buttonName]="labelService.myAccountEditInformationButton | label" [borderRadius]="'30px'"
            [backgroundColor]="environment.brandStyleVariables.mainBrandColor"
            [brandColor]="environment.brandStyleVariables.mainBrandColor" [fontColor]="environment.brandStyleVariables.mainBackgroundColor" [fontSize]="'var(--small-font-size)'" (click)="toggleEdit()"
            [minHeight]="'unset'">
          </app-generic-button>
        }
        @if (iseditModeOn) {
          <app-generic-button [buttonName]="labelService.myAccountSaveInformationButton | label" [borderRadius]="'30px'"
            [backgroundColor]="environment.brandStyleVariables.mainBrandColor"
            [brandColor]="environment.brandStyleVariables.mainBrandColor" [fontColor]="environment.brandStyleVariables.mainBackgroundColor" [fontSize]="'var(--small-font-size)'" (click)="saveInformation()"
            [minHeight]="'unset'">
          </app-generic-button>
        }
        <app-generic-button [buttonName]="!iseditModeOn ? (labelService.myAccountBackButton | label) : (labelService.myAccountCancelButton | label)" [borderRadius]="'30px'"
          [backgroundColor]="environment.brandStyleVariables.mainErrorColor"
          [brandColor]="environment.brandStyleVariables.mainErrorColor" [fontColor]="environment.brandStyleVariables.mainBackgroundColor" [fontSize]="'var(--small-font-size)'" (click)="!iseditModeOn ? closeDetails(false) : cancelEdit() "
          [minHeight]="'unset'">
        </app-generic-button>
      </div>
    }
  </div>

  <div class="profile-account-buttons-wrapper w-100">
    <div class="profile-account-buttons-title">
      @if (isDetailsOpen) {
        {{'my_account_title' | label}}
      } @else {
        {{'my_account_details_title' | label}}
      }
    </div>

    @if (!isDetailsOpen) {
      <div class="profile-account-buttons-container">
        @for (card of menuCards; track card) {
          <app-info-card [cardTitle]="card.title" [cardIcon]="card.icon"
            [cardDescription]="card.description" [brandColor]="environment.brandStyleVariables.mainBrandColor"
          [hasUrl]="card.hasUrl" (buttonClicked)="card.hasUrl ? this.redirectToPage(card.url!) : openDetails($event)"></app-info-card>
        }
      </div>
    }
  </div>

  @if (isDetailsOpen) {
    <div class="personal-details-wrapper w-100" [@Fade]="animationState">
      <app-account-details class="w-100" #detailsComponent [userDetails]="userDetails" [resetForm]="resetEdit" [editMode]="iseditModeOn" (formIsReset)="resetEdit = $event" (updateUserDetails)="updatedDetails = $event"></app-account-details>
    </div>
  }
</div>
</div>
</div>
