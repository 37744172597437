import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { ProductInfo } from '../our-products.model';
import { NgStyle, CommonModule } from '@angular/common';
import { SafeHtmlPipe } from '../../../_shared-modules/safe-html-pipe-module/safehtml.pipe';

@Component({
  selector: 'app-our-product-banner',
  templateUrl: './our-product-banner.component.html',
  styleUrls: ['./our-product-banner.component.sass'],
  standalone: true,
  imports: [
    CommonModule,
    SafeHtmlPipe
  ]
})
export class OurProductBannerComponent implements OnInit {

  @Input() productInfo: ProductInfo;
  @Input() flexFlow: string;

  constructor(
    private host: ElementRef
    ) { }

  ngOnInit(): void {
    this.setCSSVars();
  }

  private setCSSVars(): void {
    this.host.nativeElement.style.setProperty('--pseudo-element-color', this.productInfo.mainColor);
  }

}
