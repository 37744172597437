<div class="no-items-wrapper">
  <div class="no-items-container">

    <img [src]="'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/icons/empty-list-top-icon.svg'" class="empty-list-top-icon">

    <div class="no-items-icon">
      <img [src]="image" [alt]="imageAlt" />
    </div>

    <div class="no-items-information">
      <div class="no-items-title">
        {{ title }}
      </div>

      @if (description) {
        <div class="no-items-description">
          {{ description }}
        </div>
      }
    </div>

    @if (showRedirectBtn) {
      <app-generic-button class="mt-4"
        [minHeight]="'unset'"
        [brandColor]="environment.brandStyleVariables.mainBrandColor" [backgroundColor]="environment.brandStyleVariables.mainBrandColor" [fontColor]="environment.brandStyleVariables.mainBackgroundColor"
        [borderRadius]="'20vw'" [fontWeight]="'700'"
        [buttonName]="redirectBtnText" [fontSize]="'var(--header-font-size)'" [buttonSize]="'123px'" [buttonSpacing]="'max(0.6vw, 9.45px) max(1.5vw, 30px)'" (click)="redirectBtnClicked.emit(true)">
      </app-generic-button>
    }

    <img [src]="'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/icons/empty-list-bottom-icon.svg'" class="empty-list-bottom-icon">
  </div>
</div>
