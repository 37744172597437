import { Component, ElementRef, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'src/environments/environment';
import { GenericButtonComponent } from "../../../../_generic-components-lib/inputs/buttons/generic-button/generic-button.component";


@Component({
  selector: 'app-no-items',
  templateUrl: './no-items.component.html',
  styleUrls: ['./no-items.component.sass'],
  standalone: true,
  imports: [GenericButtonComponent]
})
export class NoItemsComponent implements OnInit {

  @Input() titleColor: string;
  @Input() descriptionColor: string;
  @Input() titleFontSize: string;
  @Input() titleFontWeight: string;
  @Input() descriptionFontSize: string;

  @Input() image: string;
  @Input() imageAlt: string;
  @Input() title: string;
  @Input() description: string;

  @Input() showRedirectBtn: boolean = false;
  @Input() redirectBtnText: string;

  @Output() redirectBtnClicked: EventEmitter<boolean> = new EventEmitter<boolean>();


  public environment = environment;

  constructor(private host: ElementRef) { }

  ngOnInit(): void {
    this.setCSSVars();
  }

  private setCSSVars(): void {
    if(this.titleColor || this.descriptionColor || this.titleFontSize || this.descriptionFontSize || this.titleFontWeight) {
      if(this.titleColor) {
        this.host.nativeElement.style.setProperty('--title-color', this.titleColor)
      }

      if(this.descriptionColor) {
        this.host.nativeElement.style.setProperty('--description-color', this.descriptionColor);
      }

      if(this.titleFontSize) {
        this.host.nativeElement.style.setProperty('--title-font-size', this.titleFontSize);
      }

      if(this.descriptionFontSize) {
        this.host.nativeElement.style.setProperty('--description-font-size', this.descriptionFontSize);
      }

      if(this.titleFontWeight) {
        this.host.nativeElement.style.setProperty('--title-font-weight', this.titleFontWeight);
      }
    }
  }
}
