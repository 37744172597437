<div class="product-payment-page-wrapper">
  <app-page-banner
    [smallBanner]="true"
    [title]="labelService.marketplaceBannerTitle | label"
    [imageUrl]="'assets/mock/marketplace-nft-image.png'"
    [backgroundImageUrl]="'assets/mock/marketplace-hero-image.png'"
    >
  </app-page-banner>

  @if (isLoaded) {
    <div class="product-payment-wrapper">
      <p class="product-payment-small-title">{{labelService.paymentDetailsPaymentType | label}}</p>
      <div class="product-payment-container">
        <div class="product-payment-header-container">
          <div class="product-payment-header">
            <p class="product-payment-title">{{labelService.paymentDetailsTitle | label}}</p>
            <span class="product-payment-warning">{{labelService.paymentDetailsWarning | label}}</span>
          </div>
          <div class="product-payment-logo">
            <img src="assets/imgs/mbway-icon.svg" alt="">
          </div>
        </div>
        <!--       <div class="product-payment-address-wrapper">
        <div class="product-payment-address-container">
          <p>{{labelService.paymentDetailsFiscalAddress}}</p>
          <span>userDetails.fiscalAddress</span>
        </div>
        <div class="product-payment-address-edit-button-container">
          <app-generic-button [borderRadius]="'60'" [brandColor]="'environment.brandStyleVariables.mainBrandColorLighter'" [fontSizePx]="(generalService.isMobile | async) ? '14px' : '12px'"
            [backgroundColor]="environment.brandStyleVariables.mainBrandColorLighter"
            [fontColor]="environment.brandStyleVariables.mainBrandColor" [fontWeight]="'700'" [smallButton]="(generalService.isMobile | async)" [minHeightPx]="'48px'" [buttonName]="labelService.paymentDetailsEditAddressBtn | label" (click)="openEditDetails()">
          </app-generic-button>
        </div>
      </div> -->
      <div class="product-payment-details-wrapper">
        <div class="product-payment-details-container">
          <div class="product-phone-payment-information">
            <p>{{labelService.paymentDetailsPhoneNumberTitle | label }}</p>
            <form [formGroup]="purchaseFormContainer">
              <!-- TODO FIX <app-generic-phone-input
              [selectedCountryISO]="CountryISO.Portugal"
              [label]="labelService.paymentDetailsPhoneNumberLabel | label" [placeholderLabel]="labelService.paymentDetailsPhoneNumberPlaceholder | label"
              [searchPlaceholderLabel]="'Search for a country'" [inputHeight]="'64px'" [fontSize]="'var(--main-info-font-size)'" [labelFontWeight]="'500'" [borderRadius]="'calc(var(--main-info-font-size) * 3)'"
              [fontColor]="environment.brandStyleVariables.mainFontColor" [errorColor]="environment.brandStyleVariables.mainErrorColor"
              [backgroundColor]="'#F0F1F2'" [roundBorders]="true" [fCName]="'phoneInput'" [fGName]="'purchaseForm'"
              >
            </app-generic-phone-input> -->
            @if ((purchaseFormContainer.get('purchaseForm')?.get('phoneInput')?.touched
              && (purchaseFormContainer.get('purchaseForm')?.get('phoneInput')?.hasError('required')))) {
              <span class="input-error"
                >
                <img src="assets/imgs/error-icon.svg">
                {{labelService.myAccountDetailsErrorField | label}}
              </span>
            }
            @if ((purchaseFormContainer.get('purchaseForm')?.get('phoneInput')?.touched
              && purchaseFormContainer.get('purchaseForm')?.get('phoneInput')?.hasError('validatePhoneNumber'))) {
              <span class="input-error"
                >
                <img src="assets/imgs/error-icon.svg">
                {{labelService.myAccountDetailsErrorTextField | label}}
              </span>
            }
          </form>
        </div>
        <!-- <div class="product-wallet-payment-information">
        <p>{{labelService.paymentDetailsWalletAddressTitle | label }}</p>
        <app-single-line-text-input
          [label]="labelService.paymentDetailsWalletAddressLabel | label" [placeholderLabel]="labelService.paymentDetailsWalletAddressPlaceholder | label" [labelFontWeight]="500" [inputHeightPx]="64" [fontSize]="'var(--main-info-font-size)'"
          [backgroundColor]="environment.brandStyleVariables.mainBackgroundColor" [roundBorders]="true" [roundedBorderPx]="30" [fontColor]="environment.brandStyleVariables.mainFontColor" [borderColor]="environment.brandStyleVariables.mainBorderColor"
          [prefixMarginPx]="20" [isSuffixIcon]="true" [inputValue]="walletString" (valueChanged)="walletString = $event">
        </app-single-line-text-input>
      </div> -->
    </div>
    <div class="product-details-information-wrapper">
      <div class="product-price-information-container">
        <p class="product-price-title">{{labelService.paymentDetailsPriceTitle | label }}</p>
        @switch (productsService.productInfo.prices[0].unit) {
          @case ('EUR') {
            <p class="product-price">{{productsService.productInfo.prices[0].amount}} €</p>
          }
          @case ('ETH') {
            <p class="product-price">{{productsService.productInfo.prices[0].amount}} €</p>
          }
        }
      </div>
      <div class="product-details-information-container">
        <p>{{labelService.paymentDetailsOrderSummary | label }}</p>
        <div class="product-details-information-img">
          <img [src]="this.productsService.productInfo.images ? this.productsService.productInfo.images[0].href : 'assets/imgs/environments/'+environment.tenantName+'/default-images/default-image-small-rectangle.svg'">
        </div>
        <div class="product-details-information">
          <div class="product-name">{{ this.productsService.productInfo.name }}</div>
          @if (this.productsService.productInfo.collection) {
            <div class="collection-name">{{ this.productsService.productInfo.collection.name }}</div>
          }
          <div class="serial-number">{{ this.productsService.productInfo.serial }}</div>
        </div>
      </div>
    </div>
  </div>
  <div class="product-payment-footer-container">
    <div class="product-policy-container">
      <!-- <app-checkbox [label]="labelService.paymentDetailsMarketTerms | label" [flexDirection]="'row-reverse'" [gapSize]="10"
      [fontSizePx]="(generalService.isMobile | async) ? 14 : 16" [fontColor]="environment.brandStyleVariables.mainBorderColor" [inputSizePx]="16" [fontWeight]="500" [checked]="termsAccepted" (valueChanged)="termsAccepted = $event">
    </app-checkbox> -->
    <p>{{labelService.paymentDetailsSecureLabel | label}}</p>
  </div>
  <div class="action-buttons-container">
    <!-- <app-generic-button class="cancel-button" [borderRadius]="'60'" [fontWeight]="'700'" [brandColor]="environment.brandStyleVariables.mainErrorColor" [fontSizePx]="(generalService.isMobile | async) ? '12px' : '18px'"
    [backgroundColor]="environment.brandStyleVariables.mainErrorColor"
    [fontColor]="'white'" [smallButton]="(generalService.isMobile | async)" [minHeightPx]="'48px'" [buttonName]="labelService.paymentDetailsCancelBtn | label" (click)="cancelPurchase()">
  </app-generic-button> -->
  <app-generic-button class="purchase-button" [borderRadius]="'60px'" [fontWeight]="'700'" [brandColor]="environment.brandStyleVariables.mainBrandColor" [backgroundColor]="environment.brandStyleVariables.mainBrandColor"
    [disabledBackgroundColor]="environment.brandStyleVariables.mainBrandColorLight" [fontSize]="'var(--small-font-size)'" [fontColor]="'white'" [buttonName]="labelService.paymentDetailsSubmitBtn | label"
    [buttonSize]="(generalService.isMobile | async) ? '123px' : '146px'" [minHeight]="'48px'" [isDisabled]="!purchaseFormContainer.get('purchaseForm')?.get('phoneInput')?.valid || purchaseFormContainer.get('purchaseForm')?.get('phoneInput')?.value === '' ? true : false" (click)="!purchaseFormContainer.get('purchaseForm')?.get('phoneInput')?.valid || purchaseFormContainer.get('purchaseForm')?.get('phoneInput')?.value === '' ? '' : this.executePayment()">
  </app-generic-button>
</div>
</div>
</div>
</div>
}

</div>

@if (showConfirmPopup || showPopup) {
  <div class="popup-wrapper">
    <!-- TODO Fix <app-generic-popup *ngIf="showConfirmPopup"
    [closeClickOutside]="false"
    [preImgTitle]="popupToShow.preImgTitle!"
    [image]="popupToShow.image!"
    [title]="popupToShow.title!"
    [descriptions]="popupToShow.descriptions!"
    [buttons]="popupToShow.buttons!"
    (popUpClosed)="showConfirmPopup = false"
    (buttonClicked)="popupActionClicked($event)">
  </app-generic-popup> -->
  @if (showPopup) {
    <div class="backdrop-container" [@BackdropFadeIn]="popupAnimationState"></div>
    <div class="loading-popup-container" [@ZoomIn]="popupAnimationState">
      @if (paymentResult === 'pending') {
        <p class="popup-title">
          {{ labelService.paymentDetailsLoadingPopupTitle | label: {number: phoneString} }}
        </p>
      }
      <div class="popup-animation-container mt-3">
        <ng-lottie
          [width]="'250px'"
          [height]="'250px'"
          [options]="options">
        </ng-lottie>
      </div>
      @if (paymentResult === 'pending') {
        <div class="popup-progress-container mt-3">
          <mat-progress-bar class="w-100" mode="indeterminate"></mat-progress-bar>
        </div>
      }
      <p class="popup-description mt-3">
        @switch (paymentResult) {
          @case ('pending') {
            {{ labelService.paymentDetailsLoadingPopupDescription | label }}
          }
          @case ('success') {
            {{ labelService.paymentDetailsSuccessPopupDescription | label }}
          }
        }
      </p>
    </div>
  }
</div>
}
