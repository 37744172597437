@if (isLoaded) {
  <div class="section activities-container">
    <p class="section-title">{{ labelService.activitiesSectionTitle | label }}</p>
    <p class="section-description">{{ labelService.activitiesSectionDescription | label }}</p>
    <!-- <div class="activities-filters-header-container" *ngIf="filterList && filterList.length > 0"> -->
    <div class="activities-filters-header-container">
      <div class="search-container">
        <form [formGroup]="form">
          <app-single-line-text-input
            [control]="searchControl"
            [formControlName]="'search'"
            [placeholderLabel]="labelService.activitiesFiltersSearchPlaceholder | label" [inputHeight]="'48px'" [fontSize]="'var(--main-info-font-size)'"
            [backgroundColor]="environment.brandStyleVariables.inputBackgroundColor" [roundBorders]="true" [borderRadius]="'calc(var(--main-info-font-size) * 3)'" [borderColor]="'transparent'"
            [prefixMargin]="'20px'" [isSuffixIcon]="true" [inputValue]="searchString" [hasSuffix]="true"
            [inactiveSuffix]="'bi bi-search'" [activeSuffix]="'bi bi-search'" [alignLeft]="true"
            [brandColor]="'var(--main-brand-color)'" [debounceTime]="0"
            (suffixClicked)="search()" (actionOnEnter)="search()">
          </app-single-line-text-input>
        </form>
        <!--       <app-toggle-button class="d-flex align-items-center" [imageAsButton]="(generalService.isMobile | async)"
        [buttonText]="(generalService.isMobile | async) ? '' : showFilters ? (labelService.activitiesFiltersButtonCloseLabel | label) : (labelService.activitiesFiltersButtonLabel | label)" [fontSizePx]="(generalService.isMobile | async) ? 20 : 12"
        [brandColor]="environment.brandStyleVariables.mainBrandColor" [fontColor]="environment.brandStyleVariables.mainBackgroundColor" [toggleOnBgColor]="environment.brandStyleVariables.mainErrorColor"
        [toggleOnFtColor]="environment.brandStyleVariables.mainBackgroundColor" [hoveredIconColor]="environment.brandStyleVariables.mainBackgroundColor"
        [imageUrl]="'assets/imgs/filter-icon.svg'" [defaultIconColor]="environment.brandStyleVariables.mainBackgroundColor"
        [isActive]="showFilters"
      (buttonActive)="showFilters = $event"></app-toggle-button> -->
    </div>
  </div>
  <!-- TODO -->
  <div class="activities-list-wrapper">
    <div class="activities-list-container">
      <!--       <div class="filter-chips-container" *ngIf="!(generalService.isMobile | async)" [ngClass]="{'with-items': filterChips.length > 0}">
      <div class="chip" *ngFor="let chip of filterChips">
        <span>{{chip.name}}</span>
        <div class="delete-icon-container" (click)="deleteChip(chip)">
          <div class="close-icon">
            <span class="cross-line"></span>
          </div>
        </div>
      </div>
    </div> -->
    <div class="activity-list-container w-100"
      infiniteScroll
      [infiniteScrollDistance]="listPercentageFromBottom"
      [infiniteScrollThrottle]="50"
      [immediateCheck]="true"
      [scrollWindow]="false"
      [infiniteScrollContainer]="mainComponent.scrollableContainerRef"
      (scrolled)="onScroll()"
      #activityContainerList>
      @if (activityList.length > 0 || isListLoading) {
        <div class="activity-list-wrapper">
          @for (activity of activityList; track activity) {
            <app-list-item-card class="activity-list-card"
              [isBlocked]="activity.type === ActivityTypes.MULTI_STEP ? activity.totalCompletedSubActivityPercentage === 100 : false"
              [image]="activity.images && activity.images.length > 0 ? activity.images[0].href : 'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/default-images/default-image-small-rectangle.svg'"
              [title]="activity.name" [highlighted]="activity.type === ActivityTypes.INPUT" [highlightBackgroundColor]="'var(--main-brand-color)'" [highlightFontColor]="environment.brandStyleVariables.mainBackgroundColor"
              [itemType]="activity.type" [itemTypeImage]="activity.type === ActivityTypes.INPUT ? 'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/activities/'+ (activity.type | lowercase) + '-activity-type-icon.png' : ''"
              [shadowBrandColor]="'rgba(0, 0, 0, 0.15)'" (click)="activityItemClicked(activity.activityId)" >
              <ng-template>
                <div class="w-100">
                  <app-activity-list-item [activity]="activity" [showProgress]="true"></app-activity-list-item>
                </div>
              </ng-template>
            </app-list-item-card>
          }
          @if (isListLoading) {
            @for (item of loadingListItems; track item) {
              <app-loading-list-item class="loading-item" [ngClass]="{'opacity-0': !isListLoading}">
                <ng-template>
                  <app-activity-loading-content class="w-100"></app-activity-loading-content>
                </ng-template>
              </app-loading-list-item>
            }
          }
        </div>
      } @else {
        <div class="no-items-found">
          <app-no-items [image]="'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/empty-list-image.png'" [title]="labelService.activityListNoItems | label"></app-no-items>
        </div>
      }
    </div>
  </div>
  <!--     <div class="activities-desktop-filters-container custom-scroll" [ngClass]="{'active' : !(generalService.isMobile | async) && showFilters}">
  <app-filters-list [filterList]="filterList" [isMobile]="(generalService.isMobile | async)" [activeFilterCount]="activeFilterCount" (filtersUpdated)="filtersUpdated()" (close)="showFilters = false"></app-filters-list>
</div> -->
</div>
</div>
}
