<div class="onboarding-container" [@slideIn]="onBoardingSlideAnimationState">
  <div class="header-container">
    <img [src]="brandInfo.brandImageUrl" [alt]="brandInfo.clientName" class="logo">
  </div>

  <div class="onboarding-step-container custom-scroll" [ngStyle]="{ 'background-image': 'url(assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/onboarding/background-pattern.png)'}">
    @if (stepInfo) {
      <div class="step-card" [@fadeIn]="onBoardingStepFadeAnimationState">
        @if (stepInfo.subtitle) {
          <p class="subtitle">{{ stepInfo.subtitle }}</p>
        }
        @if (stepInfo.title) {
          <p class="title">{{ stepInfo.title }}</p>
        }
        @switch (stepInfo.type) {
          <!-- ################### SIMPLE STEP ################### -->
          @case (onboardingStepType.CONFIRMATION) {
            @if ($any(stepInfo.content).mainImageUrl) {
              <img [src]="$any(stepInfo.content).mainImageUrl" class="main-img">
            }
            @if ($any(stepInfo.content).mainImageTitle) {
              <p class="main-img-title mt-2 mt-5"><!-- {{ (generalService.userDetails | async)?.category }} -->{{ $any(stepInfo.content).mainImageTitle }}</p>
            }
            @if ($any(stepInfo.content).simpleDescription) {
              <p class="simple-description">{{ $any(stepInfo.content).simpleDescription }}</p>
            }
          }
          <!-- ################### OPTION STEP ################### -->
          @case (onboardingStepType.MULTIPLE_CHOICE) {
            @if ($any(stepInfo.content).options) {
              <div class="options-container">
                @for (option of $any(stepInfo.content).options; track option) {
                  <div class="option"
                    (click)="optionSelected(option)"
                    [ngClass]="{ selected: option.selected }"
                    [ngStyle]="{ 'background-image': 'url(' + (option.imgUrl ? option.imgUrl : 'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/default-images/default-image-square.svg') + ')' }">
                    @if (option.selected) {
                      <i class="selected-icon bi bi-check-circle-fill"></i>
                    }
                    @if (option.title) {
                      <div class="option-title">
                        <span>{{ option.title }}</span>
                      </div>
                    }
                  </div>
                }
              </div>
            }
          }
          <!-- ################### GOAL INFO STEP ################### -->
          @case (onboardingStepType.INFORMATIVE) {
            @if ($any(stepInfo.content).banner) {
              <div class="banner-container">
                <div class="banner-info">
                  @if ($any(stepInfo.content).banner.title) {
                    <p class="banner-title">{{ $any(stepInfo.content).banner.title }}</p>
                  }
                  @if ($any(stepInfo.content).banner.description) {
                    <p class="banner-description">{{ $any(stepInfo.content).banner.description }}</p>
                  }
                </div>
                @if ($any(stepInfo.content).banner.imgTitle || $any(stepInfo.content).banner.imgUrl) {
                  <div class="banner-img-container"
              [ngStyle]="{
                'background-image': 'url(' + ($any(stepInfo.content).banner.imgUrl !== '' ? $any(stepInfo.content).banner.imgUrl : 'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/default-images/default-image-small-rectangle.svg') + ')'
              }">
                    @if ($any(stepInfo.content).banner.imgTitle) {
                      <div class="img-title">
                        <span>{{ $any(stepInfo.content).banner.imgTitle }}</span>
                      </div>
                    }
                  </div>
                }
              </div>
            }
            @if ($any(stepInfo.content).itemsList && $any(stepInfo.content).itemsList.length > 0) {
              <div class="activities-section">
                @if ($any(stepInfo.content).activitiesSectionTitle) {
                  <p class="activities-section-title">{{ $any(stepInfo.content).activitiesSectionTitle }}</p>
                }
                <div class="activities-list">
                  @for (activity of $any(stepInfo.content).itemsList; track activity) {
                    <div class="activity-info">
                      <img [src]="activity.imgUrl ? activity.imgUrl : 'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/default-images/default-image-small-rectangle.svg'">
                      @if (activity.description) {
                        <p class="activity-description">{{ activity.description }}</p>
                      }
                      <div class="activity-prize-value">
                        {{ labelsService.onboardingActivityPrizeLabel | label: { points: activity.points } }}
                      </div>
                    </div>
                  }
                </div>
              </div>
            }
            @if ($any(stepInfo.content).finalTitle) {
              <p class="final-title">{{ $any(stepInfo.content).finalTitle }}</p>
            }
          }
        }
      </div>
    }
  </div>

  <div class="footer-container">
    <div class="progress-steps-container">
      <app-circular-steps
        [activeStepColor]="'var(--main-brand-color)'"
        [nextStepsColor]="'#d9d9d9'"
        [fontSize]="'var(--small-section-description-font-size)'"
        [currentStep]="currentStep"
        [totalSteps]="totalSteps"
        [showLabel]="!(generalService.isMobile | async)">
      </app-circular-steps>
    </div>

    <div class="actions-container">
      @if (currentStep > 0) {
        <app-generic-button
          [minHeight]="'unset'"
          [borderRadius]="'50px'"
          [buttonName]="labelsService.onboardingPreviousStepBtnLabel | label"
          [backgroundColor]="'#424242'"
          [brandColor]="'#424242'" [fontColor]="environment.brandStyleVariables.mainBackgroundColor" [fontSize]="'var(' + ((generalService.isMobile | async) ? '--smallest-font-size' : '--small-font-size') + ')'"
          [buttonSpacing]="'calc(var(' + ((generalService.isMobile | async)? '--smallest-font-size' : '--small-font-size') + ')) calc(var(' + ((generalService.isMobile | async) ? '--smallest-font-size' : '--small-font-size') + ') * 3)'"
          [fontWeight]="'700'" [buttonSize]="!(generalService.isMobile | async)? 'min(160px, 17.5vw)' : 'min(125px, 35vw)'" (click)="prevButtonPressed()">
        </app-generic-button>
      }

      <app-generic-button [ngClass]="{ 'ms-3' : currentStep > 0 }"
        [minHeight]="'unset'"
        [borderRadius]="'50px'"
        [buttonName]="(currentStep < totalSteps - 1 ? labelsService.onboardingNextStepBtnLabel : labelsService.onboardingFinalizeBtnLabel) | label"
        [backgroundColor]="environment.brandStyleVariables.mainBrandColor"
        [brandColor]="environment.brandStyleVariables.mainBrandColor" [fontColor]="environment.brandStyleVariables.mainBackgroundColor" [fontSize]="'var(' + ((generalService.isMobile | async) ? '--smallest-font-size' : '--small-font-size') + ')'"
        [buttonSpacing]="'calc(var(' + ((generalService.isMobile | async) ? '--smallest-font-size' : '--small-font-size') + ')) calc(var(' + ((generalService.isMobile | async) ? '--smallest-font-size' : '--small-font-size') + ') * 3)'"
        [fontWeight]="'700'" [buttonSize]="!(generalService.isMobile | async) ? 'min(160px, 17.5vw)' : 'min(125px, 35vw)'"
        [isDisabled]="(stepInfo && $any(stepInfo.content).options && $any(stepInfo.content).options.length > 0 && !selectedOption) ? true: false"  [disabledBackgroundColor]="environment.brandStyleVariables.mainBrandColorLight"
        (click)="nextButtonPressed()">
      </app-generic-button>
    </div>
  </div>
</div>
