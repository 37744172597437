import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';
import { BannerInfo, GenericErrorReply, GetGeneralInfoReply, Label, LanguageDTO, MenuDTO, UserDetailsDTO } from 'src/app/_services/_general-service/general-service.model';
import { FilterGroupDTO } from 'src/app/pages/_shared-components/filters-list-module/filters-list/filter.model';
import { CategoryDTO } from 'src/app/pages/_shared-components/list-item-cards/cardItem.model';
import { ArticleDTO } from 'src/app/pages/blog/blog.model';
import { OrderEntryDTO, OrderEntryReplyDTO } from 'src/app/pages/marketplace/_services/_order-entry/order-entry.model';
import { ProductDTO } from 'src/app/pages/marketplace/_services/_products/products.service.model';
import { TierItemDTO } from 'src/app/pages/marketplace/marketplace.model';
import { PartnerDTO } from 'src/app/pages/partners/partners.model';
import { TransactionHeaderDTO, TransactionResponse } from 'src/app/pages/profile/_services/profile.model';
import { HtmlContents } from 'src/app/pages/terms-and-conditions/terms.model';
import { MockBlogService } from '../mocked-services/mock-blog-service/mock-blog.service';
import { MockContactusService } from '../mocked-services/mock-contact-us/mock-contactus.service';
import { MockGeneralService } from '../mocked-services/_mock-general-service/mock-general.service';
import { MockLanguagesService } from '../mocked-services/mock-languages-service/mock-languages.service';
import { MockOrderEntryService } from '../mocked-services/mock-order-entry-service/mock-order-entry-service.service';
import { MockProfileService } from '../mocked-services/mock-profile-service/mock-profile.service';
import { MockTermsAndConditionsService } from '../mocked-services/mock-terms-and-conditions-service/mock-terms-and-conditions.service';
import { apisToMock } from './_main-mock.model';
import { TutorialStepDTO } from 'src/app/pages/tutorial/tutorial.model';
import { MockTutorialService } from '../mocked-services/mock-terms-and-conditions-service/mock-tutorial-service/mock-tutorial.service';
import { MockMarketplaceService } from '../mocked-services/mock-marketplace-service/mock-marketplace.service';
import { MockPartnerService } from '../mocked-services/mock-partner-service/mock-partner.service';
import { OurProductsInfo } from 'src/app/pages/our-products/our-products.model';
import { MockOurProductsService } from '../mocked-services/mock-our-products-service/mock-our-products.service';
import { AboutUsInfoDTO } from 'src/app/pages/about-us/about-us.model';
import { MockAboutUsService } from '../mocked-services/mock-about-us-service/mock-about-us.service';
import { MockOnboardingService } from '../mocked-services/mock-onboarding-service/mock-onboarding.service';
import { OnboardingStepInfoDTO } from 'src/app/pages/onboarding/onboarding.model';
import { GoalOptionDTO } from 'src/app/pages/user-goal-module/user-goal.model';
import { MockUserGoalService } from '../mocked-services/mock-user-goal-service/mock-user-goal.service';
import { TransactionDTO } from 'src/app/pages/history-module/_services/transactions/transactions.model';
import { MockTransactionHistoryService } from '../mocked-services/mock-history/transactions/mock-transaction-history.service';
import { ActivityInfoDTO } from 'src/app/pages/activities/activities.model';
import { MockActivitiesService } from '../mocked-services/mock-activities/mock-activities.service';
import { Accordion } from 'src/app/_generic-components-lib/__models/accordion/accordion.model';

@Injectable({
  providedIn: 'root'
})
export class MainMockService {

  constructor(
    private mockGeneralService: MockGeneralService,
    private mockMarketplaceService: MockMarketplaceService,
    private mockLanguagesService: MockLanguagesService,
    private mockPartnersService: MockPartnerService,
    private mockOrderEntryService: MockOrderEntryService,
    private mockProfileService: MockProfileService,
    private mockTermsAndConditions: MockTermsAndConditionsService,
    private mockContactus: MockContactusService,
    private mockTutorial: MockTutorialService,
    private mockBlogService: MockBlogService,
    private mockOurProductsService: MockOurProductsService,
    private mockAboutUsService: MockAboutUsService,
    private mockOnboardingService: MockOnboardingService,
    private mockUserGoalService: MockUserGoalService,
    private mockTransactionHistoryService: MockTransactionHistoryService,
    private mockActivitiesService: MockActivitiesService
    ) { }

  mockApiCall(apiToMock: apisToMock, timeToWait: number): Observable<any> {
    const apiReturn$: Subject<any> = new Subject<any>();
    (async () => {
      await new Promise<void>(resolve => setTimeout(() => resolve(), timeToWait)).then(() => {

        switch (apiToMock) {
          case apisToMock.getInfo:
            this.mockGeneralService.getInfo().subscribe((resp: GetGeneralInfoReply) => {
              apiReturn$.next(resp);
            });
            break;

          case apisToMock.getLabels:
            this.mockGeneralService.getLabels().subscribe((resp: Array<Label>) => {
              apiReturn$.next(resp);
            });
            break;

          case apisToMock.getLPBanners:
            this.mockGeneralService.getLPBanners().subscribe((resp: Array<BannerInfo>) => {
              apiReturn$.next(resp);
            });
            break;
          case apisToMock.me:
            this.mockGeneralService.getUserDetails().subscribe((resp: UserDetailsDTO) => {
              apiReturn$.next(resp);
            });
            break;

          case apisToMock.getMenus:
            this.mockGeneralService.getMenus().subscribe((resp: Array<MenuDTO>) => {
              apiReturn$.next(resp);
            });
            break;

          case apisToMock.getLanguages:
            this.mockLanguagesService.getLanguages().subscribe((resp: Array<LanguageDTO>) => {
              apiReturn$.next(resp);
            });
            break;

          case apisToMock.marketplaceGetTiers:
            this.mockMarketplaceService.getTiers().subscribe((resp: Array<TierItemDTO>) => {
              apiReturn$.next(resp);
            });
            break;

          case apisToMock.marketplaceGetFilters:
            this.mockMarketplaceService.getFilters().subscribe((resp: Array<FilterGroupDTO>) => {
              apiReturn$.next(resp);
            });
            break;

          case apisToMock.marketplaceGetProducts:
            this.mockMarketplaceService.getProductList().subscribe((resp: Array<ProductDTO>) => {
              apiReturn$.next(resp);
            });
            break;

          case apisToMock.marketplaceGetProduct:
            this.mockMarketplaceService.getProductDetails().subscribe((resp: ProductDTO) => {
              apiReturn$.next(resp);
            });
            break;

          case apisToMock.getPartnersList:
            this.mockPartnersService.getPartnersList().subscribe((resp: Array<PartnerDTO>) => {
              apiReturn$.next(resp);
            });
            break;

          case apisToMock.getPartnerDetails:
            this.mockPartnersService.getPartnerDetails().subscribe((resp: PartnerDTO) => {
              apiReturn$.next(resp);
            });
            break;

          case apisToMock.getCategories:
            this.mockPartnersService.getCategories().subscribe((resp: Array<CategoryDTO>) => {
              apiReturn$.next(resp);
            });
            break;

          case apisToMock.createOrderEntry:
            this.mockOrderEntryService.createOrderEntry().subscribe((resp: OrderEntryDTO) => {
              apiReturn$.next(resp);
            });
            break;

          case apisToMock.getOrderEntry:
            this.mockOrderEntryService.getOrderEntry().subscribe((resp: OrderEntryDTO) => {
              apiReturn$.next(resp);
            });
            break;

            case apisToMock.getOrderEntryList:
            this.mockOrderEntryService.getOrderEntryList().subscribe((resp: Array<OrderEntryReplyDTO>) => {
              apiReturn$.next(resp);
            });
            break;

          case apisToMock.getHistoryProductList:
            this.mockProfileService.getCollectionsList().subscribe((resp: Array<ProductDTO>) => {
              apiReturn$.next(resp);
            });
            break;

          case apisToMock.getUserTransactionList:
            this.mockProfileService.getUserTransactionList().subscribe((resp: TransactionResponse) => {
              apiReturn$.next(resp);
            });
            break;

          case apisToMock.getUserTransactionListHeaders:
            this.mockProfileService.getUserTransactionListHeaders().subscribe((resp: Array<TransactionHeaderDTO>) => {
              apiReturn$.next(resp);
            });
            break;

          case apisToMock.getTerms:
            this.mockTermsAndConditions.getTerms().subscribe((resp: Array<HtmlContents>) => {
              apiReturn$.next(resp);
            });
            break

          case apisToMock.getFAQs:
            this.mockContactus.getFaqs().subscribe((resp: Array<Accordion>) => {
              apiReturn$.next(resp);
            });
            break;

          case apisToMock.getTutorialInfo:
            this.mockTutorial.getTutorialInfo().subscribe((resp: Array<TutorialStepDTO>) => {
              apiReturn$.next(resp);
            });
            break;

          case apisToMock.getBlogList:
            this.mockBlogService.getNewsList().subscribe((resp: Array<ArticleDTO>) => {
              apiReturn$.next(resp);
            });
            break;

          case apisToMock.getBlogFilters:
            this.mockBlogService.getFiltersList().subscribe((resp: Array<FilterGroupDTO>) => {
              apiReturn$.next(resp);
            });
            break;

          case apisToMock.getOurProductsList:
            this.mockOurProductsService.getProductsList().subscribe((resp: OurProductsInfo) => {
              apiReturn$.next(resp);
            });
            break;

          case apisToMock.getAboutUsInfo:
            this.mockAboutUsService.getAboutUsInfo().subscribe((resp: AboutUsInfoDTO) => {
              apiReturn$.next(resp);
            });
            break;

          case apisToMock.getOnboardingList:
            this.mockOnboardingService.getOnboardingList().subscribe((resp: Array<OnboardingStepInfoDTO>) => {
              apiReturn$.next(resp);
            });
            break;

          case apisToMock.getOnboardingStep:
            this.mockOnboardingService.getOnboardingStep().subscribe((resp: OnboardingStepInfoDTO) => {
              apiReturn$.next(resp);
            });
            break;

          case apisToMock.getUserGoalOptionDTOsList:
            this.mockUserGoalService.getUserGoalOptionDTOsList().subscribe((resp: Array<GoalOptionDTO>) => {
              apiReturn$.next(resp);
            });
            break;

          case apisToMock.getHistoryTransactionList:
            this.mockTransactionHistoryService.getTransactionsList().subscribe((resp: Array<TransactionDTO>) => {
              apiReturn$.next(resp);
            });
            break;

          case apisToMock.getActivityList:
            this.mockActivitiesService.getActivityList().subscribe((resp: Array<ActivityInfoDTO>) => {
              apiReturn$.next(resp);
            });
            break;

          case apisToMock.getActivityInfo:
            this.mockActivitiesService.getActivityInfo().subscribe((resp: ActivityInfoDTO) => {
              apiReturn$.next(resp);
            });
            break;

          case apisToMock.addEditActivityUserInput:
            this.mockActivitiesService.addEditUserInput().subscribe((resp: null | GenericErrorReply) => {
              apiReturn$.next(resp);
            });
            break;
          case apisToMock.removeActivityUserInput:
            this.mockActivitiesService.removeUserInput().subscribe((resp: null | GenericErrorReply) => {
              apiReturn$.next(resp);
            });
            break;


        }
    })})();

    return apiReturn$.asObservable();
  }
}
