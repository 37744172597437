<div class="list-item-container">
  <div class="list-item-left-side">
    <img [src]="cardImage" [alt]="cardTitle">
  </div>

  <div class="list-item-right-side">
    <div class="list-item-info">
      <p class="title">{{cardTitle}}</p>

      <div class="list-item-desc">
        <p>{{ cardDescription }}</p>
      </div>

      <div class="list-item-properties-container">
        <ul>
          @for (tag of cardTags; track tag) {
            <li>{{ tag.name }}</li>
          }
        </ul>
      </div>
    </div>

    <div class="list-item-buttons-container">
      @for (button of cardButtons; track button) {
        <app-generic-button
          [minHeight]="'unset'"
          [buttonName]="button.text" [fontColor]="button.textColor" [hasIcon]="button.hasIcon" [iconClass]="button.iconClass!"
          [buttonSpacing]="button.buttonSize!" [buttonSpacing]="button.buttonSpacing!" [defaultIconColor]="button.iconColor!"
          [fontSize]="button.fontSize" [fontWeight]="button.fontWeight" [borderRadius]="button.buttonRadius!"
          [brandColor]="button.backgroundColor" (click)="buttonActionClicked(button.action!, button.actionValue)">
        </app-generic-button>
      }
    </div>
  </div>
</div>
