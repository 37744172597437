<div class="product-page-wrapper">
  <app-page-banner
    [title]="mainBannerInfo.title ? mainBannerInfo.title : ''"
    [backgroundImageUrl]="mainBannerInfo.backgroundImageUrl ? mainBannerInfo.backgroundImageUrl : ''"
    [imageUrl]="mainBannerInfo.itemImageUrl ? mainBannerInfo.itemImageUrl : ''"
    [useGradient]="mainBannerInfo.useGradient"
    [pseudoElementColor]="environment.brandStyleVariables.pseudoElementHighlightColor"
    [smallBanner]="(environment.showUserPointsInBanners && (generalService.isUserLoggedIn | async)) ? false : true"
    [textContainerMaxWidth]="'70%'"
    [imageSize]="'35%'">
  </app-page-banner>

  <p class="product-info-title mt-4">{{labelService.productDetailsScreenTitle | label}}</p>

  @if (isLoaded) {
    <div class="product-info-wrapper mb-4"  [@FadeIn]="animationState">
      <div class="product-info-container card-radius">
        <app-item-image-slider
          [itemTypeImage]="'assets/imgs/environments/'+ environment.tenantLocalAssetsPath +'/icons/product-bought-icon.svg'"
          [imageList]="product.images ? product.images : []"
          [paginationBulletColor]="environment.brandStyleVariables.pseudoElementHighlightColor">
        </app-item-image-slider>
        @switch (product.type) {
          @case (ProductTypeEnum.VOUCHER) {
            <div>
              <app-voucher-details [product]="product" [partner]="partner" [isHistory]="true"></app-voucher-details>
            </div>
          }
          @case (ProductTypeEnum.NFT) {
            <div>
              <app-nft-details [product]="product" [partner]="partner"></app-nft-details>
            </div>
          }
        }
      </div>
      <div class="product-codes-list-wrapper">
        <p class="product-codes-list-title">{{labelService.productHistoryDetailsCodeListTitle | label}}</p>
        <div class="product-codes-list-container">
          @for (productCode of productCodeList; track productCode) {
            <app-product-code-list-item [productCode]="productCode"></app-product-code-list-item>
          }
          @if (isListLoading) {
            @for (item of loadingListItems; track item) {
              <app-loading-list-item class="loading-item" [ngClass]="{'opacity-0': !isListLoading}"></app-loading-list-item>
            }
          }
        </div>
      </div>
    </div>
  }
</div>
