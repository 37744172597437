import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnChanges } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { SingleClickDirective } from '../../../__directives/single-click/single-click.directive';
import { LibraryService } from '../../../__services/library.service';
import { ButtonBaseComponent } from '../../../_extended-components/button-base-component/button-base.component';

@Component({
  standalone: true,
  selector: 'app-generic-button',
  templateUrl: './generic-button.component.html',
  styleUrls: [
    '../../../_base-component/base.component.sass',
    '../../../_extended-components/button-base-component/button-base.component.sass',
    './generic-button.component.sass'
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SingleClickDirective
  ]
})
export class GenericButtonComponent extends ButtonBaseComponent implements OnChanges {

  constructor(
    public override host: ElementRef<HTMLElement>,
    public override libraryService: LibraryService
  ) {
    super(host, libraryService);
  }

  ngOnChanges(): void {
    super.setCSSVars();
    super.setSpecificCSSVars();
  }
}
