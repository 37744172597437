
import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { ControlValueAccessor, FormControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule, ValidationErrors, Validator } from '@angular/forms';
import { LibraryService } from '../../__services/library.service';
import { BaseComponent } from '../../_base-component/base.component';

@Component({
  standalone: true,
  selector: 'app-input-base-component',
  template: '',
  styleUrls: [
    '../../_base-component/base.component.sass',
    './input-base.component.sass',
  ],
  imports: [ReactiveFormsModule, BaseComponent],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputBaseComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: InputBaseComponent
    },
    LibraryService
  ]
})
export class InputBaseComponent<T = unknown> extends BaseComponent implements ControlValueAccessor, Validator {

  @Input() public cropperPrimaryBackgroundColor: string;
  @Input() public cropperBackgroundColor: string;
  @Input() public removeBtnColor: string = '#4D4D4D';
  @Input() public removeBtnTextColor: string = 'white';
  @Input() public barColor: string;
  @Input() public toggleBarColor: string;
  @Input() public roundedBackground: string;

  @Input() public iconFontSize: string;
  @Input() public maxCharactersFontSize: string;

  @Input() public uploadBtnBorderRadius: string;

  @Input() public uploadBtnLabel: string = 'Upload';
  @Input() public removeBtnLabel: string = 'Remove';
  @Input() public aspectRatio: number = 1/1;
  @Input() public cropperAspectRatio: number = 1/1;
  @Input() public imageBorderRadius: string;
  @Input() public popupBorderRadius: string;

  @Input() public inputPadding: string;
  @Input() public prefixMargin: string;
  @Input() public suffixMargin: string;
  @Input() public placeholderLabel: string;

  @Input() public iconUrl: string;
  @Input() public labelMargin: string;

  @Input() public debounceTime: number;

  @Input() public roundBorders: boolean;

  @Input() public inputValue: string = '';

  @Input() public errors: ValidationErrors;
  @Input({required: true}) public control: FormControl;

  @Output() public valueChanged: EventEmitter<string> = new EventEmitter<string>();
  @Output() public actionOnEnter: EventEmitter<string> = new EventEmitter<string>();
  @Output() public suffixClicked: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public notFocused: EventEmitter<boolean> = new EventEmitter<boolean>();

  public onChange: (inputValue: T) => void;
  public onTouched: () => void;
  public onValidatorChange: () => void;

  public isDisabled: boolean = false;

  constructor(
    public override host: ElementRef,
    public override libraryService: LibraryService
  ) {
    super(host, libraryService);
    super.setCSSVars();
    this.setSpecificCSSVars();
  }

  public writeValue(value: unknown): void {
    if (value !== undefined && value !== null) {
      this.inputValue = (value as string);
    } else {
      this.inputValue = '';
    }
  }

  public registerOnChange(onChange: () => void): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  public registerOnValidatorChange(onValidatorChange: () => void): void {
    this.onValidatorChange = onValidatorChange;
  }

  public validate(): ValidationErrors | null {
    const errors: ValidationErrors = {};

    return errors;
  }

  // action on debounce
  public onValueChange(input: string): void {
    this.valueChanged.emit(input);
  }

  public onFocusOut(): void {
    this.notFocused.emit(true);
  }

  // action on "Enter"
  public checkKeyPressed(keyPressed: KeyboardEvent): void {
    if (keyPressed.key === 'Enter' || keyPressed.key === 'NumpadEnter') {
      this.actionOnEnter.emit();
    }
  }

  public setSpecificCSSVars(): void {
    if (this.inputPadding || this.prefixMargin || this.suffixMargin || this.placeholderStyle || this.cropperPrimaryBackgroundColor || this.cropperBackgroundColor || this.removeBtnColor || this.removeBtnTextColor || this.uploadBtnBorderRadius
      || this.aspectRatio || this.cropperAspectRatio || this.imageBorderRadius || this.popupBorderRadius || this.barColor || this.toggleBarColor || this.iconUrl || this.roundedBackground
      || this.iconFontSize || this.maxCharactersFontSize) {

      if (this.inputPadding)
        this.host.nativeElement.style.setProperty('--input-padding', this.inputPadding);
      if (this.prefixMargin)
        this.host.nativeElement.style.setProperty('--prefix-margin', this.prefixMargin);
      if (this.suffixMargin)
        this.host.nativeElement.style.setProperty('--suffix-margin', this.suffixMargin);
      if (this.cropperPrimaryBackgroundColor)
        this.host.nativeElement.style.setProperty('--cropper-primary-background-color', this.libraryService.convertToHexColor(this.host, this.cropperPrimaryBackgroundColor));
      if (this.cropperBackgroundColor)
        this.host.nativeElement.style.setProperty('--cropper-background-color', this.libraryService.convertToHexColor(this.host, this.cropperBackgroundColor));
      if (this.removeBtnTextColor)
        this.host.nativeElement.style.setProperty('--remove-btn-text-color', this.libraryService.convertToHexColor(this.host, this.removeBtnTextColor));
      if (this.removeBtnColor)
        this.host.nativeElement.style.setProperty('--remove-btn-color', this.libraryService.convertToHexColor(this.host,this.removeBtnColor));
      if (this.imageBorderRadius)
        this.host.nativeElement.style.setProperty('--image-border-radius', this.imageBorderRadius);
      if (this.uploadBtnBorderRadius)
        this.host.nativeElement.style.setProperty('--btn-border-radius', this.uploadBtnBorderRadius);
      if (this.aspectRatio)
        this.host.nativeElement.style.setProperty('--aspect-ratio', this.aspectRatio);
      if (this.cropperAspectRatio)
        this.host.nativeElement.style.setProperty('--cropper-aspect-ratio', this.cropperAspectRatio);
      if (this.popupBorderRadius)
        this.host.nativeElement.style.setProperty('--popup-border-radius', this.popupBorderRadius);
      if (this.barColor)
        this.host.nativeElement.style.setProperty('--default-bar-color', this.libraryService.convertToHexColor(this.host, this.barColor));
      if (this.toggleBarColor)
        this.host.nativeElement.style.setProperty('--toggle-bg-color', this.libraryService.convertToHexColor(this.host,this.toggleBarColor));
      if (this.iconUrl)
        this.host.nativeElement.style.setProperty('--icon-url', `url(${this.iconUrl})`);
      if (this.roundedBackground)
        this.host.nativeElement.style.setProperty('--rounded-bg', this.libraryService.convertToHexColor(this.host, this.roundedBackground));
      if (this.iconFontSize)
        this.host.nativeElement.style.setProperty('--icon-font-size', this.iconFontSize);
      if (this.maxCharactersFontSize)
        this.host.nativeElement.style.setProperty('--maxchars-font-size', this.maxCharactersFontSize);

      if (this.placeholderStyle)
        this.setPlaceholderStyles();

      // default lightBrandColor to BackgroundColor value
      if (!this.lightBrandColor)
        this.host.nativeElement.style.setProperty('--brand-lighter', window.getComputedStyle(this.host.nativeElement).getPropertyValue('--background-color'));
    }
  }

  private setPlaceholderStyles(): void {
    if (this.placeholderStyle.fontSize)
      this.host.nativeElement.style.setProperty('--placeholder-font-size', this.placeholderStyle.fontSize);
    if (this.placeholderStyle.fontStyle)
      this.host.nativeElement.style.setProperty('--placeholder-font-style', this.placeholderStyle.fontStyle);
    if (this.placeholderStyle.fontWeight)
      this.host.nativeElement.style.setProperty('--placeholder-font-weight', this.placeholderStyle.fontWeight);
    if (this.placeholderStyle.color)
      this.host.nativeElement.style.setProperty('--placeholder-color', this.libraryService.convertToHexColor(this.host, this.placeholderStyle.color));
  }
}
