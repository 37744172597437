import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { AuthGuardKeycloak } from './_guards/AuthKeycloak.guard';
import { AuthGuard } from './_guards/auth.guard';
import { BlogListComponent } from './pages/blog/blog-list/blog-list.component';
import { ContactUsComponent } from './pages/contact-us/contact-us/contact-us.component';
import { GeneralInfoComponent } from './pages/contact-us/general-info/general-info.component';
import { LandingPageComponent } from './pages/landing-page/landing-page.component';
import { ProductDetailsComponent } from './pages/marketplace/product-details/product-details.component';
import { ProductPaymentPageComponent } from './pages/marketplace/product-payment-page/product-payment-page.component';
import { PageNotFoundComponent } from './pages/page-not-found-module/page-not-found/page-not-found.component';
import { PartnerDetailsComponent } from './pages/partners/partner-details/partner-details.component';
import { PartnersComponent } from './pages/partners/partners-list/partners.component';
import { MyAccountComponent } from './pages/profile/my-account/my-account.component';
import { MyCollectionsComponent } from './pages/profile/my-collections/my-collections.component';
import { TermsAndConditionsComponent } from './pages/terms-and-conditions/terms/terms-and-conditions.component';
import { OurProductsContainerComponent } from './pages/our-products/our-products-container/our-products-container.component';
import { ContactUsFormComponent } from './pages/contact-us/contact-us-form/contact-us-form.component';
import { AboutUsContentComponent } from './pages/about-us/about-us-content/about-us-content.component';
import { BlogContentComponent } from './pages/blog/blog-content/blog-content.component';
import { HomeComponent } from './pages/home-module/home/home.component';
import { MarketplaceListContainerComponent } from './pages/marketplace/marketplace-list-container/marketplace-list-container.component';
import { HistoryContainerComponent } from './pages/history-module/history-container/history-container.component';
import { ProductHistoryListContainerComponent } from './pages/history-module/history-container/product-history-list-container/product-history-list-container.component';
import { ActivitiesHistoryListContainerComponent } from './pages/history-module/history-container/activities-history-list-container/activities-history-list-container.component';
import { TransactionsHistoryListContainerComponent } from './pages/history-module/history-container/transactions-history-list-container/transactions-history-list-container.component';
import { HistoryProductDetailsComponent } from './pages/history-module/history-container/product-history-list-container/history-product-details/history-product-details.component';
import { ActivitiesListContainerComponent } from './pages/activities/activities-list-container/activities-list-container.component';
import { ActivityDetailsContainerComponent } from './pages/activities/activity-details-container/activity-details-container.component';
import { ActivitySubTasksListComponent } from './pages/activities/activity-details-container/activity-details-card/activity-types/multi-step/activity-sub-tasks-list/activity-sub-tasks-list.component';

const authGuard = environment.useKeycloak ? AuthGuardKeycloak : AuthGuard;

export const routes: Routes = [
  { path: '', component: LandingPageComponent, canActivate: [authGuard],
    data: {
      // redirect to other route instead when the user is logged in
      shouldRedirectIfLoggedIn: environment.routeRedirect.landingPage.shouldRedirectIfLoggedIn,
      pathToRedirect: environment.routeRedirect.landingPage.pathToRedirect,

      restricted: false,
      // roles: environment.homeRequiredRoles // to add specific roles for users that can access the page
    },
  },
  { path: '404', component: PageNotFoundComponent, canActivate: [authGuard],
    data: {
      restricted: false,
      // roles: environment.homeRequiredRoles // to add specific roles for users that can access the page
    }
  },
  {
    path: 'blog', component: BlogListComponent, canActivate: [authGuard],
    data: {
      restricted: false,
      // roles: environment.homeRequiredRoles // to add specific roles for users that can access the page
    }
  },
  {
    path: 'blog/:id', component: BlogContentComponent, canActivate: [authGuard],
    data: {
      restricted: false,
      // roles: environment.homeRequiredRoles // to add specific roles for users that can access the page
    }
  },
  {
    path: 'our-products', component: OurProductsContainerComponent, canActivate: [authGuard],
    data: {
      restricted: false,
      // roles: environment.homeRequiredRoles // to add specific roles for users that can access the page
    }
  },
  { path: 'home', component: HomeComponent, canActivate: [authGuard],
    data: {
      restricted: true,
      roles: environment.homeRequiredRoles // to add specific roles for users that can access the page
    }
  },
  { path: 'my-account', component: MyAccountComponent, canActivate: [authGuard],
    data: {
      restricted: true,
      // roles: environment.homeRequiredRoles // to add specific roles for users that can access the page
    }
  },
  /* {
    path: 'my-account/my-collections', component: MyCollectionsComponent, canActivate: [authGuard],
    data: {
      restricted: true,
      // roles: environment.homeRequiredRoles // to add specific roles for users that can access the page
    }
  },
  { path: 'my-account/my-transactions', component: TransactionsHistoryListContainerComponent, canActivate: [authGuard],
    data: {
      restricted: true,
      // roles: environment.homeRequiredRoles // to add specific roles for users that can access the page
    }
  }, */
  { path: 'history', component: HistoryContainerComponent, canActivate: [authGuard],
    data: {
      restricted: true,
      // roles: environment.homeRequiredRoles // to add specific roles for users that can access the page
    },
    children: [
      {
        path: 'transactions', component: TransactionsHistoryListContainerComponent, canActivate: [authGuard],
        data: {
          restricted: true,
          // roles: environment.homeRequiredRoles // to add specific roles for users that can access the page
        }
      },
      {
        path: 'products', component: ProductHistoryListContainerComponent, canActivate: [authGuard],
        data: {
          restricted: true,
          // roles: environment.homeRequiredRoles // to add specific roles for users that can access the page
        },
      },
      {
        path: 'activities', component: ActivitiesHistoryListContainerComponent, canActivate: [authGuard],
        data: {
          restricted: true,
          // roles: environment.homeRequiredRoles // to add specific roles for users that can access the page
        }
      },
    ]
  },
  { path: 'history/products/:id', component: HistoryProductDetailsComponent, canActivate: [authGuard],
    data: {
      restricted: true,
      // roles: environment.homeRequiredRoles // to add specific roles for users that can access the page
    },
  },
  { path: 'marketplace', component: MarketplaceListContainerComponent, canActivate: [authGuard],
    data: {
      restricted: false,
      // roles: environment.homeRequiredRoles // to add specific roles for users that can access the page
    }
  },
  { path: 'marketplace/product/:id', component: ProductDetailsComponent, canActivate: [authGuard],
    data: {
      restricted: false,
      // roles: environment.homeRequiredRoles // to add specific roles for users that can access the page
    }
  },
  { path: 'marketplace/product/:id/payment-page', component: ProductPaymentPageComponent, canActivate: [authGuard],
    data: {
      restricted: true,
      // roles: environment.homeRequiredRoles // to add specific roles for users that can access the page
    }
  },
  { path: 'institutions', component: PartnersComponent, canActivate: [authGuard],
    data: {
      restricted: false,
      // roles: environment.homeRequiredRoles // to add specific roles for users that can access the page
    }
  },
  { path: 'activities', component: ActivitiesListContainerComponent, canActivate: [authGuard],
    data: {
      restricted: true,
      // roles: environment.homeRequiredRoles // to add specific roles for users that can access the page
    }
  },
  { path: 'activities/:id', component: ActivityDetailsContainerComponent, canActivate: [authGuard],
    data: {
      restricted: true,
      // roles: environment.homeRequiredRoles // to add specific roles for users that can access the page
    }
  },
  { path: 'activities/:id/sub-tasks', component: ActivitySubTasksListComponent, canActivate: [authGuard],
    data: {
      restricted: true,
      // roles: environment.homeRequiredRoles // to add specific roles for users that can access the page
    }
  },
  { path: 'terms-of-use', component: TermsAndConditionsComponent, canActivate: [authGuard],
    data: {
      restricted: false,
      // roles: environment.homeRequiredRoles // to add specific roles for users that can access the page
    }
  },
  { path: 'privacy-policy', component: TermsAndConditionsComponent, canActivate: [authGuard],
    data: {
      restricted: false,
      // roles: environment.homeRequiredRoles // to add specific roles for users that can access the page
    }
  },
  { path: 'about-us', component: AboutUsContentComponent, canActivate: [authGuard],
    data: {
      restricted: false,
      // roles: environment.homeRequiredRoles // to add specific roles for users that can access the page
    }
  },
  {
    path: 'contact-us', component: ContactUsComponent, canActivate: [authGuard],
    data: {
      restricted: false,
      // roles: environment.homeRequiredRoles // to add specific roles for users that can access the page
    },
    children: [
      {
        path: 'general-information', component: GeneralInfoComponent, canActivate: [authGuard],
        data: {
          restricted: false,
          // roles: environment.homeRequiredRoles // to add specific roles for users that can access the page
        }
      },
      {
        path: 'create-institution-account', component: ContactUsFormComponent, canActivate: [authGuard],
        data: {
          restricted: false,
          // roles: environment.homeRequiredRoles // to add specific roles for users that can access the page
        }
      },
      {
        path: '**', component: ContactUsFormComponent, canActivate: [authGuard],
        data: {
          restricted: false,
          // roles: environment.homeRequiredRoles // to add specific roles for users that can access the page
        }
      },
    ]
  },
  // !IMPORTANT this line needs to be last routing line!!
  /* { path: ':partnerId', component: PartnerDetailsComponent, canActivate: [authGuard],
    data: {
      restricted: false,
      // roles: environment.homeRequiredRoles // to add specific roles for users that can access the page
    }
  }, */
  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
