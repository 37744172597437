/**
 * Can be used in conjunction with {@link Transaction.supports}
 * to query on tx capabilities
 */
export var Capability;
(function (Capability) {
  /**
   * Tx supports EIP-155 replay protection
   * See: [155](https://eips.ethereum.org/EIPS/eip-155) Replay Attack Protection EIP
   */
  Capability[Capability["EIP155ReplayProtection"] = 155] = "EIP155ReplayProtection";
  /**
   * Tx supports EIP-1559 gas fee market mechanism
   * See: [1559](https://eips.ethereum.org/EIPS/eip-1559) Fee Market EIP
   */
  Capability[Capability["EIP1559FeeMarket"] = 1559] = "EIP1559FeeMarket";
  /**
   * Tx is a typed transaction as defined in EIP-2718
   * See: [2718](https://eips.ethereum.org/EIPS/eip-2718) Transaction Type EIP
   */
  Capability[Capability["EIP2718TypedTransaction"] = 2718] = "EIP2718TypedTransaction";
  /**
   * Tx supports access list generation as defined in EIP-2930
   * See: [2930](https://eips.ethereum.org/EIPS/eip-2930) Access Lists EIP
   */
  Capability[Capability["EIP2930AccessLists"] = 2930] = "EIP2930AccessLists";
})(Capability || (Capability = {}));
export function isAccessListUint8Array(input) {
  if (input.length === 0) {
    return true;
  }
  const firstItem = input[0];
  if (Array.isArray(firstItem)) {
    return true;
  }
  return false;
}
export function isAccessList(input) {
  return !isAccessListUint8Array(input); // This is exactly the same method, except the output is negated.
}
