import { Component, Input, OnInit } from '@angular/core';
import { Money, Product, ProductPartner } from '../../_services/_products/products.service.model';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { environment } from 'src/environments/environment';
import { PopupService } from 'src/app/_services/popup-service.service';
import { LabelPipe } from 'src/app/_shared-modules/label-pipe-module/label.pipe';
import { ProductsService } from '../../_services/_products/products.service';
import { Params } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-nft-details',
  templateUrl: './nft-details.component.html',
  styleUrls: ['./nft-details.component.sass'],
  standalone: true,
  imports: [
    CommonModule,
    LabelPipe,
    TranslateModule
  ]
})
export class NftDetailsComponent implements OnInit {

  @Input() product: Product;
  @Input() partner: ProductPartner | undefined;;

  constructor(
    public labelService: LabelsService,
    public generalService: GeneralService,
    public translateService: TranslateService,
    private popupService: PopupService,
    private labelPipe: LabelPipe,
    private productService: ProductsService
  ) { }

  ngOnInit(): void {

  }

  public goToPaymentPage(): void {
    if(!this.generalService.isUserLoggedIn$.value) {
      this.popupService.setPopupToOpen(
        {
          text: ''
        },
        `assets/imgs/environments/${environment.tenantName}/popup-login-profile-image.png`,
        this.popupService.getTitle(this.labelPipe.transform(this.labelService.popupWelcomeTitle).replace('{{client}}', environment.tenantName)),
        [this.popupService.getSimpleDescription(this.labelPipe.transform(this.labelService.popupWelcomeDescription))],
        [{
          text: this.labelPipe.transform(this.labelService.popupWelcomeButtonLogin),
          isCloseBtn: false,
          actionText: 'login',
          style: {
            backgroundColor: environment.brandStyleVariables.mainBrandColor,
            fontColor: environment.brandStyleVariables.mainBackgroundColor
          }
        }/*, {
          text: this.labelPipe.transform(this.labelService.popupWelcomeButtonCancel),
          isCloseBtn: true,
          actionText: '',
          backgroundColor: '#ff3e3e',
          textColor: '#ffffff'
        } */],
        true
      );
    } else {
      this.productService.productInfo = this.product;

      let queryParams: Params = {};
      queryParams['quantity'] = '1';

      this.generalService.navigateToWithQueryParams(`marketplace/product/${this.product.id}/payment-page`, queryParams);

      this.generalService.autoScrollTop('page-router-container');
    }
  }

  public selectCurrencyToShow(selectedCurrency: Money): void {
    this.product.prices.forEach(currency => {
      currency.selected = false;
    });

    selectedCurrency.selected = true;
  }

}
