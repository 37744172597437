<div class="register-banner-wrapper">
  <div class="register-banner-container">

    <div class="banner-text-container">
      <p>{{bannerText}}</p>

      @if (!isLoggedIn) {
        <div class="banner-button-container">
          <app-generic-button
            [borderRadius]="'30px'"
            [backgroundColor]="'var(--main-brand-color)'"
            [brandColor]="'var(--main-brand-color)'"
            [buttonName]="bannerButtonText"
            [fontColor]="'#ffffff'"
            [minHeight]="'unset'"
          (click)="goToPage()"></app-generic-button>
        </div>
      }
    </div>

    <div class="banner-icon-container">
      <img [src]="bannerIcon" [alt]="bannerIconAlt">
    </div>
  </div>
</div>
