import { Component, EventEmitter, Input, OnInit, Output, ElementRef } from '@angular/core';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { NgClass, CommonModule } from '@angular/common';
import { LabelPipe } from '../../../../_shared-modules/label-pipe-module/label.pipe';

@Component({
  selector: 'app-circular-steps',
  templateUrl: './circular-steps.component.html',
  styleUrls: ['./circular-steps.component.sass'],
  standalone: true,
  imports: [
    CommonModule,
    LabelPipe
  ]
})
export class CircularStepsComponent implements OnInit {

  @Input() activeStepColor: string;
  @Input() nextStepsColor: string;
  @Input() fontSize: string;
  @Input() showLabel: boolean = true;

  @Input() currentStep: number;
  @Input() totalSteps: number;

  @Output() stepClicked: EventEmitter<number> = new EventEmitter<number>();

  public stepsList: Array<number>;

  constructor(
    private host: ElementRef,
    public labelsService: LabelsService
    ) { }

  ngOnInit(): void {
    this.stepsList = new Array(this.totalSteps);
    this.setCSSVars();
  }

  private setCSSVars(): void {
    if(this.activeStepColor || this.nextStepsColor || this.fontSize) {

      // set variables
      if(this.activeStepColor)
        this.host.nativeElement.style.setProperty('--active-step-color', this.activeStepColor);
      if(this.nextStepsColor)
        this.host.nativeElement.style.setProperty('--next-steps-color', this.nextStepsColor);
      if(this.fontSize)
        this.host.nativeElement.style.setProperty('--font-size', this.fontSize);
    }
  }
}
