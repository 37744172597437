import { Component, OnInit, ViewChild, ElementRef, Input } from '@angular/core';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { ActivityInfo } from 'src/app/pages/activities/activities.model';
import { environment } from 'src/environments/environment';
import { Rive } from "@rive-app/canvas";
import { AnimationOptions, LottieComponent } from 'ngx-lottie';
import { AnimationItem } from 'lottie-web';
import { ActivatedRoute, Router } from '@angular/router';
import { NgProgressbar } from 'ngx-progressbar';

import { LabelPipe } from 'src/app/_shared-modules/label-pipe-module/label.pipe';
import { GenericButtonComponent } from 'src/app/_generic-components-lib/inputs/buttons/generic-button/generic-button.component';


@Component({
  selector: 'app-activity-type-multi-step',
  templateUrl: './activity-type-multi-step.component.html',
  styleUrls: ['./activity-type-multi-step.component.sass'],
  standalone: true,
  imports: [
    LabelPipe,
    NgProgressbar,
    LottieComponent,
    GenericButtonComponent
]
})
export class ActivityTypeMultiStepComponent implements OnInit {
  @ViewChild('completedTasksProgressBar') progressBar: NgProgressbar;
  @ViewChild('lottieAnimation', { static: false }) private lottieAnimation: LottieComponent;
  @ViewChild('canvasRive', { static: false }) public canvasRive: ElementRef;

  @Input() activity: ActivityInfo;

  public math = Math;

  public completedTasks: number = 1;
  public totalTasks: number = 5;

  public environment = environment;

  public isMobile: boolean = false;
  private r: Rive;

  public lottieOptions: AnimationOptions;
  public lottieAnimationItem: AnimationItem | null = null;

  constructor(
    public labelsService: LabelsService,
    private generalService: GeneralService,
    private host: ElementRef,
    private router: Router,
    private route: ActivatedRoute
  ) {
    window.addEventListener('resize', () => {
      this.screenResized();
    });

    this.screenResized();
  }

  ngOnInit(): void {
    this.generalService.oldAsyncFunction(() => this.initProgressBar());

    switch(environment.animations.activityMultiStep.type) {
      case 'LOTTIE':
        this.lottieOptions = {
          autoplay: false,
          loop: true,
          path: `assets/imgs/environments/${environment.tenantName}/animations/activity-multi-step-animation.json`
        };
        break;

      case 'RIVE':
        this.initiateRiveAnimation(`assets/imgs/environments/${environment.tenantName}/animations/activity-multi-step-image.png`);
        break;

      case 'IMAGE':
        break;
    }
  }

  private initProgressBar(): void {
    this.totalTasks = this.activity.totalSubActivities!;
    this.completedTasks = this.activity.totalCompletedSubActivities!;

    // set percentage of progress
    this.progressBar.set(0);
    this.generalService.oldAsyncFunction(() => {
      this.progressBar.set( this.activity.totalCompletedSubActivityPercentage! );
    }, 0);
  }

  private screenResized(): void {
    this.isMobile = window.innerWidth / window.innerHeight <= 1 || window.innerWidth <= 760;

    if (this.lottieAnimation) {
      this.lottieAnimationItem?.play();
    }

    if (this.canvasRive) {
      this.r.resizeDrawingSurfaceToCanvas();
      this.r?.play();
    }
  }

  private initiateRiveAnimation(riveAnimationUrl: string): void {
    this.generalService.oldAsyncFunction(() => {
      this.r = new Rive({
        src: riveAnimationUrl,
        canvas: this.canvasRive.nativeElement,
        autoplay: true,
        onLoad: () => {
          this.r.resizeDrawingSurfaceToCanvas();
          this.r?.play();
        },
      });
    }, 0);
  }

  public checkTasks(): void {
    this.router.navigate([`activities/${this.activity.activityId}/sub-tasks`]);
    this.generalService.autoScrollTop('page-router-container');
  }
}
