<div class="filters-list-container p-3">
  <div class="title-container">
    <span>{{filtersTitle}}</span>

    @if (isMobile) {
      <div class="close-btn-container" (click)="closeFilter()">
        <div class="close-icon">
          <span class="cross-line"></span>
        </div>
      </div>
    }

    @if (!isMobile && activeFilterCount > 0) {
      <div class="clear-filters-container" (click)="clearFilters()">
        {{ clearFiltersLabel }}
      </div>
    }
  </div>

  @if (isMobile && activeFilterCount > 0) {
    <div class="clear-filters-container" (click)="clearFilters()">
      {{ clearFiltersLabel }}
    </div>
  }


  <form [formGroup]="form">
    @for (group of filterList; track group; let filterIndex = $index) {
      <div class="filter-group-container mt-3" [ngClass]="{'pb-5': filterIndex === filterList.length - 1}">
        <div class="group-title-container" (click)="toggleFilterGroup(group)">
          <span class="group-title">{{group.name}}</span>
          <div class="arrow-container">
            <div class="arrow-icon" [ngClass]="{'active': group.isOpen}"></div>
          </div>
        </div>
        <div class="group-filters-container" [ngClass]="{'active': group.isOpen, 'animation-started': group.animationStarted}">
          @switch (group.type) {
            <!-- CHECKBOXES -->
            @case (filterTypes.Checkbox) {
              @for (filter of group.values; track filter; let checkboxIndex = $index) {
                <div class="filter-item-container" >
                  <div class="filter-input-container">
                    <app-checkbox
                      [control]="$any(form.controls['cb-' + filterIndex + '-' + checkboxIndex])"
                      [formControlName]="'cb-' + filterIndex + '-' + checkboxIndex"
                      [flexDirection]="'row'"
                      [gapSize]="'1vw'"
                      [activeCheckboxColor]="'#000000'"
                      [minHeight]="'var(--smallest-font-size)'"
                      [label]="$any(filter).description" [justifyContent]="'space-between'"
                      [fontSize]="'15px'" (valueChanged)="isFiltersCheckboxUpdated(group, $any(filter), $event)">
                    </app-checkbox>
                  </div>
                </div>
              }
            }
            <!-- RADIO BTNS -->
            @case (filterTypes.Radio) {
              <div class="filter-item-container">
                <div class="filter-input-container">
                  <app-radio-group
                    [control]="$any(form.controls['radio-' + filterIndex])"
                    [formControlName]="'radio-' + filterIndex"
                    [radioButtons]="$any(group).values"
                    [vertical]="true"
                    [labelFirst]="true"
                    [fontSize]="'15px'"
                    [minHeight]="'unset'"
                    [verticalMargin]="'3px'"
                    [justifyContent]="'space-between'"
                    [brandColor]="environment.brandStyleVariables.mainBrandColor"
                    (valueChanged)="isFiltersRadioUpdated(group, $event)">
                  </app-radio-group>
                </div>
              </div>
            }
            <!-- RANGE -->
            @case (filterTypes.Range) {
              <!-- TODO -->
            }
          }
        </div>
      </div>
    }
  </form>
</div>
