<div class="list-item-wrapper" [ngClass]="{ 'selected': item.selected, 'not-clickable' : item.notClickable}">
  <div class="list-item-container" (click)="itemClicked()">
    <app-list-item [item]="item" [fullSize]="fullSize"
      [itemMargin]="itemMargin" [enableClick]="enableClick"
      (itemEvent)="itemClicked()" (actionEvent)="actionClicked($event)">
    </app-list-item>

    @let info = item.info[item.info.length - 1];
    @if (info.state && fullSize ? info.sizes!.size : info.sizes!.sizeSmall) {
      <div class="state-indicator-container d-flex align-items-center justify-content-end pe-3"
        [ngStyle]="{
          'width': (fullSize ? info.sizes!.size : info.sizes!.sizeSmall) + '%'
        }">

        <div class="state-indicator" [ngClass]="info.state!.class">
          <span [@Rotate]="arrowAnimationState"><i class="bi bi-arrow-down"></i></span>
        </div>
      </div>
    }
  </div>

  <div class="list-item-expandable-wrapper" [@Expand]="animationState">
    <div class="list-item-expandable">
        <div class="item-header">
          <app-list-header [fullSize]="true" [headerList]="expandableHeader" [showArrows]="false" [fontSize]="expandableFontSize" [fontColor]="headerFontColor" [fontWeight]="headerFontWeight"></app-list-header>
        </div>
        @for (item of expandableItem; track $index) {
          <div class="item-info">
            <app-list-item [fullSize]="true"
              [item]="item" [itemMargin]="'0'" [fontSize]="expandableFontSize" [stateActiveColor]="stateActiveColor" [stateDisabledColor]="stateDisabledColor"
              [statePendingColor]="statePendingColor" [backgroundOpacity]="expandableBackgroundOpacity"></app-list-item>

            @if (item.action) {
              <div class="expandable-action-container" aria-hidden="true" [ngStyle]="{
                  'color': item.action.fontColor ? item.action.fontColor : '#424242',
                  'font-weight': item.action.fontWeight ? item.action.fontWeight : 'normal',
                  'font-size': item.action.fontSize ? item.action.fontSize : '12px',
                  'backgroundColor': item.action.backgroundColor ? item.action.backgroundColor : 'transparent',
                  'border-radius': item.action.borderRadius ? item.action.borderRadius : 'unset',
                  'opacity': item.action.disabled ? item.action.disabled : 1
                }" (click)="actionClicked(item.action.actionId); $event.stopPropagation()">

                @if (item.action.imageUrl) {
                  <img alt="" [src]="item.action.imageUrl">
                }

                @if (item.action.textValue) {
                  <span>{{ item.action.textValue }}</span>
                }

                @if (item.action.bootstrapIconClass) {
                  <i class="bi" [ngClass]="item.action.bootstrapIconClass"></i>
                }
              </div>
            }
          </div>
        }
    </div>
  </div>
</div>

