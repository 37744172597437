@if (!mobile) {
  <div #dateInputContainer class="custom-date-container position-relative w-100">
    @if (label !== null) {
      <label for="control" [ngStyle]="{'margin-bottom': labelMargin}"
        [ngClass]="{'error': control.touched && !control.valid && !control.disabled}">{{ label }}</label>
    }

    <mat-form-field class="custom-formfield custom-date-formfield w-100" subscriptSizing="dynamic"
      appearance="fill" [ngClass]="{'disabled': control.disabled, 'error': control.touched && !control.valid && !control.disabled}" (click)="picker.open()">
      <div class="h-100">
        <input #formInput matInput readonly autocomplete="off" [matDatepicker]="picker"
          [placeholder]="placeholderLabel"  [formControl]="control!" [min]="minDate" [max]="maxDate" [value]="inputDateValue"
          (dateInput)="onDate($event.value)" (blur)="onTouched()">
      </div>

      <mat-datepicker-toggle matSuffix matRippleDisabled [for]="picker" [disabled]="control.disabled">
        @if (customIcon) {
          <mat-icon matDatepickerToggleIcon svgIcon="customIcon">
            <i class="bi" [ngClass]="customIconClass"></i>
          </mat-icon>
        }
      </mat-datepicker-toggle>

      <mat-datepicker #picker [disabled]="control.disabled" [calendarHeaderComponent]="customHeader"></mat-datepicker>
      @if (showMultiYearPicker) {
        <mat-multi-year-view [activeDate]="calendar.activeDate" (yearSelected)="onYearSelected($event)"></mat-multi-year-view>
      }
    </mat-form-field>
  </div>
} @else {
  <fieldset #dateInputContainer class="custom-date-container position-relative w-100">
    @if (label !== null) {
      <label for="control" [ngStyle]="{'margin-bottom': labelMargin}"
        [ngClass]="{'error': control.touched && !control.valid && !control.disabled}">{{ label }}</label>
    }

    <mat-form-field class="custom-formfield custom-date-formfield w-100" subscriptSizing="dynamic"
      appearance="fill"
      [ngClass]="{'disabled': control.disabled, 'error': control.touched && !control.valid && !control.disabled}" (click)="mobilePicker.open()">

      <div class="h-100">
        <input #formInput matInput readonly autocomplete="off" [matDatepicker]="mobilePicker"
          [disabled]="control.disabled" [min]="minDate" [max]="maxDate" [value]="inputDateValue"
          [placeholder]="placeholderLabel" (dateChange)="onDate($event.value)"
          (blur)="onTouched()">
      </div>

      <mat-datepicker-toggle matSuffix matRippleDisabled [for]="mobilePicker" [disabled]="control.disabled">
        @if (customIcon) {
          <mat-icon matDatepickerToggleIcon svgIcon="customIcon">
            <i class="bi" [ngClass]="customIconClass"></i>
          </mat-icon>
        }
      </mat-datepicker-toggle>

      <mat-datepicker #mobilePicker touchUi [disabled]="control.disabled" [calendarHeaderComponent]="customHeader"></mat-datepicker>
      @if (showMultiYearPicker) {
        <mat-multi-year-view touchUi [activeDate]="calendar.activeDate" (yearSelected)="onYearSelected($event)"></mat-multi-year-view>
      }
    </mat-form-field>
  </fieldset>
}
