<div class="know-for-banner-wrapper">
  <div class="know-for-banner-title mb-5">{{ labelsService.ourProductsKnownForBannerTitle | label }}</div>

  <div class="know-for-products-list">
    @for (product of products; track product) {
      <a class="product-container" [ngStyle]="{ 'background-color': product.backgroundColor }" (click)="scrollToProdcut(product.productName)">
        <img class="main-image" [src]="product.squareImageUrl" [alt]="product.productName">
        <img class="device-preview" [src]="product.mobileImagePreviewUrl">
      </a>
    }
  </div>
</div>
