<div class="product-page-wrapper">
  <app-page-banner
    [title]="mainBannerInfo.title ? mainBannerInfo.title : ''"
    [backgroundImageUrl]="mainBannerInfo.backgroundImageUrl ? mainBannerInfo.backgroundImageUrl : ''"
    [imageUrl]="mainBannerInfo.itemImageUrl ? mainBannerInfo.itemImageUrl : ''"
    [useGradient]="mainBannerInfo.useGradient"
    [pseudoElementColor]="environment.brandStyleVariables.pseudoElementHighlightColor"
    [smallBanner]="(environment.showUserPointsInBanners && (generalService.isUserLoggedIn | async)) ? false : true"
    [textContainerMaxWidth]="'70%'"
    [imageSize]="'35%'">
  </app-page-banner>

  <p class="product-info-title mt-4">{{labelService.productDetailsScreenTitle | label}}</p>

  @if (isLoaded) {
    <div class="product-info-wrapper mb-4"  [@FadeIn]="animationState">
      <div class="product-info-container card-radius">
        <app-item-image-slider
          [isLocked]="(generalService.isUserLoggedIn | async)! && product.prices[0].amount > userDefaultWalletValue"
          [imageList]="product.images ? product.images : []"
          [paginationBulletColor]="environment.brandStyleVariables.pseudoElementHighlightColor">
        </app-item-image-slider>
        @switch (product.type) {
          @case (ProductTypeEnum.VOUCHER) {
            <div>
              @if (product) {
                <app-voucher-details [product]="product" [partner]="partner" [isBlocked]="(generalService.isUserLoggedIn | async)! && product.prices[0].amount > userDefaultWalletValue"></app-voucher-details>
              }
            </div>
          }
          @case (ProductTypeEnum.NFT) {
            <div>
              <app-nft-details [product]="product" [partner]="partner"></app-nft-details>
            </div>
          }
        }
      </div>
      <div class="related-product-list-wrapper">
        <p class="product-list-title">{{labelService.productDetailsRelated | label}}</p>
        <div class="related-product-list-container">
          <!-- //TODO implement [quantityWarningNumber] and [quantityWarningColor] when Marketplace product quantity is Higher than one and we want to have a warning like quantity -->
          @for (productCard of relatedProductsList; track productCard) {
            <app-list-item-card class="product-list-card"
              [isBlocked]="(generalService.isUserLoggedIn | async)! && productCard.prices[0].amount > userDefaultWalletValue"
              [image]="productCard.images && productCard.images.length > 0 ? productCard.images[0].href : 'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/default-images/default-image-small-rectangle.svg'"
              [title]="productCard.name" [shadowBrandColor]="environment.brandStyleVariables.listShadowBrandColor" (click)="productItemClicked(productCard.id)">
              <ng-template>
                <div class="w-100">
                  <app-product-content [prices]="productCard.prices!" [lowestPriceValue]="productCard.lowestPrice!" [quantity]="productCard.quantity!"
                    [isBlocked]="(generalService.isUserLoggedIn | async)! && productCard.prices[0].amount > userDefaultWalletValue"
                  [endDate]="productCard.endDate!" [category]="productCard.categories!"  [serial]="productCard.serial!"></app-product-content>
                </div>
              </ng-template>
            </app-list-item-card>
          }
          @if (isListLoading) {
            @for (item of loadingListItems; track item) {
              <app-loading-list-item class="loading-item" [ngClass]="{'opacity-0': !isListLoading}"></app-loading-list-item>
            }
          }
        </div>
      </div>
    </div>
  }
</div>
