export default {
  "name": "EIP-3198",
  "number": 3198,
  "comment": "BASEFEE opcode",
  "url": "https://eips.ethereum.org/EIPS/eip-3198",
  "status": "Final",
  "minimumHardfork": "london",
  "gasConfig": {},
  "gasPrices": {
    "basefee": {
      "v": 2,
      "d": "Gas cost of the BASEFEE opcode"
    }
  },
  "vm": {},
  "pow": {}
};
