<app-page-banner
  [title]="mainBannerInfo.title ? mainBannerInfo.title : ''"
  [description]="mainBannerInfo.description ? mainBannerInfo.description : ''"
  [backgroundImageUrl]="mainBannerInfo.backgroundImageUrl ? mainBannerInfo.backgroundImageUrl : ''"
  [imageUrl]="mainBannerInfo.itemImageUrl ? mainBannerInfo.itemImageUrl : ''"
  [useGradient]="mainBannerInfo.useGradient"
  [pseudoElementColor]="environment.brandStyleVariables.pseudoElementHighlightColor"
  [textContainerMaxWidth]="'70%'"
  [imageSize]="'35%'">
</app-page-banner>


<div class="home-page-container">
  <!-- ####### User Goal Info ####### -->
  <div class="section user-goal-container">
    @if (labelService.homePageUserGoalTitle | label) {
      <p class="w-100 section-title">{{ labelService.homePageUserGoalTitle | label }}</p>
    }
    <!-- ### User progress card ### -->
    <div class="goal-progress-card">
      <div class="progress-bar-wrapper">
        <div class="progress-bar-container">
          <div class="start-progress-circle">
            <i class="bi bi-check-lg"></i>
          </div>
          @if (generalService.userDefaultWallet) {
            <span class="user-balance">{{ generalService.userDefaultWallet.currentValue }}</span>
          }
          <ng-progress
            #progressBar
            [direction]="'ltr+'"
            [speed]="800"
            [debounceTime]="0"
            [spinner]="false"
            [min]="0"
            [max]="100"
            [relative]="true"
            [flat]="true"
            (completed)="progressAnimationCompleted()">
          </ng-progress>
          <div class="end-progress-circle">
            <i class="bi bi-record2"></i>
          </div>
          @if (userHasGoal && isLoaded) {
            <div class="user-goal-value-container">
              @if (labelService.homePageUserGoalProgressLabel | label) {
                <p class="goal-progress-lable">{{ labelService.homePageUserGoalProgressLabel | label }}</p>
              }
              <div class="user-goal-value">{{ this.userGoal.prices[0].amount }}</div>
            </div>
          }
        </div>
      </div>
      <!-- Rive -->
      <div class="rive-animation-container">
        <app-rive-animation-component
          [loop]="true"
        [animationFileUrl]="'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/home/goal-progress-animation.riv'"></app-rive-animation-component>
      </div>
    </div>
    @if (isLoaded) {
      <!-- ### Balance to win card ### -->
      <div class="available-balance-to-win-card">
        <div class="text-container">
          <p class="card-title">{{ labelService.homePageAvailableToWinCardTitle | label }}</p>
          <div class="winning-potencials">
            @if (!potencialRewardsLoading && potencialRewards) {
              <div class="activities-potencial-winnings">
                <div>
                  @if (labelService.homePageAvailableToWinTargetLabel | label) {
                    <p class="potencial-prize-label">{{ labelService.homePageAvailableToWinTargetLabel | label }}</p>
                  }
                  @if (labelService.homePageAvailableToWinFirstTargetName | label) {
                    <p class="target-name">{{ labelService.homePageAvailableToWinFirstTargetName | label }}</p>
                  }
                </div>
                <div>
                  <p class="potencial-prize-value">{{ potencialRewards.potentialPartnerRewards }}</p>
                  @if (labelService.homePageAvailableToWinCurrencyName | label) {
                    <p class="potencial-prize-currency">{{ labelService.homePageAvailableToWinCurrencyName | label }}</p>
                  }
                </div>
              </div>
              <div class="input-activities-potencial-winnings">
                <div>
                  @if (labelService.homePageAvailableToWinTargetLabel | label) {
                    <p class="potencial-prize-label">{{ labelService.homePageAvailableToWinTargetLabel | label }}</p>
                  }
                  @if (labelService.homePageAvailableToWinSecondTargetName | label) {
                    <p class="target-name">{{ labelService.homePageAvailableToWinSecondTargetName | label }}</p>
                  }
                </div>
                <div>
                  <p class="potencial-prize-value">{{ potencialRewards.potentialUserInputRewards }}</p>
                  @if (labelService.homePageAvailableToWinCurrencyName | label) {
                    <p class="potencial-prize-currency">{{ labelService.homePageAvailableToWinCurrencyName | label }}</p>
                  }
                </div>
              </div>
            } @else {
              <app-main-loading class="loading"></app-main-loading>
            }
            <ng-template #loading>
              <app-main-loading class="loading"></app-main-loading>
            </ng-template>
          </div>
        </div>
        <img [src]="'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/home/available-to-win-card-img.png'">
      </div>
      <!-- ### Goal info card ### -->
      @if (userHasGoal) {
        <div class="goal-info-card" [ngStyle]="{ 'background-image': 'url(' + userGoalBackgroundImg + ')'}">
          <div class="info-container">
            <app-generic-icon-button class="edit-goal-btn"
              [minHeight]="'unset'"
              [iconClass]="'bi bi-pencil'" [iconColor]="'var(--main-brand-color)'" [hoveredIconColor]="'var(--main-brand-color)'"
              [buttonSizeWidth]="'calc(var(--small-section-description-font-size) * 2)'"
              [buttonSizeHeight]="'calc(var(--small-section-description-font-size) * 2)'"
              [iconSize]="'var(--header-font-size)'" [buttonBackgroundColor]="environment.brandStyleVariables.mainBackgroundColor"
              [hoveredBackgroundColor]="environment.brandStyleVariables.mainBackgroundColor"
              [borderRadius]="'20vw'"
              [buttonSpacing]="'0'"
              (buttonAction)="promptEditGoal()"></app-generic-icon-button>
            <p class="info-label">{{ labelService.homePageUserGoalCardTitle | label }}</p>
            <div class="goal-info">
              <p class="goal-value">{{ 'marketplace_listitem_price' | label: { price: this.userGoal.prices[0].amount, unit: getCurrencySymbol(this.userGoal.prices[0].unit, 'narrow') | label } }}</p>
              <div class="goal-title-redirect">
                <span class="goal-title">{{ this.userGoal.name }}</span>
                <div class="goal-redirect-btn" (click)="redirectToGoal()">
                  <i class="bi bi-arrow-right-short"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      } @else {
        <div class="goal-info-card empty-goal">
          <div class="info-container">
            <p class="info-label">{{ labelService.homePageUserGoalCardTitle | label }}</p>
            <p class="add-goal-text-line">{{ labelService.homePageUserGoalCardEmptyTitle | label }}</p>
            <p class="add-goal-text-line">{{ labelService.homePageUserGoalCardEmptySubtitle | label }}</p>
            <div class="goal-info">
              <div class="goal-title-redirect">
                <span class="goal-title" [ngStyle]="{ 'color': 'var(--main-brand-color)' }">{{ labelService.homePageUserGoalCardEmptyButtonLabel | label }}</span>
                <div class="goal-redirect-btn" (click)="promptEditGoal()">
                  <i class="bi bi-plus"></i>
                </div>
              </div>
            </div>
          </div>
        </div>
      }
    }
  </div>
  @if (isLoaded) {
    <!-- ####### Activities List ####### -->
    <div class="activities-list-wrapper section">
      @if (labelService.homePageActivityListTitle | label) {
        <p class="section-title">{{ labelService.homePageActivityListTitle | label }}</p>
      }
      @if (labelService.homePageActivityListDescription | label) {
        <p class="section-subtitle">{{ labelService.homePageActivityListDescription | label }}</p>
      }
      @if (activitiesList.length > 0) {
        <div class="activities-list-container">
          <div class="activity-list-header">
            @if (activitiesHeaderList.length > 0) {
              <app-list-header
                [fullSize]="!(generalService.isMobile | async)" [fontSize]="'var(--main-info-font-size)'" [fontColor]="environment.brandStyleVariables.mainBorderColor"
              [headerList]="activitiesHeaderList" [showArrows]="false" [clickable]="false"></app-list-header>
            }
          </div>
          <div class="activity-list" #activityListContainer>
            @if (activitiesList.length > 0) {
              @for (item of activitiesList; track item) {
                <app-list-item-expandable [fontSize]="'var(--header-font-size)'" [expandableFontSize]="'var(--small-font-size)'" [item]="item" [expandableItem]="item.infoExpandable!"
                  [expandableHeader]="homeService.expandableHeaders" [headerFontWeight]="'600'" [backgroundOpacity]="'1'" [fullSize]="!(generalService.isMobile | async)!" [showId]="false"
                  [isMobile]="(generalService.isMobile | async)!" [expandableBackgroundOpacity]="'0%'" [backgroundOpacity]="'10%'" [stateActiveColor]="'var(--main-brand-color)'"
                  (actionEvent)="listItemActionClicked($event, item.id)">
                </app-list-item-expandable>
              }
            }
          </div>
          <p class="show-full-activity-list" (click)="showMoreActivities()">
            {{ labelService.homePageActivityListShowMoreLabel | label }}
          </p>
        </div>
      } @else {
        <div class="not-items-found mt-5 mb-5">
          <app-no-items [image]="'assets/imgs/environments/'+ environment.tenantLocalAssetsPath + '/empty-list-image.png'" [title]="labelService.homePageEmptyActivitiesListTitle | label"
            [titleColor]="'#424242'" [titleFontSize]="'var(--intermediate-title-font-size)'" [titleFontWeight]="'600'"
            [showRedirectBtn]="true" [redirectBtnText]="labelService.homePageEmptyActivitiesRedirectBtnLabel | label"
            (redirectBtnClicked)="generalService.navigateTo('/activities')"></app-no-items>
        </div>
      }
    </div>
  }
</div>


@if (showEditUserGoalPopup) {
  <app-edit-user-goal-popup class="edit-user-goal-popup-container"
    [isAddingGoal]="!userHasGoal"
    (popUpClosed)="showEditUserGoalPopup = false;"
    (newGoalSelected)="goalSelected($event)"></app-edit-user-goal-popup>
}
