
import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule, ValidationErrors, Validator } from '@angular/forms';
import { SingleClickDirective } from '../../__directives/single-click/single-click.directive';
import { LibraryService } from '../../__services/library.service';
import { BaseComponent } from '../../_base-component/base.component';

@Component({
  standalone: true,
  selector: 'app-button-base-component',
  template: '',
  styleUrls: [
    '../../_base-component/base.component.sass',
    './button-base.component.sass'
  ],
  imports: [ReactiveFormsModule, BaseComponent, SingleClickDirective],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: ButtonBaseComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: ButtonBaseComponent
    },
    LibraryService
  ]
})
export class ButtonBaseComponent<T = unknown> extends BaseComponent implements ControlValueAccessor, Validator {

  @Input() public toggledOffColor: string;
  @Input() public buttonBackgroundColor: string;
  @Input() public hoveredBackgroundColor: string;
  @Input() public iconColor: string;
  @Input() public hoveredIconColor: string;
  @Input() public defaultIconColor: string;
  @Input() public disabledBackgroundColor: string;

  @Input() public buttonSize: string;
  @Input() public buttonSizeWidth: string;
  @Input() public buttonSizeHeight: string;
  @Input() public buttonSpacing: string;
  @Input({required: true}) public minHeight: string;
  @Input() public iconSize: string;
  @Input() public groupGap: string;
  @Input() public verticalMargin: string = '15px';
  @Input() public justifyContent: string = 'unset';
  @Input() public inputSize: string;

  @Input() public toggleOnBgColor: string;
  @Input() public toggleWidth: string | null;
  @Input() public toggleHeight: string | null;

  @Input() public isDisabled: boolean;
  @Input() public iconClass: string;

  @Input() public buttonName: string;
  @Input() public delayTime: number = 1000;

  @Input() public hasBorder: boolean;

  @Input() public hasIcon: boolean;
  @Input() public hasPrefixIcon: boolean;
  @Input() public isActive: boolean;
  @Input() public imageAsButton: boolean;

  @Output() public buttonAction: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() public buttonActive: EventEmitter<boolean> = new EventEmitter<boolean>();

  public isHovered: boolean;

  public onChange: (inputValue: T) => void;
  public onTouched: () => void;
  public onValidatorChange: () => void;

  public buttonValue: string;

  public lastActionExecution: Date;

  constructor(
    public override host: ElementRef,
    public override libraryService: LibraryService
  ) {
    super(host, libraryService);
  }

  public writeValue(value: string): void {
    this.buttonValue = value;
  }

  public registerOnChange(onChange: () => void): void {
    this.onChange = onChange;
  }

  public registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public setDisabledState(isDisabled: boolean): void {
    // console.log(isDisabled);
  }

  public registerOnValidatorChange(onValidatorChange: () => void): void {
    this.onValidatorChange = onValidatorChange;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  public validate(control: AbstractControl): ValidationErrors | null {
    const errors: ValidationErrors = {};

    return errors;
  }

  public emitButtonAction(): void {
    this.buttonAction.emit(true);
  }

  public toggleAction(): void {
    this.isActive = !this.isActive;

    this.buttonActive.emit(this.isActive);
  }

  public setSpecificCSSVars(): void {
    if (this.minHeight || this.buttonSize || this.buttonSpacing || this.toggledOffColor || this.iconSize || this.buttonBackgroundColor
      || this.iconColor || this.hoveredIconColor || this.hoveredBackgroundColor || this.toggleWidth || this.toggleHeight || this.toggleOnBgColor
      || this.groupGap || this.verticalMargin || this.justifyContent || this.inputSize || this.defaultIconColor || this.disabledBackgroundColor
      || this.buttonSizeWidth || this.buttonSizeHeight) {

      if (this.buttonSize)
        this.host.nativeElement.style.setProperty('--button-size', this.buttonSize);
      if (this.buttonSpacing)
        this.host.nativeElement.style.setProperty('--button-spacing', this.buttonSpacing);
      if (this.minHeight)
        this.host.nativeElement.style.setProperty('--min-height', this.minHeight);
      if (this.toggledOffColor)
        this.host.nativeElement.style.setProperty('--toggled-off-bg', this.libraryService.convertToHexColor(this.host, this.toggledOffColor));
      if (this.iconSize)
        this.host.nativeElement.style.setProperty('--icon-size', this.iconSize);
      if (this.buttonBackgroundColor)
        this.host.nativeElement.style.setProperty('--button-background-color', this.libraryService.convertToHexColor(this.host, this.buttonBackgroundColor));
      if (this.hoveredBackgroundColor)
        this.host.nativeElement.style.setProperty('--hovered-background-color', this.libraryService.convertToHexColor(this.host, this.hoveredBackgroundColor));
      if (this.iconColor)
        this.host.nativeElement.style.setProperty('--icon-color', this.libraryService.convertToHexColor(this.host, this.iconColor));
      if (this.hoveredIconColor)
        this.host.nativeElement.style.setProperty('--hovered-icon-color', this.libraryService.convertToHexColor(this.host,this.hoveredIconColor));
      if (this.toggleWidth)
        this.host.nativeElement.style.setProperty('--toggle-width', this.toggleWidth);
      if (this.toggleHeight)
        this.host.nativeElement.style.setProperty('--toggle-height', this.toggleHeight);
      if (this.toggleOnBgColor)
        this.host.nativeElement.style.setProperty('--active-background', this.libraryService.convertToHexColor(this.host,this.toggleOnBgColor));
      if (this.justifyContent)
        this.host.nativeElement.style.setProperty('--labels-justify-content', this.justifyContent);
      if (this.verticalMargin)
        this.host.nativeElement.style.setProperty('--vertical-margin', this.verticalMargin);
      if (this.groupGap)
        this.host.nativeElement.style.setProperty('--group-spacing', this.groupGap);
      if (this.inputSize)
        this.host.nativeElement.style.setProperty('--input-size', this.inputSize);
      if (this.defaultIconColor)
        this.host.nativeElement.style.setProperty('--icon-default-color', this.libraryService.convertToHexColor(this.host, this.defaultIconColor));
      if (this.disabledBackgroundColor)
        this.host.nativeElement.style.setProperty('--disabled-background', this.libraryService.convertToHexColor(this.host, this.disabledBackgroundColor));
      if (this.buttonSizeWidth)
        this.host.nativeElement.style.setProperty('--button-size-width', this.buttonSizeWidth);
      if (this.buttonSizeHeight)
        this.host.nativeElement.style.setProperty('--button-size-height', this.buttonSizeHeight);
    }
  }
}
