<div class="product-list-wrapper">
  @if (isLoaded) {
    <div class="section product-list-container">
      <p class="section-title">{{ labelService.productHistoryListTitle | label }}</p>
      <!-- <div class="product-list-filters-header-container" *ngIf="filterList && filterList.length > 0"> -->
      <div class="product-list-filters-header-container">
        <div class="search-container">
          <form [formGroup]="form">
            <app-single-line-text-input
              [control]="searchControl"
              [formControlName]="'search'"
              [placeholderLabel]="labelService.productHistorySearchPlaceholder | label" [inputHeight]="'48px'" [fontSize]="'var(--main-info-font-size)'"
              [brandColor]="'var(--main-brand-color)'"
              [backgroundColor]="environment.brandStyleVariables.inputBackgroundColor" [roundBorders]="true" [borderRadius]="'calc(var(--main-info-font-size) * 3)'" [borderColor]="'transparent'"
              [prefixMargin]="'20px'" [isSuffixIcon]="true" [inputValue]="searchString" [hasSuffix]="true"
              [inactiveSuffix]="'bi bi-search'" [activeSuffix]="'bi bi-search'" [alignLeft]="true" [debounceTime]="0"
              (suffixClicked)="search()" (actionOnEnter)="search()">
            </app-single-line-text-input>
          </form>
          <!--       <app-toggle-button class="d-flex align-items-center" [imageAsButton]="(generalService.isMobile | async)"
          [buttonText]="(generalService.isMobile | async) ? '' : showFilters ? (labelService.marketplaceFiltersButtonCloseLabel | label) : (labelService.marketplaceFiltersButtonLabel | label)" [fontSizePx]="(generalService.isMobile | async) ? 20 : 12"
          [brandColor]="environment.brandStyleVariables.mainBrandColor" [fontColor]="environment.brandStyleVariables.mainBackgroundColor" [toggleOnBgColor]="environment.brandStyleVariables.mainErrorColor"
          [toggleOnFtColor]="environment.brandStyleVariables.mainBackgroundColor" [hoveredIconColor]="environment.brandStyleVariables.mainBackgroundColor"
          [imageUrl]="'assets/imgs/filter-icon.svg'" [defaultIconColor]="environment.brandStyleVariables.mainBackgroundColor"
          [isActive]="showFilters"
        (buttonActive)="showFilters = $event"></app-toggle-button> -->
      </div>
    </div>
    <!--
    <div class="product-list-mobile-filters-container custom-scroll" [ngClass]="{'active' : (generalService.isMobile | async) && showFilters}">
      <app-filters-list [filterList]="filterList" [isMobile]="(generalService.isMobile | async)" [activeFilterCount]="activeFilterCount" (filtersUpdated)="filtersUpdated()" (close)="showFilters = false"></app-filters-list>
    </div> -->
    <div class="product-list-list-wrapper">
      <div class="product-list-list-container">
        <!--       <div class="filter-chips-container" *ngIf="!(generalService.isMobile | async)" [ngClass]="{'with-items': filterChips.length > 0}">
        <div class="chip" *ngFor="let chip of filterChips">
          <span>{{chip.name}}</span>
          <div class="delete-icon-container" (click)="deleteChip(chip)">
            <div class="close-icon">
              <span class="cross-line"></span>
            </div>
          </div>
        </div>
      </div> -->
      <div class="product-list-container w-100"
        infiniteScroll
        [infiniteScrollDistance]="listPercentageFromBottom"
        [infiniteScrollThrottle]="50"
        [immediateCheck]="true"
        [scrollWindow]="false"
        [infiniteScrollContainer]="mainComponent.scrollableContainerRef"
        (scrolled)="onScroll()"
        #productContainerList>
        @if (itemList.length > 0 || isListLoading) {
          <div class="product-list-wrapper">
            @for (productCard of itemList; track productCard) {
              <app-list-item-card class="product-list-card" [image]="productCard.images && productCard.images.length > 0 ? productCard.images[0].href : 'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/default-images/default-image-small-rectangle.svg'"
                [title]="productCard.name" [shadowBrandColor]="environment.brandStyleVariables.listShadowBrandColor" [itemTypeImage]="'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/icons/product-bought-icon.png'" (click)="listItemClicked(productCard.productId)">
                <ng-template>
                  <div class="w-100">
                    <app-product-content [isHistoryItem]="true" [quantity]="productCard.quantity? productCard.quantity : 0"
                    [endDate]="productCard.expirationDate ? productCard.expirationDate : ''"></app-product-content>
                  </div>
                </ng-template>
              </app-list-item-card>
            }
            @if (isListLoading) {
              @for (item of loadingListItems; track item) {
                <app-loading-list-item class="loading-item" [ngClass]="{'opacity-0': !isListLoading}">
                  <ng-template>
                    <app-product-loading-content></app-product-loading-content>
                  </ng-template>
                </app-loading-list-item>
              }
            }
          </div>
        } @else {
          <div class="no-items-found">
            <app-no-items [image]="'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/empty-list-image.png'" [title]="labelService.productHistoryListNoItems | label"></app-no-items>
          </div>
        }
      </div>
    </div>
    <!--     <div class="marketplace-desktop-filters-container custom-scroll" [ngClass]="{'active' : !(generalService.isMobile | async) && showFilters}">
    <app-filters-list [filterList]="filterList" [isMobile]="(generalService.isMobile | async)" [activeFilterCount]="activeFilterCount" (filtersUpdated)="filtersUpdated()" (close)="showFilters = false"></app-filters-list>
  </div> -->
</div>
</div>
}
</div>
