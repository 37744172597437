<div class="bg-loading" [ngClass]="{ 'is-mobile': generalService.isMobile | async }">
  @if (!(environment.customLoading)) {
    <div class="spinner">
      <div class="bounce1"></div>
      <div class="bounce2"></div>
      <div class="bounce3"></div>
    </div>
  } @else {
    @switch (environment.customLoadingAnimationType) {
      <!-- Rive -->
      @case ('rive') {
        <canvas #canvasRive>
        </canvas>
      }
      <!-- Lottie -->
      @case ('lottie') {
        <ng-lottie #lottieAnimation
          [width]="'100%'"
          [height]="'100%'"
          [options]="lottieOptions"
          (animationCreated)="lottieAnimationItem = $event;">
        </ng-lottie>
      }
      <!-- GIF -->
      @case ('gif') {
        <img [src]="'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/custom-loading-animation.gif'" alt="loading...">
      }
    }
  }

  <ng-template #customLoading>
    @switch (environment.customLoadingAnimationType) {
      <!-- Rive -->
      @case ('rive') {
        <canvas #canvasRive>
        </canvas>
      }
      <!-- Lottie -->
      @case ('lottie') {
        <ng-lottie #lottieAnimation
          [width]="'100%'"
          [height]="'100%'"
          [options]="lottieOptions"
          (animationCreated)="lottieAnimationItem = $event;">
        </ng-lottie>
      }
      <!-- GIF -->
      @case ('gif') {
        <img [src]="'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/custom-loading-animation.gif'" alt="loading...">
      }
    }
  </ng-template>
</div>




