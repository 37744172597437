import { PaymentTypeEnum } from "src/app/pages/marketplace/_services/_order-entry/order-entry.model";

export interface GenericErrorReply {
  errorCode: number;
  title: string;
  detail: string;
  errors?: Array<ValidationFailure>;
  errorType: errorTypes;
}

export interface ValidationFailure {
  key: string;
  value: Array<string>;
}

export enum errorTypes {

  NotFound = 0, // to redirect to 404 page
  FORM_ERROR = 1, // to show an error under form inputs
  SNACKBAR_ERROR = 2, // to open a snackbar with the error
  POPUP_ERROR = 3, // to open a popup with the error
  BREAKING_POPUP_ERROR = 4 // to open a popup with the error that refreshes the page on close
}

export interface GetGeneralInfoReply extends GeneralInfo {
}

export interface Label {
  labelId: string;
  labelKey: string;
  labelValue: string;
  state?: LabelState;
}

export enum LabelState {
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED'
}

export interface LabelDTO {
  labelId: string;
  labelKey: string;
  labelValue: string;
  state?: LabelState;
}

export interface GetUserDetailsReply{
  details: UserDetails;
}

export interface GeneralInfo {
  brandingInfo: BrandingInfo;
  // TODO
}

export interface BrandingInfo {
  brandImageUrl: string;
  phoneContact: string;
  emailContact: string;
  socialMediaList: Array<SocialMedia>;
  clientName: string;
  // TODO

  brandHQAddress?: FiscalAddress;
  brandHQMapImageUrl?: string;
  currencies?: Array<Currency>;
}

export interface Currency {
  type: string;
  name: string;
  description?: string;
  requiresWallet: boolean;
  paymentTypes: Array<PaymentTypeEnum>;
}

export interface SocialMedia {
  id: number;
  name: string;
  redirectUrl: string;
  iconUrl: string;
}

export interface BannerInfo {
  bannerId: number;
  type: BannerTypes;
  content: VideoBanner | DescriptionListBanner | CarouselBanner | ActionIncentiveBanner | ProductDetailBanner | ImageCardBanner | FeaturedTitleBanner | PartnerListBanner;
}

export enum BannerTypes {
  VIDEO = 'VIDEO',
  DESCRIPTION_LIST = 'DESCRIPTION_LIST',
  CAROUSEL = 'CAROUSEL',
  ACTION_INCENTIVE = 'ACTION_INCENTIVE',
  PRODUCT_DETAIL = 'PRODUCT_DETAIL',
  IMAGE_CARD = 'IMAGE_CARD',
  FEATURED_TITLE = 'FEATURED_TITLE',
  PARTNER_LIST = 'PARTNER_LIST'
}

export interface VideoBanner {
  videoUrl: string;
  mobileVideoUrl?: string;
  animationDesktop?: Animation;
  animationMobile?: Animation;
  title: string;
  subtitle: string;
  fontColor: string;
}

export interface DescriptionListBanner {
  backgroundColor: string;
  descriptionList: Array<DescriptionContent>;
}

export interface DescriptionContent {
  title: string;
  subtitle: string;
  imageUrl?: string;
  animation?: Animation;
}

export interface Animation {
  type: AnimationTypes;
  url: string;
  loop: boolean;
}

export enum AnimationTypes {
  GIF = 'GIF',
  LOTTIE = 'LOTTIE',
  RIVE = 'RIVE'
}

export interface CarouselBanner {
  backgroundColor: string;
  mainCarouselContent: Array<CarouselContent>;
  smallCarouselContent: Array<CarouselContent>;
}

export interface CarouselContent {
  id: string;
  imageUrl: string;
  type?: CarouselType;
  title: string;
  description?: string;
  iconUrl: string;
  badgeIconUrl?: string;
}

export interface ActionIncentiveBanner {
  backgroundColor: string;
  fontColor: string;
  title: string;
  imageUrl: string;
  description?: string;
  actionText?: string;
  actionIconUrl?: string;
  actionUrl?: string;
}

export interface ProductDetailBanner {
  backgroundColor: string;
  title: string;
  subtitle: string;
  text: string;
  imageUrl: string;
  iconUrls: Array<string>;
  bulletPoints?: Array<string>;
  incentive?: ProductIncentive;
}

export interface ProductIncentive {
  title: string;
  subtitle: string;
  description: string;
}

export interface ImageCardBanner {
  backgroundColor: string;
  title: string;
  text: string;
  imageUrl: string;
  mobileImageUrl: string;
  iconUrls: Array<string>;
  scrollAnimationEnabled?: boolean;
}

export interface FeaturedTitleBanner {
  backgroundColor: string;
  title: string;
  fontColor: string;
  imageUrl: string;
  scrollAnimationEnabled?: boolean;
}

export interface PartnerListBanner {
  backgroundColor: string;
  title: string;
  partnerList: Array<PartnerContent>;
}

export interface PartnerContent {
  title: string;
  imageUrl: string;
}

export interface UserDetails {
  tenantId?: string;
  firstName?: string;
  lastName?: string;
  fiscalNumber?: string;
  birthdate?: Date;
  fiscalAddress?: Contact;
  emailAddress?: Contact;
  images?: Image;
  showTutorial?: boolean;
  showOnboarding?: boolean;
  category?: string;
  wallets?: Array<Wallet>;
  goalId?: string;
}

export interface UserDetailsDTO {
  tenantId?: string;
  firstName?: string;
  lastName?: string;
  fiscalNumber?: string;
  birthDate?: string;
  contacts?: Array<ContactDTO>;
  images?: Image;
  showTutorial?: boolean;
  showOnboarding?: boolean;
  category?: string;
  wallets?: Array<WalletDTO>;
  goalId?: string;
}

export interface Wallet {
  currentValue: number;
  isDefault: boolean;
  totalValue: number;
  type: string;
}

export interface WalletDTO {
  currentValue: number;
  isDefault: boolean;
  totalValue: number;
  type: string;
}

export interface Image {
  coverImage?: ImageContent;
  profilePictureImage?: ImageContent;
}

export interface ImageContent {
  href: string;
  content: string;
  type: ContentType;
}

export interface ImageContentDTO {
  href: string;
  content: string;
  type: ContentType;
}

export enum ContentType {
  IMAGE = 'IMAGE',
  VIDEO = 'VIDEO',
}

export interface Contact {
  mediumType: MediumType;
  preferred?: boolean;
  emailAddress?: EmailAddress;
  fiscalAddress?: FiscalAddress;
}

export interface ContactDTO {
  mediumType: MediumType;
  preferred?: boolean;
  characteristic?: FiscalAddress | EmailAddress;
  characteristicObject?: FiscalAddress | EmailAddress;
}

export interface FiscalAddress {
  addressLine1?: string;
  addressLine2?: string;
  postalCode?: string;
  city?: string;
  country?: string;
  gpsCoords?: GpsCoords;
  distance?: number;
}

export interface EmailAddress {
  emailAddress: string;
}

export interface GpsCoords {
  x: number;
  y: number;
}

export enum MediumType {
  fiscalAddress = "FISCAL_ADDRESS",
  emailAddress = "EMAIL_ADDRESS"
}

export interface Menu {
  menuId: string;
  name: string;
  viewUrl: string;
  iconUrl?: string;
  fatherId?: string;
  childs?: Array<Menu>;
  isHidden?: boolean;

  bannerContent?: MenuBanner;
}

export interface MenuDTO {
  menuId: string;
  name: string;
  viewUrl: string;
  iconUrl?: string | null;
  fatherId?: string | null;
  childs?: Array<MenuDTO> | null;
  isHidden?: boolean;

  bannerContent?: MenuBannerDTO | null;
}

export interface Language {
  languageId: string;
  code: string;
  name: string;
  image?: string;
  isDefault: boolean;
}

export interface LanguageDTO {
  languageId: string;
  code: string;
  name: string;
  image?: string | null;
  isDefault: boolean;
}

export interface MenuBannerDTO {
  title?: string | null;
  description?: string | null;
  backgroundImageUrl: string;
  itemImageUrl?: string | null;
  useGradient: boolean;
}

export interface MenuBanner {
  title?: string;
  description?: string;
  backgroundImageUrl: string;
  itemImageUrl?: string;
  useGradient: boolean;
}

/* export enum BannerTypes {
  notUsed,

  video,
  descriptions,
  carousel,
  actionIncentive,
  productDetail,
  imageCard,
  featuredTitle,
  partnerList
} */

export enum CarouselType {
  COLLECTIONS = 'COLLECTIONS',
  NEWS = 'NEWS',
  NFT = 'NFT',
  BLOG = 'BLOG',
  INSTITUTIONS = 'INSTITUTIONS'
}
