import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { ImageCardBanner } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LandingPageService } from '../../landing-page.service';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-image-card',
  templateUrl: './image-card.component.html',
  styleUrls: ['./image-card.component.sass'],
  standalone: true,
  imports: [
    CommonModule
  ]
})
export class ImageCardComponent implements OnInit, AfterViewInit {
  @ViewChild('AnimationScrollableContainer') public animationScrollableContainerRef: ElementRef;
  @ViewChild('ImageContainer') public imageContainerRef: ElementRef;
  @ViewChild('TextContainer') public textContainerRef: ElementRef;
  @ViewChild('IconsContainer') public iconsContainerRef: ElementRef;

  @Input() content: ImageCardBanner

  private headerHeight: number = 0;
  private animationPercentage: number = 0;


  constructor(
    private host: ElementRef<HTMLElement>,
    public generalService: GeneralService,
    private landingPageService: LandingPageService
  ) {
  }

  ngOnInit(): void {
    this.setCSSVars();
  }

  ngAfterViewInit(): void {
    this.scrollAnimation();
  }

  private setCSSVars(): void {
    if (this.content.backgroundColor) {

      //set variables
      if (this.content.backgroundColor)
        this.host.nativeElement.style.setProperty('--background-color', this.content.backgroundColor);
    }
  }

  private scrollAnimation(): void {
    const imageContainer = this.animationScrollableContainerRef.nativeElement;
    const image = this.imageContainerRef.nativeElement;
    const icons = this.iconsContainerRef.nativeElement;
    const text = this.textContainerRef.nativeElement;

    this.headerHeight = this.generalService.getCssComputedValueFromExpression(window.getComputedStyle(this.generalService.scrollableContainerRef.nativeElement).getPropertyValue('--navbar-height'));

    window.addEventListener('resize', () => {
      this.headerHeight = this.generalService.getCssComputedValueFromExpression(window.getComputedStyle(this.generalService.scrollableContainerRef.nativeElement).getPropertyValue('--navbar-height'));
      this.animation(imageContainer, image, icons, text);
    });

    this.generalService.addScrollableEvent({id: 'imageCard', fn: () => {
      this.animationLogic(imageContainer, image, icons, text);
    }});
  }

  private animationLogic(imageContainer: HTMLElement, image: HTMLElement, icons: HTMLElement, text: HTMLElement) {
    if (imageContainer.getBoundingClientRect().y < 0 && imageContainer.getBoundingClientRect().y + imageContainer.clientHeight > image.clientHeight) {
      this.animation(imageContainer, image, icons, text);
    }
  }

  private animation(imageContainer: HTMLElement, image: HTMLElement, icons: HTMLElement, text: HTMLElement): void {
    this.animationPercentage = ((imageContainer.getBoundingClientRect().y * - 1) * 100) / (imageContainer.clientHeight - image.clientHeight);

    // image fade in animation
    if (this.animationPercentage < 5)
      image.style.opacity = '0';
    else if (this.animationPercentage <= 20)
      image.style.opacity = (this.animationPercentage - 5) / (20 - 5) + '';
    else
      image.style.opacity = '1';

    // text and icons fade in animation
    if (this.animationPercentage <= 25) {
      text.style.opacity = '0';
      icons.style.opacity = '0';
    } else if (this.animationPercentage <= 50) {
      text.style.opacity = (this.animationPercentage - 25) / (50 - 25) + '';
      icons.style.opacity = (this.animationPercentage - 25) / (50 - 25) + '';
    } else {
      text.style.opacity = '1';
      icons.style.opacity = '1';
    }

    // icons slide animation
    if (this.animationPercentage <= 60)
      icons.style.transform = 'translateY(0px)';
    else if (this.animationPercentage <= 90)
      icons.style.transform = 'translateY(' + ((this.animationPercentage - 60) / (90 - 60) * (window.innerHeight / 5)) + 'px)';
    else
      icons.style.transform = 'translateY(' + ((window.innerHeight / 5)) + 'px)';
  }
}
