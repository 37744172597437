import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  standalone: true,
  selector: 'app-tooltip',
  templateUrl: './tooltip.component.html',
  styleUrls: ['./tooltip.component.sass'],
  imports: [
    CommonModule
  ]
})
export class ToolTipComponent implements OnInit {

  @Input() public backgroundColor: string;
  @Input() public fontColor: string;
  @Input() public borderColor: string;

  @Input() public fontSize: string;
  @Input() public fontWeight: number;
  @Input() public borderRadius: string;
  @Input() public borderWidth: string;

  @Input() public borderStyle: string;

  @Input() public alignClass: string;
  @Input() public iconClass: string = 'bi-info-circle';
  @Input({required: true}) public textContent: string;

  constructor(private host: ElementRef) { }

  ngOnInit(): void {
    this.setCSSVars();
  }

  private setCSSVars(): void {
    if (this.backgroundColor || this.fontColor || this.borderColor || this.fontSize || this.fontWeight || this.borderRadius || this.borderWidth || this.borderStyle) {

      if (this.backgroundColor)
        this.host.nativeElement.style.setProperty('--background-color', this.backgroundColor);

      if (this.fontColor)
        this.host.nativeElement.style.setProperty('--font-color', this.fontColor);

      if (this.borderColor)
        this.host.nativeElement.style.setProperty('--border-color', this.borderColor);

      if (this.fontSize)
        this.host.nativeElement.style.setProperty('--font-size', this.fontSize);

      if (this.fontWeight)
        this.host.nativeElement.style.setProperty('--font-weight', this.fontWeight);

      if (this.borderRadius)
        this.host.nativeElement.style.setProperty('--border-radius', this.borderRadius);

      if (this.borderRadius)
        this.host.nativeElement.style.setProperty('--border-width', this.borderWidth);

      if (this.borderStyle)
        this.host.nativeElement.style.setProperty('--border-style', this.borderStyle);
    }
  }
}
