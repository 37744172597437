import { Component, Input, OnInit } from '@angular/core';
import { ProductCode, ProductCodeStatus } from '../../../_services/products/product-history.model';
import { environment } from 'src/environments/environment';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { CommonModule } from '@angular/common';
import { LabelPipe } from 'src/app/_shared-modules/label-pipe-module/label.pipe';

@Component({
  selector: 'app-product-code-list-item',
  templateUrl: './product-code-list-item.component.html',
  styleUrls: ['./product-code-list-item.component.sass'],
  standalone: true,
  imports: [
    CommonModule,
    LabelPipe
  ]
})
export class ProductCodeListItemComponent implements OnInit {

  @Input() productCode: ProductCode;

  public environment = environment;
  public productCodeStatus = ProductCodeStatus;

  constructor(
    public labelService: LabelsService,
  ) { }

  ngOnInit(): void {}

}
