import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-loading-small-list-item',
  templateUrl: './loading-small-list-item.component.html',
  styleUrls: ['./loading-small-list-item.component.sass'],
  standalone: true
})
export class LoadingSmallListItemComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
