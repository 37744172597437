/*
This file is part of web3.js.

web3.js is free software: you can redistribute it and/or modify
it under the terms of the GNU Lesser General Public License as published by
the Free Software Foundation, either version 3 of the License, or
(at your option) any later version.

web3.js is distributed in the hope that it will be useful,
but WITHOUT ANY WARRANTY; without even the implied warranty of
MERCHANTABILITY or FITNESS FOR A PARTICULAR PURPOSE.  See the
GNU Lesser General Public License for more details.

You should have received a copy of the GNU Lesser General Public License
along with web3.js.  If not, see <http://www.gnu.org/licenses/>.
*/
export var Chain;
(function (Chain) {
  Chain[Chain["Mainnet"] = 1] = "Mainnet";
  Chain[Chain["Goerli"] = 5] = "Goerli";
  Chain[Chain["Sepolia"] = 11155111] = "Sepolia";
})(Chain || (Chain = {}));
export var Hardfork;
(function (Hardfork) {
  Hardfork["Chainstart"] = "chainstart";
  Hardfork["Homestead"] = "homestead";
  Hardfork["Dao"] = "dao";
  Hardfork["TangerineWhistle"] = "tangerineWhistle";
  Hardfork["SpuriousDragon"] = "spuriousDragon";
  Hardfork["Byzantium"] = "byzantium";
  Hardfork["Constantinople"] = "constantinople";
  Hardfork["Petersburg"] = "petersburg";
  Hardfork["Istanbul"] = "istanbul";
  Hardfork["MuirGlacier"] = "muirGlacier";
  Hardfork["Berlin"] = "berlin";
  Hardfork["London"] = "london";
  Hardfork["ArrowGlacier"] = "arrowGlacier";
  Hardfork["GrayGlacier"] = "grayGlacier";
  Hardfork["MergeForkIdTransition"] = "mergeForkIdTransition";
  Hardfork["Merge"] = "merge";
  Hardfork["Shanghai"] = "shanghai";
  Hardfork["ShardingForkDev"] = "shardingFork";
})(Hardfork || (Hardfork = {}));
export var ConsensusType;
(function (ConsensusType) {
  ConsensusType["ProofOfStake"] = "pos";
  ConsensusType["ProofOfWork"] = "pow";
  ConsensusType["ProofOfAuthority"] = "poa";
})(ConsensusType || (ConsensusType = {}));
export var ConsensusAlgorithm;
(function (ConsensusAlgorithm) {
  ConsensusAlgorithm["Ethash"] = "ethash";
  ConsensusAlgorithm["Clique"] = "clique";
  ConsensusAlgorithm["Casper"] = "casper";
})(ConsensusAlgorithm || (ConsensusAlgorithm = {}));
export var CustomChain;
(function (CustomChain) {
  /**
   * Polygon (Matic) Mainnet
   *
   * - [Documentation](https://docs.matic.network/docs/develop/network-details/network)
   */
  CustomChain["PolygonMainnet"] = "polygon-mainnet";
  /**
   * Polygon (Matic) Mumbai Testnet
   *
   * - [Documentation](https://docs.matic.network/docs/develop/network-details/network)
   */
  CustomChain["PolygonMumbai"] = "polygon-mumbai";
  /**
   * Arbitrum Rinkeby Testnet
   *
   * - [Documentation](https://developer.offchainlabs.com/docs/public_testnet)
   */
  CustomChain["ArbitrumRinkebyTestnet"] = "arbitrum-rinkeby-testnet";
  /**
   * Arbitrum One - mainnet for Arbitrum roll-up
   *
   * - [Documentation](https://developer.offchainlabs.com/public-chains)
   */
  CustomChain["ArbitrumOne"] = "arbitrum-one";
  /**
   * xDai EVM sidechain with a native stable token
   *
   * - [Documentation](https://www.xdaichain.com/)
   */
  CustomChain["xDaiChain"] = "x-dai-chain";
  /**
   * Optimistic Kovan - testnet for Optimism roll-up
   *
   * - [Documentation](https://community.optimism.io/docs/developers/tutorials.html)
   */
  CustomChain["OptimisticKovan"] = "optimistic-kovan";
  /**
   * Optimistic Ethereum - mainnet for Optimism roll-up
   *
   * - [Documentation](https://community.optimism.io/docs/developers/tutorials.html)
   */
  CustomChain["OptimisticEthereum"] = "optimistic-ethereum";
})(CustomChain || (CustomChain = {}));
