<div class="snackbar-container snack-bar-status" [ngClass]="snackbarClass">
  @if (showPrefixIcon) {
    <img alt="" class="prefix-icon" [src]="prefixIconUrl">
  }

  <span class="message">
    {{message}}
  </span>

  @if (showAction) {
    <div class="action-button" aria-hidden="true" (click)="ActionClicked()">
      {{actionText}}
    </div>
  }

  @if (showCloseIcon) {
    <div aria-hidden="true" class="close-icon-container" (click)="closeSnackbar()">
      <div class="close-icon">
        <span class="cross-line"></span>
      </div>
    </div>
  }
</div>
