import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { FeaturedTitleBanner } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { environment } from 'src/environments/environment';
import { LandingPageService } from '../../landing-page.service';


@Component({
  selector: 'app-featured-title',
  templateUrl: './featured-title.component.html',
  styleUrls: ['./featured-title.component.sass'],
  standalone: true,
  imports: []
})
export class FeaturedTitleComponent {
  @ViewChild('ScrollAnimationContainer') public scrollAnimationContainerRef: ElementRef;
  @ViewChild('FeaturedTitleContainer') public titleContainerRef: ElementRef;

  @Input() content: FeaturedTitleBanner;

  public hovered: boolean = false;

  private animationPercentage: number = 0;

  private scrollHeight: number;
  private titleHeight: number;

  public animationStarted: boolean = false;

  public environment = environment;

  constructor(
    private host: ElementRef<HTMLElement>,
    private generalService: GeneralService
  ) { }

  ngAfterViewInit(): void {
    this.setCSSVars();

    if (this.content.scrollAnimationEnabled) {
      this.scrollAnimation();
    }
  }

  private setCSSVars(): void {
    if (this.content.backgroundColor || this.content.fontColor) {

      //set variables
      if (this.content.backgroundColor)
        this.host.nativeElement.style.setProperty('--background-color', this.content.backgroundColor);
      if (this.content.fontColor)
        this.host.nativeElement.style.setProperty('--font-color', this.content.fontColor);
    }

    if (this.content.scrollAnimationEnabled) {
      this.titleContainerRef.nativeElement.style.opacity = '0';
      document.getElementById('featured-title-scroll-container')!.style.height = '300vh';
      document.getElementById('featured-title-container')!.style.transform = 'translateY(-50%)';
    }
  }

  private scrollAnimation(): void {
    const scrollAnimationContainer = this.scrollAnimationContainerRef.nativeElement;
    const title = this.titleContainerRef.nativeElement;

    this.scrollHeight = scrollAnimationContainer.clientHeight;
    this.titleHeight = title.clientHeight;

    window.addEventListener('resize', () => {
      this.scrollHeight = scrollAnimationContainer.clientHeight;
      this.titleHeight = title.clientHeight;

      this.generalService.oldAsyncFunction(() => this.animationLogic(scrollAnimationContainer, title), 150);
    });

    this.generalService.addScrollableEvent({id: 'featuredTitle', fn: () => {
      this.animationLogic(scrollAnimationContainer, title);
    }});
  }

  private animationLogic(scrollAnimationContainer: HTMLElement, title: HTMLElement) {
    const scrollAnimationPos = scrollAnimationContainer.getBoundingClientRect().y;
    if (scrollAnimationPos <= 0 && scrollAnimationPos + this.scrollHeight > this.titleHeight) {
      this.animation(title!, scrollAnimationPos);
    }
  }

  private animation(title: HTMLElement, scrollAnimationPos: number): void {
    this.animationPercentage = ((scrollAnimationPos * - 1) * 100) / (this.scrollHeight - this.titleHeight);
    // title fade in animation
    if (this.animationPercentage < 15)
      title.style.opacity = '0';
    else if (this.animationPercentage <= 70)
      title.style.opacity = (this.animationPercentage - 15) / (70 - 15) + '';
    else
      title.style.opacity = '1';
  }
}
