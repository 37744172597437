export default {
  "name": "EIP-4345",
  "number": 4345,
  "comment": "Difficulty Bomb Delay to June 2022",
  "url": "https://eips.ethereum.org/EIPS/eip-4345",
  "status": "Final",
  "minimumHardfork": "london",
  "gasConfig": {},
  "gasPrices": {},
  "vm": {},
  "pow": {
    "difficultyBombDelay": {
      "v": 10700000,
      "d": "the amount of blocks to delay the difficulty bomb with"
    }
  }
};
