<app-page-banner
  [title]="mainBannerInfo.title ? mainBannerInfo.title : ''"
  [description]="mainBannerInfo.description ? mainBannerInfo.description : ''"
  [backgroundImageUrl]="mainBannerInfo.backgroundImageUrl ? mainBannerInfo.backgroundImageUrl : ''"
  [imageUrl]="mainBannerInfo.itemImageUrl ? mainBannerInfo.itemImageUrl : ''"
  [useGradient]="mainBannerInfo.useGradient"
  [pseudoElementColor]="environment.brandStyleVariables.pseudoElementHighlightColor"
  [containerHeight]="(environment.showUserPointsInBanners && (generalService.isUserLoggedIn | async)) ? '36vh' : '36vh'"
  [smallBanner]="(environment.showUserPointsInBanners && (generalService.isUserLoggedIn | async)) ? false : true"
  [textContainerMaxWidth]="'70%'"
  [imageSize]="'35%'">
</app-page-banner>

@if (isLoaded) {
  <div class="activity-sub-tasks-page-wrapper card-radius" [@FadeIn]="animationState">
    <div class="activity-info-container mb-3">
      <div class="activity-info">
        <p class="tasks-progress">
          {{ activityInfo.totalCompletedSubActivities + '/' + activityInfo.totalSubActivities + ' ' + ( labelsService.activityMultiStepTaskName | label)}}
        </p>
        @if (activityInfo.name) {
          <p class="activity-name">{{ activityInfo.name }}</p>
        }
        @if (activityInfo.endDate) {
          <p class="activity-date">
            {{ labelsService.activityListItemDateLabel | label: { date: activityInfo.endDate | date: 'dd MMMM, yyyy': translateService.currentLang: translateService.currentLang | titlecase } }}
          </p>
        }
      </div>
      <img [src]="activityInfo.images && activityInfo.images.length > 0 ? activityInfo.images[0].href : 'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/default-images/default-image-small-rectangle.svg'">
    </div>
    @if (labelsService.activityMultiStepTitle | label) {
      <p class="sub-task-list-title">
        {{ labelsService.activityMultiStepTitle | label }}
      </p>
    }
    <div class="sub-tasks-list">
      @for (subTask of activityInfo.subActivities; track subTask; let index = $index) {
        <div class="sub-task-info-container"
          [ngClass]="{ 'completed': subTask.isCompleted }">
          <p class="task-index">
            {{ ( labelsService.activityMultiStepTaskItemIndex | label: {index: index + 1}) }}
          </p>
          @if (subTask.name) {
            <p class="sub-task-title">
              {{ subTask.name }}
            </p>
          }
          @if (subTask.description) {
            <p class="sub-task-description">
              {{ subTask.description }}
            </p>
          }
          @if (subTask.rewards[0].amount != null) {
            <p class="prize-container mt-3">
              @if (subTask.isCompleted) {
                <span>{{ subTask.rewards[0].amount + ' ' + getCurrencySymbol('pinkcoins', 'narrow') }}</span>
              } @else {
                {{ labelsService.activityListItemPrizeLabel | label: { amount: subTask.rewards[0].amount, unit: getCurrencySymbol('pinkcoins', 'narrow') | label } }}
              }
            </p>
          }
          @if (subTask.isCompleted) {
            <p class="sub-task-completed">
              @if (subTask.isCompleted) {
                <i class="bi bi-check-circle"></i> <span class="ms-2"> {{ labelsService.activityMultiStepCompletedSubTaskLabel | label }}</span>
              }
            </p>
          }
          @if (activityInfo.endDate && !subTask.isCompleted) {
            <p class="date-container mt-4 mb-2">
              @if (!subTask.isCompleted) {
                <!-- TODO {{ labelsService.activityListItemDateLabel | label: { date: subTask.endDate | date: 'dd MMMM, YYYY': translateService.currentLang: translateService.currentLang } }} -->
                {{ labelsService.activityListItemDateLabel | label: { date: activityInfo.endDate | date: 'dd MMMM, yyyy': translateService.currentLang: translateService.currentLang | titlecase } }}
              }
            </p>
          }
        </div>
      }
    </div>
  </div>
}
