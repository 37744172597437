<div class="partners-wrapper">
  <app-page-banner
    [title]="labelService.partnersBannerTitle | label"
    [description]="labelService.partnersBannerDescription | label"
    [backgroundImageUrl]="'assets/imgs/institutions-banner.png'"
    [imageUrl]="'assets/imgs/institutions-nft.png'"
    >
  </app-page-banner>

  @if (isLoaded) {
    <div class="section partners-container mt-4 mb-4">
      <div class="section-top-area">
        <p class="section-title">{{ labelService.partnersListTitle | label }}</p>
        <p class="section-description">{{ labelService.partnersListDescription | label }}</p>
      </div>
      <!-- <div class="partners-filters-container"> -->
      <!--       <div class="partners-filters-top-area">
      <app-single-line-text-input
        [placeholderLabel]="labelService.partnersFilterSearchPlaceholder | label" [inputHeightPx]="48" [fontSizePx]="14"
        [backgroundColor]="environment.brandStyleVariables.inputBackgroundColor" [roundBorders]="true" [roundedBorderPx]="30" [borderColor]="'transparent'"
        [prefixMarginPx]="20" [isSuffixIcon]="true" [inputValue]="searchString" [placeholderColor]="'#8692A6'"
        [inactiveSuffix]="'bi bi-search'" [activeSuffix]="'bi bi-search'" [alignLeft]="true" [debounceTime]="0"
        (suffixClicked)="search()" (actionOnEnter)="search()" (valueChanged)="searchString = $event">
      </app-single-line-text-input>
      <div class="partners-secondary-filter">
        <div class="partners-dropdown-filter">
          <p *ngIf="!(generalService.isMobile | async)">{{labelService.partnersFiltersCategoryTitle | label}}</p>
          <app-chips-dropdown [chipsList]="categoryList" [arrowIconUrl]="'assets/imgs/chevron-updown.svg'"
            [defaultLabel]="labelService.partnersFiltersCategoryPlaceholder | label" (selectedFilterOptions)="getSelectedCategories($event)">
          </app-chips-dropdown>
        </div> -->
        <!--           <app-toggle-button [buttonText]="'Open Filter'" [imageAsButton]="(generalService.isMobile | async)" [imageUrl]="'assets/imgs/filter-icon.svg'" [hoveredIconColor]="'#ffffff'"
        [toggleWidth]="(generalService.isMobile | async) ? 32 : 25" [toggleHeight]="(generalService.isMobile | async) ? 32 : 25" [defaultIconColor]="'#ffffff'" [fontColor]="'#ffffff'"
        [fontSizePx]="(generalService.isMobile | async) ? 15 : 12" [toggleOnBgColor]="'#FF3E3E'" [toggleOnFtColor]="'#ffffff'" [isActive]="isButtonMobileActive"(click)="toggleFilters()">
      </app-toggle-button> -->
    <!--         </div>
  </div> -->
  <!--       <div class="partners-filters-area" [@Slide]="animationState">
  <div class="partners-filters-area-wrapper">
    <div class="partners-filters-area-header" *ngIf="(generalService.isMobile | async)">
      <p>Filter</p>
      <div class="close-btn-container" *ngIf="(generalService.isMobile | async)" (click)="toggleFilters()">
        <div class="close-icon">
          <span class="cross-line"></span>
        </div>
      </div>
    </div>
    <div class="filters-area">
      <div class="partners-search">
        <p>Institution</p>
        <app-single-line-text-input
          [placeholderLabel]="'Enter institution'" [inputHeightPx]="48" [fontSizePx]="14"
          [backgroundColor]="'transparent'" [roundBorders]="true" [roundedBorderPx]="30" [borderColor]="'#8692A6'"
          [placeholderColor]="'#8692A6'" [inputValue]="partnerSearch" (valueChanged)="partnerSearch = $event">
        </app-single-line-text-input>
      </div>
      <div class="partners-district-search">
        <p>District</p>
        <div class="generic-wrapper">
          <app-dropdown-input [dropdownOptions]="districtList" [fontSizePx]="14" [arrowIconUrl]="'assets/imgs/chevron-updown.svg'" [backgroundColor]="'transparent'" [borderColor]="'#8692A6'"
            [labelWeight]="500" [borderRadius]="30" [dropdownHeight]="48" [inputPlaceholder]="'Enter district'" [optionToSelect]="districtSelectedOption" (optionSelected)="districtSelectedOption = $event">
          </app-dropdown-input>
        </div>
      </div>
    </div>
    <div class="partners-submit-filter ms-auto">
      <app-toggle-button [buttonText]="'Apply Filter'" [fontColor]="'#ffffff'"
        [toggleOnBgColor]="'#27a09a66'" [toggleOnFtColor]="'#ffffff'" (click)="submitFilter()">
      </app-toggle-button>
    </div>
  </div>
</div> -->
<!--     </div> -->
<div class="partners-list-wrapper">
  <div class="partners-list-container">
    <div class="partners-list-items-wrapper w-100"
      infiniteScroll
      [infiniteScrollDistance]="listPercentageFromBottom"
      [infiniteScrollThrottle]="50"
      [immediateCheck]="true"
      [scrollWindow]="false"
      [infiniteScrollContainer]="mainComponent.scrollableContainerRef"
      (scrolled)="onScroll()"
      #partnerContainerList>
      @if (partnerList.length > 0 || isListLoading) {
        <div class="partners-list-items-container">
          @for (partner of partnerList; track partner) {
            <app-list-item-small-card
              [cardImage]="partner.images && partner.images.profilePictureImage.href ? partner.images.profilePictureImage.href : 'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/default-image-square.png'"
              [cardTitle]="partner.name"
              [cardDescription]="partner.description"
              [cardTags]="partner.categories"
                  [cardButtons]="[{
                    text: labelService.partnersListKnowMoreButton | label,
                    textColor: environment.brandStyleVariables.brandBackgroundColor,
                    fontSize: 'var(--small-font-size)',
                    fontWeight: '800',
                    hasIcon: false,
                    backgroundColor: 'rgba(38, 159, 152, 0.15)',
                    buttonSize: '123px',
                    buttonSpacing: '0.3vw 1.5vw',
                    buttonRadius: 'calc(var(--smallest-font-size) * 3)',
                    action: 'navigate',
                    actionValue: '/' + partner.id
                  }]">
            </app-list-item-small-card>
          }
          @if (isListLoading) {
            @for (item of loadingListItems; track item) {
              <app-loading-small-list-item class="loading-item"></app-loading-small-list-item>
            }
          }
        </div>
      } @else {
        <div class="no-items-found">
          <app-no-items [image]="'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/empty-list-image.png'" [title]="labelService.partnersListNoItems | label"></app-no-items>
        </div>
      }
    </div>
  </div>
</div>
</div>
}

<app-register-banner [isLoggedIn]="loggedIn" [bannerIcon]="'assets/imgs/institution-letter.png'" [bannerIconAlt]="labelService.partnersRegisterBannerTitle | label"
  [bannerText]="loggedIn ? (labelService.partnersLoginBannerTitle | label) : (labelService.partnersRegisterBannerTitle | label)" [bannerButtonText]="labelService.partnersRegisterButton | label">
</app-register-banner>
</div>
