import { getCurrencySymbol } from '@angular/common';
import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map } from 'rxjs';
import { HeaderItem } from 'src/app/_generic-components-lib/__models/list/list-header.model';
import { ActionId, Info, ListItem, Sizes, Styles } from 'src/app/_generic-components-lib/__models/list/list-item.model';
import { GenericErrorReply } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { ApiService } from 'src/app/_services/api.service';
import { environment } from 'src/environments/environment';
import { ActivityInfoDTO } from '../../activities/activities.model';
import { PotencialRewards } from './home.model';

@Injectable({
  providedIn: 'root'
})
export class HomeService {

  private activitiesListSizes: Array<Sizes> = [];
  private activitiesListStyles: Array<Styles> = [];
  private activitiesListHeaderStyles: Array<Styles> = [];

  public expandableHeaders: Array<HeaderItem> = [];
  private expandableSizes: Array<Sizes> = [
    { size: 100 / 3, sizeSmall: 100 / 3 },
    { size: 100 / 3, sizeSmall: 100 / 3 },
    { size: 100 / 3, sizeSmall: 100 / 3 }
  ];

  constructor(
    private generalService: GeneralService,
    private apiService: ApiService,
    private labelsService: LabelsService
  ) {
    this.fillSizes();
    this.fillStyles();
  }

  public getPotencialRewards(): Observable<PotencialRewards> {
    return this.apiService.get(`activity-management/public/activity/potential-rewards`, {}, '1.0', false, environment.useMockedData.homeActivityPotencialRewards, '', 0).pipe(
      catchError(
        (error: HttpErrorResponse) => {
            this.generalService.isValidServerReply(error['error']);
            throw error['message'];
          }
        ),
        map((response: PotencialRewards | GenericErrorReply) => {
          if(this.generalService.isValidServerReply(response)) {
            response = response as PotencialRewards;

            return response;
          } else {
            throw response;
          }
        }
      ));
  }

  public getActivities(): Observable<{itemsList: Array<ListItem>, headerItemsList: Array<HeaderItem>}> {
    return this.apiService.get(`activity-management/public/activity/history?filter=IN_PROGRESS`, {}, '1.0', false, environment.useMockedData.homeActivityHistoryGetList, '', 0).pipe(
      catchError(
        (error: HttpErrorResponse) => {
            this.generalService.isValidServerReply(error['error']);
            throw error['message'];
          }
        ),
        map((response: Array<ActivityInfoDTO> | GenericErrorReply) => {
          if(this.generalService.isValidServerReply(response)) {
            response = response as Array<ActivityInfoDTO>;

            let headerList: Array<HeaderItem> = [];
            let activitiesList: Array<ListItem> = [];

            if(response) {
              headerList = this.getHeaderItemListFromDTO(response);
              activitiesList = this.getItemListFromDTO(response);
            }

            return { itemsList: activitiesList, headerItemsList: headerList };
          } else {
            throw response;
          }
        }
      ));
  }

  private getHeaderItemListFromDTO(activityListDTO: Array<ActivityInfoDTO>): Array<HeaderItem> {
    const headerList: Array<HeaderItem> = this.getItemHeaderForActivities();

    return headerList;
  }

  private getItemListFromDTO(activityListDTO: Array<ActivityInfoDTO>): Array<ListItem> {
    const itemList: Array<ListItem> = [];

    activityListDTO.forEach(dto => {
      itemList.push(
        {
          id: dto.activityId,
          type: 'Activity',
          info: this.getItemInfoFromActivityDTO(dto), // TODO
          notClickable: true,
          expand: true,
          infoExpandable: [{
            id: dto.activityId,
            notClickable: true,
            info: this.getExpandableInfoFromActivityInfoDTO(dto),
            action: {
              actionId: ActionId.goTo,
              backgroundColor: 'transparent',
              tooltipText: '',
              textValue: this.labelsService.getLabel(this.labelsService.homePageActivityListMobileActionLabel)?.labelValue,
              fontSize: 'var(--smallest-font-size)',
              fontColor: 'var(--main-brand-color)',
              fontWeight: '700'
            }
          }]
        }
      );
    });

    return itemList;
  }

  private getItemHeaderForActivities(): Array<HeaderItem> {
    const infoList: Array<HeaderItem> = [];
    this.expandableHeaders = [];

    const names: Array<string> = [
      this.labelsService.homePageActivityListHeaderNameLabel,
      this.labelsService.homePageActivityListHeaderTasksLabel,
      this.labelsService.homePageActivityListHeaderProgressLabel,
      this.labelsService.homePageActivityListHeaderPrizeLabel,
      ''
    ];

    this.activitiesListSizes.forEach((size, index) => {
      const name = this.labelsService.getLabel(names[index])?.labelValue;

      infoList.push({
        id: infoList.length,
        name: name ? name : '',
        sizes: size,
        styles: this.activitiesListHeaderStyles[index]
      });

      if(index !== 0 && this.expandableSizes.length >= index) {
        this.expandableHeaders.push({
          id: index,
          name: name ? name : '',
          sizes: this.expandableSizes[index - 1],
          styles: {
            textAlign: 'center'
          }
        });
      }
    });

    return infoList;
  }

  private getItemInfoFromActivityDTO(dto: ActivityInfoDTO): Array<Info> {
    const infoList: Array<Info> = [];

    infoList.push({
      text: dto.name,
      sizes: this.activitiesListSizes[0],
      styles: this.activitiesListStyles[0]
    });

    infoList.push({
      text: dto.totalSubActivities + '',
      sizes: this.activitiesListSizes[1],
      styles: this.activitiesListStyles[1]
    });

    infoList.push({
      text: dto.totalCompletedSubActivityPercentage + '%',
      sizes: this.activitiesListSizes[2],
      styles: this.activitiesListStyles[2]
    });

    infoList.push({
      text: dto.totalReward + ' ' + getCurrencySymbol('pinkcoins', 'narrow'),
      sizes: this.activitiesListSizes[3],
      styles: this.activitiesListStyles[3]
    });

    infoList.push({
      // TODO add possibility to have btn in list item
      actions: [{
        bootstrapIconClass: 'bi-arrow-right-short',
        tooltipText: '',
        actionId: ActionId.goTo,
        borderRadius: '100px',
        backgroundColor: 'var(--main-brand-color)'
      }],
      sizes: this.activitiesListSizes[4],
      styles: this.activitiesListStyles[4]
    });

    infoList.push({
      expandButton: true,
      state: { label: '', class: 'brand' },
      sizes: this.activitiesListSizes[5],
      styles: {
        padding: '0',
        justify: 'flex-end'
      }
    });

    return infoList;
  }

  private fillSizes(): void {
    this.activitiesListSizes = [
      {
        size: 40,
        sizeSmall: 85
      },
      {
        size: 15,
        sizeSmall: 0
      },
      {
        size: 15,
        sizeSmall: 0
      },
      {
        size: 15,
        sizeSmall: 0
      },
      {
        size: 15,
        sizeSmall: 0
      },
      {
        size: 0,
        sizeSmall: 15
      }
    ];
  }

  private getExpandableInfoFromActivityInfoDTO(activityInfoDTO: ActivityInfoDTO): Array<Info> {
    let infoList: Array<Info> = [];

    infoList.push({
      text: activityInfoDTO.totalSubActivities + '',
      sizes: this.expandableSizes[0],
      styles: {
        textAlign: 'center',
        fontSize: 'var(--main-info-font-size)',
        fontColor: '#424242',
      }
    });

    infoList.push({
      text: activityInfoDTO.totalCompletedSubActivityPercentage + '%',
      sizes: this.expandableSizes[1],
      styles: {
        textAlign: 'center',
        fontSize: 'var(--main-info-font-size)',
        fontColor: '#424242',
      }
    });

    infoList.push({
      text: activityInfoDTO.totalReward + ' ' + getCurrencySymbol('pinkcoins', 'narrow'),
      sizes: this.expandableSizes[2],
      styles: {
        textAlign: 'center',
        fontSize: 'var(--main-info-font-size)',
        fontColor: 'var(--main-brand-color)',
        fontWeight: '600'
      }
    });

    return infoList;
  }

  private fillStyles(): void {
    this.activitiesListHeaderStyles = [
      {
        fontSize: 'var(--main-info-font-size)',
        fontColor: '#8692A6',
        textAlign: 'left',
        padding: '0 0 0 2vw'
      },
      {
        fontSize: 'var(--main-info-font-size)',
        fontColor: '#8692A6',
        textAlign: 'left'
      },
      {
        fontSize: 'var(--main-info-font-size)',
        fontColor: '#8692A6',
        textAlign: 'left'
      },
      {
        fontSize: 'var(--main-info-font-size)',
        fontColor: '#8692A6',
        textAlign: 'center'
      },
      {
        fontSize: 'var(--main-info-font-size)',
        fontColor: '#8692A6',
        textAlign: 'right'
      },
    ];

    this.activitiesListStyles = [
      {
        fontSize: 'var(--small-font-size)',
        fontColor: '#424242',
        fontWeight: '600',
        textAlign: 'start',
        maxAmountTextLines: 2,
        padding: '0 1vw 0 1vw'
      },
      {
        fontSize: 'var(--main-info-font-size)',
        fontColor: '#424242',
        textAlign: 'start'
      },
      {
        fontSize: 'var(--main-info-font-size)',
        fontColor: '#424242',
        textAlign: 'start'
      },
      {
        fontSize: 'var(--main-info-font-size)',
        fontColor: 'var(--main-brand-color)',
        textAlign: 'center',
        fontWeight: '600'
      },
      {
        fontSize: 'max(1.5vw, 25px)',
        fontWeight: '600',
        fontColor: 'white',
        textAlign: 'end'
      },
    ];
  }
}
