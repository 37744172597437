<form [formGroup]="form">
  <mat-radio-group [formControlName]="'option'" [formControl]="control" [ngClass]="{'display-vertically': vertical}">
    @for(rbutton of radioButtons; track $index) {
      <div class="radio-button-wrapper" [ngClass]="{'d-flex': rbutton.hasTooltip, 'align-items-center': rbutton.hasTooltip}"
        [ngStyle]="{'gap': rbutton.hasTooltip ? '0.5vw' : ''}">
        <mat-radio-button class="radio-margin" [disableRipple]="true" [labelPosition]="labelFirst ? 'before' : 'after'"
          [value]="rbutton.value">
          <span class="lbl-text">{{ rbutton.label }}</span>
        </mat-radio-button>

        @if (rbutton.hasTooltip) {
          <app-tooltip [textContent]="rbutton.tooltipText!"></app-tooltip>
        }
      </div>
    }
  </mat-radio-group>
</form>
