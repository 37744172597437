import { Component, ElementRef, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-small-page-banner',
  templateUrl: './small-page-banner.component.html',
  styleUrls: ['./small-page-banner.component.sass'],
  standalone: true
})
export class SmallPageBannerComponent implements OnInit {

  @Input() brandColor: string;
  @Input() fontColor: string;
  @Input() errorColor: string;

  @Input() titleFontSizePx: string;
  @Input() descFontSizePx: string;

  @Input() title: string;
  @Input() description: string;
  @Input() imageSrc: string;

  constructor(private host: ElementRef) { }

  ngOnInit(): void {
    this.setCSSVars();
  }


  private setCSSVars(): void {
    if (this.brandColor || this.fontColor || this.errorColor || this.titleFontSizePx || this.descFontSizePx) {
      //set variables

      if (this.brandColor)
        this.host.nativeElement.style.setProperty('--brand-color', this.brandColor);
      if (this.fontColor)
        this.host.nativeElement.style.setProperty('--font-color', this.fontColor);
      if (this.errorColor)
        this.host.nativeElement.style.setProperty('--error-color', this.errorColor);
      if (this.titleFontSizePx)
        this.host.nativeElement.style.setProperty('--title-font-size', this.titleFontSizePx);
      if (this.descFontSizePx)
        this.host.nativeElement.style.setProperty('--desc-font-size', this.descFontSizePx);
    }
  }
}
