import { CommonModule, getCurrencySymbol } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output, OnChanges, SimpleChanges, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { environment } from 'src/environments/environment';
import { ActivityInfo, ActivityTypes } from '../../activities.model';
import { LoggedUserService } from 'src/app/_services/_logged_user_service/logged-user.service';
import { LabelPipe } from 'src/app/_shared-modules/label-pipe-module/label.pipe';
import { ActivityTypeInputComponent } from './activity-types/input/activity-type-input.component';
import { ActivityTypeMultiStepComponent } from './activity-types/multi-step/activity-type-multi-step.component';
import { SwiperOptions } from 'swiper/types';
import { SwiperDirective } from 'src/app/_directives/swiper/swiper.directive';


@Component({
  selector: 'app-activity-details-card',
  templateUrl: './activity-details-card.component.html',
  styleUrls: ['./activity-details-card.component.sass'],
  standalone: true,
  imports: [
    CommonModule,
    LabelPipe,
    ActivityTypeInputComponent,
    ActivityTypeMultiStepComponent,
    SwiperDirective
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class ActivityDetailsCardComponent implements OnInit, OnChanges {

  @Input() activity: ActivityInfo;
  @Output() triggerGetActivityInfo: EventEmitter<boolean> = new EventEmitter<boolean>();

  public getCurrencySymbol = getCurrencySymbol;
  public ActivityTypes = ActivityTypes;

  public environment = environment;

  public userGrantedPermission: boolean = false;

  public addressSwiperConfig: SwiperOptions = {
    slidesPerView: 1,
    spaceBetween: 20,
    centeredSlides: true,
    navigation: false,
    autoplay: false,
    speed: 500
  };

  constructor(
    public labelService: LabelsService,
    public translateService: TranslateService,
    private loggedUserService: LoggedUserService
  ) {
    this.checkUserDistance();
  }

  ngOnInit(): void { }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['activity']) {
      this.checkUserDistance();

      if (this.activity.addresses && this.activity.addresses.length > 1) {
        this.addressSwiperConfig.pagination = {
          el: '.swiper-pagination',
          clickable: false,
          renderBullet: (index, className) => {
            return `<div class="${className}"></div>`;
          },
          dynamicBullets: true,
          dynamicMainBullets: 3
        };
      } else {
        this.addressSwiperConfig.pagination = false;
      }
    }
  }

  public getActivityInfo(triggerValue: boolean): void {
    this.triggerGetActivityInfo.emit(triggerValue);
  }

  private checkUserDistance(): void {
    if (this.activity && this.activity.addresses && this.activity.addresses.some(a => a.gpsCoords != null)) {
      this.loggedUserService.getCurrentPosition(
        (position: GeolocationPosition) => {
          // Handle successful geolocation updates
          console.log('Geolocation updated:', position);

          this.userGrantedPermission = true;
          this.updateUserDistance(position);
        },
        (error: GeolocationPositionError) => {
          // Handle geolocation errors
          console.error('Geolocation error:', error);
        }
      );
    }
  }

  private updateUserDistance(position: GeolocationPosition): void {
    this.activity.addresses?.forEach(address => {
      address.distance = google.maps.geometry.spherical.computeDistanceBetween({lat: position.coords.latitude, lng: position.coords.longitude}, {lat: address.gpsCoords!.x, lng: address.gpsCoords!.y})
    });
  }

}
