<div class="banners-wrapper">
  @if (isLoaded && generalService.banners.length > 0 && generalService.banners[0].type === bannerTypes.VIDEO) {
    <div class="banner-container">
      <div class="banner-video">
        @if (!mobileVideo) {
          @if (!$any(generalService.banners[0].content).animationDesktop) {
            <div class="main-banner-texts" [ngStyle]="{ 'color' : $any(generalService.banners[0].content).fontColor }">
              @if ($any(generalService.banners[0].content).title) {
                <p class="title">{{ $any(generalService.banners[0].content).title }}</p>
              }
              @if ($any(generalService.banners[0].content).subtitle) {
                <p class="subtitle">{{ $any(generalService.banners[0].content).subtitle }}</p>
              }
            </div>
          } @else {
            @switch ($any(generalService.banners[0].content).animationDesktop?.type) {
              @case (animationTypes.GIF) {
                <img width="'100%'" height="'100%'"
                  [src]="$any(generalService.banners[0].content).animationDesktop.url">
              }
              @case (animationTypes.LOTTIE) {
                <ng-lottie
                  [width]="'90%'"
                  [height]="'90%'"
                  [options]="options">
                </ng-lottie>
              }
              @case (animationTypes.RIVE) {
                <app-rive-animation-component
                  [loop]="$any(generalService.banners[0].content).animationDesktop.loop"
                [animationFileUrl]="$any(generalService.banners[0].content).animationDesktop.url"></app-rive-animation-component>
              }
            }
          }
          <video [autoplay]="true" [loop]="true" [muted]="true" playsinline id="video">
            <source [src]="$any(generalService.banners[0].content).videoUrl" type="video/mp4">
          </video>
        } @else {
          @if (!$any(generalService.banners[0].content).animationMobile) {
            <div class="main-banner-texts" [ngStyle]="{ 'color' : $any(generalService.banners[0].content).fontColor }">
              @if ($any(generalService.banners[0].content).title) {
                <p class="title">{{ $any(generalService.banners[0].content).title }}</p>
              }
              @if ($any(generalService.banners[0].content).subtitle) {
                <p class="subtitle">{{ $any(generalService.banners[0].content).subtitle }}</p>
              }
            </div>
          } @else {
            @switch ($any(generalService.banners[0].content).animationMobile?.type) {
              @case (animationTypes.GIF) {
                <img width="'100%'" height="'100%'"
                  [src]="$any(generalService.banners[0].content).animationMobile.url">
              }
              @case (animationTypes.LOTTIE) {
                <ng-lottie
                  [width]="'100%'"
                  [height]="'100%'"
                  [options]="optionsMobile">
                </ng-lottie>
              }
              @case (animationTypes.RIVE) {
                <app-rive-animation-component
                  [loop]="$any(generalService.banners[0].content).animationMobile.loop"
                [animationFileUrl]="$any(generalService.banners[0].content).animationMobile.url"></app-rive-animation-component>
              }
            }
          }
          <ng-template #animationMobile>
            @switch ($any(generalService.banners[0].content).animationMobile?.type) {
              @case (animationTypes.GIF) {
                <img width="'100%'" height="'100%'"
                  [src]="$any(generalService.banners[0].content).animationMobile.url">
              }
              @case (animationTypes.LOTTIE) {
                <ng-lottie
                  [width]="'100%'"
                  [height]="'100%'"
                  [options]="optionsMobile">
                </ng-lottie>
              }
              @case (animationTypes.RIVE) {
                <app-rive-animation-component
                  [loop]="$any(generalService.banners[0].content).animationMobile.loop"
                [animationFileUrl]="$any(generalService.banners[0].content).animationMobile.url"></app-rive-animation-component>
              }
            }
          </ng-template>
          <video [autoplay]="true" [loop]="true" [muted]="true" playsinline id="video-mobile">
            <source [src]="$any(generalService.banners[0].content).mobileVideoUrl" type="video/mp4">
          </video>
        }
      </div>
      <div class="down-arrow-button">
        <img src="assets/imgs/down-arrow.svg">
      </div>
    </div>
  }

  @if (isLoaded) {
    <div class="lp-main-container">
      <!-- switch case with every banner -->
      @for (banner of generalService.banners; track banner; let index = $index) {
        @switch (banner.type) {
          @case (bannerTypes.DESCRIPTION_LIST) {
            <app-description-list [content]="$any(banner.content)"></app-description-list>
          }
          @case (bannerTypes.CAROUSEL) {
            @if (($any(banner.content).mainCarouselContent && $any(banner.content).mainCarouselContent.length > 0)
              || ($any(banner.content).smallCarouselContent && $any(banner.content).smallCarouselContent.length > 0)) {
              <app-carousel [content]="$any(banner.content)"></app-carousel>
            }
          }
          @case (bannerTypes.ACTION_INCENTIVE) {
            <app-action-incentive [content]="$any(banner.content)"></app-action-incentive>
          }
          @case (bannerTypes.PRODUCT_DETAIL) {
            <app-product-detail [content]="$any(banner.content)"></app-product-detail>
          }
          @case (bannerTypes.IMAGE_CARD) {
            <app-image-card [content]="$any(banner.content)"></app-image-card>
          }
          @case (bannerTypes.FEATURED_TITLE) {
            <app-featured-title [content]="$any(banner.content)"></app-featured-title>
          }
          @case (bannerTypes.PARTNER_LIST) {
            <app-partner-list [content]="$any(banner.content)"></app-partner-list>
          }
        }
      }
    </div>
  }
</div>

