import { Component, Input, OnInit } from '@angular/core';
import { GeneralService } from 'src/app/_services/_general-service/general.service';

import { GenericButtonComponent } from 'src/app/_generic-components-lib/inputs/buttons/generic-button/generic-button.component';

@Component({
  selector: 'app-register-banner',
  templateUrl: './register-banner.component.html',
  styleUrls: ['./register-banner.component.sass'],
  standalone: true,
  imports: [
    GenericButtonComponent
]
})
export class RegisterBannerComponent implements OnInit {

  @Input() bannerIcon: string;
  @Input() bannerIconAlt: string;
  @Input() bannerText: string;
  @Input() bannerButtonText: string;

  @Input() isLoggedIn: boolean;

  constructor(
    private generalService: GeneralService
    ) { }

  ngOnInit(): void {
  }

  goToPage(): void {
    history.pushState({page: 1}, 'back'); // force entrance in history of current page to allow navigation back from login page
    this.generalService.userLogin();
  }
}
