<div class="product-info mt-4 mb-5">
  <div class="product-info-title-container">
    <span>{{ product.name }}</span>
  </div>

  <div class="product-info-table">
    @if (product.collection) {
      <div class="collection-container">
        <p class="info-table-header">{{labelService.productDetailsCollectionTitle | label}}</p>
        @if (product.collection) {
          <p class="collection-name">{{ product.collection.name }}</p>
        }
        <!-- <div class="available-collectibles-container"> //TODO uncomment when needed
        <div class="label">{{ labelService.productDetailsCollectableTitle | label }}</div>
        <div class="value">{{ product.quantity + ' ' + (labelService.productDetailsSale | label) }}</div>
      </div> -->
    </div>
  }
  <div class="collectible-detail-container">
    <p class="info-table-header">{{labelService.productDetailsInfoTitle | label}}</p>

    <div class="serial-number-container">
      <div class="label">{{ labelService.productDetailsSerial | label }}</div>
      <div class="value">{{ product.serial}}</div>
    </div>

    @if (product.quantity && product.quantity > 0) {
      <div class="quantity-container">
        <div class="value">
          {{ (product.quantity | i18nPlural: generalService.getPluralKey('product_quantity')) | translate: {n: product.quantity} }}
        </div>
      </div>
    }

    @if (product.endDate) {
      <div class="date-container">
        {{ (labelService.productDetailsDate | label) + (product.endDate | date: 'dd MMMM, yyyy': translateService.currentLang: translateService.currentLang | titlecase)}}
      </div>
    }
  </div>
  @if (partner) {
    <div class="partner-container">
      <p class="info-table-header">{{labelService.productDetailsInstitution | label}}</p>
      <div class="partner-info-container">
        <img [src]="partner.image" [alt]="partner.name" class="partner-img">
        <div class="partner-name">{{ partner.name }}</div>
      </div>
    </div>
  }
</div>

@if (product.prices.length > 1) {
  <div class="product-price-details-container mt-3 mb-3">
    <p class="price-header">{{labelService.productDetailsPrice | label}}</p>
    <div class="price-container">
      @if (product.prices) {
        @for (currency of product.prices; track currency) {
          @if (currency.selected) {
            <span class="product-value">
              <span>{{ currency.amount }}</span>
              @switch (currency.unit) {
                @case ('EUR') {
                  <span class="currency-symbol">
                    €
                  </span>
                }
                @case ('ETH') {
                  <div class="ethereum currency-symbol">
                  </div>
                }
                @default {
                  <span class="currency-name">
                    &nbsp;{{currency.unit}}
                  </span>
                }
              }
            </span>
          }
        }
      }
      @if (product.prices && product.prices.length > 1) {
        <div class="currencies-container">
          @for (currency of product.prices; track currency) {
            <div class="currency-container" (click)="selectCurrencyToShow(currency)" [ngClass]="{'selected': currency.selected}">
              <!-- currency button -->
              @switch (currency.unit) {
                @case ('EUR') {
                  €
                }
                @case ('ETH') {
                  <div class="ethereum crypto-icon">
                  </div>
                }
              }
            </div>
          }
        </div>
      }
    </div>
  </div>
}

@if (product.description) {
  <div class="product-info-text w-100">
    <span class="title">{{labelService.productDetailsDescription | label}}</span>
    <div class="description">{{ product.description }}</div>
  </div>
}

@if (product.traits && product.traits.length > 0) {
  <div class="product-properties-wrapper w-100 mt-4">
    <p class="title">{{labelService.productDetailsProperties | label}}</p>
    <div class="properties-list-container">
      @for (property of product.traits; track property) {
        <div class="property-container">
          <span class="property-name">{{ property.name }}</span>
          <br>
            <span class="property-value">{{ property.value }}</span>
          </div>
        }
      </div>
    </div>
  }

  <!-- <div class="product-tags w-100 mt-4" *ngIf="product.categories">
  <p class="title">Tags</p>
  <span class="tags-container" *ngFor="let tag of product.categories">
    {{ tag.name }}
  </span>
</div> -->
</div>

@if (product.quantity! > 0) {
  <div class="product-info-button-container">
    <div class="purchase-button button-shadow" role="button" (click)="goToPaymentPage()">{{labelService.productDetailsBuyButton | label}}</div>
  </div>
}
