<app-page-banner
  [title]="mainBannerInfo.title ? mainBannerInfo.title : ''"
  [description]="mainBannerInfo.description ? mainBannerInfo.description : ''"
  [backgroundImageUrl]="mainBannerInfo.backgroundImageUrl ? mainBannerInfo.backgroundImageUrl : ''"
  [imageUrl]="mainBannerInfo.itemImageUrl ? mainBannerInfo.itemImageUrl : ''"
  [useGradient]="mainBannerInfo.useGradient"
  [pseudoElementColor]="environment.brandStyleVariables.pseudoElementHighlightColor"
  [containerHeight]="(environment.showUserPointsInBanners && (generalService.isUserLoggedIn | async)) ? undefined : '36vh'"
  [textContainerMaxWidth]="'70%'"
  [imageSize]="'35%'">
</app-page-banner>

<div class="content-wrapper">
  @if (subMenuList && subMenuList.length > 0) {
    <div class="submenu-area-container mb-5">
      @for (submenu of subMenuList; track submenu) {
        <a class="submenu-item" [routerLink]="currentMenu.viewUrl + submenu.viewUrl" [routerLinkActive]="'active'">
          <span>{{submenu.name}}</span>
        </a>
      }
    </div>
  }

  <router-outlet></router-outlet>
</div>
