import { Injectable } from '@angular/core';
import { catchError, map, Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { GenericErrorReply, Label, LabelDTO } from '../_general-service/general-service.model';
import { GeneralService } from '../_general-service/general.service';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root'
})
export class LabelsService {

  // General
  public readonly loginButton = 'general_login_btn';
  public readonly logoutButton = 'general_logout_btn';
  public readonly walletButton = 'general_wallet_btn';
  public readonly demoButton = 'general_demo_btn';
  public readonly phoneCountrySearchPlaceholder = 'phone_country_search_placeholder'

  // Errors
  public readonly genericErrorMessage = 'generic_error_message';

  // Error Page
  public readonly errorPageCode = 'error_page_code';
  public readonly errorPageTitle = 'error_page_title';
  public readonly errorPageDescription = 'error_page_description';

  // Footer
  public readonly footerBrandDescription = 'footer_brand_description';
  public readonly footerContactsPhoneTitle = 'footer_contacts_phone_title';
  public readonly footerContactsEmailTitle = 'footer_contacts_email_title';
  public readonly footerContactsShortcutAbout = 'footer_shortcut_about';
  public readonly footerContactsShortcutPrivacy = 'footer_shortcut_privacy';
  public readonly footerContactsShortcutTerms = 'footer_shortcut_terms';
  public readonly footerNewsletterTitle = 'footer_newsletter_title';
  public readonly footerNewsletterDescription = 'footer_newsletter_description';
  public readonly footerNewsletterPlaceholder = 'footer_newsletter_placeholder';
  public readonly footerNewsletterSubmitBtn = 'footer_newsletter_submit_button';
  public readonly footerAllRightsReserved = 'footer_all_rights_reserved';

  // NewsLetter
  public readonly newsletterPopUpTitleSuccess = 'newsletter_popup_title_success';
  public readonly newsletterPopUpTitleError = 'newsletter_popup_title_error';
  public readonly newsletterPopupSucessMessage = 'newsletter_popup_success_message';
  public readonly newsletterPopupErrorMessage = 'newsletter_popup_error_message';

  // Sidebar
  public readonly sidebarPrimaryInfo = 'sidebar_primary_info';
  public readonly sidebarFooterInfo = 'sidebar_footer_info';
  public readonly sidebarMetamask = 'sidebar_metamask';
  public readonly sidebarWalletConnect = 'sidebar_wallet_connect';
  public readonly sidebarCurrentWalletConnected = 'sidebar_current_wallet_connected';
  public readonly sidebarMyWallet = 'sidebar_my_wallet';
  public readonly sidebarPopUpWalletTitle = 'sidebar_popup_wallet_title';
  public readonly sidebarPopUpWalletDesc = 'sidebar_popup_wallet_desc';

  // Marketplace
  public readonly marketplaceBannerTitle = 'marketplace_banner_title';
  public readonly marketplaceBannerDescription = 'marketplace_banner_description';
  public readonly marketplaceSectionTitle = 'marketplace_section_title';
  public readonly marketplaceSectionDescription = 'marketplace_section_description';
  public readonly marketplaceTiersTitle = 'marketplace_tiers_title';
  public readonly marketplaceTiersDescription = 'marketplace_tiers_description';
  public readonly markplaceListNoItems = 'marketplace_list_no_items';
  public readonly marketplaceListItemSerial = 'marketplace_listitem_serial';
  public readonly marketplaceListItemQuantity = 'marketplace_listitem_quantity';
  public readonly marketplaceListLowestAsk = 'marketplace_listitem_lowestask';
  public readonly marketplaceListItemPrice = 'marketplace_listitem_price';
  public readonly marketplaceListItemDate = 'marketplace_listitem_date';
  public readonly marketplaceFiltersSearchPlaceholder = 'marketplace_filters_search_placeholder';
  public readonly marketplaceFiltersButtonLabel = 'marketplace_filters_button_label';
  public readonly marketplaceFiltersButtonCloseLabel = 'marketplace_filters_button_close_label';

  // MarketFilters
  public readonly marketFiltersTitle = 'market_filters_title';
  public readonly marketFiltersClear = 'market_filters_clear';

  // Product Details
  public readonly productDetailsScreenTitle = 'product_details_screen_title';
  public readonly productDetailsCollectionTitle = 'product_details_collection_title';
  public readonly productDetailsCollectableTitle = 'product_details_collectable_title';
  public readonly productDetailsInfoTitle = 'product_details_info_title';
  public readonly productDetailsSale = 'product_details_for_sale';
  public readonly productDetailsSerial = 'product_details_serial';
  public readonly productDetailsDate = 'product_details_date';
  public readonly productDetailsInstitution = 'product_details_institution';
  public readonly productDetailsPrice = 'product_details_price';
  public readonly productDetailsDescription = 'product_details_description';
  public readonly productDetailsProperties = 'product_details_properties';
  public readonly productDetailsBuyButton = 'product_details_buy_button';
  public readonly productDetailsRedirectToMissionsButton = 'product_details_redirect_to_missions_button';
  public readonly productDetailsRelated = 'product_details_related';
  public readonly productDetailsUserDistance = 'product_details_user_distance';
  public readonly productDetailsSeeAddress = 'product_details_see_address';

  // Product Instant Purchase
  public readonly productPurchaseSuccessPopupTitle = 'product_purchase_success_popup_title';
  public readonly productPurchaseSuccessPopupPluralQuantityLabel = 'product_purchase_success_popup_plural_quantity_label';
  public readonly productPurchaseSuccessPopupQuantityLabel = 'product_purchase_success_popup_quantity_label';
  public readonly productPurchaseSuccessPopupButtonLabel = 'product_purchase_success_popup_button_label';

  // Payment Page
  public readonly paymentDetailsPaymentType = 'payment_details_payment_type';
  public readonly paymentDetailsTitle = 'payment_details_title';
  public readonly paymentDetailsWarning = 'payment_details_warning';
  public readonly paymentDetailsFiscalAddress = 'payment_details_fiscal_address';
  public readonly paymentDetailsEditAddressBtn = 'payment_details_edit_address_btn';
  public readonly paymentDetailsPhoneNumberTitle = 'payment_details_phone_number_title';
  public readonly paymentDetailsPhoneNumberLabel = 'payment_details_phone_number_label';
  public readonly paymentDetailsPhoneNumberPlaceholder = 'payment_details_phone_number_placeholder';
  public readonly paymentDetailsWalletAddressTitle = 'payment_details_wallet_address_title';
  public readonly paymentDetailsWalletAddressLabel = 'payment_details_wallet_address_label';
  public readonly paymentDetailsWalletAddressPlaceholder = 'payment_details_wallet_address_placeholder';
  public readonly paymentDetailsPriceTitle = 'payment_details_price_title';
  public readonly paymentDetailsOrderSummary = 'payment_details_order_summary';
  public readonly paymentDetailsCancelBtn = 'payment_details_cancel_btn';
  public readonly paymentDetailsSubmitBtn = 'payment_details_submit_btn';
  public readonly paymentDetailsMarketTerms = 'payment_details_market_terms';
  public readonly paymentDetailsSecureLabel = 'payment_details_secure_label';
  public readonly paymentDetailsPopUpPurchaseConfirmationTitle = 'payment_details_popup_purchase_confirmation_title';
  public readonly paymentDetailsPopUpPurchaseConfirmationDescription = 'payment_details_popup_purchase_confirmation_description';
  public readonly paymentDetailsPopUpPurchaseConfirmationButton = 'payment_details_popup_purchase_confirmation_button';
  public readonly paymentDetailsPopUpPurchaseConfirmationCancelButton = 'payment_details_popup_purchase_confirmation_cancel_button';

  // Partners
  public readonly partnersBannerTitle = 'partners_banner_title';
  public readonly partnersBannerDescription = 'partners_banner_description';
  public readonly partnersListTitle = 'partners_list_title';
  public readonly partnersListDescription = 'partners_list_description';
  public readonly partnersFilterSearchPlaceholder = 'partners_filters_search_placeholder';
  public readonly partnersFiltersCategoryTitle = 'partners_filters_category_title';
  public readonly partnersFiltersCategoryPlaceholder = 'partners_filters_category_placeholder';
  public readonly partnersListKnowMoreButton = 'partners_list_know_more_button';
  public readonly partnersListDonateButton = 'partners_list_donate_button';
  public readonly partnersRegisterBannerTitle = 'partners_register_banner_title';
  public readonly partnersRegisterButton = 'partners_register_button';
  public readonly partnersLoginBannerTitle = 'partners_login_banner_title';
  public readonly partnersListNoItems = 'partners_list_no_items';

  // Partner Details
  public readonly partnersDetailsTitle = 'partners_details_title';
  public readonly partnersDetailsDescription = 'partners_details_description';
  public readonly partnersDetailsSubTitle = 'partners_details_subtitle';
  public readonly partnersDetailsCollectionTitle = 'partners_details_collection_title';
  public readonly partnersDetailsCollectionDescription = 'partners_details_collection_description';
  public readonly partnersDetailsNoItems = 'partners_details_no_items';
  public readonly partnerDetailsNoDetailsTitle = 'partners_details_no_details_title';
  public readonly partnerDetailsNoDetailsDesc = 'partners_details_no_details_description';
  public readonly partnerDetailsFilterSearchPlaceholder = 'partners_details_filter_search_placeholder';
  public readonly partnerDetailsFilterButtonOpen = 'partners_details_filter_button_open';
  public readonly partnerDetailsFilterButtonClose = 'partners_details_filter_button_close';

  // Popups
  public readonly popupWelcomeTitle = 'popup_welcome_title';
  public readonly popupWelcomeDescription = 'popup_welcome_description';
  public readonly popupWelcomeButtonLogin = 'popup_welcome_button_login';
  public readonly popupWelcomeButtonCancel = 'popup_welcome_button_cancel';
  public readonly popupLogoutConfirmationTitle = 'popup_logout_confirmation_title';
  public readonly popupLogoutConfirmationDescription = 'popup_logout_confirmation_description';
  public readonly popupLogoutConfirmationCancelButton = 'popup_logout_confirmation_cancel_button';
  public readonly popupLogoutConfirmationButton = 'popup_logout_confirmation_button';

  // My Account
  public readonly myAccountTitle = 'my_account_title';
  public readonly myAccountInformationTitle = 'my_account_information_title';
  public readonly myAccountCollectionTitle = 'my_account_collection_title';
  public readonly myAccountTransactionTitle = 'my_account_transaction_title';
  public readonly myAccountInformationDescription = 'my_account_information_description';
  public readonly myAccountCollectionDescription = 'my_account_collection_description';
  public readonly myAccountTransactionDescription = 'my_account_transaction_description';
  public readonly myAccountEditCoverButton = 'my_account_edit_cover_button';
  public readonly myAccountLogoutButton = 'my_account_logout_button';
  public readonly myAccountEditInformationButton = 'my_account_edit_information_button';
  public readonly myAccountSaveInformationButton = 'my_account_save_information_button';
  public readonly myAccountCancelButton = 'my_account_cancel_button';
  public readonly myAccountBackButton = 'my_account_back_button';
  public readonly myAccountDetailsTitle = 'my_account_details_title';
  public readonly myAccountDetailsFirstName = 'my_account_details_first_name';
  public readonly myAccountDetailsLastName = 'my_account_details_last_name';
  public readonly myAccountDetailsBirthdate = 'my_account_details_birthdate';
  public readonly myAccountDetailsEmail = 'my_account_details_email';
  public readonly myAccountDetailsFiscalNumber = 'my_account_details_fiscal_number';
  public readonly myAccountDetailsAddressLine1Label = 'my_account_details_address_line1_label';
  public readonly myAccountDetailsAddressLine2Label = 'my_account_details_address_line2_label';
  public readonly myAccountDetailsFiscalPostalCode = 'my_account_details_fiscal_postal_code';
  public readonly myAccountDetailsFiscalCity = 'my_account_details_fiscal_city';
  public readonly myAccountDetailsFiscalCountry = 'my_account_details_fiscal_country';
  public readonly myAccountDetailsEmailLabel = 'my_account_details_email_label';
  public readonly myAccountDetailsBirthdateLabel = 'my_account_details_birthdate_label';
  public readonly myAccountDetailsFiscalNumberLabel = 'my_account_details_fiscal_number_label';
  public readonly myAccountDetailsFiscalPostalCodeLabel = 'my_account_details_fiscal_postal_code_label';
  public readonly myAccountDetailsFiscalCityLabel = 'my_account_details_fiscal_city_label';
  public readonly myAccountDetailsFiscalCoutryLabel = 'my_account_details_fiscal_country_label';
  public readonly myAccountDetailsErrorField = 'my_account_details_error_field';
  public readonly myAccountDetailsEmailErrorField = 'my_account_details_email_error_field';
  public readonly myAccountDetailsErrorTextField = 'my_account_details_error_text_field';

  // Product History
  public readonly productHistoryTitle = 'product_history_title';
  public readonly productHistoryListTitle = 'product_history_list_title';
  public readonly productHistoryListNoItems = 'product_history_list_no_items';
  public readonly productHistorySearchPlaceholder = 'product_history_search_placeholder';
  public readonly productHistoryDetailsCodeListTitle = 'product_history_details_code_list_title';
  public readonly productHistoryListQuantityLabel = 'product_history_list_quantity_label';
  public readonly productHistoryListQuantityPluralLabel = 'product_history_list_quantity_plural_label';
  public readonly productHistoryDetailsCodeTitle = 'product_history_details_code_title';
  public readonly productHistoryDetailsPurchaseDateLabel = 'product_history_details_purchase_date_label';
  public readonly productHistoryDetailsRedeemDateLabel = 'product_history_details_redeem_date_label';
  public readonly productHistoryDetailsValidUntilDateLabel = 'product_history_details_valid_until_date_label';
  public readonly productHistoryDetailsExpireDateLabel = 'product_history_details_expired_date_label';
  public readonly productHistoryDetailsCodeRedeemedLabel = 'product_history_details_code_redeemed_label';
  public readonly productHistoryDetailsCodeExpiredLabel = 'product_history_details_code_expired_label';

  // Transaction
  public readonly transactionsTitle = 'transactions_title';
  public readonly transactionsDashboardTitle = 'transactions_dashboard_title';
  public readonly transactionDashboardDescription = 'transactions_dashboard_description';
  public readonly transactionsListTitle = 'transactions_list_title';
  public readonly transactionListDescription = 'transactions_list_description';
  public readonly transactionListNoItems = 'transaction_list_no_items';

  // Transactions Status
  public readonly transactionStatusPending = 'transaction_status_pending';
  public readonly transactionStatusCompleted = 'transaction_status_completed';

  // Order Entry Status
  public readonly orderEntryStatusCompleted = 'order_entry_status_completed';
  public readonly orderEntryStatusPending = 'order_entry_status_pending';
  public readonly orderEntryStatusFailed = 'order_entry_status_failed';
  public readonly orderEntryStatusReject = 'order_entry_status_reject';
  public readonly orderEntryStatusAcknowledge = 'order_entry_status_acknowledged';
  public readonly orderEntryStatusProgress = 'order_entry_status_progress';

  // Order Entry PopUp
  public readonly orderEntryPopupSuccessTitle = 'order_entry_popup_success_title';
  public readonly orderEntryPopupSuccessDescription = 'order_entry_popup_success_description';
  public readonly orderEntryPopUpErrorTitle = 'order_entry_popup_error_title';
  public readonly orderEntryPopUpErrorDescription = 'order_entry_popup_error_description';
  public readonly orderEntryPopUpErrorPaymentRejectedDescription = 'order_entry_popup_error_payment_rejected_description';

  // Payment PopUp
  public readonly paymentDetailsLoadingPopupTitle = 'payment_details_loading_popup_title';
  public readonly paymentDetailsLoadingPopupDescription = 'payment_details_loading_popup_description';
  public readonly paymentDetailsSuccessPopupDescription = 'payment_details_success_popup_description';

  // Contact Us
  public readonly contactusBannerTitle = 'contact_us_banner_title';
  public readonly contactusBannerDescription = 'contact_us_banner_description';
  public readonly contactusGeneralInfoTitle = 'contact_us_general_information_title';
  public readonly contactusCreateInstitutionTitle = 'contact_us_create_institution_title';

  // Contact us General Information
  public readonly contactusGeneralInfoBannerTitle = 'contact_us_general_info_title';
  public readonly contactusGeneralInfoBannerDescription = 'contact_us_general_info_description';
  public readonly contactusGeneralInfoFAQTitle = 'contact_us_general_info_faq_title';
  public readonly contactusGeneralInfoFormTitle = 'contact_us_general_info_form_title';
  public readonly contactusGeneralInfoFormDesc = 'contact_us_general_info_form_description';
  public readonly contactusGeneralInfoNameTitle = 'contact_us_general_info_name_title';
  public readonly contactusGeneralInfoNamePlaceholder = 'contact_us_general_info_name_placeholder';
  public readonly contactusGeneralInfoPhoneTitle = 'contact_us_general_info_phone_title';
  public readonly contactusGeneralInfoPhonePlaceholder = 'contact_us_general_info_phone_placeholder';
  public readonly contactusGeneralInfoEmailTitle = 'contact_us_general_info_email_title';
  public readonly contactusGeneralInfoEmailPlaceholder = 'contact_us_general_info_email_placeholder';
  public readonly contactusGeneralInfoMessageTitle = 'contact_us_general_info_message_title';
  public readonly contactusGeneralInfoMessagePlaceholder = 'contact_us_general_info_message_placeholder';
  public readonly contactusGeneralInfoSubmitButton = 'contact_us_general_info_submit_button';

  // Contact Us Form
  public readonly contactUsFormTitle = 'contact_us_form_title';
  public readonly contactUsFormDesc = 'contact_us_form_description';
  public readonly contactUsNameTitle = 'contact_us_name_title';
  public readonly contactUsNamePlaceholder = 'contact_us_name_placeholder';
  public readonly contactUsPhoneTitle = 'contact_us_phone_title';
  public readonly contactUsPhonePlaceholder = 'contact_us_phone_placeholder';
  public readonly contactUsEmailTitle = 'contact_us_email_title';
  public readonly contactUsEmailPlaceholder = 'contact_us_email_placeholder';
  public readonly contactUsMessageTitle = 'contact_us_message_title';
  public readonly contactUsMessagePlaceholder = 'contact_us_message_placeholder';
  public readonly contactUsSubmitButton = 'contact_us_submit_button';

  // Contact Us Headquarters Address
  public readonly contactUsHeadquartersAddressTitle = 'contact_us_headquarters_address_title';

  // News List
  public readonly newsBannerTitle = 'news_banner_title';
  public readonly newsListTitle = 'news_list_title';
  public readonly newsListDescription = 'news_list_description';
  public readonly newsListSearchPlaceholder = 'news_list_search_placeholder';
  public readonly newsListFilterButtonLabel = 'news_list_filter_button_label';
  public readonly newsListFilterButtonCloseLabel = 'news_list_filter_button_close_label';
  public readonly newsListFiltersTitle = 'news_list_filter_title';
  public readonly newsListFiltersClearLabel = 'news_list_filters_clear_label';
  public readonly newsArticleDateLabel = 'news_article_publish_date_label';

  // Our Products Container
  public readonly ourProductsBannerTitle = 'our_products_banner_title';
  public readonly ourProductsKnownForBannerTitle = 'our_products_known_for_banner_title';

  // About Us
  public readonly blogArticleDetailsPageTitle = 'blog_article_details_page_title';

  // Tutorial
  public readonly tutorialFirstStepButtonLabel = 'tutorial_first_step_button_label';
  public readonly tutorialPrevStepButtonLabel = 'tutorial_previous_step_button_label';
  public readonly tutorialNextStepButtonLabel = 'tutorial_next_step_button_label';
  public readonly tutorialLastStepButtonLabel = 'tutorial_last_step_button_label';
  public readonly tutorialSkipTutorialLabel = 'tutorial_skip_tutorial_label';

  // Onboarding
  public readonly onboardingStepsLabel = 'onboarding_steps_label';
  public readonly onboardingPreviousStepBtnLabel = 'onboarding_previous_step_btn_label';
  public readonly onboardingNextStepBtnLabel = 'onboarding_next_step_btn_label';
  public readonly onboardingFinalizeBtnLabel = 'onboarding_finalize_btn_label';
  public readonly onboardingActivityPrizeLabel = 'onboarding_activity_prize_label';

  // Home
  public readonly homePageBannerTitle = 'home_page_banner_title';
  public readonly homePageUserGoalTitle = 'home_page_user_goal_title';
  public readonly homePageActivityListTitle = 'home_page_activity_list_title';
  public readonly homePageActivityListDescription = 'home_page_activity_list_description';
  public readonly homePageUserGoalProgressLabel = 'home_page_user_goal_progress_label';
  public readonly homePageAvailableToWinCardTitle = 'home_page_available_to_win_card_title';
  public readonly homePageAvailableToWinTargetLabel = 'home_page_available_to_win_target_label';
  public readonly homePageAvailableToWinFirstTargetName = 'home_page_available_to_win_first_target_name';
  public readonly homePageAvailableToWinSecondTargetName = 'home_page_available_to_win_second_target_name';
  public readonly homePageAvailableToWinCurrencyName = 'home_page_available_to_win_currency_name';
  public readonly homePageUserGoalCardTitle = 'home_page_user_goal_card_title';
  public readonly homePageUserGoalCardEmptyTitle = 'home_page_user_goal_card_empty_title';
  public readonly homePageUserGoalCardEmptySubtitle = 'home_page_user_goal_card_empty_subtitle';
  public readonly homePageUserGoalCardEmptyButtonLabel = 'home_page_user_goal_card_empty_button_label';
  public readonly homePageEmptyActivitiesListTitle = 'home_page_empty_activities_list_title';
  public readonly homePageEmptyActivitiesRedirectBtnLabel = 'home_page_empty_activities_redirect_btn_label';
  public readonly homePageActivityListHeaderNameLabel = 'home_page_activity_list_header_name_label';
  public readonly homePageActivityListHeaderTasksLabel = 'home_page_activity_list_header_tasks_label';
  public readonly homePageActivityListHeaderProgressLabel = 'home_page_activity_list_header_progress_label';
  public readonly homePageActivityListHeaderPrizeLabel = 'home_page_activity_list_header_prize_label';
  public readonly homePageActivityListShowMoreLabel = 'home_page_activity_list_show_more_label';
  public readonly homePageActivityListMobileActionLabel = 'home_page_activity_list_mobile_action_label';

  // Edit Goal Popup
  public readonly editGoalPopupSubtitle = 'edit_goal_popup_subtitle';
  public readonly editGoalPopupTitle = 'edit_goal_popup_title';
  public readonly editGoalPopupAddButtonLabel = 'edit_goal_popup_add_button_label';
  public readonly editGoalPopupEditButtonLabel = 'edit_goal_popup_edit_button_label';

  // Completed Goal Popup
  public readonly completedGoalPopupTitle = 'completed_goal_popup_title';
  public readonly completedGoalPopupDescription = 'completed_goal_popup_description';
  public readonly completedGoalPopupCancelButton = 'completed_goal_popup_cancel_button';
  public readonly completedGoalPopupGoToButton = 'completed_goal_popup_go_to_button';

  // Banner
  public readonly bannersBalanceLabel = 'banners_balance_label';

  // Currencies
  public readonly PINK_COINS = 'PINK_COINS';

  // Purchase Product Popup
  public readonly purchaseProductPopupTitle = 'purchase_product_popup_title';
  public readonly purchaseProductPopupQuantityLabel = 'purchase_product_popup_quantity_label';
  public readonly purchaseProductPopupAvailableCurrencyInWallet = 'purchase_product_popup_available_currency_in_wallet';
  public readonly purchaseProductPopupConfirmButtonLabel = 'purchase_product_popup_confirm_button_label';
  public readonly purchaseProductPopupInsuficientFundsErrorMessage = 'purchase_product_popup_insuficient_funds_error_message';
  public readonly purchaseProductPopupLimitAmountExceededErrorMessage = 'purchase_product_popup_limit_amount_exceeded_error_message';
  public readonly purchaseProductPopupLimitReachedErrorMessage = 'purchase_product_popup_limit_reached_error_message';

  // Activities
  public readonly activitiesSectionTitle = 'activities_list_section_title';
  public readonly activitiesSectionDescription = 'activities_list_section_description';
  public readonly activitiesFiltersSearchPlaceholder = 'activities_list_search_placeholder';
  public readonly activityListItemSubtaskSingular = 'activity_list_item_subtask_singular';
  public readonly activityListItemSubtaskPlural = 'activity_list_item_subtask_plural';
  public readonly activityListItemPrizeLabel = 'activity_list_item_prize_label';
  public readonly activityListItemDateLabel = 'activity_list_item_date_label';
  public readonly activityListNoItems = 'activity_list_no_items';
  public readonly activityDetailsScreenTitle = 'activity_details_screen_title';
  public readonly activityDetailsRelated = 'activity_details_related';
  public readonly activityDetailsInfoTitle = 'activity_details_info_title';
  public readonly activityDetailsPartnerLabel = 'activity_details_partner_label';
  public readonly activityDetailsDescriptionLabel = 'activity_details_description_label';
  public readonly activityDetailsUserDistance = 'activity_details_user_distance';
  public readonly activityDetailsSeeAddress = 'activity_details_see_address';
  // input type activities
  public readonly activityAddInputBtnLabel = 'activity_add_input_btn_label';
  public readonly activityTypeInputPopupMaxCharsLabel = 'activity_type_input_popup_max_chars_label';
  public readonly activityTypeInputStateHeaderLabel = 'activity_type_input_state_header_label';
  public readonly activityTypeInputStateCompletedLabel = 'activity_type_input_state_completed_label';
  public readonly activityTypeInputStatePendingLabel = 'activity_type_input_state_pending_label';
  public readonly activityTypeInputStateRejectedLabel = 'activity_type_input_state_rejected_label';
  public readonly activitySaveInputBtnLabel = 'activity_save_input_btn_label';
  public readonly activityDeleteInputBtnLabel = 'activity_delete_input_btn_label';
  public readonly activityEditInputBtnLabel = 'activity_edit_input_btn_label';
  public readonly activityTypeInputSuccessCreationTitle = 'activity_type_input_success_creation_title';
  public readonly activityTypeInputSuccessCreationDescription = 'activity_type_input_success_creation_description';
  public readonly activityTypeInputSuccessEditTitle = 'activity_type_input_success_edit_title';
  public readonly activityTypeInputSuccessEditDescription = 'activity_type_input_success_edit_description';
  public readonly activityTypeInputRemoveConfirmationTitle = 'activity_type_input_remove_confirmation_title';
  public readonly activityTypeInputSuccessRemoveTitle = 'activity_type_input_success_remove_title';
  public readonly activityTypeInputSuccessRemoveDescription = 'activity_type_input_success_remove_description';
  // multi-step type activities
  public readonly activityMultiStepTitle = 'activity_multi_step_title';
  public readonly activityMultiStepSubtitle = 'activity_multi_step_subtitle';
  public readonly activityMultiStepTaskName = 'activity_multi_step_task_name';
  public readonly activityMultiStepProgressBarTitle = 'activity_multi_step_progress_bar_title';
  public readonly activityMultiStepCheckTasksBtn = 'activity_multi_step_check_tasks_btn';
  public readonly activityMultiStepStartTasksBtn = 'activity_multi_step_start_tasks_btn';
  public readonly activityMultiStepTaskItemIndex = 'activity_multi_step_task_item_index';
  public readonly activityMultiStepCompletedSubTaskLabel = 'activity_multi_step_completed_sub_task_label';


  labelsLoaded: boolean = false;

  private labels:{[id: string]: Label} = {}

  constructor(private apiService: ApiService,
    private generalService: GeneralService) {}

  public getLabelsList(): Observable<Array<Label>> {
    return this.apiService.get(`tenant-management/public/tenant/${environment.tenantId}/application/${environment.applicationId}/labels`, {}, '1.0', true, environment.useMockedData.labelsGetList).pipe(
      catchError(
        error => {
          throw error.error.Message;
        }
      ),
      map((response: Array<LabelDTO> | GenericErrorReply) => {
        if (this.generalService.isValidServerReply(response)) {
          const labelsListDTO = (response as Array<LabelDTO>);
          const labelsList = this.getLabelsListFromDTO(labelsListDTO);

          this.saveLabelList(labelsList);

          return labelsList;
        } else {
          throw response;
        }
      }));
  }

  saveLabelList(labelsList: Array<Label>) {
    labelsList.forEach(label => {
      if (this.isInvalidLabel(label)) {
        console.error('Invalid label:', label);
        return;
      }
      this.labels[label.labelKey] = label;
    });
    this.labelsLoaded = true;
  }

  getLabel(labelKey: string): Label | null {
    const label = this.labels[labelKey];

    if (label == null) {
      console.error('could not find label with key ' + labelKey);

      return null;
    }
    return label;
  }

  private getLabelsListFromDTO(dto: Array<LabelDTO>): Array<Label> {
    let labelsList: Array<Label> = [];

    dto.forEach((labelDto: LabelDTO) => {
      labelsList.push({
        labelId: labelDto.labelId,
        labelKey: labelDto.labelKey,
        labelValue: labelDto.labelValue,
        state: labelDto.state
      });
    });

    return labelsList;
  }

  private isInvalidLabel(label: Label) {
    return label == null || label.labelKey == null || label.labelValue == null;
  }
}
