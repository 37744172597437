import { Component, DestroyRef, ElementRef, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { MenuBanner } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { AppComponent } from 'src/app/app.component';
import { environment } from 'src/environments/environment';
import { FilterChip, SelectedFilterGroup } from '../../_shared-components/filters-list-module/filters-list/filter.model';
import { ActivitiesService } from '../_services/activities/activities.service';
import { ActivityListItem, ActivityTypes } from '../activities.model';
import { PageBannerComponent } from "../../_shared-components/page-banner-module/page-banner/page-banner.component";
import { ActivityListItemComponent } from './activity-list-item/activity-list-item.component';
import { ActivityLoadingContentComponent } from './activity-loading-content/activity-loading-content.component';
import { NoItemsComponent } from '../../_shared-components/no-items-module/no-items/no-items.component';
import { LabelPipe } from 'src/app/_shared-modules/label-pipe-module/label.pipe';
import { CommonModule } from '@angular/common';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { ListItemCardComponent } from "../../_shared-components/list-item-cards/list-item-card/list-item-card.component";
import { SingleLineTextInputComponent } from 'src/app/_generic-components-lib/inputs/text-inputs/single-line-text-input/single-line-text-input.component';
import { Form, FormBuilder, FormControl, ReactiveFormsModule } from '@angular/forms';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { LoadingListItemComponent } from "../../_shared-components/loading-list-module/loading-list-item/loading-list-item.component";

@Component({
  selector: 'app-activities-list-container',
  templateUrl: './activities-list-container.component.html',
  styleUrls: ['./activities-list-container.component.sass'],
  standalone: true,
  imports: [
    CommonModule,
    PageBannerComponent,
    ActivityListItemComponent,
    ActivityLoadingContentComponent,
    NoItemsComponent,
    LabelPipe,
    SingleLineTextInputComponent,
    InfiniteScrollDirective,
    ListItemCardComponent,
    ReactiveFormsModule,
    LoadingListItemComponent
]
})
export class ActivitiesListContainerComponent implements OnInit, OnDestroy {
  @ViewChild('activityContainerList', { static: false }) public activityListContainerRef: ElementRef;

  private destroyRef: DestroyRef = inject(DestroyRef);
  private destroy: Subject<boolean> = new Subject<boolean>();

  public mainBannerInfo: MenuBanner;
  public environment = environment;
  public isLoaded: boolean = false;

  private queryParameters: Params = {};

  public loadingListItems: Array<number> = [];
  public isListLoading: boolean = false;
  public listPercentageFromBottom: number = 0;

  public searchString: string = '';
  public selectedFilters: Array<SelectedFilterGroup> = [];

  public filterChips: Array<FilterChip> = [];
  public activityList: Array<ActivityListItem> = [];

  public ActivityTypes = ActivityTypes;

  public form = this.fb.group({
    search: new FormControl<string>('')
  });

  constructor(
    public generalService: GeneralService,
    public labelService: LabelsService,
    private route: ActivatedRoute,
    private activitiesService: ActivitiesService,
    public mainComponent: AppComponent,
    private fb: FormBuilder
  ) {
    this.generalService.userDetails.pipe(takeUntil(this.destroy)).subscribe(() => {
      // TODO
    });

    this.searchControl.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
      (value: string) => {
        this.searchString = value;
      }
    );
  }

  // #region - Form Controls
    public get searchControl(): FormControl {
      return this.form.controls['search'];
    }
  // #endregion - Form Controls


  ngOnInit(): void {
    this.route.queryParams.subscribe((params: Params) => {
      this.queryParameters = params;
    });

    const currentMenu = this.generalService.menuList.find(menu => menu.viewUrl === '/activities');
    this.mainBannerInfo = currentMenu?.bannerContent!;

    this.isListLoading = true;
    this.activitiesService.getEndOfScrollReached().subscribe(() => {
      if (!this.isListLoading) {
        this.isListLoading = true;
        this.getMoreItems(this.queryParameters);
      }
    });

    this.generalService.oldAsyncFunction(() => this.getListPercentageFromBottom(), 0);

    this.searchString = this.queryParameters['search'];

    // amount of items that will be shown when list is loading
    this.loadingListItems = [1, 2, 3, 4, 5, 6];

    /* this.getFilters().subscribe(() => {
      this.filterList.forEach(group => {
        this.selectedFilters.push({
          queryParameter: group.queryParameter,
          items: []
        });
      });
      this.filtersService.selectedFilters = this.selectedFilters;
      this.updateFilters(this.queryParameters); */

      this.isLoaded = true;
      this.generalService.oldAsyncFunction(() => {this.getListPercentageFromBottom();}, 0);

      this.updateQueryParameters(); // TODO Delete when filters implemented
    /* }); */
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.complete();
  }

  public activityItemClicked(activityId: string): void {
    this.generalService.navigateTo(`activities/${activityId}`);
    this.generalService.autoScrollTop('page-router-container');
  }

  public onScroll(): void {
    if(this.activitiesService.infiniteScrollActive) {
      this.activitiesService.getEndOfScrollReached().next(true);
    }
  }

  private getMoreItems(params: Params = {}): void {
    if(this.activitiesService.totalPages > this.activitiesService.currentPageIndex) {
      this.activitiesService.currentPageIndex++;
      this.getItems(this.activitiesService.currentPageIndex, params);
    }
  }

  private getItems(page: number, params: Params = {}): void {
    if(!this.isListLoading){
      this.isListLoading = true;
    }

    this.activitiesService.getActivityList(page, params).subscribe({
      next: list => {
        if(this.activitiesService.currentPageIndex > 1 && this.activityList) {
          this.activityList = this.activityList.concat(list);
        } else {
          this.activityList = list;
        }

        this.generalService.oldAsyncFunction(() => {
          this.getListPercentageFromBottom();
        }, 0);
        this.isListLoading = false;
      },
      error: error => {
        this.isListLoading = false;
      }
    });
  }

  public search(): void {
    this.updateQueryParameters();
  }

  private updateQueryParameters(): void {
    let queryParams: Params = {};
    this.selectedFilters.forEach(group => {
      if(group.items.length > 0) {
        queryParams[group.queryParameter] = group.items.map(item => item.value).toString();
      }
    });

    this.updateSearchParam(queryParams);

    this.getItems(1, queryParams);
    this.generalService.navigateToWithQueryParams('/activities', queryParams);
  }

  private updateSearchParam(params: Params): void {

    if ('search' in this.queryParameters) {
      params['search'] = this.queryParameters['search'];
    }

    if (this.searchString){
      params['search'] = this.searchString;
    } else {
      delete params['search']
    }
  }

  private getListPercentageFromBottom(): void {
    if (this.isLoaded) {
      let listPos = this.activityListContainerRef.nativeElement.offsetTop;
      let listHeight = this.activityListContainerRef.nativeElement.clientHeight;
      let totalPageHeight = this.mainComponent.scrollableContainerRef.nativeElement.scrollHeight; // page height with scroll

      let percentageFromBottom = (Math.abs(listPos) + listHeight) / totalPageHeight * 100;

      this.listPercentageFromBottom = (100 - percentageFromBottom) / 10 - 0.1; // We divide by 10 to get the distance of the list from the bottom of page with a small margin of 0.5 which can be adjusted.
    }
  }
}
