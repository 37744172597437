<div class="outside-dropdown" aria-hidden="true" (click)="clickOutside($event);"></div>

<div class="dropdown aux" id="dropdownContainer"></div>
@if (dropdownOpen) {
  <div class="dropdown custom-scroll" aria-hidden="true" [@simpleFadeAnimation]="'in'" [ngClass]="{'show-from-above' : showDropdownAbove}" (click)="$event.stopPropagation()">
    <div class="search-country">
      <i class="bi bi-search" [ngClass]="{'active': searchQuery !== ''}"></i>
      <input type="text" class="w-100" [(ngModel)]="searchQuery" [placeholder]="placeholder">
    </div>
    @if (countryList.length !== 0) {
      @for (country of countryList | filter: searchQuery; track $index) {
        <div>
          @if (country.name!.toLowerCase()) {
            <div class="option-item" [ngClass]="{'selected': country.selected }" (click)="toggleOptionSelected(country.iso2)">
              <div class="option-flag-container">
                <div class="country-flag" [ngClass]="country.flagClass || ''"></div>
              </div>
              <span class="d-flex align-items-center name">{{country.name}}</span>
              <span class="option-code">{{country.dialCode}}</span>
            </div>
          }
        </div>
      }
    } @else {
      <div class="option-item">
        <span class="title">{{noResultsLabel}}</span>
      </div>
    }
  </div>
}
