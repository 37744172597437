import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { register } from "swiper/element/bundle";
import { SwiperOptions } from 'swiper/types';

register();
@Directive({
  selector: '[swiperJS]',
  standalone: true
})
export class SwiperDirective implements AfterViewInit {

  private readonly swiperElement: HTMLElement;

  @Input() public config?: SwiperOptions;

  constructor(private el: ElementRef<HTMLElement>) {
    if (el) {
      this.swiperElement = el.nativeElement;
    }
  }

  ngAfterViewInit(): void {
    if (this.el) {
      Object.assign(this.el.nativeElement, this.config);

      // @ts-expect-error - // TODO fix since initialize() doesn't seem to exist on HTMLElements
      this.el.nativeElement.initialize();
    }
  }
}
