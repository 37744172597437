import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { HeaderItem } from 'src/app/_generic-components-lib/__models/list/list-header.model';
import { ListItem } from 'src/app/_generic-components-lib/__models/list/list-item.model';
import { UserDetails } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { AppComponent } from 'src/app/app.component';
import { ChartContainer, ChartTypes, SizeTypes } from 'src/app/pages/_shared-components/dashboard-module/dashboard.model';
import { environment } from 'src/environments/environment';
import { TransactionsHistoryService } from '../../_services/transactions/transactions-history.service';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';
import { LabelPipe } from 'src/app/_shared-modules/label-pipe-module/label.pipe';
import { CommonModule } from '@angular/common';
import { ListItemExpandableComponent } from 'src/app/_generic-components-lib/list-item-module/list-item-expandable/list-item-expandable.component';
import { NoItemsComponent } from 'src/app/pages/_shared-components/no-items-module/no-items/no-items.component';
import { ListHeaderComponent } from 'src/app/_generic-components-lib/list-header/list-header.component';
import { LoadingListItemComponent } from "../../../_shared-components/loading-list-module/loading-list-item/loading-list-item.component";
import { ProductLoadingContentComponent } from "../../../marketplace/product-loading-content/product-loading-content.component";

@Component({
  selector: 'app-transactions-history-list-container',
  templateUrl: './transactions-history-list-container.component.html',
  styleUrls: ['./transactions-history-list-container.component.sass'],
  standalone: true,
  imports: [
    CommonModule,
    ListHeaderComponent,
    InfiniteScrollDirective,
    LabelPipe,
    ListItemExpandableComponent,
    NoItemsComponent,
    LoadingListItemComponent,
    ProductLoadingContentComponent
  ]
})
export class TransactionsHistoryListContainerComponent implements OnInit {

  @ViewChild('transactionListContainer', { static: false }) public transactionListContainer: ElementRef;

  public headerListItems: Array<HeaderItem> = [];
  public expandableListHeaderItems: Array<HeaderItem> = [];

  public transactionsList: Array<ListItem> = [];

  public userDetails: UserDetails;

  public isLoaded: boolean = false;
  public isListLoading: boolean = false;
  public loadingListItems: Array<number> = [];

  public listPercentageFromBottom: number = 0;

  public environment = environment;

  public chartTypes = ChartTypes;
  public sizeTypes = SizeTypes;

  public charts: Array<ChartContainer> = [
    {
      type: this.chartTypes.PIE,
      size: this.sizeTypes.HALF,
      title: 'Resumo de pinkcoins',
      totalLabel: 'Total:',
      chartTabs: [
        {
          data: [
            { name: 'Saldo', value: 100 },
            { name: 'Gasto', value: 900 }
          ],
          colors: [
            { name: 'Saldo', value: '#5ED788' },
            { name: 'Gasto', value: '#FF3E3E' }
          ]
        },
      ]
    },
    {
      type: this.chartTypes.VERTICAL_BARS,
      size: this.sizeTypes.HALF,
      title: 'Pinkcoins recebidas',
      chartTabs: [
        {
          tabName: 'Dia',
          data: [
            { name: '01', value: 0 },
            { name: '02', value: 0 },
            { name: '03', value: 0 },
            { name: '04', value: 0 },
            { name: '05', value: 0 },
            { name: '06', value: 0 },
            { name: '07', value: 20 },
            { name: '08', value: 0 },
            { name: '09', value: 0 },
            { name: '10', value: 20 },
            { name: '11', value: 20 },
            { name: '12', value: 40 },
            { name: '13', value: 40 },
            { name: '14', value: 10 },
            { name: '15', value: 20 },
            { name: '16', value: 0 }
          ],
          colors: [
            { name: '01', value: '#FF3E3E' },
            { name: '02', value: '#FF3E3E' },
            { name: '03', value: '#FF3E3E' },
            { name: '04', value: '#FF3E3E' },
            { name: '05', value: '#FF3E3E' },
            { name: '06', value: '#FF3E3E' },
            { name: '07', value: '#FFB200' },
            { name: '08', value: '#FF3E3E' },
            { name: '09', value: '#FF3E3E' },
            { name: '10', value: '#FFB200' },
            { name: '11', value: '#FFB200' },
            { name: '12', value: '#5ED788' },
            { name: '13', value: '#5ED788' },
            { name: '14', value: '#FFB200' },
            { name: '15', value: '#FFB200' },
            { name: '16', value: '#FF3E3E' }
          ]
        },
        {
          tabName: 'Semana',
          data: [
            { name: '01', value: 0 },
            { name: '02', value: 0 },
            { name: '03', value: 0 },
            { name: '04', value: 0 },
            { name: '05', value: 0 },
            { name: '06', value: 0 },
            { name: '07', value: 200 }
          ],
          colors: [
            { name: '01', value: '#FF3E3E' },
            { name: '02', value: '#FF3E3E' },
            { name: '03', value: '#FF3E3E' },
            { name: '04', value: '#FF3E3E' },
            { name: '05', value: '#FF3E3E' },
            { name: '06', value: '#FF3E3E' },
            { name: '07', value: '#FFB200' }
          ]
        },
        {
          tabName: 'Mês',
          data: [
            { name: 'Janeiro', value: 0 },
            { name: 'Feveiro', value: 200 },
            { name: 'Março', value: 0 },
            { name: 'Abril', value: 0 },
            { name: 'Maio', value: 0 },
            { name: 'Junho', value: 0 },
            { name: 'Julho', value: 200 },
            { name: 'Agosto', value: 0 },
            { name: 'Setembro', value: 0 },
            { name: 'Outubro', value: 200 },
            { name: 'Novembro', value: 200 },
            { name: 'Dezembro', value: 400 }
          ],
          colors: [
            { name: 'Janeiro', value: '#FF3E3E' },
            { name: 'Feveiro', value: '#FFB200' },
            { name: 'Março', value: '#FF3E3E' },
            { name: 'Abril', value: '#FF3E3E' },
            { name: 'Maio', value: '#FF3E3E' },
            { name: 'Junho', value: '#FF3E3E' },
            { name: 'Julho', value: '#FFB200' },
            { name: 'Agosto', value: '#FF3E3E' },
            { name: 'Setembro', value: '#FF3E3E' },
            { name: 'Outubro', value: '#FFB200' },
            { name: 'Novembro', value: '#FFB200' },
            { name: 'Dezembro', value: '#5ED788' },
          ]
        },
        {
          tabName: 'Ano',
          data: [
            { name: '2021', value: 0 },
            { name: '2022', value: 2000 },
            { name: '2023', value: 1200 },
            { name: '2024', value: 1200 },
          ],
          colors: [
            { name: '2021', value: '#FF3E3E' },
            { name: '2022', value: '#5ED788' },
            { name: '2023', value: '#FFB200' },
            { name: '2024', value: '#FFB200' },
          ]
        },
      ]
    }
  ];

  constructor(
    public mainComponent: AppComponent,
    public generalService: GeneralService,
    private transactionsHistoryService: TransactionsHistoryService,
    public labelService: LabelsService,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): void {
    this.userDetails = this.generalService.userDetails$.value;

    this.isListLoading = true;
    this.transactionsHistoryService.getEndOfScrollReached().subscribe(() => {
      if (!this.isListLoading) {
        this.isListLoading = true;
        this.getMoreItems();
      }
    });
    this.loadingListItems = [1, 2, 3, 4, 5, 6];

    this.isLoaded = true;

    this.transactionsHistoryService.getUserTransactionListHeaders().subscribe((headers: Array<HeaderItem>) => {
      this.headerListItems = headers;
      this.expandableListHeaderItems = this.transactionsHistoryService.expandableHeaders;

      for(let i = 0; i < this.transactionsHistoryService.sizesArray.length - 1; i++) {
        this.headerListItems[i].sizes = this.transactionsHistoryService.sizesArray[i];
      }

      for(let i = 0; i < this.transactionsHistoryService.expandableHeaders.length; i++) {
        this.expandableListHeaderItems[i].sizes = this.transactionsHistoryService.expandableSizes[i];
      }

      this.getItems(1);
    });
  }

  ngAfterViewInit(): void {
    this.generalService.currentScreen$.next('my-transactions');
  }

  private getItems(page: number): void {
    if(!this.isListLoading){
      this.isListLoading = true;
    }

    this.transactionsHistoryService.getTransactionsList(page).subscribe({
      next: list => {
        if(this.transactionsHistoryService.currentPageIndex > 1 && this.transactionsList) {
          this.transactionsList = this.transactionsList.concat(list);
        } else {
          this.transactionsList = list;
        }

        this.isListLoading = false;
      },
      error: error => {
        this.isListLoading = false;
      }
    });
  }

  private getMoreItems(): void {
    if(this.transactionsHistoryService.totalPages > this.transactionsHistoryService.currentPageIndex) {
      this.transactionsHistoryService.currentPageIndex++;
      this.getItems(this.transactionsHistoryService.currentPageIndex);
    }
  }

  public openTransactionDetails(productId: string): void {
    // TODO
    this.generalService.navigateTo(`marketplace/product/${productId}`);
    this.generalService.autoScrollTop('page-router-container');
  }

  public onScroll(): void {
    if(this.transactionsHistoryService.infiniteScrollActive) {
      this.transactionsHistoryService.getEndOfScrollReached().next(true);
    }
  }
}
