import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, DestroyRef, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, inject } from '@angular/core';
import { FormBuilder, FormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ActionId, ItemState, ListItem } from '../../__models/list/list-item.model';
import { LibraryService } from '../../__services/library.service';
import { ListBaseComponent } from '../../_extended-components/list-base-component/list-base.component';
import { CheckboxComponent } from '../../inputs/checkbox-input/checkbox.component';

@Component({
  standalone: true,
  selector: 'app-list-item',
  templateUrl: './list-item.component.html',
  styleUrls: [
    '../../_base-component/base.component.sass',
    '../../_extended-components/list-base-component/list-base.component.sass',
    './list-item.component.sass'
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    CheckboxComponent
  ]
})
export class ListItemComponent extends ListBaseComponent implements OnInit, AfterViewInit, OnChanges {
  private destroyRef = inject(DestroyRef);

  @Input({required: true}) public item: ListItem;

  @Input({required: true}) public fullSize: boolean;
  @Input() public itemMargin: string = '0.5vw 0.5vw 0.5vw 0.65vw';
  @Input() public enableClick: boolean = true;
  @Input() public actionHidden: boolean = false;
  @Input() public disableCheckbox: boolean = false;
  @Input() public hasbeenUpdated: boolean = false;
  @Input() public detailsClosed: boolean = false;

  @Output() public itemEvent: EventEmitter<string> = new EventEmitter<string>();
  @Output() public actionEvent: EventEmitter<number> = new EventEmitter<number>();
  @Output() public itemCheckboxSelected: EventEmitter<boolean> = new EventEmitter<boolean>();

  public itemState = ItemState; //This enum allows the verification of the State if it's active, archived etc.
  public ActionId = ActionId; // This enum allows the verification of the multi function button type if it's a toggle for example.

  public actionActive: boolean = false;

  public itemForm = this.fb.group({
    itemCheck: new FormControl<boolean>(false, [])
  });

  public firstItemIndex = 0;

  constructor(
    public override host: ElementRef,
    public override libraryService: LibraryService,
    private fb: FormBuilder
    ) {
      super(host, libraryService);
  }

  ngOnInit(): void {
    super.setCSSVars();
    super.setSpecificCSSVars();
  }

  public get itemCheckControl(): FormControl {
    return this.itemForm.controls['itemCheck'];
  }

  ngAfterViewInit(): void {
    this.enableDragOrClick();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['hasbeenUpdated']) {
      this.updateFormValues();
    }

    if (changes['disableCheckbox']) {
      if (this.disableCheckbox) {
        this.itemCheckControl.disable();
      } else {
        this.itemCheckControl.enable();
      }
    }

    if (this.fullSize) {
      this.firstItemIndex = 0;
    } else {
      this.firstItemIndex = this.item.info.findIndex(item => item.sizes?.sizeSmall !== null && item.sizes?.sizeSmall !== 0);
    }
  }

  private updateFormValues(): void {
    this.itemCheckControl.patchValue(this.hasbeenUpdated);
    this.itemForm.updateValueAndValidity();
  }

  // Click event to handle the emit of the item.
  public itemClicked(): void {
    if (!this.item.notClickable) {
      if (this.enableClick) {
        this.item.selected = true;
        this.itemEvent.emit(this.item.id);
      }
    }
  }

  public checkBoxClicked(event: boolean): void {
    if (this.item.checkBoxEnabled) {
      this.itemCheckboxSelected.emit(event);
    }
  }

  //Click event for the multi function button
  public actionClicked(actionId: number): void {
    if (actionId !== null) {
      if (actionId === ActionId.visibility) {
        this.actionActive = !this.actionActive;
      }
      this.actionEvent.emit(actionId);
    }
  }

  //This function will allow each list item to have its content selectable without firing the click event.
  public enableDragOrClick(): void {
    const element: HTMLElement = this.host.nativeElement.firstChild;
    const delta: number = 6;
    let startX: number;
    let startY: number;

    element.addEventListener('mousedown', (event: MouseEvent) => {
      startX = event.pageX;
      startY = event.pageY;
    });

    element.addEventListener('mouseup', (event: MouseEvent) => {
      const diffX: number = Math.abs(event.pageX - startX);
      const diffY: number = Math.abs(event.pageY - startY);

      if (diffX < delta && diffY < delta) {
        this.enableClick = true;
      } else {
        this.enableClick = false;
      }
    });
  }
}
