import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, UntypedFormControl, Validators, FormsModule, ReactiveFormsModule, FormControl } from '@angular/forms';
import * as lpn from 'google-libphonenumber';
import { Subscription, take } from 'rxjs';
import { phoneValidator } from 'src/app/_generic-components-lib/inputs/generic-phone-input/validators/generic-phone-input.validator';
import { CountryISO } from 'src/app/_generic-components-lib/__models/phone/phone-number.model';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { PopupService } from 'src/app/_services/popup-service.service';
import { environment } from 'src/environments/environment';
import { ContactUsService } from '../_services/contact-us.service';
import { CommonModule } from '@angular/common';
import { SingleLineTextInputComponent } from 'src/app/_generic-components-lib/inputs/text-inputs/single-line-text-input/single-line-text-input.component';
import { GenericButtonComponent } from 'src/app/_generic-components-lib/inputs/buttons/generic-button/generic-button.component';
import { LabelPipe } from '../../../_shared-modules/label-pipe-module/label.pipe';
import { GenericPhoneInputComponent } from 'src/app/_generic-components-lib/inputs/generic-phone-input/generic-phone-input.component';
import { TextareaInputComponent } from 'src/app/_generic-components-lib/inputs/textarea-input/textarea-input.component';
@Component({
  selector: 'app-contact-us-form',
  templateUrl: './contact-us-form.component.html',
  styleUrls: ['./contact-us-form.component.sass'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SingleLineTextInputComponent,
    GenericPhoneInputComponent,
    TextareaInputComponent,
    GenericButtonComponent,
    LabelPipe
  ]
})
export class ContactUsFormComponent implements OnInit, OnDestroy {

  public environment = environment;

  public form = this.fb.group({
    name: new UntypedFormControl('', [
      Validators.pattern("^[A-Za-zÀ-ÿ\\s]*$"),
      Validators.required
    ]),
    phoneInput: new UntypedFormControl('', []),
    countryCode: new UntypedFormControl('', []),
    email: new UntypedFormControl('', [
      Validators.pattern("^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+([.][a-zA-Z]{2,})+$"),
      Validators.required
    ]),
    message: new UntypedFormControl('', [
      Validators.pattern("^(?! )[^\s].*$"),
      Validators.required
    ])
  });
  public CountryISO = CountryISO;
  private subscription: Subscription;

  constructor(
    private fb: FormBuilder,
    public labelService: LabelsService,
    private popupService: PopupService,
    private contactUsService: ContactUsService
  ) { }

  // #region - Form Controls
    public get nameControl(): FormControl {
      return this.form.controls['name'];
    }

    public get phoneInputControl(): FormControl {
      return this.form.controls['phoneInput'];
    }

    public get countryCodeControl(): FormControl {
      return this.form.controls['countryCode'];
    }

    public get emailControl(): FormControl {
      return this.form.controls['email'];
    }

    public get messageControl(): FormControl {
      return this.form.controls['message'];
    }
  // #endregion - Form Controls

  ngOnInit(): void {
    this.form.controls['phoneInput'].addValidators(
      phoneValidator(this.form.get('countryCode')?.value));

    if (environment.showPhoneInput) {
      this.form.get('phoneInput')?.addValidators(Validators.required);
      this.form.get('countryCode')?.addValidators(Validators.required);
    }

    this.subscription = this.form.get('phoneInput')?.valueChanges.subscribe((value: string) => {
      const phoneUtil = lpn.PhoneNumberUtil.getInstance();
      const countryCode = this.form.get('countryCode')?.value;
      const regionCode = phoneUtil.getCountryCodeForRegion(countryCode);
      const isoCode = phoneUtil.getRegionCodeForCountryCode(phoneUtil.getCountryCodeForRegion(countryCode));
      const phoneNumberLength = phoneUtil.getExampleNumberForType(isoCode, lpn.PhoneNumberType.FIXED_LINE_OR_MOBILE).getNationalNumber()?.toString().length;

      if (countryCode && typeof value === 'string' && value.length === phoneNumberLength && !value.startsWith(`+${regionCode}`)) {
        this.form.get('phoneInput')?.patchValue(
          `+${regionCode} ${value}`,
        );
      }
    })!;
  }

  public submitForm(): void {
    this.form.markAllAsTouched();
    if(this.form.valid) {
      // Add a way to submit the information to BE

      this.contactUsService.submitContactUsForm(this.form).pipe(take(1)).subscribe(resp => {

      });

      this.popupService.setPopupToOpen(
        {text:''},
        'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/popup-success-image.png',
        { text: 'Thank you'},
        [
          {text: 'Request sent successfully.\nA member of our team will get back to you shortly.'},
        ],
        [{
          text: 'Ok',
          actionText: 'close',
          isCloseBtn: true,
          style: {
            backgroundColor: this.environment.brandStyleVariables.mainBrandColor,
            fontColor: this.environment.brandStyleVariables.mainBackgroundColor,
            marginTop: '17px',
            fontSize: '14px',
            textAlign: 'center',
            fontWeight: '700',
            borderRadius: '66px'
          }
        }]
      );

      this.popupService.hasPopups.subscribe(haspopUp => {
        if(!haspopUp) {
          this.form.reset();
        }
      });
    }
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
