import { animate, state, style, transition, trigger } from '@angular/animations';
import { CommonModule } from '@angular/common';
import { AfterViewInit, Component, DestroyRef, ElementRef, EventEmitter, inject, Input, OnInit, Output } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { asapScheduler, take } from 'rxjs';
import { Description, IconImage, PopUpButton, PopupButtonAction, Title } from '../../__models/popups/popups.model';
import { LibraryService } from '../../__services/library.service';
import { BaseComponent } from '../../_base-component/base.component';

@Component({
  standalone: true,
  selector: 'app-generic-popup',
  templateUrl: './generic-popup.component.html',
  styleUrls: ['./generic-popup.component.sass'],
  animations: [
    trigger('ZoomIn', [
      state('hide', style({
        opacity: '0',
        transform: 'translate(-50%, -50%) scale(0.75, 0.75)'
      })),
      state('show', style({
        opacity: '1',
        transform: 'translate(-50%, -50%) scale(1, 1)'
      })),
      transition('show => hide',  animate('300ms ease-in-out')),
      transition('hide => show',  animate('300ms ease-in-out'))
    ]),
    trigger('BackdropFadeIn', [
      state('hide', style({
        opacity: '0',
      })),
      state('show', style({
        opacity: '0.32',
      })),
      transition('hide <=> show',  animate('300ms ease-in-out')),
    ]),
  ],
  imports: [
    CommonModule,
  ]
})
export class GenericPopupComponent extends BaseComponent implements OnInit, AfterViewInit {

  @Input() public preImgTitle: Title;
  @Input() public image: string;
  @Input() public iconImage: IconImage;
  @Input() public imageUsingIcon: boolean;
  @Input() public bottomImage: string;
  @Input() public bottomDescription: string;
  @Input() public title: Title;
  @Input() public descriptions: Array<Description>;
  @Input() public navigationUrl: string;
  @Input() public buttons: Array<PopUpButton>;

  @Input() public closeClickOutside: boolean;
  @Input() public buttonsVertical: boolean;
  @Input() public enableXButton: boolean = true;

  @Output() public buttonClicked: EventEmitter<PopupButtonAction> = new EventEmitter<PopupButtonAction>();
  @Output() public popUpClosed: EventEmitter<boolean> = new EventEmitter<boolean>();

  public animationState: string = 'hide';

  public destroyRef = inject(DestroyRef);

  constructor(
    private router: Router,
    public override host: ElementRef,
    public override libraryService: LibraryService
  ) {
    super(host, libraryService);
  }

  ngOnInit(): void {
    super.setCSSVars();
  }

  ngAfterViewInit(): void {
    asapScheduler.schedule(() => {
      this.animationState = 'show';
    });
  }

  public btnAction(btn: PopUpButton): void {
    if (btn.isCloseBtn) {
      this.closePopUp();
      this.buttonClicked.emit({actionText: btn.actionText, actionValue: btn.actionValue ?? null});
    }
    else this.buttonClicked.emit({actionText: btn.actionText, actionValue: btn.actionValue ?? null});
  }

  public closePopUp(): void {
    this.animationState = 'hide';
    super.oldAsyncFunction(() => {
      this.popUpClosed.emit(true);
    }, 300).pipe(take(1), takeUntilDestroyed(this.destroyRef)).subscribe();
  }

  public navigateTo(): void {
    if (this.navigationUrl.includes('https')) {
      window.open(this.navigationUrl, "_blank");
    } else {
      this.router.navigate([this.navigationUrl]);
    }
  }
}
