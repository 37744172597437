import { AbstractControl, ValidationErrors } from "@angular/forms";

export function noWhiteSpaceValidator() {
  return (control: AbstractControl): ValidationErrors | null => {
    const isWhitespace: boolean = (control.value || '').trim().length === 0;
    const isValid: boolean = !isWhitespace;

    return isValid ? null: { 'whitespace': true };
  };
}
