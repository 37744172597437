<div class="backdrop-container" aria-hidden="true" [@BackdropFadeIn]="animationState" (click)="closeClickOutside ? closePopUp() : null"></div>

<div class="generic-popup-container card-radius" [@Slide]="animationState">
  <div class="close-button" aria-hidden="true" (click)="closePopUp()">
    <i class="bi bi-x"></i>
  </div>

  <div class="generic-popup-top-area mb-4">
    @if (preImgTitle) {
      <span class="title mb-4 text-center"
        [ngStyle]="{
          'text-align': preImgTitle.style?.textAlign,
          'font-size': preImgTitle.style?.fontSize,
          'font-weight': preImgTitle.style?.fontWeight,
          'font-style': preImgTitle.style?.fontStyle,
          'color': preImgTitle.style?.fontColor,
          'margin-top': preImgTitle.style?.marginTop,
          'margin-bottom': preImgTitle.style?.marginBottom,
          'text-transform': preImgTitle.style?.textTransform
        }">
        {{ preImgTitle.text }}
      </span>
    }

    @if (title) {
      <span class="description text-center"
        [ngStyle]="{
          'text-align': title.style?.textAlign,
          'font-size': title.style?.fontSize,
          'font-weight': title.style?.fontWeight,
          'font-style': title.style?.fontStyle,
          'color': title.style?.fontColor,
          'margin-top': title.style?.marginTop,
          'margin-bottom': title.style?.marginBottom,
          'text-transform': title.style?.textTransform
        }">
        {{ title.text }}
      </span>
    }
  </div>

  @if (image) {
    <img [src]="image" [alt]="preImgTitle != null ? preImgTitle.text : title != null ? title.text : '' ">
  }

  <div class="generic-popup-information mb-4">
    <p class="wallet-name mt-4 text-center">{{walletLabel }}</p>

    <span>{{ walletName }}</span>
  </div>

  <div class="generic-popup-buttons">
    @for (btn of buttons; track $index) {
      <div class="w-100 generic-btn-shadow button mt-2" aria-hidden="true" [ngStyle]="{
          'background-color': btn.style?.backgroundColor,
          'color': btn.style?.fontColor,
          'font-size': btn.style?.fontSize,
          'font-weight': btn.style?.fontWeight,
          'font-style': btn.style?.fontStyle,
          'text-transform': btn.style?.textTransform,
          'border-radius': btn.style?.borderRadius
        }"
        (click)="btnAction(btn)">
        {{ btn.text }}
      </div>
    }
  </div>
</div>
