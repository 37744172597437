
import { HttpClient } from '@angular/common/http';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, DestroyRef, ElementRef, EventEmitter, Inject, inject, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, ViewChild, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { FormGroupDirective, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatButtonModule } from '@angular/material/button';
import { DateAdapter, MAT_DATE_LOCALE, MatNativeDateModule } from '@angular/material/core';
import { MatCalendar, MatDatepicker, MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconRegistry } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { DomSanitizer } from '@angular/platform-browser';
import { asapScheduler, Subject, takeUntil } from 'rxjs';
import { LibraryService } from '../../../__services/library.service';
import { InputBaseComponent } from '../../../_extended-components/input-base-component/input-base.component';
import { CustomDateAdapter } from '../custom-date-adapter';
import { NgClass, NgStyle } from '@angular/common';


@Component({
  standalone: true,
  selector: 'app-date-input',
  templateUrl: './date-input.component.html',
  styleUrls: [
    '../../../_base-component/base.component.sass',
    '../../../_extended-components/input-base-component/input-base.component.sass',
    './date-input.component.sass'
  ],
  imports: [
    NgClass,
    NgStyle,
    MatFormFieldModule,
    MatInputModule,
    MatButtonModule,
    MatNativeDateModule,
    MatDatepickerModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: DateInputComponent
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: DateInputComponent
    },
    FormGroupDirective,
    HttpClient,
    CustomDateAdapter,
  ],
  encapsulation: ViewEncapsulation.None
})
export class DateInputComponent extends InputBaseComponent implements OnInit, OnChanges {
  private destroyRef = inject(DestroyRef);

  @ViewChild('formInput') public formInput: ElementRef;
  @ViewChild('picker') public picker: MatDatepicker<Date>;
  @ViewChild('mobilePicker') public mobilePicker: MatDatepicker<Date>;

  @ViewChild('dateInputContainer') public inputContainer: ElementRef;

  @Input() public customIcon: boolean;
  @Input() public customIconClass: string = 'bi-calendar4';

  @Input({required: true}) public forcedLocaleFormat: string;
  @Input({required: true}) public mobile: boolean;
  @Input() public minDate: Date;
  @Input() public maxDate: Date;
  @Input() public savedDate: Date | undefined;
  @Input({required: true}) public uniqueId: string;

  @Output() public dateChanged: EventEmitter<Date> = new EventEmitter<Date>();

  public customHeader = CustomHeaderDateComponent;

  public calendar: MatCalendar<Date>;
  public showMultiYearPicker: boolean = false;
  public dateAdapter: CustomDateAdapter;

  public inputLoaded: boolean;

  public inputDateValue: Date;

  public override onChange: <T>(inputValue: T) => void;

  constructor(
    public matIconRegistry: MatIconRegistry,
    public domSanitizer: DomSanitizer,
    public _adapter: DateAdapter<unknown>,
    public override host: ElementRef,
    public override libraryService: LibraryService,
    @Inject(MAT_DATE_LOCALE) public _locale: string
  ) {
    super(host, libraryService);
  }

  public override writeValue(value: Date): void {
    this.inputDateValue = value;
  }

  ngOnInit() {
    if (!this.minDate) {
      this.minDate = new Date();
    }

    super.setCSSVars();
    super.setSpecificCSSVars();
    this.setCSSValues();

    if (this.forcedLocaleFormat) {
      this._locale = this.forcedLocaleFormat;
      this._adapter.setLocale(this._locale);
    }

    if (this.savedDate)
     this.inputDateValue = this.savedDate;

    this.inputLoaded = true;

    this.control.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe({
      next: value => {
        if (!value) {
          this.formInput.nativeElement.value = null;
        }
      }
    });
  }

  ngOnChanges(changes: SimpleChanges) {
    if (this.inputLoaded) {
      if (!this.control.value) {
        this.formInput.nativeElement.value = null;
      }

      if (changes['minDate']) {
        const previousMinDate: number = (new Date(changes['minDate'].previousValue)).getTime();
        const currentDate: number = (new Date(this.formInput.nativeElement.value))?.getTime();

        if (previousMinDate > currentDate) {
          this.formInput.nativeElement.value = null;
        }
      }

      if (changes['maxDate']) {
        const previousMaxDate: number = (new Date(changes['maxDate'].previousValue)).getTime();
        const currentDate: number = (new Date(this.formInput.nativeElement.value)).getTime();

        if (previousMaxDate < currentDate) {
          this.formInput.nativeElement.value = null;
        }
      }

      if (changes['savedDate'] || changes['isDisabled']) {
        this.inputDateValue = this.savedDate!;
      }

      this.setCSSValues();
    }
  }

  public onYearSelected(year: number): void {
    this.calendar.activeDate = this.dateAdapter.addCalendarYears(new Date(year), 0);
    this.showMultiYearPicker = false;
  }

  public onDate(date: Date) {
    if (date) {
      this.onChange(date);
    }
  }

  public setCSSValues(): void {
    asapScheduler.schedule(() => {
      const matFFFlex: HTMLElement = Array.from(this.inputContainer.nativeElement.getElementsByClassName('mat-mdc-form-field-flex') as HTMLCollectionOf<HTMLElement>)[0];

      if (this.backgroundColor) {
        matFFFlex.style.backgroundColor = this.backgroundColor;
      }
    });
  }
}

/** Custom header component for datepicker. */
@Component({
  standalone: true,
  selector: 'app-custom-header',
  styleUrls: [
    '../../../_base-component/base.component.sass',
    '../../../_extended-components/input-base-component/input-base.component.sass',
    './date-input.component.sass'
  ],
  template: `
    <div class="custom-header">
      <div class="mat-icon-button-container" aria-hidden="true" mat-icon-button (click)="changeDate(-1)">
        <i class="bi bi-chevron-left"></i>
      </div>
      <div class="mat-calendar-multi-year-select" aria-hidden="true" (click)="openYearView()">
        <div mat-button>
          <p>{{periodLabel}}</p>
          <i class="bi bi-chevron-expand"></i>
        </div>
      </div>
      <div class="mat-icon-button-container" aria-hidden="true" mat-icon-button (click)="changeDate(1)">
        <i class="bi bi-chevron-right"></i>
      </div>
    </div>
  `,
  imports: [
    DateInputComponent
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CustomHeaderDateComponent implements OnInit, OnDestroy {
  private _destroyed = new Subject<void>();

  public setPickerView: string;

  constructor(
    private _calendar: MatCalendar<Date>,
    private _dateAdapter: CustomDateAdapter,
    cdr: ChangeDetectorRef,
    private host: ElementRef,
    private dateInputComponent: DateInputComponent
  ) {
    _calendar.stateChanges.pipe(takeUntil(this._destroyed)).subscribe(() => cdr.markForCheck());
  }

  ngOnInit(): void {
    this.dateInputComponent.calendar = this._calendar;
    this.dateInputComponent.dateAdapter = this._dateAdapter;
    document.getElementsByTagName('mat-datepicker-content')[0].setAttribute('id', `datepicker-${this.dateInputComponent.uniqueId}`);
    this.setCSSVars();
  }

  ngOnDestroy() {
    this._destroyed.next();
    this._destroyed.complete();
  }

  public get periodLabel() {
    return this._dateAdapter
      .format(this._calendar.activeDate, {year: 'numeric'})
      .toLocaleUpperCase();
  }

  public changeDate(amount: -1 | 1): void {
    // increment or decrement month or year
    this._calendar.activeDate =
    this._calendar.currentView === 'month'
        ? this._dateAdapter.addCalendarMonths(this._calendar.activeDate, amount)
        : this._dateAdapter.addCalendarYears(this._calendar.activeDate, this._calendar.currentView === 'multi-year' ? amount*24 : amount);
  }

  public openYearView(): void {
    this._calendar.currentView = 'multi-year';
  }

  public setCSSVars(): void {
    document.getElementById(`datepicker-${this.dateInputComponent.uniqueId}`)?.style.setProperty('--brand-color', this.dateInputComponent.brandColor);
    document.getElementById(`datepicker-${this.dateInputComponent.uniqueId}`)?.style.setProperty('--font-color', this.dateInputComponent.fontColor);
    document.getElementById(`datepicker-${this.dateInputComponent.uniqueId}`)?.style.setProperty('--error-color', this.dateInputComponent.errorColor);
    document.getElementById(`datepicker-${this.dateInputComponent.uniqueId}`)?.style.setProperty('--font-size', this.dateInputComponent.fontSize);
  }
}
