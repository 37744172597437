<div class="input-container w-100">
  @if (label) {
    <label [for]="control" [ngClass]="{
      'error': control.touched && !control.valid,
      'mb-1': !labelMargin
    }" [ngStyle]="{'margin-bottom': labelMargin}">{{ label }}</label>
  }

  <div class="inner-container position-relative w-100 d-flex align-items-center" [ngClass]="{'error': control.touched && !control.valid }">
    <div class="dropdown-button" aria-hidden="true" [attr.role]="allowDropdown ? 'button' : ''" (click)="allowDropdown ? openDropdown() : ''">
      <div class="country-flag" [ngClass]="selectedCountry.flagClass || ''"></div>
      @if (allowDropdown) {
        <i class="bi" [ngClass]="{'bi-chevron-down': !dropdownOpen, 'bi-chevron-up': dropdownOpen}"></i>
      }
    </div>
    <input type="tel" class="w-100 ps-1 pe-3" [placeholder]="placeholderLabel" [formControl]="control">
    <input type="hidden" [formControl]="countryCodeControl">
  </div>
  <div class="position-relative w-100">
    @if (dropdownOpen && allowDropdown) {
      <app-country-dropdown [countryList]="allCountries" [brandColor]="brandColor" [borderRadius]="dropdownBorderRadius ? dropdownBorderRadius : borderRadius" [noResultsLabel]="noResultsLabel" [placeholder]="searchPlaceholderLabel" [lightBrandColor]="'#D9D9D94D'" [borderColor]="'white'"
        [errorColor]="errorColor" [fontColor]="fontColor" [fontSize]="fontSize" (countrySelected)="emitOptionSelected($event)" (closeDropdown)="dropdownOpen = false">
      </app-country-dropdown>
    }
  </div>
</div>
