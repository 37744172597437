<div class="list-item-content-container w-100 ps-3 pe-3 mt-auto" [ngClass]="{ 'highlighted': activity.type === ActivityTypes.INPUT }">
  @if (activity.rewards) {
    <div class="prize-container left-top-column">
      <!-- {{ labelsService.activityListItemPrizeLabel | label: { amount: activity.totalValue, unit: getCurrencySymbol(activity.totalValue.unit, 'narrow') | label } }} -->
      {{ labelsService.activityListItemPrizeLabel | label: { amount: activity.totalReward, unit: getCurrencySymbol('pinkcoins', 'narrow') | label } }}
    </div>
  }

  @if (activity.totalSubActivities) {
    <div class="quantity-container right-top-column">
      <span class="quantity-normal-msg">
        {{
        activity.totalSubActivities === 1 ?
        (labelsService.activityListItemSubtaskSingular | label : { quantity : activity.totalSubActivities }) : labelsService.activityListItemSubtaskPlural | label : { quantity : activity.totalSubActivities }
        }}
      </span>
    </div>
  }

  @if (activity.partner) {
    <div class="partner-container left-bottom-column">
      @if (activity.partner.images && activity.partner.images.profilePictureImage) {
        <img [src]="activity.partner.images.profilePictureImage.href" alt="">
      }
      @if (activity.partner.name) {
        <span class="partner-name">{{ activity.partner.name }}</span>
      }
    </div>
  }

  @if (activity.totalSubActivities && showProgress) {
    <div class="progress-bar-wrapper">
      <div class="progress-bar-container">
        <div class="progress-bar" role="progressbar" [ngClass]="{'danger': activity.totalCompletedSubActivityPercentage! >= 80}" [ngStyle]="{'width': activity.totalCompletedSubActivityPercentage + '%' }" [attr.aria-valuenow]="activity.totalCompletedSubActivityPercentage" aria-valuemin="0" [attr.aria-valuemax]="100"></div>
      </div>
      <span> {{ activity.totalCompletedSubActivityPercentage }}%</span>
    </div>
  }

  @if (activity.endDate && !(activity.totalSubActivities && showProgress)) {
    <div class="date-container right-bottom-column">
      <ng-container>
        @if (activity.endDate) {
          <span>{{ labelsService.activityListItemDateLabel | label: { date: activity.endDate | date: 'dd MMMM, yyyy': translateService.currentLang: translateService.currentLang | titlecase } }}</span>
        }
      </ng-container>
    </div>
  }
</div>
