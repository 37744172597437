<div class="product-detail-container">
  <div class="product-detail-scrollable-container" #scrollableContainer>
    <div class="product-description-container">
      @if (content.title) {
        <p class="product-title">{{ content.title }}</p>
      }
      @if (content.text) {
        <p class="product-text">{{ content.text }}</p>
      }
      @if (content.subtitle) {
        <p class="product-subtitle">{{ content.subtitle }}</p>
      }
      @if (content.bulletPoints) {
        <ul class="product-solution-list">
          @for (item of content.bulletPoints; track item) {
            <li class="product-solution-item">{{ item }}</li>
          }
        </ul>
      }
      @if (content.incentive) {
        <div class="product-incentive">
          @if (content.incentive.title) {
            <p class="product-title">{{ content.incentive.title }}</p>
          }
          @if (content.incentive.subtitle) {
            <p class="product-subtitle">{{ content.incentive.subtitle }}</p>
          }
          @if (content.incentive.description) {
            <p class="product-text">{{ content.incentive.description }}</p>
          }
        </div>
      }
    </div>

    <div class="product-image-container" #ImagesContainerRef>
      <div class="product-image-wrapper">
        @if (content.imageUrl) {
          <img class="product-main-image" id="product-details-main-image" #MainImage [src]="content.imageUrl">
          } <!-- Main Image -->
          @if (content.iconUrls && content.iconUrls.length > 0) {
            <img class="product-left-icon" id="product-details-left-icon" #LeftIcon [src]="content.iconUrls[0]">
            } <!-- First Icon -->
            @if (content.iconUrls && content.iconUrls.length > 1) {
              <img class="product-right-icon" id="product-details-right-icon" #RightIcon [src]="content.iconUrls[1]">
              } <!-- Second Icon -->
              @if (content.iconUrls && content.iconUrls.length > 2) {
                <img class="product-bottom-left" id="product-details-bottom-left-icon" #LeftBottomIcon [src]="content.iconUrls[2]">
                } <!-- Third Icon -->
              </div>
            </div>
          </div>
        </div>
