import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { Component, ElementRef, EventEmitter, forwardRef, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroupDirective, FormsModule, NG_VALIDATORS, NG_VALUE_ACCESSOR, ReactiveFormsModule } from '@angular/forms';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { LibraryService } from '../../__services/library.service';
import { ButtonBaseComponent } from '../../_extended-components/button-base-component/button-base.component';

@Component({
  standalone: true,
  selector: 'app-checkbox',
  templateUrl: './checkbox.component.html',
  styleUrls: [
    '../../_base-component/base.component.sass',
    '../../_extended-components/button-base-component/button-base.component.sass',
    './checkbox.component.sass'
  ],
  imports: [
    CommonModule,
    MatFormFieldModule,
    MatInputModule,
    FormsModule,
    ReactiveFormsModule,
    MatCheckboxModule,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => CheckboxComponent)
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: forwardRef(() => CheckboxComponent)
    },
    HttpClient,
    FormGroupDirective
  ]
})
export class CheckboxComponent extends ButtonBaseComponent implements OnInit {

  @Input({required: true}) public activeCheckboxColor: string;

  @Input({required: true}) public gapSize: string;

  @Input({required: true}) public flexDirection: string;

  @Input({required: false}) public disabled: boolean;

  @Input({required: true}) public control: FormControl;

  @Output() public valueChanged: EventEmitter<boolean> = new EventEmitter<boolean>();

  public checked: boolean = false;

  constructor(
    public override host: ElementRef,
    public override libraryService: LibraryService) {
    super(host, libraryService);
  }

  public override writeValue(value: string | boolean): void {
    this.checked = (value as boolean);
  }

  ngOnInit(): void {
    super.setCSSVars();
    super.setSpecificCSSVars();
    this.setSpecificCheckboxCSS();
  }

  public onValueChange(): void {
    if (!this.control.disabled) {
      this.checked = !this.checked;
      this.valueChanged.emit(!this.checked);
      this.onChange(this.checked);
    }
  }

  public setSpecificCheckboxCSS(): void {
    if (this.activeCheckboxColor) {
      this.host.nativeElement.style.setProperty('--active-font-color', this.activeCheckboxColor);
    }
  }
}
