<!-- ##################### Intro Banner ##################### -->
@if (bannerInfo.titleAboveBanner) {
  <p class="pre-banner-title mt-5">{{bannerInfo.titleAboveBanner}}</p>
}

<div class="intro-banner" [ngStyle]="{'flex-flow' : flexFlow}">
  <div class="intro-info-container" [ngStyle]="{'width': bannerInfo.bannerImageUrl ? '45%' : '100%'}">
    @if (bannerInfo.bannerTitleImageUrl) {
      <img class="main-product-logo" [src]="bannerInfo.bannerTitleImageUrl">
    }

    @if (bannerInfo.bannerTitle) {
      <p class="banner-title mt-5">{{ bannerInfo.bannerTitle }}</p>
    }
    @if (bannerInfo.bannerSubTitle) {
      <p class="banner-subtitle mt-5">{{ bannerInfo.bannerSubTitle }}</p>
    }
    @if (bannerInfo.bannerDescriptionHTML) {
      <div class="banner-description" [innerHTML]="bannerInfo.bannerDescriptionHTML | safehtml"></div>
    }
  </div>

  @if (bannerInfo.bannerImageUrl) {
    <img class="main-banner-image" [src]="bannerInfo.bannerImageUrl">
  }
</div>
