
<div class="action-incentive-container">
  @if (content.imageUrl) {
    <img class="background-image-container w-100" id="action-image-container" [src]="content.imageUrl">
  }

  <div class="action-text-container" id="action-text-container">
    <span class="action-title">{{content.title}}</span>
    @if (content.description) {
      <span class="action-desc">{{content.description}}</span>
    }

    @if (content.actionText) {
      <app-generic-button class="action-button"
        [minHeight]="'unset'"
        [buttonName]="content.actionText" [hasIcon]="content.actionIconUrl != null"
        [iconClass]="content.actionIconUrl ? content.actionIconUrl : ''"
        [fontSize]="'var(--small-font-size)'" [fontWeight]="'800'"
        [borderRadius]="'calc(var(--small-font-size) * 3)'"
        [buttonSpacing]="'0.55vw 1.5vw'"
        [fontColor]="'white'"
        [defaultIconColor]="'white'"
        (buttonAction)="actionClicked()">
      </app-generic-button>
    }
  </div>
</div>
