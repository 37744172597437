import { animate, state, style, transition, trigger } from '@angular/animations';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';
import { MenuBanner, Wallet } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { PopupService } from 'src/app/_services/popup-service.service';
import { LabelPipe } from 'src/app/_shared-modules/label-pipe-module/label.pipe';
import { environment } from 'src/environments/environment';
import { PartnersService } from '../../partners/_services/partners.service';
import { ProductsService } from '../_services/_products/products.service';
import { Product, ProductPartner, ProductTypeEnum } from '../_services/_products/products.service.model';
import { CommonModule } from '@angular/common';
import { PageBannerComponent } from "../../_shared-components/page-banner-module/page-banner/page-banner.component";
import { ItemImageSliderComponent } from "../../_shared-components/item-image-slider-module/item-image-slider/item-image-slider.component";
import { VoucherDetailsComponent } from "./voucher-details/voucher-details.component";
import { NftDetailsComponent } from "./nft-details/nft-details.component";
import { ListItemCardComponent } from "../../_shared-components/list-item-cards/list-item-card/list-item-card.component";
import { ProductContentComponent } from "../marketplace-list-container/product-content/product-content.component";
import { LoadingListItemComponent } from "../../_shared-components/loading-list-module/loading-list-item/loading-list-item.component";

@Component({
  selector: 'app-product-details',
  templateUrl: './product-details.component.html',
  styleUrls: ['./product-details.component.sass'],
  animations: [
    trigger('FadeIn', [
      state(
        'hide',
        style({
          opacity: '0',
        })
      ),
      state(
        'show',
        style({
          opacity: '1',
        })
      ),
      transition('hide <=> show', animate('600ms ease-in-out')),
    ])
  ],
  standalone: true,
  imports: [
    CommonModule,
    PageBannerComponent,
    ItemImageSliderComponent,
    VoucherDetailsComponent,
    NftDetailsComponent,
    ListItemCardComponent,
    ProductContentComponent,
    LabelPipe,
    LoadingListItemComponent
]
})
export class ProductDetailsComponent implements OnInit, AfterViewInit, OnDestroy {

  private destroy: Subject<boolean> = new Subject<boolean>();

  public animationState: string = 'hide';

  public loadingListItems: Array<number> = [1,2,3];

  public isLoaded: boolean = false;
  public isListLoading: boolean = false;

  public mainBannerInfo: MenuBanner;

  public product: Product;
  private productId: string;

  public partner: ProductPartner | undefined;

  public relatedProductsList: Array<Product> = [];

  public environment = environment;
  public ProductTypeEnum = ProductTypeEnum;

  public userDefaultWalletValue: number;

  constructor(
    public generalService: GeneralService,
    private productService: ProductsService,
    private popupService: PopupService,
    public labelService: LabelsService,
    private labelPipe: LabelPipe,
    private partnerService: PartnersService,
    private route: ActivatedRoute,
    public translateService: TranslateService,
    ) {
    const sessionLanguage = localStorage.getItem('SessionLanguageCode');

    this.translateService.currentLang = environment.defaultLanguage;
    this.translateService.setDefaultLang(environment.defaultLanguage);
    this.translateService.use(environment.defaultLanguage);

    this.generalService.userDetails.pipe(takeUntil(this.destroy)).subscribe(() => {
      const defaultWallet: Wallet | undefined = this.generalService.userDetails$.value.wallets![0];
      this.userDefaultWalletValue = defaultWallet ? defaultWallet.currentValue: 0;
    });

  }

  ngOnInit(): void {
    const currentMenu = this.generalService.menuList.find(menu => menu.viewUrl === '/marketplace');
    this.mainBannerInfo = currentMenu?.bannerContent!;

    this.route.params.subscribe(params => {
      this.productId = params['id']; //

      this.productService.getProductDetail(this.productId).subscribe(product => {
        this.product = product;

        this.generalService.oldAsyncFunction(() => {this.animationState = 'show'});

        let relatedListQueryParameters: Params = {};
        if (this.product.collection) {
          relatedListQueryParameters = {
            ...relatedListQueryParameters,
            'collection': this.product.collection.id
          };
        }

        // TODO if needed uncomment
        /* if(this.product.partner) {
          this.partnerService.getPartnerDetails(this.product.partner.id).subscribe((resp) => {
            this.partner = resp;
            this.isLoaded = true;
          });
        } else {
          this.isLoaded = true;
        } */

        this.partner = product.partner;
        this.isLoaded = true;

        this.isListLoading = true;
        this.productService.getRelatedProductList(relatedListQueryParameters).subscribe({
          next: (list: Array<Product>) => {
            this.relatedProductsList = list.filter(p => p.id !== this.productId).slice(0, 5);;
            this.isListLoading = false;
          },
          error: error => {
            this.isListLoading = false;
          }
        });
      }, err => {
        this.generalService.navigateTo('/404');
      });
    });
  }

  ngAfterViewInit(): void {
    this.generalService.currentScreen$.next('product-details-' + this.productId);
  }

  ngOnDestroy(): void {
    this.destroy.next(true);
    this.destroy.complete();
  }

  public productItemClicked(productId: string): void {
    this.generalService.navigateTo(`marketplace/product/${productId}`);

    this.generalService.autoScrollTop('page-router-container');
  }
}
