<div class="list-item-container"
  aria-hidden="true"
  [ngStyle]="{ 'margin': itemMargin, 'border': item.borderStyle }"
  [ngClass]="{ 'selected': item.selected, 'not-clickable' : item.notClickable }"
  (click)="itemClicked()">

  @for (info of item.info; track $index) {
    <div class="item-info"
      [ngStyle]="{
        'width': (fullSize ? info.sizes!.size : info.sizes!.sizeSmall) + '%',
        'color': info.styles?.fontColor ? info.styles!.fontColor : 'var(--font-color)',
        'font-weight': info.styles?.fontWeight ? info.styles!.fontWeight : 'normal',
        'font-size': info.styles?.fontSize ? info.styles!.fontSize : 'var(--font-size)',
        'text-align': info.styles?.textAlign ? info.styles?.textAlign : 'center',
        'padding': info.styles?.padding ? info.styles?.padding : 'none'
      }">

      @if (!info.expandButton && (fullSize || info.sizes!.sizeSmall)) {
        <div class="d-flex align-items-center h-100" [ngClass]="{'ps-3' : $index === firstItemIndex && !item.checkBoxEnabled && (!info.styles?.textAlign || info.styles?.textAlign !== 'center')}">
          <!-- Selected Border -->
          @if (item.selected && $index === firstItemIndex) {
            <div class="selected-item-line"></div>
          }

          @if (item.checkBoxEnabled && $index === firstItemIndex && detailsClosed) {
            <div class="checkbox-container">
              <form [formGroup]="itemForm">
                <app-checkbox [brandColor]="brandColor" [control]="itemCheckControl" [minHeight]="'var(--smallest-font-size)'"
                  [gapSize]="'1vw'" [flexDirection]="'row'" [activeCheckboxColor]="'#000000'" [disabledBackgroundColor]="disabledCheckboxColor"
                  [formControlName]="'itemCheck'" (valueChanged)="checkBoxClicked($event)">
                </app-checkbox>
              </form>
            </div>
          }

          <!-- Item Thumbnail Image -->
          @if (info.imageUrl) {
            <div class="pe-3">
              <img class="image-container" alt="" [src]="info.imageUrl">
            </div>
          }

          <!-- Item Icon -->
          @if (info.icon) {
            <div class="pe-1">
              @if (info.icon.iconClass) {
                <i class="bi" [ngClass]="info.icon.iconClass"></i>
              } @else {
                <img class="sec-image-container" alt="" [src]="info.icon.iconUrl">

              }
            </div>
          }

          <!-- Item Text Info -->
          @if (info.text) {
            <div class="h-100 w-100 d-flex align-items-center justify-content-center text-container" [ngStyle]="{
              '-webkit-line-clamp': info.styles?.maxAmountTextLines ? info.styles?.maxAmountTextLines : 'unset',
              '-webkit-box-orient': info.styles?.maxAmountTextLines && info.styles?.maxAmountTextLines! > 1 ? 'vertical' : 'unset',
              'display': info.styles?.maxAmountTextLines && info.styles?.maxAmountTextLines! > 1 ? '-webkit-box' : 'block',
              'overflow': 'hidden',
              'text-overflow': 'ellipsis',
              'line-height': 1.1
            }">
              <div [ngClass]="{'multiple-lines': info.styles?.maxAmountTextLines && info.styles?.maxAmountTextLines! >= 2}">{{info.text}}</div>
            </div>
          }

          <!-- Item Number Info -->
          @if (info.number != null) {
            <div class="h-100 w-100 d-flex align-items-center justify-content-center text-container" [ngStyle]="{
              'overflow': 'hidden',
              'text-overflow': 'ellipsis',
              'line-height': 1.1
            }">
              <span>{{ info.number }}</span>
            </div>
          }

          <!-- Item Dates (already formated) -->
          @if (info.date) {
            <div class="h-100 w-100 d-flex align-items-center justify-content-center date-container" [ngStyle]="{
                'white-space': info.styles?.whiteSpace ? info.styles?.whiteSpace : 'unset',
                '-webkit-line-clamp': info.styles?.maxAmountTextLines ? info.styles?.maxAmountTextLines : 'unset',
                '-webkit-box-orient': info.styles?.maxAmountTextLines && info.styles?.maxAmountTextLines! > 1 ? 'vertical' : 'unset',
                'display': info.styles?.maxAmountTextLines && info.styles?.maxAmountTextLines! > 1 ? '-webkit-box' : 'block',
                'overflow': 'hidden',
                'text-overflow': 'ellipsis',
                'line-height': 1.1
              }">
              <span>{{info.date}}</span>
            </div>
          }

          <!-- Progress Bar -->
          @if (info.progressBar) {
            <div class="h-100 w-100 d-flex flex-column align-items-center progress-bar-container">
              <div class="progress-title">
                <span class="current-stock">{{info.progressBar.currentValue}}</span> / <span class="total-items">{{info.progressBar.total}}</span>
              </div>
              <div class="progress w-75">
                <div class="progress-bar" role="progressbar"
                  aria-valuemin="0"
                  [ngClass]="{
                    'warning': ((info.progressBar.currentValue/info.progressBar.total)*100) >= 80,
                    'danger': ((info.progressBar.currentValue/info.progressBar.total)*100) >= 100}" [ngStyle]="{'width': (info.progressBar.currentValue-0)*100/(info.progressBar.total-0) + '%' }" [attr.aria-valuenow]="info.progressBar.currentValue" [attr.aria-valuemax]="info.progressBar.total"></div>
              </div>
            </div>
          }

          <!-- Item State (based on state enum) -->
          @if (info.state) {
            <div class="w-100 d-flex align-items-center justify-content-center state-container" [ngClass]="info.state.class">
              <span>{{info.state.label}}</span>
            </div>
          }

          @if (info.actions) {
            <div class="w-100 d-flex align-items-center justify-content-end multi-function-btns-container">
              @for (action of info.actions; track $index) {
                @if (!actionHidden) {
                  <div class="action-btn me-2" [ngClass]="{'eyeBtn': action.actionId === ActionId.visibility}"
                    (click)="item.actionDisabled ? '' : actionClicked(action.actionId); action ? $event.stopPropagation() : ''">
                    @if (action.imageUrl) {
                      <div class="action-icon-wrapper" [ngClass]="{'iconActive': actionActive}">
                        <div class="default-img" [ngStyle]="{'mask-image' : 'url(' + action.imageUrl + ')', '-webkit-mask-image': 'url(' + action.imageUrl + ')', 'background-color': !actionActive ? 'var(--font-color)' : 'var(--brand-color)' }"></div>
                        <!-- Action Icon Tooltip -->
                        @if (action.tooltipText) {
                          <div class="custom-tooltip">
                            <div class="tooltip-text">{{ action.tooltipText }}</div>
                            <div class="arrow"></div>
                          </div>
                        }
                      </div>
                    } @else {
                      <div class="action-icon-wrapper d-flex align-items-center justify-content-center"
                        [ngClass]="{'disabled': item.actionDisabled}"
                        [ngStyle]="{
                          'background-color': action.backgroundColor? action.backgroundColor : 'transparent',
                          'border-radius': action.borderRadius? action.borderRadius : '0',
                          'font-size': info.styles?.fontSize ? info.styles!.fontSize : 'var(--font-size)',
                          'width': action.buttonWidth ? action.buttonWidth : 'calc(' + (info.styles?.fontSize ? info.styles!.fontSize : 'var(--font-size)') + '* 1.5)',
                          'height': action.buttonHeight ? action.buttonHeight : 'calc(' + (info.styles?.fontSize ? info.styles!.fontSize : 'var(--font-size)') + '* 1.5)'
                        }">
                        <i class="bi" [ngClass]="action.bootstrapIconClass"></i>
                      </div>
                    }
                  </div>
                }
              }
            </div>
          }
        </div>
      }


      <!-- ############################### OLD CODE ############################### -->
      <!-- <ng-container *ngIf="fullSize || info.sizes!.sizeSmall">
        <div *ngIf="info.name" class="h-100 w-100 d-flex align-items-center name-info-container" [ngStyle]="{'padding-left' : (item.selected ? '0px' : '4px')}">
          <!-- Selected Border
          <div *ngIf="item.selected" class="selected-item-line"></div>

          <!-- Item Thumbnail Image
          <div *ngIf="info.thumbnailUrl" class="ps-3 pe-3">
            <img [src]="info.thumbnailUrl" class="image-container">
          </div>

          <!-- Item Type Icon
          <div *ngIf="info.type?.imageUrl" class="pe-1">
            <img [src]="info.type?.imageUrl" class="sec-image-container">
          </div>

          <!-- Item Name
          <div class="name-container" [ngStyle]="{
            'white-space': info.styles?.whiteSpace ? info.styles?.whiteSpace : 'unset',
            '-webkit-line-clamp': info.styles?.maxAmountTextLines ? info.styles?.maxAmountTextLines : 'unset',
            '-webkit-box-orient': info.styles?.maxAmountTextLines && info.styles?.maxAmountTextLines! > 1 ? 'vertical' : 'unset',
            'display': info.styles?.maxAmountTextLines && info.styles?.maxAmountTextLines! > 1 ? '-webkit-box' : 'block',
            'overflow': 'hidden',
            'text-overflow': 'ellipsis',
            'line-height': 1.1
          }">
            <span>{{ info.name }}</span>
            <ng-container *ngIf="info.hasStateWithName">
              <div *ngIf="info.state" class="d-flex align-items-center justify-content-center state-container" [ngClass]="info.state.class">
                <span>{{info.state.label}}</span>
              </div>
            </ng-container>
          </div>
        </div>

        <!-- Item Text Info
        <div *ngIf="info.description" class="h-100 w-100 d-flex align-items-center justify-content-center text-container" [ngStyle]="{
          'white-space': info.styles?.whiteSpace ? info.styles?.whiteSpace : 'unset',
          '-webkit-line-clamp': info.styles?.maxAmountTextLines ? info.styles?.maxAmountTextLines : 'unset',
          '-webkit-box-orient': info.styles?.maxAmountTextLines && info.styles?.maxAmountTextLines! > 1 ? 'vertical' : 'unset',
          'display': info.styles?.maxAmountTextLines && info.styles?.maxAmountTextLines! > 1 ? '-webkit-box' : 'block',
          'overflow': 'hidden',
          'text-overflow': 'ellipsis',
          'line-height': 1.1
        }">
          <div>{{info.description}}</div>
        </div>

        <!-- Item Dates (already formated)
        <div *ngIf="info.date" class="h-100 w-100 d-flex align-items-center justify-content-center date-container" [ngStyle]="{
          'white-space': info.styles?.whiteSpace ? info.styles?.whiteSpace : 'unset',
          '-webkit-line-clamp': info.styles?.maxAmountTextLines ? info.styles?.maxAmountTextLines : 'unset',
          '-webkit-box-orient': info.styles?.maxAmountTextLines && info.styles?.maxAmountTextLines! > 1 ? 'vertical' : 'unset',
          'display': info.styles?.maxAmountTextLines && info.styles?.maxAmountTextLines! > 1 ? '-webkit-box' : 'block',
          'overflow': 'hidden',
          'text-overflow': 'ellipsis',
          'line-height': 1.1
        }">
          <span>{{info.date}}</span>
        </div>

        <div *ngIf="info.progressBar" class="h-100 w-100 d-flex flex-column align-items-center progress-bar-container">
          <div class="progress-title">
            <span class="current-stock">{{info.progressBar.currentValue}}</span> / <span class="total-items">{{info.progressBar.total}}</span>
          </div>
          <div class="progress w-75">
            <div class="progress-bar" role="progressbar" [ngClass]="{'danger': ((info.progressBar.currentValue/info.progressBar.total)*100) >= 80}" [ngStyle]="{'width': (info.progressBar.currentValue-0)*100/(info.progressBar.total-0) + '%' }" [attr.aria-valuenow]="info.progressBar.currentValue" aria-valuemin="0" [attr.aria-valuemax]="info.progressBar.total"></div>
          </div>
        </div>

        <!-- Item State (based on state enum)
        <div *ngIf="info.state && !info.hasStateWithName" class="w-100 d-flex align-items-center justify-content-center state-container" [ngClass]="info.state.class">
          <span>{{info.state.label}}</span>
        </div>

        <div *ngIf="info.actions" class="w-100 d-flex align-items-center justify-content-end multi-function-btns-container">
          <div class="action-btn me-2" *ngFor="let action of info.actions; let index = index" [ngClass]="{'eyeBtn': action.actionId === ActionId.visibility}"
          (click)="item.actionDisabled ? '' : actionClicked(action.actionId); action ? $event.stopPropagation() : ''">

            <ng-container *ngIf="action.imageUrl; else bootstrapIcon">
              <div class="action-icon-wrapper" [ngClass]="{'iconActive': actionActive}">
                <div [ngStyle]="{'mask-image' : 'url(' + action.imageUrl + ')', '-webkit-mask-image': 'url(' + action.imageUrl + ')', 'background-color': !actionActive ? 'var(--font-color)' : 'var(--brand-color)' }" class="default-img"></div>
                <!-- Action Icon Tooltip
                <div class="custom-tooltip" *ngIf="action.tooltipText">
                  <div class="tooltip-text">{{ action.tooltipText }}</div>
                  <div class="arrow"></div>
                </div>
              </div>
            </ng-container>

            <ng-template #bootstrapIcon>
              <div class="action-icon-wrapper d-flex align-items-center justify-content-center"
                [ngClass]="{'disabled': item.actionDisabled}"
                [ngStyle]="{
                  'background-color': action.backgroundColor? action.backgroundColor : 'transparent',
                  'border-radius': action.borderRadius? action.borderRadius : '0',
                  'font-size': info.styles?.fontSize ? info.styles!.fontSize : 'var(--font-size)',
                  'width': action.buttonWidth ? action.buttonWidth : 'calc(' + (info.styles?.fontSize ? info.styles!.fontSize : 'var(--font-size)') + '* 1.5)',
                  'height': action.buttonHeight ? action.buttonHeight : 'calc(' + (info.styles?.fontSize ? info.styles!.fontSize : 'var(--font-size)') + '* 1.5)'
                }">
                <i class="bi" [ngClass]="action.bootstrapIconClass"></i>
              </div>
            </ng-template>
          </div>
        </div>
      </ng-container>-->
    </div>
  }
</div>
