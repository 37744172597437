<div class="product-code-container" [ngClass]="{ 'faded' : productCode.state !== productCodeStatus.PURCHASED }">
  <img [src]="'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/products/product-code-image.png'">

  <div class="code-info">
    <div class="code-title-label">
      <span>{{ labelService.productHistoryDetailsCodeTitle | label }}</span>

      @if (productCode.state !== productCodeStatus.PURCHASED) {
        <span class="code-state">
          @switch (productCode.state) {
            @case (productCodeStatus.REDEEMED) {
              {{ labelService.productHistoryDetailsCodeRedeemedLabel | label }}
            }
            @case (productCodeStatus.EXPIRED) {
              {{ labelService.productHistoryDetailsCodeExpiredLabel | label }}
            }
          }
        </span>
      }
    </div>

    @if (productCode.code) {
      <div class="code-container">
        {{ productCode.code }}
      </div>
    }

    <div class="date-container">
      @if (productCode.purchaseDate) {
        <p>{{ (labelService.productHistoryDetailsPurchaseDateLabel | label) + ' ' + (productCode.purchaseDate | date: 'MMMM dd, YYYY': 'pt' : 'pt' | titlecase) }}</p>
      }
      @if (productCode.redeemDate) {
        <p>{{ (labelService.productHistoryDetailsRedeemDateLabel | label) + ' ' + (productCode.redeemDate | date: 'MMMM dd, YYYY': 'pt' : 'pt' | titlecase) }}</p>
      }
      @if (productCode.expirationDate && !productCode.redeemDate) {
        <p>
          {{ ((productCode.state === productCodeStatus.EXPIRED ? labelService.productHistoryDetailsExpireDateLabel : labelService.productHistoryDetailsValidUntilDateLabel) | label)
          + ' ' + (productCode.expirationDate | date: 'MMMM dd, YYYY': 'pt' : 'pt' | titlecase) }}
        </p>
      }
    </div>
  </div>
</div>
