import { Component, ElementRef, OnInit } from '@angular/core';
import { Router, RouterLink } from '@angular/router';
import { BrandingInfo } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { PopupService } from 'src/app/_services/popup-service.service';
import { LabelPipe } from 'src/app/_shared-modules/label-pipe-module/label.pipe';
import { environment } from 'src/environments/environment';
import validator from 'validator';
import { FooterNavigationItem } from './footer.model';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { GenericButtonComponent } from 'src/app/_generic-components-lib/inputs/buttons/generic-button/generic-button.component';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.sass'],
  standalone: true,
  imports: [
    CommonModule,
    LabelPipe,
    RouterLink,
    GenericButtonComponent,
    FormsModule
  ]
})
export class FooterComponent implements OnInit {

  public brandInfo: BrandingInfo;

  public newsletterEmail: string;

  public termsUrl: string;
  public policyUrl: string;

  public environment = environment;

  private pjson = require('../../../../../package.json');
  public appVersion: string;

  public urlList: FooterNavigationItem[] = [];

  constructor(
    private generalService: GeneralService,
    public labelsService: LabelsService,
    private labelPipe: LabelPipe,
    public router: Router,
    private popUpService: PopupService,
    private host: ElementRef
  ) {
    this.appVersion = this.pjson.version
  }

  ngOnInit(): void {
    this.brandInfo = this.generalService.generalInfo.brandingInfo;

    this.urlList = [
      { url: "/about-us", name: '', enabled: false },
      { url: "/privacy-policy", name: 'footer_shortcut_privacy', enabled: environment.showPrivacyPolicy },
      { url: "/terms-of-use", name: 'footer_shortcut_terms', enabled: environment.showTermsAndConditions }
    ];
  }

  public submitNewsletter(): void {
    const isValidEmail = this.newsletterEmail && this.newsletterEmail.trim() !== '' && validator.isEmail(this.newsletterEmail);

    if (isValidEmail) {
      this.generalService
        .registerNewsletter(this.newsletterEmail)
        .subscribe((resp) => {
          this.popUpService.setPopupToOpen(
            { text: '' },
            'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/popup-success-image.png',
            { text: this.labelPipe.transform(this.labelsService.newsletterPopUpTitleSuccess) },
            [
              {
                text: this.labelPipe.transform(this.labelsService.newsletterPopupSucessMessage),
              },
            ],
            [
              {
                text: 'Ok',
                style: {
                  fontColor: this.environment.brandStyleVariables.mainBackgroundColor,
                  backgroundColor: this.environment.brandStyleVariables.mainBrandColor
                },
                actionText: 'newsLetterRegistered',
              },
            ]
          );
      });
    } else {
      this.popUpService.setPopupToOpen(
        {
          text: this.labelPipe.transform(this.labelsService.newsletterPopUpTitleError)
        },
        'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/popup-error-image.png',
        {text: ''},
        [this.popUpService.getSimpleDescription(this.labelPipe.transform(this.labelsService.newsletterPopupErrorMessage))],
        [{
          text: 'OK',
          isCloseBtn: true,
          actionText: '',
          style: {
            backgroundColor: this.environment.brandStyleVariables.mainBrandColor,
            fontColor: this.environment.brandStyleVariables.mainBackgroundColor
          }
        }]
      );
    }
  }

  public navigateOutside(url: string): void {
    window.open(url, '_blank');
  }
}
