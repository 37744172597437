<div class="input-container">
  @if (label) {
    <label [for]="control" [ngClass]="{'mb-1': !labelMargin, 'error': control.touched && !control.valid }" [ngStyle]="{'margin-bottom': labelMargin}">{{ label }}</label>
  }
  <div class="position-relative w-100">
    @if (units) {
      <span class="units">{{ units }}</span>
    }
    <input type="text" class="w-100" [value]="inputValue" [ngClass]="{'error': control.touched && !control.valid}" [placeholder]="placeholderLabel"
    (keydown)="blockNonNumbers($event, allowNegative, allowDecimals, minValue, maxValue)"
    (input)="this.removeFromInput($event, this.allowNegative, this.allowDecimals, this.maxValue); onInput($event)"
    (blur)="onTouched()">
  </div>
</div>
