import { CommonModule } from '@angular/common';
import { Component, ElementRef, OnInit } from '@angular/core';
import { SingleClickDirective } from '../../../__directives/single-click/single-click.directive';
import { LibraryService } from '../../../__services/library.service';
import { ButtonBaseComponent } from '../../../_extended-components/button-base-component/button-base.component';

@Component({
  standalone: true,
  selector: 'app-generic-icon-button',
  templateUrl: './generic-icon-button.component.html',
  styleUrls: [
    '../../../_base-component/base.component.sass',
    '../../../_extended-components/button-base-component/button-base.component.sass',
    './generic-icon-button.component.sass'
  ],
  imports: [CommonModule, SingleClickDirective]
})
export class GenericIconButtonComponent extends ButtonBaseComponent implements OnInit {

  constructor(
    public override host: ElementRef,
    public override libraryService: LibraryService
  ) {
    super(host, libraryService);
  }

  ngOnInit(): void {
    super.setCSSVars();
    super.setSpecificCSSVars();
  }
}
