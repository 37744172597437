<form class="account-details-form" [formGroup]="accountDetailsForm" (ngSubmit)="saveDetails()">
  <div class="details-column">
    <div class="account-details-control">
      @if (!editMode) {
        <label>{{labelService.myAccountDetailsFirstName | label}}*</label>
      }
      @if (!editMode) {
        <p>{{ userDetails.firstName }}</p>
      }

      @if (editMode) {
        <app-single-line-text-input
          [control]="firstNameControl"
          [formControlName]="'firstname'"
          [label]="(labelService.myAccountDetailsFirstName | label)+'*'"
          [inputHeight]="'64px'"
          [fontSize]="'var(--main-info-font-size)'"
          [roundBorders]="true"
          [borderRadius]="'calc(var(--main-info-font-size) * 3)'"
          [backgroundColor]="environment.brandStyleVariables.inputSecondaryBackgroundColor"
          [brandColor]="environment.brandStyleVariables.mainBrandColor"
          [placeholderLabel]="userDetails.firstName!">
        </app-single-line-text-input>
      }

      @if ((accountDetailsForm.get('firstname')?.touched
        && (accountDetailsForm.get('firstname')?.hasError('required')
        || accountDetailsForm.get('firstname')?.hasError('minlength')))) {
        <span class="input-error"
          >
          <img src="assets/imgs/error-icon.svg">
          {{labelService.myAccountDetailsErrorField | label}}
        </span>
      }
      @if ((accountDetailsForm.get('firstname')?.touched
        && accountDetailsForm.get('firstname')?.hasError('pattern'))) {
        <span class="input-error"
          >
          <img src="assets/imgs/error-icon.svg">
          {{labelService.myAccountDetailsErrorTextField | label}}
        </span>
      }
    </div>

    <div class="account-details-control">
      @if (!editMode) {
        <label>{{labelService.myAccountDetailsLastName | label}}*</label>
      }
      @if (!editMode) {
        <p>{{ userDetails.lastName }}</p>
      }

      @if (editMode) {
        <app-single-line-text-input
          [control]="lastNameControl"
          [formControlName]="'lastname'"
          [label]="(labelService.myAccountDetailsLastName | label)+'*'"
          [inputHeight]="'64px'"
          [fontSize]="'var(--main-info-font-size)'"
          [roundBorders]="true"
          [borderRadius]="'calc(var(--main-info-font-size) * 3)'"
          [backgroundColor]="environment.brandStyleVariables.inputSecondaryBackgroundColor"
          [brandColor]="environment.brandStyleVariables.mainBrandColor"
          [placeholderLabel]="userDetails.lastName!">
        </app-single-line-text-input>
      }

      @if ((accountDetailsForm.get('lastname')?.touched
        && (accountDetailsForm.get('lastname')?.hasError('required')
        || accountDetailsForm.get('lastname')?.hasError('minlength')))) {
        <span class="input-error"
          >
          <img src="assets/imgs/error-icon.svg">
          {{labelService.myAccountDetailsErrorField | label}}
        </span>
      }
      @if ((accountDetailsForm.get('lastname')?.touched
        && accountDetailsForm.get('lastname')?.hasError('pattern'))) {
        <span class="input-error"
          >
          <img src="assets/imgs/error-icon.svg">
          {{labelService.myAccountDetailsErrorTextField | label}}
        </span>
      }
    </div>

    <div class="account-details-control">
      @if (!editMode) {
        <label>{{ labelService.myAccountDetailsBirthdate | label }}</label>
      }
      @if (!editMode) {
        <p>{{ userDetails.birthdate ? (userDetails.birthdate | date: 'dd/MM/yyyy': 'pt') : '-' }}</p>
      }

      @if (editMode) {
        <app-date-input class="mt-3"
          [control]="birthDateControl"
          [formControlName]="'birthdate'"
          [uniqueId]="'birthDate'"
          [customIconClass]="'assets/imgs/calendar.svg'"
          [label]="(labelService.myAccountDetailsBirthdate | label)"
          [placeholderLabel]="(labelService.myAccountDetailsBirthdateLabel | label)"
          [backgroundColor]="environment.brandStyleVariables.inputSecondaryBackgroundColor"
          [brandColor]="environment.brandStyleVariables.mainBrandColor"
          [inputHeight]="'64px'"
          [fontSize]="'var(--main-info-font-size)'"
          [borderRadius]="'calc(var(--main-info-font-size) * 3)'"
          [savedDate]="userDetails.birthdate"
          [minDate]="minDate"
          [mobile]="(generalService.isMobile | async)!"
          [forcedLocaleFormat]="'pt'">
        </app-date-input>
      }

      @if ((accountDetailsForm.get('birthdate')?.touched
        && (accountDetailsForm.get('birthdate')?.hasError('required')
        || accountDetailsForm.get('birthdate')?.hasError('minlength')))) {
        <span class="input-error"
          >
          <img src="assets/imgs/error-icon.svg">
          {{labelService.myAccountDetailsErrorField | label}}
        </span>
      }
    </div>

    <div class="account-details-control">
      @if (!editMode) {
        <label>{{ labelService.myAccountDetailsEmail | label }}*</label>
      }
      @if (!editMode) {
        <p>{{ $any(userDetails.emailAddress?.emailAddress)?.emailAddress ? $any(userDetails.emailAddress?.emailAddress)?.emailAddress : '-' }}</p>
      }
      @if (editMode) {
        <app-single-line-text-input
          [control]="emailAddressControl"
          [formControlName]="'emailaddress'"
          [label]="(labelService.myAccountDetailsEmail | label)+'*'"
          [inputHeight]="'64px'"
          [fontSize]="'var(--main-info-font-size)'"
          [roundBorders]="true"
          [borderRadius]="'calc(var(--main-info-font-size) * 3)'"
          [backgroundColor]="environment.brandStyleVariables.inputSecondaryBackgroundColor"
          [brandColor]="environment.brandStyleVariables.mainBrandColor"
          [placeholderLabel]="$any(userDetails.emailAddress?.emailAddress)?.emailAddress ? $any(userDetails.emailAddress?.emailAddress)?.emailAddress : labelService.myAccountDetailsEmailLabel | label">
        </app-single-line-text-input>
      }

      @if ((accountDetailsForm.get('emailaddress')?.touched
        && accountDetailsForm.get('emailaddress')?.hasError('required'))) {
        <span class="input-error"
          >
          <img src="assets/imgs/error-icon.svg">
          {{labelService.myAccountDetailsErrorField | label}}
        </span>
      }

      @if ((accountDetailsForm.get('emailaddress')?.touched
        && accountDetailsForm.get('emailaddress')?.hasError('pattern'))) {
        <span class="input-error"
          >
          <img src="assets/imgs/error-icon.svg">
          {{labelService.myAccountDetailsEmailErrorField | label}}
        </span>
      }
    </div>

    <div class="account-details-control">
      @if (!editMode) {
        <label>{{labelService.myAccountDetailsFiscalNumber | label}}*</label>
      }
      @if (!editMode) {
        <p>{{userDetails.fiscalNumber! ? userDetails.fiscalNumber! : '-'}}</p>
      }
      @if (editMode) {
        <app-single-line-text-input
          [control]="fiscalNumberControl"
          [formControlName]="'fiscalnumber'"
          [label]="(labelService.myAccountDetailsFiscalNumber | label)+'*'"
          [inputHeight]="'64px'"
          [fontSize]="'var(--main-info-font-size)'"
          [roundBorders]="true"
          [borderRadius]="'calc(var(--main-info-font-size) * 3)'"
          [backgroundColor]="environment.brandStyleVariables.inputSecondaryBackgroundColor"
          [brandColor]="environment.brandStyleVariables.mainBrandColor"
          [placeholderLabel]="userDetails.fiscalNumber! ? userDetails.fiscalNumber! : labelService.myAccountDetailsFiscalNumberLabel | label">
        </app-single-line-text-input>
      }


      @if ((accountDetailsForm.get('fiscalnumber')?.touched
        && (accountDetailsForm.get('fiscalnumber')?.hasError('required')))) {
        <span class="input-error"
          >
          <img src="assets/imgs/error-icon.svg">
          {{labelService.myAccountDetailsErrorField | label}}
        </span>
      }

      @if ((accountDetailsForm.get('fiscalnumber')?.touched
        && accountDetailsForm.get('fiscalnumber')?.hasError('minlength')
        || accountDetailsForm.get('fiscalnumber')?.hasError('maxlength'))) {
        <span class="input-error"
          >
          <img src="assets/imgs/error-icon.svg">
          {{labelService.myAccountDetailsErrorTextField | label}}
        </span>
      }
    </div>

  </div>

  <div class="details-column">
    <div class="account-details-control">
      @if (!editMode) {
        <label>{{ labelService.myAccountDetailsAddressLine1Label | label }}</label>
      }
      @if (!editMode) {
        <p>{{ userDetails.fiscalAddress && $any(userDetails.fiscalAddress!.fiscalAddress)?.addressLine1 ? $any(userDetails.fiscalAddress!.fiscalAddress)?.addressLine1 : '-' }}</p>
      }
      @if (editMode) {
        <app-single-line-text-input
          [control]="addressLine1Control"
          [formControlName]="'addressline1'"
          [label]="labelService.myAccountDetailsAddressLine1Label | label"
          [inputHeight]="'64'"
          [fontSize]="'var(--main-info-font-size)'"
          [roundBorders]="true"
          [borderRadius]="'calc(var(--main-info-font-size) * 3)'"
          [backgroundColor]="environment.brandStyleVariables.inputSecondaryBackgroundColor"
          [brandColor]="environment.brandStyleVariables.mainBrandColor"
          [placeholderLabel]="userDetails.fiscalAddress ? $any(userDetails.fiscalAddress!.fiscalAddress)?.addressLine1 : labelService.myAccountDetailsAddressLine1Label | label">
        </app-single-line-text-input>
      }

      @if ((accountDetailsForm.get('addressline1')?.touched
        && (accountDetailsForm.get('addressline1')?.hasError('required')
        || accountDetailsForm.get('addressline1')?.hasError('minlength')))) {
        <span class="input-error"
          >
          <img src="assets/imgs/error-icon.svg">
          {{labelService.myAccountDetailsErrorField | label}}
        </span>
      }
    </div>

    <div class="account-details-control">
      @if (!editMode) {
        <label>{{ labelService.myAccountDetailsAddressLine2Label | label }}</label>
      }
      @if (!editMode) {
        <p>{{ userDetails.fiscalAddress && $any(userDetails.fiscalAddress!.fiscalAddress)?.addressLine2 ? $any(userDetails.fiscalAddress!.fiscalAddress)?.addressLine2 : '-' }}</p>
      }
      @if (editMode) {
        <app-single-line-text-input
          [control]="addressLine2Control"
          [formControlName]="'addressline2'"
          [label]="labelService.myAccountDetailsAddressLine2Label | label"
          [inputHeight]="'64px'"
          [fontSize]="'var(--main-info-font-size)'"
          [roundBorders]="true"
          [borderRadius]="'calc(var(--main-info-font-size) * 3)'"
          [backgroundColor]="environment.brandStyleVariables.inputSecondaryBackgroundColor"
          [brandColor]="environment.brandStyleVariables.mainBrandColor"
          [placeholderLabel]="userDetails.fiscalAddress ? $any(userDetails.fiscalAddress!.fiscalAddress)?.addressLine2 : labelService.myAccountDetailsAddressLine2Label | label">
        </app-single-line-text-input>
      }

      @if ((accountDetailsForm.get('addressline2')?.touched
        && (accountDetailsForm.get('addressline2')?.hasError('required')
        || accountDetailsForm.get('addressline2')?.hasError('minlength')))) {
        <span class="input-error"
          >
          <img src="assets/imgs/error-icon.svg">
          {{labelService.myAccountDetailsErrorField | label}}
        </span>
      }
    </div>

    <div class="account-details-control">
      @if (!editMode) {
        <label>{{ labelService.myAccountDetailsFiscalPostalCode | label }}</label>
      }
      @if (!editMode) {
        <p>{{ userDetails.fiscalAddress ? $any(userDetails.fiscalAddress.fiscalAddress)?.postalCode : '-' }}</p>
      }
      @if (editMode) {
        <app-single-line-text-input
          [control]="postalCodeControl"
          [formControlName]="'postalcode'"
          [label]="labelService.myAccountDetailsFiscalPostalCode | label"
          [inputHeight]="'64px'"
          [fontSize]="'var(--main-info-font-size)'"
          [roundBorders]="true"
          [borderRadius]="'calc(var(--main-info-font-size) * 3)'"
          [backgroundColor]="environment.brandStyleVariables.inputSecondaryBackgroundColor"
          [brandColor]="environment.brandStyleVariables.mainBrandColor"
          [placeholderLabel]="userDetails.fiscalAddress ? $any(userDetails.fiscalAddress.fiscalAddress)?.postalCode : labelService.myAccountDetailsFiscalPostalCodeLabel | label">
        </app-single-line-text-input>
      }

      @if ((accountDetailsForm.get('postalcode')?.touched
        && (accountDetailsForm.get('postalcode')?.hasError('required')
        || accountDetailsForm.get('postalcode')?.hasError('minlength')))) {
        <span class="input-error"
          >
          <img src="assets/imgs/error-icon.svg">
          {{labelService.myAccountDetailsErrorField | label}}
        </span>
      }
    </div>

    <div class="account-details-control">
      @if (!editMode) {
        <label>{{ labelService.myAccountDetailsFiscalCity | label }}</label>
      }
      @if (!editMode) {
        <p>{{ userDetails.fiscalAddress ? $any(userDetails.fiscalAddress.fiscalAddress)?.city : '-' }}</p>
      }
      @if (editMode) {
        <app-single-line-text-input
          [control]="cityControl"
          [formControlName]="'city'"
          [label]="labelService.myAccountDetailsFiscalCity | label"
          [inputHeight]="'64px'"
          [fontSize]="'var(--main-info-font-size)'"
          [roundBorders]="true"
          [borderRadius]="'calc(var(--main-info-font-size) * 3)'"
          [backgroundColor]="environment.brandStyleVariables.inputSecondaryBackgroundColor"
          [brandColor]="environment.brandStyleVariables.mainBrandColor"
          [placeholderLabel]="userDetails.fiscalAddress ? $any(userDetails.fiscalAddress.fiscalAddress)?.city : this.labelService.myAccountDetailsFiscalCityLabel| label">
        </app-single-line-text-input>
      }

      @if ((accountDetailsForm.get('city')?.touched
        && (accountDetailsForm.get('city')?.hasError('required')
        || accountDetailsForm.get('city')?.hasError('minlength')))) {
        <span class="input-error"
          >
          <img src="assets/imgs/error-icon.svg">
          {{labelService.myAccountDetailsErrorField | label}}
        </span>
      }
    </div>

    <div class="account-details-control">
      @if (!editMode) {
        <label>{{ labelService.myAccountDetailsFiscalCountry | label }}</label>
      }
      @if (!editMode) {
        <p>{{ userDetails.fiscalAddress ? $any(userDetails.fiscalAddress.fiscalAddress)?.country : '-' }}</p>
      }
      @if (editMode) {
        <app-single-line-text-input
          [control]="countryControl"
          [formControlName]="'country'"
          [label]="labelService.myAccountDetailsFiscalCountry | label"
          [inputHeight]="'64px'"
          [fontSize]="'var(--main-info-font-size)'"
          [roundBorders]="true"
          [borderRadius]="'var(--main-info-font-size) * 3'"
          [backgroundColor]="environment.brandStyleVariables.inputSecondaryBackgroundColor"
          [brandColor]="environment.brandStyleVariables.mainBrandColor"
          [placeholderLabel]="userDetails.fiscalAddress ? $any(userDetails.fiscalAddress.fiscalAddress)?.country : labelService.myAccountDetailsFiscalCoutryLabel | label">
        </app-single-line-text-input>
      }

      @if ((accountDetailsForm.get('city')?.touched
        && (accountDetailsForm.get('city')?.hasError('required')
        || accountDetailsForm.get('city')?.hasError('minLength')))) {
        <span class="input-error"
          >
          <img src="assets/imgs/error-icon.svg">
          {{labelService.myAccountDetailsErrorField | label}}
        </span>
      }
    </div>

  </div>

</form>
