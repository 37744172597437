import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Params } from '@angular/router';
import { catchError, map, Observable, Subject } from 'rxjs';
import { GenericErrorReply } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { ApiService } from 'src/app/_services/api.service';
import { LabelPipe } from 'src/app/_shared-modules/label-pipe-module/label.pipe';
import { TransactionHeaderDTO } from 'src/app/pages/profile/_services/profile.model';
import { environment } from 'src/environments/environment';
import { TransactionDTO, TransactionStatus, TransactionType } from '../transactions/transactions.model';
import { HeaderItem } from 'src/app/_generic-components-lib/__models/list/list-header.model';
import { Info, ListItem, Sizes, State } from 'src/app/_generic-components-lib/__models/list/list-item.model';

@Injectable({
  providedIn: 'root'
})
export class TransactionsHistoryService {

  private endOfScrollReached$ = new Subject<boolean>();
  public infiniteScrollActive: boolean = false;

  public currentPageIndex: number = 0;
  private pageSize: number = 16;
  public totalPages: number = 1;

  public expandableHeaders: Array<HeaderItem> = [];

  public sizesArray: Array<Sizes> = [
    { size: 45, sizeSmall: 85 },
    { size: 20, sizeSmall: 0},
    { size: 20, sizeSmall: 0},
    { size: 15, sizeSmall: 0},
    { size: 0, sizeSmall: 15}
  ];

  public expandableSizes: Array<Sizes> = [
    { size: 20, sizeSmall: 20 },
    { size: 45, sizeSmall: 45 },
    { size: 35, sizeSmall: 35 },
  ]

  constructor(
    private apiService: ApiService,
    private generalService: GeneralService,
    private labelService: LabelsService,
    private labelPipe: LabelPipe
  ) { }

  public getUserTransactionListHeaders(): Observable<Array<HeaderItem>> {
    return this.apiService.get('user/transactionHeaders', {}, '1.0', true, environment.useMockedData.profileGetTransactionsListHeaders).pipe(
      catchError(
        (error: HttpErrorResponse) => {
          this.generalService.isValidServerReply(error['error']);
          throw error['message'];
        }
      ),
      map((response: Array<TransactionHeaderDTO>  | GenericErrorReply) => {
        if(this.generalService.isValidServerReply(response)) {
          const transactionListDTO = (response as Array<TransactionHeaderDTO>);
          const transactionList = this.getTransactionHeadersFromDTO(transactionListDTO);
          return transactionList;
        } else {
          throw response;
        }
    }));
  }

  private getTransactionHeadersFromDTO(dto: Array<TransactionHeaderDTO>): Array<HeaderItem> {
    let headerList: Array<HeaderItem> = [];
    this.expandableHeaders = [];

    dto.forEach((header: TransactionHeaderDTO, index: number) => {
      headerList.push({
        id: header.id,
        name: header.name,
        sizes: header.sizes![index],
        styles: {
          textAlign: index === 0 ? 'left' : 'center'
        }
      });

      if(index !== 0) {
        this.expandableHeaders.push({
          id: header.id,
          name: header.name,
          sizes: header.sizes![index],
        });
      }
    });

    return headerList;
  }

  public getTransactionsList(pageIndex: number, params: Params = {}, pageSize?: number): Observable<Array<ListItem>> {
    return this.apiService.get(`transaction-management/transaction/me`, {}, '1.0', true, environment.useMockedData.historyGetTransactionList).pipe(
      catchError(
        error => {
          throw error.error.Message;
        }
      ),
      // map((response: GetTransactionListReply | GenericErrorReply) => { TODO uncomment all comments when pagination is implemented
      map((response: Array<TransactionDTO> | GenericErrorReply) => {
        if(this.generalService.isValidServerReply(response)) {
          const transactionListDTO = (response as Array<TransactionDTO>);

          this.currentPageIndex = pageIndex;
          // this.totalPages = Math.ceil(response.totalItems / this.pageSize);
          this.updateInfiniteScrollState(this.currentPageIndex, this.totalPages);
          // const productListDTO = response.items;

          const transactionList = this.getTransactionListFromDTO(transactionListDTO);
          return transactionList;
        } else {
          throw response;
        }
    }));
  }
  getTransactionListFromDTO(dto: Array<TransactionDTO>): Array<ListItem> {
    let transactionList: Array<ListItem> = [];

    dto.forEach((transactionDTO: TransactionDTO) => {
      transactionList.push({
        id: transactionDTO.transactionId,
        expand: true,
        info: this.getInfoFromTransactionDTO(transactionDTO),
        notClickable: true,
        infoExpandable: [{
          id: transactionDTO.transactionId,
          notClickable: true,
          info: this.getExpandableInfoFromTransactionDTO(transactionDTO)
        }]
      });
    });

    return transactionList;
  }

  getInfoFromTransactionDTO(transactionDTO: TransactionDTO): Array<Info> {
    let infoList: Array<Info> = [];

    infoList.push({
      text: transactionDTO.description,
      styles: {
        textAlign: 'left'
      },
      sizes: this.sizesArray[0]
    });

    const statusTranslation = this.getTransactionStatusTranslation(transactionDTO.status);
    infoList.push({
      state: statusTranslation,
      sizes: this.sizesArray[1]
    });

    infoList.push({
      date: this.generalService.createDateString(transactionDTO.transactionDate),
      sizes: this.sizesArray[2]
    });

    infoList.push({
      text: (transactionDTO.points * (transactionDTO.transactionType === TransactionType.WON ? 1 : (-1))).toString(),
      sizes: this.sizesArray[3],
      styles: {
        fontColor: transactionDTO.transactionType === TransactionType.WON && transactionDTO.points >= 0 ? '#5ED788' : environment.brandStyleVariables.mainErrorColor,
        fontWeight: '700',
        textAlign: 'center'
      }
    });

    infoList.push({
      expandButton: true,
      state: statusTranslation,
      sizes: this.sizesArray[4],
      styles: {
        padding: '0',
        justify: 'flex-end'
      }
    });

    return infoList;
  }

  private getExpandableInfoFromTransactionDTO(transactionDTO: TransactionDTO): Array<Info> {
    let infoList: Array<Info> = [];


    const statusTranslation = this.getTransactionStatusTranslation(transactionDTO.status);
    infoList.push({
      state: statusTranslation,
      sizes: this.expandableSizes[0]
    });

    infoList.push({
      date: this.generalService.createDateString(transactionDTO.transactionDate, {month: 'short'}),
      sizes: this.expandableSizes[1]
    });

    infoList.push({
      text: (transactionDTO.points * (transactionDTO.transactionType === TransactionType.WON ? 1 : (-1))).toString(),
      sizes: this.expandableSizes[2],
      styles: {
        fontColor: transactionDTO.transactionType === TransactionType.WON ? '#5ED788' : environment.brandStyleVariables.mainErrorColor,
        fontWeight: '700'
      }
    });

    return infoList;
  }

  private getTransactionStatusTranslation(transactionStatus: TransactionStatus): State {

    switch (transactionStatus) {
      case TransactionStatus.PENDING:
        return {
          label: this.labelPipe.transform(this.labelService.transactionStatusPending),
          class: 'yellow'
        }

      case TransactionStatus.COMPLETED:
        return {
          label: this.labelPipe.transform(this.labelService.transactionStatusCompleted),
          class: 'green'
        }
    }
  }

  public getEndOfScrollReached() {
    return this.endOfScrollReached$;
  }

  public updateInfiniteScrollState(curPage = 0, totalPages = 0) {
    this.infiniteScrollActive = curPage < totalPages;
  }
}
