<div class="w-100 d-flex flex-column mb-3">
  <div class="add-input-button align-self-end mb-2">
    @if (activity.canWinRewards && activity.inputs && activity.inputs.length > 0) {
      <app-generic-button
        [buttonName]="labelService.activityAddInputBtnLabel | label" [borderRadius]="'50px'"
        [backgroundColor]="environment.brandStyleVariables.mainBrandColor"
        [brandColor]="'var(--main-brand-color)'" [fontColor]="environment.brandStyleVariables.mainBackgroundColor" [fontSize]="'var(--main-info-font-size)'"
        [minHeight]="'calc(var(--main-info-font-size) * 2.8)'"
        [buttonSpacing]="'calc(var(--main-info-font-size) / 0.8) calc(var(--main-info-font-size) * 1.4)'"
        [hasIcon]="true" [iconClass]="'bi bi-plus'" [defaultIconColor]="'white'" [iconSize]="'calc(var(--main-info-font-size) * 2.5)'"
        [fontWeight]="'700'" (click)="openInputPopup()">
      </app-generic-button>
    }
  </div>

  @if (activity.userInputs && activity.userInputs.length > 0) {
    <div class="previous-inputs-container">
      <app-list-header [fullSize]="!(generalService.isMobile | async)" [fontSize]="'var(--smallest-font-size)'" [fontColor]="'#8692A6'"
      [headerList]="userInputsListItemHeader" [showArrows]="false" [clickable]="false"></app-list-header>
      <div class="list-container">
        @for (userInput of userInputsList; track userInput; let index = $index) {
          <app-list-item
            [item]="userInput" [itemMargin]="'1vw 0'" [fullSize]="!(generalService.isMobile | async)"
          (actionEvent)="actionClicked(index, $event)"></app-list-item>
        }
      </div>
    </div>
  }
</div>

@if (showInputPopup) {
  <app-activity-input-form-popup
    [form]="form" (popUpClosed)="closeInputPopup()"
    (formSubmited)="formSubmited($event)" (userInputRemoved)="userInputTriggerRemoveConfirmation($event)">
  </app-activity-input-form-popup>
}

@if (popupToShow) {
  <div class="popup-container">
    <app-generic-popup
      [closeClickOutside]="popupToShow.closeClickOutside!"
      [preImgTitle]="popupToShow.preImgTitle!"
      [image]="popupToShow.image!"
      [title]="popupToShow.title!"
      [descriptions]="popupToShow.descriptions!"
      [buttons]="popupToShow.buttons!"
      [enableXButton]="false"
      (popUpClosed)="popupClosed()"
      (buttonClicked)="popupActionClicked($event)">
    </app-generic-popup>
  </div>
}
