<app-page-banner [title]="labelService.ourProductsBannerTitle | label" [backgroundImageUrl]="'assets/mock/environments/Gamifyou/our-products/banner.png'"
  [imageUrl]="'assets/mock/environments/Gamifyou/our-products/gamifas_rocket.png'" [containerHeight]="'36vh'" [pseudoElementColor]="environment.brandStyleVariables.pseudoElementHighlightColor"
  [useGradient]="true" [alignImageBottom]="true" [imageSize]="'35%'">
</app-page-banner>

@if (isLoaded) {
  <div class="intro-container">
    @for (banner of introBanners; track banner; let index = $index) {
      <app-intro-banner [bannerInfo]="banner" [flexFlow]="index % 2 === 0 ? 'row' : 'row-reverse'"></app-intro-banner>
    }
  </div>
  <div class="known-for-container">
    <app-known-for-banner [products]="productsList"></app-known-for-banner>
  </div>
  <div class="products-banner-list-container">
    @for (product of productsList; track product; let index = $index) {
      <div [id]="product.productName">
        <app-our-product-banner [productInfo]="product" [flexFlow]="index % 2 === 0 ? 'row' : 'row-reverse'"></app-our-product-banner>
      </div>
    }
  </div>
  <div class="request-demo-container">
    <!-- TODO -->
  </div>
}
