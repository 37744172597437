import { animate, state, style, transition, trigger } from '@angular/animations';

import { AfterViewInit, Component, ElementRef, Input } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Router } from '@angular/router';
import { asapScheduler, take } from 'rxjs';
import { LibraryService } from '../../__services/library.service';
import { GenericPopupComponent } from '../generic-popup/generic-popup.component';
import { NgStyle } from '@angular/common';

@Component({
  standalone: true,
  selector: 'app-generic-popup-from-bottom',
  templateUrl: './generic-popup-from-bottom.component.html',
  styleUrls: ['./generic-popup-from-bottom.component.sass'],
  animations: [
    trigger('Slide', [
      state('down', style({
        opacity: '0',
        transform: 'translateY(100%)'
      })),
      state('up', style({
        opacity: '1',
        transform: 'translateY(0%)',
      })),
      transition('up => down',  animate('300ms ease-in-out')),
      transition('down => up',  animate('300ms ease-in-out'))
    ]),
    trigger('BackdropFadeIn', [
      state('down', style({
        opacity: '0',
      })),
      state('up', style({
        opacity: '0.32',
      })),
      transition('up <=> down',  animate('300ms ease-in-out')),
    ]),
  ],
  imports: [
    NgStyle
  ]
})
export class GenericPopupFromBottomComponent extends GenericPopupComponent implements AfterViewInit {

  @Input() public walletName: string;
  @Input() public walletLabel: string;

  public override animationState: string;
  constructor(
    private routerOverride: Router,
    private hostOverride: ElementRef,
    private LibraryService: LibraryService,
    ) {
    super(routerOverride, hostOverride, LibraryService);
  }

  override ngAfterViewInit(): void {
    asapScheduler.schedule(() => {
      this.animationState = 'up';
    });
  }

  public override closePopUp(): void {
    this.animationState = 'down';

    super.oldAsyncFunction(() => {
      this.popUpClosed.emit(true);
    }, 300).pipe(take(1), takeUntilDestroyed(this.destroyRef)).subscribe();
  }
}
