<app-page-banner
  [title]="mainBannerInfo.title ? mainBannerInfo.title : ''"
  [description]="mainBannerInfo.description ? mainBannerInfo.description : ''"
  [backgroundImageUrl]="mainBannerInfo.backgroundImageUrl ? mainBannerInfo.backgroundImageUrl : ''"
  [imageUrl]="mainBannerInfo.itemImageUrl ? mainBannerInfo.itemImageUrl : ''"
  [useGradient]="mainBannerInfo.useGradient"
  [pseudoElementColor]="environment.brandStyleVariables.pseudoElementHighlightColor"
  [containerHeight]="(environment.showUserPointsInBanners && (generalService.isUserLoggedIn | async)) ? undefined : '36vh'"
  [textContainerMaxWidth]="'70%'"
  [imageSize]="'35%'">
</app-page-banner>

@if (isLoaded && tierList && tierList.length > 0) {
  <div class="section">
    <p class="section-title">{{labelService.marketplaceTiersTitle | label}}</p>
    <p class="section-description">{{labelService.marketplaceTiersDescription | label}}</p>
    <div class="tiers-container">
      <app-tiers-filter [tierList]="tierList" (tierClicked)="selectedTier($event)"></app-tiers-filter>
    </div>
  </div>
}

@if (isLoaded) {
  <div class="section market-place-container">
    <p class="section-title">{{labelService.marketplaceSectionTitle | label}}</p>
    <p class="section-description">{{labelService.marketplaceSectionDescription | label}}</p>
    <!-- <div class="marketplace-filters-header-container" *ngIf="filterList && filterList.length > 0"> -->
    <div class="marketplace-filters-header-container">
      <div class="search-container">
        <form [formGroup]="form">
          <app-single-line-text-input
            [control]="searchControl"
            [formControlName]="'search'"
            [placeholderLabel]="labelService.marketplaceFiltersSearchPlaceholder | label" [inputHeight]="'48px'" [fontSize]="'var(--main-info-font-size)'"
            [backgroundColor]="environment.brandStyleVariables.inputBackgroundColor" [roundBorders]="true" [borderRadius]="'calc(var(--main-info-font-size) * 3)'" [borderColor]="'transparent'"
            [prefixMargin]="'20px'" [isSuffixIcon]="true" [inputValue]="searchString" [hasSuffix]="true"
            [inactiveSuffix]="'bi bi-search'" [activeSuffix]="'bi bi-search'" [alignLeft]="true"
            [brandColor]="'var(--main-brand-color)'" [debounceTime]="0"
            (suffixClicked)="search()" (actionOnEnter)="search()">
          </app-single-line-text-input>
        </form>
        <!--       <app-toggle-button class="d-flex align-items-center" [imageAsButton]="(generalService.isMobile | async)"
        [buttonText]="(generalService.isMobile | async) ? '' : showFilters ? (labelService.marketplaceFiltersButtonCloseLabel | label) : (labelService.marketplaceFiltersButtonLabel | label)" [fontSizePx]="(generalService.isMobile | async) ? 20 : 12"
        [brandColor]="environment.brandStyleVariables.mainBrandColor" [fontColor]="environment.brandStyleVariables.mainBackgroundColor" [toggleOnBgColor]="environment.brandStyleVariables.mainErrorColor"
        [toggleOnFtColor]="environment.brandStyleVariables.mainBackgroundColor" [hoveredIconColor]="environment.brandStyleVariables.mainBackgroundColor"
        [imageUrl]="'assets/imgs/filter-icon.svg'" [defaultIconColor]="environment.brandStyleVariables.mainBackgroundColor"
        [isActive]="showFilters"
      (buttonActive)="showFilters = $event"></app-toggle-button> -->
    </div>
  </div>
  <!--
  <div class="marketplace-mobile-filters-container custom-scroll" [ngClass]="{'active' : (generalService.isMobile | async) && showFilters}">
    <app-filters-list [filterList]="filterList" [isMobile]="(generalService.isMobile | async)" [activeFilterCount]="activeFilterCount" (filtersUpdated)="filtersUpdated()" (close)="showFilters = false"></app-filters-list>
  </div> -->
  <div class="marketplace-list-wrapper">
    <div class="marketplace-list-container">
      <!--       <div class="filter-chips-container" *ngIf="!(generalService.isMobile | async)" [ngClass]="{'with-items': filterChips.length > 0}">
      <div class="chip" *ngFor="let chip of filterChips">
        <span>{{chip.name}}</span>
        <div class="delete-icon-container" (click)="deleteChip(chip)">
          <div class="close-icon">
            <span class="cross-line"></span>
          </div>
        </div>
      </div>
    </div> -->
    <div class="product-list-container w-100"
      infiniteScroll
      [infiniteScrollDistance]="listPercentageFromBottom"
      [infiniteScrollThrottle]="50"
      [immediateCheck]="true"
      [scrollWindow]="false"
      [infiniteScrollContainer]="mainComponent.scrollableContainerRef"
      (scrolled)="onScroll()"
      #productContainerList>
      @if (productList.length > 0 || isListLoading) {
        <div class="product-list-wrapper">
          @for (productCard of productList; track productCard) {
            <!-- TODO is block will be true if user doesn't have enough points to buy -->
            <app-list-item-card class="product-list-card"
              [isBlocked]="(generalService.isUserLoggedIn | async)! && productCard.prices[0].amount > userDefaultWalletValue"
              [image]="productCard.images && productCard.images.length > 0 ? productCard.images[0].href : 'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/default-images/default-image-small-rectangle.svg'"
              [title]="productCard.name" [shadowBrandColor]="environment.brandStyleVariables.listShadowBrandColor" (click)="productItemClicked(productCard.id)">
              <ng-template>
                <div class="w-100">
                  <app-product-content [isBlocked]="(generalService.isUserLoggedIn | async)! && productCard.prices[0].amount > userDefaultWalletValue" [prices]="productCard.prices!" [lowestPriceValue]="productCard.lowestPrice!"
                    [quantity]="productCard.quantity && productCard.quantity <= 3 ? productCard.quantity : 0" [quantityWarningNumber]="productCard.quantity? productCard.quantity : 0"
                  [category]="productCard.categories!" [serial]="productCard.serial!" [endDate]="productCard.endDate ? productCard.endDate : ''"></app-product-content>
                </div>
              </ng-template>
            </app-list-item-card>
            <!-- <app-list-item-card class="product-list-card" [image]="productCard.images && productCard.images.length > 0 ? productCard.images[0].href : 'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/default-images/default-image-small-rectangle.svg'"
            [title]="productCard.name" [prices]="productCard.prices!" [lowestPriceValue]="productCard.lowestPrice!" [quantity]="productCard.quantity!" [quantityWarningNumber]="1"
            [quantityWarningColor]="environment.brandStyleVariables.mainWarningColor" [date]="productCard.endDate!" [category]="productCard.categories!"  [serial]="productCard.serial!" (click)="productItemClicked(productCard.id)">
          </app-list-item-card> -->
        }
        @if (isListLoading) {
          @for (item of loadingListItems; track item) {
            <app-loading-list-item class="loading-item" [ngClass]="{'opacity-0': !isListLoading}">
              <ng-template>
                <app-product-loading-content></app-product-loading-content>
              </ng-template>
            </app-loading-list-item>
          }
        }
      </div>
    } @else {
      <div class="no-items-found">
        <app-no-items [image]="'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/empty-list-image.png'" [title]="labelService.markplaceListNoItems | label"></app-no-items>
      </div>
    }
  </div>
</div>
<!--     <div class="marketplace-desktop-filters-container custom-scroll" [ngClass]="{'active' : !(generalService.isMobile | async) && showFilters}">
<app-filters-list [filterList]="filterList" [isMobile]="(generalService.isMobile | async)" [activeFilterCount]="activeFilterCount" (filtersUpdated)="filtersUpdated()" (close)="showFilters = false"></app-filters-list>
</div> -->
</div>
</div>
}
