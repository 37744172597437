{
  "name": "web-portal",
  "version": "1.2.0",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "start-gamifyou": "ng serve --configuration DEV-GAMIFYOU",
    "start-gamifyou-demo": "ng serve --configuration DEMO-GAMIFYOU",
    "start-gamifyou-portal": "ng serve --configuration DEV-GAMIFYOU-PORTAL",
    "start-YOUR": "ng serve --configuration DEV-YOUR",
    "start-YOUR-qa": "ng serve --configuration QA-YOUR",
    "start-https": "ng serve --ssl",
    "build": "ng build --configuration development",
    "build-qa": "ng build --configuration QA",
    "build-prod": "ng build --configuration production",
    "build-GIVIN-prod": "ng build --configuration PROD-GIVIN",
    "build-GAMIFYOU-dev": "ng build --configuration DEV-GAMIFYOU",
    "build-GAMIFYOU-qa": "ng build --configuration QA-GAMIFYOU",
    "build-GAMIFYOU-demo": "ng build --configuration DEMO-GAMIFYOU",
    "build-GAMIFYOU-prod": "ng build --configuration PROD-GAMIFYOU",
    "build-GAMIFYOU-PORTAL-dev": "ng build --configuration DEV-GAMIFYOU-PORTAL",
    "build-GAMIFYOU-PORTAL-qa": "ng build --configuration QA-GAMIFYOU-PORTAL",
    "build-GAMIFYOU-PORTAL-prod": "ng build --configuration PROD-GAMIFYOU-PORTAL",
    "build-YOUR-dev": "ng build --configuration DEV-YOUR",
    "build-YOUR-qa": "ng build --configuration QA-YOUR",
    "watch": "ng build --watch --configuration development",
    "start-remote": "ng serve --host 0.0.0.0",
    "test": "ng test"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^18.2.8",
    "@angular/cdk": "^18.2.8",
    "@angular/common": "^18.2.8",
    "@angular/compiler": "^18.2.8",
    "@angular/core": "^18.2.8",
    "@angular/forms": "^18.2.8",
    "@angular/google-maps": "^18.2.8",
    "@angular/material": "^18.2.8",
    "@angular/platform-browser": "^18.2.8",
    "@angular/platform-browser-dynamic": "^18.2.8",
    "@angular/router": "^18.2.8",
    "@auth0/angular-jwt": "^5.1.0",
    "@metamask/providers": "^17.2.1",
    "@ngx-translate/core": "^14.0.0",
    "@ngx-translate/http-loader": "^7.0.0",
    "@rive-app/canvas": "^2.21.5",
    "@rive-app/canvas-advanced": "^2.21.5",
    "@swimlane/ngx-charts": "^20.5.0",
    "@types/validator": "^13.7.10",
    "@web3auth/base": "^9.2.3",
    "@web3auth/modal": "^9.2.3",
    "bootstrap": "^5.2.1",
    "bootstrap-icons": "^1.10.3",
    "dompurify": "^3.0.2",
    "ethers": "^6.13.4",
    "firebase": "^9.17.1",
    "google-libphonenumber": "^3.2.32",
    "intl-tel-input": "^17.0.3",
    "keycloak-angular": "^16.0.1",
    "keycloak-js": "^25.0.6",
    "loglevel": "^1.8.1",
    "lottie-web": "^5.12.2",
    "moment": "^2.29.4",
    "ng-rive": "^0.3.1",
    "ngx-infinite-scroll": "^18.0.0",
    "ngx-lottie": "^12.0.0",
    "ngx-progressbar": "^12.0.2",
    "rxjs": "~7.5.4",
    "swiper": "^11.1.14",
    "tslib": "^2.3.0",
    "validator": "^13.7.0",
    "web3": "^4.13.0",
    "zone.js": "~0.14.10"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^18.2.8",
    "@angular/cli": "^18.2.8",
    "@angular/compiler-cli": "^18.2.8",
    "@types/dompurify": "^3.0.2",
    "@types/google-libphonenumber": "^7.4.23",
    "@types/google.maps": "^3.58.1",
    "@types/jasmine": "~3.10.0",
    "@types/keccak": "^3.0.1",
    "@types/node": "^12.20.55",
    "@types/readable-stream": "^2.3.15",
    "@types/trusted-types": "^2.0.3",
    "assert": "^2.0.0",
    "buffer": "^6.0.3",
    "crypto-browserify": "^3.12.0",
    "https-browserify": "^1.0.0",
    "jasmine-core": "~4.0.0",
    "karma": "~6.3.0",
    "karma-chrome-launcher": "~3.1.0",
    "karma-coverage": "~2.1.0",
    "karma-jasmine": "~4.0.0",
    "karma-jasmine-html-reporter": "~1.7.0",
    "os-browserify": "^0.3.0",
    "process": "^0.11.10",
    "stream-browserify": "^3.0.0",
    "stream-http": "^3.2.0",
    "typescript": "~5.4.5",
    "url": "^0.11.0"
  }
}
