<div class="backdrop-container" [@BackdropFadeIn]="isMobile() ? mobileAnimationState() : animationState()"></div>

<div [@ZoomIn]="animationState()" [@SlideIn]="mobileAnimationState()" class="popup-container">
  <div class="close-button" (click)="closePopUp()">
    <i class="bi bi-x"></i>
  </div>

  <div class="title">
    <span>{{ labelService.activityAddInputBtnLabel | label }}</span>

    @if (formInputItemStatus.CREATING) {
      <div class="state-container" [ngClass]="{
        'yellow': form().status === formInputItemStatus.PENDING,
        'green': form().status === formInputItemStatus.COMPLETED,
        'red': form().status === formInputItemStatus.REJECTED
        }">
        @switch (form().status) {
          @case (formInputItemStatus.PENDING) {
            <span>{{ labelService.activityTypeInputStatePendingLabel | label }}</span>
          }
          @case (formInputItemStatus.COMPLETED) {
            <span>{{ labelService.activityTypeInputStateCompletedLabel | label }}</span>
          }
          @case (formInputItemStatus.REJECTED) {
            <span>{{ labelService.activityTypeInputStateRejectedLabel | label }}</span>
          }
        }
      </div>
    }
  </div>

  @if (isLoaded() && editing()) {
    <form class="form-container custom-scroll" [formGroup]="dynamicForm()" (ngSubmit)="saveForm()">
      @for (input of form().inputs; track input) {
      <div class="input-container" [ngClass]="{
        'half-width': input.size === widthOptions.HALF && !isMobile(),
        'full-width': (!input.size || input.size === widthOptions.FULL) || isMobile()
      }">
          @switch (input.type) {
            @case (inputType.SINGLE_LINE) {
              <app-single-line-text-input
                [control]="$any(dynamicForm()).controls[input.order + '']"
                [formControlName]="input.order + ''"
                [label]="input.label + (input.required? '*' : '')"
                [labelFontSize]="'var(--small-font-size)'" [labelMargin]="'var(--smaller-font-size)'"
                [placeholderLabel]="input.placeholder"
                [placeholderStyle]="{
                  color: '#8692A6',
                  fontSize: 'var(--smaller-font-size)',
                  fontWeight: '500'
                }"
                [inputHeight]="'calc(var(--small-font-size) * 4)'"
                [fontSize]="'var(--small-font-size)'"
                [backgroundColor]="'white'"
                [borderColor]="'#8692A6'"
                [borderRadius]="'calc(var(--small-font-size) * 4)'" [roundBorders]="true"
                [brandColor]="'var(--main-brand-color)'" [debounceTime]="300"
                [inputValue]="$any(input.value)"
                (valueChanged)="input.value = $event">
              </app-single-line-text-input>
            }
            @case (inputType.TEXT_AREA) {
              <app-textarea-input
                [control]="$any(dynamicForm()).controls[input.order + '']"
                [formControlName]="input.order + ''"
                [shouldGrow]="false" [rowSize]="3" [charLength]="input.maxLength ? input.maxLength : 99999"
                [inputPadding]="'var(--smaller-font-size) calc(var(--smaller-font-size) * 2)'"
                [showCharacterCount]="input.maxLength ? true: false" [maxCharactersLabel]="labelService.activityTypeInputPopupMaxCharsLabel | label"
                [label]="input.label + (input.required? '*' : '')"
                [labelFontSize]="'var(--small-font-size)'" [labelMargin]="'var(--smaller-font-size)'"
                [placeholder]="input.placeholder"
                [placeholderStyle]="{
                  color: '#8692A6',
                  fontSize: 'var(--smaller-font-size)',
                  fontWeight: '500'
                }"
                [inputHeight]="'calc(var(--small-font-size) * 4)'"
                [fontSize]="'var(--small-font-size)'"
                [backgroundColor]="'white'"
                [borderColor]="'#8692A6'"
                [borderRadius]="'calc(var(--small-font-size) * 4)'" [roundBorders]="true"
                [brandColor]="'var(--main-brand-color)'"
                [inputValue]="$any(input.value)"
                (valueChanged)="input.value = $event">
              </app-textarea-input>
            }
            @case (inputType.NUMBER) {
              <app-numeric-input
                [control]="$any(dynamicForm()).controls[input.order + '']"
                [formControlName]="input.order + ''"
                [allowNegative]="false" [allowDecimals]="false"
                [minValue]="0" [maxValue]="input.maxLength ? input.maxLength : 99999999"
                [label]="input.label + (input.required? '*' : '')"
                [labelFontSize]="'var(--small-font-size)'" [labelMargin]="'var(--smaller-font-size)'"
                [placeholderLabel]="input.placeholder"
                [placeholderStyle]="{
                  color: '#8692A6',
                  fontSize: 'var(--smaller-font-size)',
                  fontWeight: '500'
                }"
                [inputHeight]="'calc(var(--small-font-size) * 4)'"
                [fontSize]="'var(--small-font-size)'"
                [backgroundColor]="'white'"
                [borderColor]="'#8692A6'"
                [borderRadius]="'calc(var(--small-font-size) * 4)'" [roundBorders]="true"
                [brandColor]="'var(--main-brand-color)'"
                [inputValue]="$any(input.value)"
                (valueChanged)="input.value = $event">
              </app-numeric-input>
            }
            @case (inputType.EMAIL) {
              <app-single-line-text-input
                [control]="$any(dynamicForm()).controls[input.order + '']"
                [formControlName]="input.order + ''"
                [label]="input.label + (input.required? '*' : '')"
                [labelFontSize]="'var(--small-font-size)'" [labelMargin]="'var(--smaller-font-size)'"
                [placeholderLabel]="input.placeholder"
                [placeholderStyle]="{
                  color: '#8692A6',
                  fontSize: 'var(--smaller-font-size)',
                  fontWeight: '500'
                }"
                [inputHeight]="'calc(var(--small-font-size) * 4)'"
                [fontSize]="'var(--small-font-size)'"
                [backgroundColor]="'white'"
                [borderColor]="'#8692A6'"
                [borderRadius]="'calc(var(--small-font-size) * 4)'" [roundBorders]="true"
                [brandColor]="'var(--main-brand-color)'" [debounceTime]="300"
                [inputValue]="$any(input.value)"
                (valueChanged)="input.value = $event">
              </app-single-line-text-input>
            }
            @case (inputType.PHONE_NUMBER) {
              <app-generic-phone-input
                [control]="$any(dynamicForm()).controls[input.order + ''].controls['phoneInput']"
                [formControlName]="input.order + ''"
                [countryCodeControl]="$any(dynamicForm()).controls[input.order + ''].controls['countryCode']"
                [allowDropdown]="true"
                [label]="input.label + (input.required? '*' : '')"
                [labelFontSize]="'var(--small-font-size)'" [labelMargin]="'var(--smaller-font-size)'"
                [placeholderLabel]="input.placeholder"
                [placeholderStyle]="{
                  color: '#8692A6',
                  fontSize: 'var(--smaller-font-size)',
                  fontWeight: '500'
                }"
                [inputHeight]="'calc(var(--small-font-size) * 4)'"
                [fontSize]="'var(--small-font-size)'"
                [backgroundColor]="'white'"
                [borderColor]="'#8692A6'"
                [borderRadius]="'calc(var(--small-font-size) * 4)'" [roundBorders]="true"
                [brandColor]="'var(--main-brand-color)'"
                [inputValue]="$any(input.value)"
                [dropdownBorderRadius]="'0.3vw'" [searchPlaceholderLabel]="labelService.phoneCountrySearchPlaceholder | label">
              </app-generic-phone-input>
            }
            @case (inputType.CHECKBOX) {
              <div class="d-flex input-checkbox-container">
                <label>{{input.label + (input.required? '*' : '')}}</label>
                <div class="checkbox-wrapper w-100 d-flex">
                  @for (option of input.options!; track option; let i = $index) {
                    <app-checkbox
                      [control]="$any(dynamicForm()).controls[input.order + '']"
                      [formControlName]="input.order + ''"
                      [minHeight]="'unset'"
                      [fontSize]="'var(--small-font-size)'"
                      [fontWeight]="'700'"
                      [fontColor]="'var(--user-font-color)'"
                      [borderColor]="'#8692A6'"
                      [activeCheckboxColor]="'var(--main-brand-color)'"
                      [brandColor]="'var(--main-brand-color)'"
                      [label]="option.value"
                      [flexDirection]="'row-reverse'"
                      [gapSize]="'0.65vw'"
                      (valueChanged)="addCheckboxValueToOptions(option, input, input.options!)">
                    </app-checkbox>
                  }
                </div>
              </div>
            }
          }
        </div>
      }
    </form>
  }

  @if (isLoaded() && !editing() && form().inputs && form().inputs.length > 0) {
    <div class="non-editing-input-wrapper">
      @for (input of form().inputs; track input) {
        <div class="non-editing-input-container"
      [ngClass]="{
        'half-width': input.size === widthOptions.HALF,
        'full-width': !input.size || input.size === widthOptions.FULL
      }">
          @if (input.type === inputType.CHECKBOX) {
            <div class="d-flex input-checkbox-container">
              <label>{{input.label + (input.required? '*' : '')}}</label>
              <div class="checkbox-wrapper w-100 d-flex">
                @for (option of input.options!; track option; let i = $index) {
                  <app-checkbox
                    [control]="$any(dynamicForm()).controls[input.order + '']"
                    [formControlName]="input.order + ''"
                    [minHeight]="'unset'"
                    [fontSize]="'var(--small-font-size)'"
                    [fontWeight]="'700'"
                    [fontColor]="'var(--user-font-color)'"
                    [borderColor]="'#8692A6'"
                    [activeCheckboxColor]="'var(--main-brand-color)'"
                    [brandColor]="'var(--main-brand-color)'"
                    [label]="option.value"
                    [flexDirection]="'row-reverse'"
                    [gapSize]="'0.65vw'"
                    [disabled]="true">
                  </app-checkbox>
                }
              </div>
            </div>
          } @else {
            <p class="input-label">
              {{ input.label + (input.required? '*' : '') }}
            </p>
            <p class="input-value">
              {{ input.value ? input.value : '-' }}
            </p>
          }
        </div>
      }
    </div>
  }

  <div class="w-100 d-flex justify-content-around buttons-wrapper" [ngClass]="{'mt-3 mb-3': !isMobile()}">
    @if (form().status === formInputItemStatus.PENDING) {
      <app-generic-button
        [minHeight]="'unset'"
        [buttonName]="labelService.activityDeleteInputBtnLabel | label" [borderRadius]="'50px'"
        [backgroundColor]="'#424242'" [fontColor]="environment.brandStyleVariables.mainBackgroundColor" [fontSize]="'var(--main-info-font-size)'"
        [buttonSpacing]="'calc(var(--main-info-font-size) / 0.8) calc(var(--main-info-font-size) * 1.4)'"
        [hasIcon]="true" [iconClass]="'bi bi-trash'" [defaultIconColor]="'white'" [iconSize]="'calc(var(--main-info-font-size) * 1.3)'"
        [fontWeight]="'700'"
        (buttonAction)="removeUserInput()">
      </app-generic-button>
      @if (!editing()) {
        <app-generic-button
          [minHeight]="'unset'"
          [buttonName]="labelService.activityEditInputBtnLabel | label" [borderRadius]="'50px'"
          [backgroundColor]="environment.brandStyleVariables.mainBrandColor"
          [brandColor]="'var(--main-brand-color)'" [fontColor]="environment.brandStyleVariables.mainBackgroundColor" [fontSize]="'var(--main-info-font-size)'"
          [buttonSpacing]="'calc(var(--main-info-font-size) / 0.8) calc(var(--main-info-font-size) * 1.4)'"
          [fontWeight]="'700'"
          [disabledBackgroundColor]="environment.brandStyleVariables.mainBrandColorLight" (buttonAction)="startEditing()">
        </app-generic-button>
      }
    }

    @if (form().status === formInputItemStatus.CREATING) {
      <app-generic-button
        [minHeight]="'unset'"
        [buttonName]="labelService.activityAddInputBtnLabel | label" [borderRadius]="'50px'"
        [backgroundColor]="environment.brandStyleVariables.mainBrandColor"
        [brandColor]="'var(--main-brand-color)'" [fontColor]="environment.brandStyleVariables.mainBackgroundColor" [fontSize]="'var(--main-info-font-size)'"
        [buttonSpacing]="'calc(var(--main-info-font-size) / 0.8) calc(var(--main-info-font-size) * 1.4)'"
        [hasIcon]="true" [iconClass]="'bi bi-plus'" [defaultIconColor]="'white'" [iconSize]="'calc(var(--main-info-font-size) * 2.5)'"
        [fontWeight]="'700'"
        [disabledBackgroundColor]="environment.brandStyleVariables.mainBrandColorLight" [isDisabled]="!dynamicForm().valid" (buttonAction)="saveForm()">
      </app-generic-button>
    }

    @if (form().status !== formInputItemStatus.CREATING && editing()) {
      <app-generic-button
        [minHeight]="'unset'"
        [buttonName]="labelService.activitySaveInputBtnLabel | label" [borderRadius]="'50px'"
        [backgroundColor]="environment.brandStyleVariables.mainBrandColor"
        [brandColor]="'var(--main-brand-color)'" [fontColor]="environment.brandStyleVariables.mainBackgroundColor" [fontSize]="'var(--main-info-font-size)'"
        [buttonSpacing]="'calc(var(--main-info-font-size) / 0.8) calc(var(--main-info-font-size) * 1.4)'"
        [fontWeight]="'700'"
        [disabledBackgroundColor]="environment.brandStyleVariables.mainBrandColorLight" [isDisabled]="!dynamicForm().valid" (buttonAction)="saveForm()">
      </app-generic-button>
    }

  </div>
</div>
