
import { Component, ElementRef } from '@angular/core';
import { LibraryService } from '../../__services/library.service';
import { BaseComponent } from '../../_base-component/base.component';

@Component({
  standalone: true,
  selector: 'app-snackbar-base-component',
  template: '',
  styleUrls: ['./snackbar-base.component.sass'],
  imports: [BaseComponent],
  providers: [LibraryService]
})
export class SnackbarBaseComponent extends BaseComponent {

  constructor(
    public override host: ElementRef,
    public override libraryService: LibraryService) {
    super(host, libraryService);
  }

}
