<button class="generic-button" dirSingleClick [delayTime]="delayTime" [ngClass]="{'has-icon': hasIcon || hasPrefixIcon, 'border': hasBorder}"
  [disabled]="isDisabled" (click)="isDisabled ? '' : emitButtonAction()">

  @if (hasPrefixIcon) {
    <div class="prefix-icon" [ngClass]="{ 'no-margin' : buttonName === '' }">
      <i [class]="iconClass"></i>
    </div>
  }

  <span>{{buttonName}}</span>

  @if (hasIcon) {
    <div class="suffix-icon" [ngClass]="{ 'no-margin' : buttonName === '' }">
      <i [class]="iconClass"></i>
    </div>
  }
</button>
