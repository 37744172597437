import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router, RouterLink, RouterLinkActive } from '@angular/router';
import { filter } from 'rxjs';
import { BrandingInfo } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { FirebaseAnalyticsService } from 'src/app/_services/firebase/analytics/firebase-analytics.service';
import { environment } from 'src/environments/environment';
import { WalletConnectionService } from '../wallet-connect-module/wallet-services/connections/wallet-connection.service';
import { PopupService } from 'src/app/_services/popup-service.service';
import { LabelPipe } from 'src/app/_shared-modules/label-pipe-module/label.pipe';
import { CommonModule } from '@angular/common';
import { WalletSidebarComponent } from '../wallet-connect-module/wallet-sidebar/wallet-sidebar.component';
import { MobileSideMenuComponent } from '../mobile-side-menu/mobile-side-menu.component';
import { GenericButtonComponent } from 'src/app/_generic-components-lib/inputs/buttons/generic-button/generic-button.component';
import { GenericDropdownComponent } from 'src/app/_generic-components-lib/inputs/dropdowns/generic-dropdown/generic-dropdown.component';
import { DropdownOption } from 'src/app/_generic-components-lib/__models/dropdown/dropdown.model';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.sass'],
  standalone: true,
  imports: [
    CommonModule,
    LabelPipe,
    GenericButtonComponent,
    GenericDropdownComponent,
    MobileSideMenuComponent,
    WalletSidebarComponent,
    RouterLink,
    RouterLinkActive
  ]
})
export class NavbarComponent implements OnInit {

  public brandInfo: BrandingInfo;

  public toggleMenuState: boolean = false;
  public isSideMenuOpen: boolean = false;
  public toggleSideBarState: boolean = false;
  public isSideBarOpen: boolean = false;

  public currentUrl: string;
  public isRouteActive: boolean;

  public isRouteRestricted: boolean;

  public userProfileDropdownOpened: boolean = false;
  public userProfileDropdownOptions: Array<DropdownOption> = [];

  public environment = environment;

  constructor(
    public generalService: GeneralService,
    public labelService: LabelsService,
    public walletService: WalletConnectionService,
    public router: Router,
    private route: ActivatedRoute,
    private firebaseAnalyticsService: FirebaseAnalyticsService,
    private popupService: PopupService,
    private labelPipe: LabelPipe,
    private labelsService: LabelsService
  ) {
    this.currentUrl = router.url.split('?')[0];

    // Filters the type of event when router events are triggered, in this case is NavigationEnd, it means that the user navigated to a page
    // we then subscribe to the RouterEventType NavigationEnd and change the value of isRouteActive by checking if the current url the user navigated is not the landing-page
    this.router.events
    .pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd)
    ).subscribe((event: NavigationEnd) => {
      this.isRouteActive = event.url.includes('/my-account');
      if(!event.url.includes('/my-account')) {
        this.userProfileDropdownOptions.forEach(option => {
          option.selected = false;
        });
      }
    });

    this.isRouteActive = this.router.url.includes('/my-account');
    if(!this.router.url.includes('/my-account')) {
      this.userProfileDropdownOptions.forEach(option => {
        option.selected = false;
      });
    }

    if (this.generalService.useKeycloak) {
      this.generalService.menuList.forEach((menu) => {
        if(menu.viewUrl === '/my-account') {
          this.userProfileDropdownOptions = !menu.isHidden ? [{
            id: menu.menuId,
            name: menu.name,
            value: menu.viewUrl
          }] : [];

          if (menu.childs && menu.childs.length > 0) {
            for(const child of menu.childs) {
              this.userProfileDropdownOptions.push({
                id: child.menuId,
                name: child.name,
                value: `${menu.viewUrl}${child.viewUrl}`
              });
            }
          }
        }
      });
    }
  }

  ngOnInit(): void {
    this.brandInfo = this.generalService.generalInfo.brandingInfo;

    window.addEventListener('resize', () => {
      if (window.innerWidth > 760 && this.isSideMenuOpen)
        this.isSideMenuOpen = false;
    });

    if(environment.useWallet) {
      if(typeof window.ethereum !== 'undefined') {
        window.ethereum.on('accountsChanged', () => {
          this.walletService.checkAccConnection();
        });
      }
    }
  }

  public goToLogin(): void {
    this.firebaseAnalyticsService.logEvents('login-btn-clicked');

    history.pushState({page: 1}, 'back'); // force entrance in history of current page to allow navigation back from login page

    this.generalService.userLogin();
  }

  public goTo(url?: string, external: boolean = false): void {
    if(url) {
      this.generalService.navigateTo(url, external);
    }
    this.generalService.autoScrollTop('page-router-container');
  }

  public toggleSideMenu(): void {
    this.toggleMenuState = !this.toggleMenuState;

    if (this.toggleMenuState)
      this.setSideMenuState(true);
  }

  public setSideMenuState(newState: boolean): void {
    this.isSideMenuOpen = newState;
    this.toggleMenuState = newState;
  }

  public openWalletSideBar(): void {
    this.toggleSideBarState = true;

    if(this.toggleSideBarState) {
      this.setSideBarState(true);
    }
  }

  public setSideBarState(newSidebarState: boolean): void {
    this.isSideBarOpen = newSidebarState;
    this.toggleSideBarState = newSidebarState;
  }

  public openUserProfileDropdown(): void {
    this.userProfileDropdownOpened = true;
  }

  public userProfileDropdownOptionSelected(optionSelected: DropdownOption): void {
    if(optionSelected.value !== '/my-account/logout') {
      this.userProfileDropdownOpened = false;
      this.generalService.navigateTo(optionSelected.value ? optionSelected.value : '');
    } else {
      // this.generalService.setConfirmLogoutPopup(window.location.origin);
      this.setConfirmLogoutPopup();
    }
  }

  private setConfirmLogoutPopup(): void {
    this.userProfileDropdownOpened = false;
    this.popupService.setPopupToOpen(
      {
        text: ''
      },
      `assets/imgs/environments/${environment.tenantName}/popup-logout-confirmation-image.png`,
      this.popupService.getTitle(this.labelPipe.transform(this.labelsService.popupLogoutConfirmationTitle)),
      [this.popupService.getSimpleDescription(this.labelPipe.transform(this.labelsService.popupLogoutConfirmationDescription))],
      [
        {
          text: this.labelPipe.transform(this.labelsService.popupLogoutConfirmationCancelButton),
          isCloseBtn: true,
          actionText: '',
          style: {
            backgroundColor: '#424242',
            fontColor: '#ffffff'
          },
        },
        {
          text: this.labelPipe.transform(this.labelsService.popupLogoutConfirmationButton),
          isCloseBtn: false,
          actionText: 'logout',
          style: {
            backgroundColor: environment.brandStyleVariables.mainBrandColor,
            fontColor: environment.brandStyleVariables.mainBackgroundColor
          }
        }
      ],
      false,
      false
    );
  }
}
