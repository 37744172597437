import { Component, ElementRef, Input, OnInit } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { LabelsService } from 'src/app/_services/_labels/labels.service';
import { environment } from 'src/environments/environment';
import { NgClass, NgStyle, AsyncPipe, CommonModule } from '@angular/common';
import { LabelPipe } from '../../../../_shared-modules/label-pipe-module/label.pipe';

@Component({
  selector: 'app-page-banner',
  templateUrl: './page-banner.component.html',
  styleUrls: ['./page-banner.component.sass'],
  standalone: true,
  imports: [
    CommonModule,
    LabelPipe
  ]
})
export class PageBannerComponent implements OnInit {

  @Input() pseudoElementColor: string;

  @Input() title: string;
  @Input() description: string;
  @Input() imageUrl: string;
  @Input() backgroundImageUrl: string;
  @Input() smallBanner: boolean;

  @Input() textContainerMaxWidth: string;
  @Input() containerHeight?: string;
  @Input() imageSize: string;
  @Input() titleFontSize: string;
  @Input() textFontSize: string;

  @Input() useGradient: boolean;
  @Input() alignImageBottom: boolean;

  public environment = environment;

  constructor(
    private host: ElementRef,
    private keycloakService: KeycloakService,
    public labelsService: LabelsService,
    public generalService: GeneralService
    ) { }

  ngOnInit(): void {
    this.setCSSVars();
  }

  private setCSSVars(): void {
    if(this.titleFontSize || this.textFontSize || this.pseudoElementColor || this.textContainerMaxWidth) {

      if(this.titleFontSize)
        this.host.nativeElement.style.setProperty('--page-title-font-size', this.titleFontSize)

      if(this.textFontSize)
        this.host.nativeElement.style.setProperty('--header-font-size', this.textFontSize)

      if(this.pseudoElementColor)
        this.host.nativeElement.style.setProperty('--pseudo-element-color', this.pseudoElementColor);

      if(this.textContainerMaxWidth)
        this.host.nativeElement.style.setProperty('--text-container-max-width', this.textContainerMaxWidth);
    }
  }

}
