<div class="form-area-container" [ngStyle]="{'flex-flow' : environment.contactUsFormAlignLeft? 'row': 'row-reverse'}" id="contact-us-form-container">
  <div class="form-container form-shadow">
    <div class="form-header">
      <div class="form-title-container pe-4">
        <p class="form-title" [ngStyle]="{ 'width': environment.contactUsFormEnvironmentIcon && environment.contactUsFormEnvironmentIcon !== '' ? '85%' : '100%' }">{{labelService.contactUsFormTitle | label}}</p>
        @if (environment.contactUsFormEnvironmentIcon && environment.contactUsFormEnvironmentIcon !== '') {
          <img class="form-environment-icon" [src]="environment.contactUsFormEnvironmentIcon">
        }
      </div>
      <p class="form-description">{{labelService.contactUsFormDesc | label}}</p>
    </div>

    <form [formGroup]="form" (ngSubmit)="submitForm()">
      <div class="contact-us-form-control">
        <app-single-line-text-input
          [control]="nameControl"
          [formControlName]="'name'"
          [label]="labelService.contactUsNameTitle | label"
          [inputHeight]="'64px'"
          [fontSize]="'var(--main-info-font-size)'"
          [roundBorders]="true"
          [borderRadius]="'calc(var(--main-info-font-size) * 3)'"
          [labelMargin]="'1rem'"
          [backgroundColor]="environment.brandStyleVariables.inputSecondaryBackgroundColor"
          [brandColor]="environment.brandStyleVariables.mainBrandColor"
          [placeholderLabel]="labelService.contactUsNamePlaceholder | label">
        </app-single-line-text-input>

        @if ((form.get('name')?.touched
          && (form.get('name')?.hasError('required')
          || form.get('name')?.hasError('minlength')))) {
          <span class="input-error"
            >
            <img src="assets/imgs/error-icon.svg">
            {{labelService.myAccountDetailsErrorField | label}}
          </span>
        }
        @if ((form.get('name')?.touched
          && form.get('name')?.hasError('pattern'))) {
          <span class="input-error"
            >
            <img src="assets/imgs/error-icon.svg">
            {{labelService.myAccountDetailsErrorTextField | label}}
          </span>
        }
      </div>

      @if (environment.showPhoneInput) {
        <div class="contact-us-form-control">
          <app-generic-phone-input
            [control]="phoneInputControl"
            [formControlName]="'phoneInput'"
            [selectedCountryISO]="CountryISO.Portugal" [brandColor]="environment.brandStyleVariables.mainBrandColor"
            [label]="labelService.contactUsPhoneTitle | label" [placeholderLabel]="labelService.contactUsPhonePlaceholder | label"
            [inputHeight]="'64px'" [fontSize]="'var(--main-info-font-size)'" [borderRadius]="'calc(var(--main-info-font-size) * 3)'" [fontColor]="environment.brandStyleVariables.mainFontColor"
            [errorColor]="environment.brandStyleVariables.mainErrorColor" [backgroundColor]="environment.brandStyleVariables.inputSecondaryBackgroundColor"
            [roundBorders]="true" [allowDropdown]="true">
          </app-generic-phone-input>
          @if ((form.get('phoneInput')?.touched
            && (form.get('phoneInput')?.hasError('required')))) {
            <span class="input-error"
              >
              <img src="assets/imgs/error-icon.svg">
              {{labelService.myAccountDetailsErrorField | label}}
            </span>
          }
          @if ((form.get('phoneInput')?.touched
            && form.get('phoneInput')?.hasError('validatePhoneNumber'))) {
            <span class="input-error"
              >
              <img src="assets/imgs/error-icon.svg">
              {{labelService.myAccountDetailsErrorTextField | label}}
            </span>
          }
        </div>
      }

      <div class="contact-us-form-control">
        <app-single-line-text-input
          [control]="emailControl"
          [formControlName]="'email'"
          [label]="labelService.contactUsEmailTitle | label"
          [inputHeight]="'64px'"
          [fontSize]="'var(--main-info-font-size)'"
          [roundBorders]="true"
          [borderRadius]="'calc(var(--main-info-font-size) * 3)'"
          [labelMargin]="'1rem'"
          [backgroundColor]="environment.brandStyleVariables.inputSecondaryBackgroundColor"
          [brandColor]="environment.brandStyleVariables.mainBrandColor"
          [placeholderLabel]="labelService.contactUsEmailPlaceholder | label">
        </app-single-line-text-input>

        @if ((form.get('email')?.touched
          && (form.get('email')?.hasError('required')
          || form.get('email')?.hasError('minlength')))) {
          <span class="input-error"
            >
            <img src="assets/imgs/error-icon.svg">
            {{labelService.myAccountDetailsErrorField | label}}
          </span>
        }
        @if ((form.get('email')?.touched
          && form.get('email')?.hasError('pattern'))) {
          <span class="input-error"
            >
            <img src="assets/imgs/error-icon.svg">
            {{labelService.myAccountDetailsErrorTextField | label}}
          </span>
        }
      </div>

      <div class="contact-us-form-control">
        <app-textarea-input
          [control]="messageControl"
          [formControlName]="'message'"
          [label]="labelService.contactUsMessageTitle | label"
          [labelMargin]="'1rem'"
          [borderRadius]="'33px'"
          [shouldGrow]="true"
          [fontSize]="'var(--main-info-font-size)'"
          [charLength]="150"
          [backgroundColor]="environment.brandStyleVariables.inputSecondaryBackgroundColor"
          [brandColor]="environment.brandStyleVariables.mainBrandColor"
          [placeholder]="labelService.contactUsMessagePlaceholder | label"
          [rowSize]="3"
          [showCharacterCount]="false"
          [maxCharactersLabel]="''"
          [inputPadding]="'var(--smaller-font-size) calc(var(--smaller-font-size) * 2)'">
        </app-textarea-input>

        @if ((form.get('message')?.touched
          && (form.get('message')?.hasError('required')
          || form.get('message')?.hasError('minlength')))) {
          <span class="input-error"
            >
            <img src="assets/imgs/error-icon.svg">
            {{labelService.myAccountDetailsErrorField | label}}
          </span>
        }
        @if ((form.get('message')?.touched
          && form.get('message')?.hasError('pattern'))) {
          <span class="input-error"
            >
            <img src="assets/imgs/error-icon.svg">
            {{labelService.myAccountDetailsErrorTextField | label}}
          </span>
        }
      </div>

      <app-generic-button [buttonName]="labelService.contactUsSubmitButton | label" [minHeight]="'64px'"
        [backgroundColor]="environment.brandStyleVariables.mainBrandColor"
        [brandColor]="environment.brandStyleVariables.mainBrandColor" [fontColor]="environment.brandStyleVariables.mainBackgroundColor"
        [buttonSize]="'244px'" [borderRadius]="'calc(var(--main-info-font-size) * 3)'" [fontSize]="'var(--main-info-font-size)'" [fontWeight]="'700'"
        [disabledBackgroundColor]="environment.brandStyleVariables.mainBrandColorLight" [isDisabled]="!form.valid"
        submit>
      </app-generic-button>
    </form>
  </div>

  <div class="form-image">
    <img [src]="environment.contactUsFormBackgroundImage">
  </div>
</div>

