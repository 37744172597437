import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-blog-loading-content',
    templateUrl: './blog-loading-content.component.html',
    styleUrls: ['./blog-loading-content.component.sass'],
    standalone: true
})
export class BlogLoadingContentComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
