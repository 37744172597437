import { CommonModule } from '@angular/common';
import {} from '@angular/common/http';
import { Component, ElementRef, Input, OnInit } from '@angular/core';
import {
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { LibraryService } from '../../__services/library.service';
import { BaseComponent } from '../../_base-component/base.component';
import { InputBaseComponent } from '../../_extended-components/input-base-component/input-base.component';
import { DebounceDirective } from 'src/app/_directives/debounce-module/debounce-directive/debounce-directive.service';

@Component({
  standalone: true,
  selector: 'app-textarea-input',
  templateUrl: './textarea-input.component.html',
  styleUrls: [
    '../../_base-component/base.component.sass',
    '../../_extended-components/input-base-component/input-base.component.sass',
    './textarea-input.component.sass',
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    DebounceDirective,
    BaseComponent,
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: TextareaInputComponent,
    },
    {
      provide: NG_VALIDATORS,
      multi: true,
      useExisting: TextareaInputComponent,
    },
  ],
})
export class TextareaInputComponent
  extends InputBaseComponent
  implements OnInit
{
  @Input({ required: true }) public charLength: number;
  @Input({ required: true }) public rowSize: number;

  @Input({ required: true }) public shouldGrow: boolean;
  @Input({ required: true }) public showCharacterCount: boolean;
  @Input({ required: true }) public placeholder: string;
  @Input({ required: true }) public maxCharactersLabel: string;

  @Input() public showError: boolean;

  constructor(
    public override host: ElementRef,
    public override libraryService: LibraryService
  ) {
    super(host, libraryService);
  }

  ngOnInit(): void {
    super.setCSSVars();
    super.setSpecificCSSVars();
  }

  public onInput(event: Event): void {
    if (event.target instanceof HTMLTextAreaElement) {
      this.inputValue = event.target.value;
      this.onChange(this.inputValue);
    }
  }
}
