<div class="item-info-slider-container swiper-container w-100 h-100" [ngClass]="{'locked': isLocked}">

  <swiper-container class="item-info-swiper" #swiperRef swiperJS init="false" [config]="swiperConfig">
    @if (imageList.length > 0) {
      @for (item of imageList; track item; let index = $index) {
        <swiper-slide class="item-info-item-container">
          <div class="item-info-image">
            @switch (item.type) {
              @case ('IMAGE') {
                <img [src]="item.href ? item.href : 'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/default-images/default-image-small-rectangle.svg'"
                  class="w-100">
              }
              @case ('VIDEO') {
                <video class="w-100" [autoplay]="false" [loop]="true" [muted]="false" [controls]="true" playsinline id="video-{{index}}">
                  <source [src]="item.href" type="video/mp4">
                </video>
              }
            }
          </div>
        </swiper-slide>
      }
    }

    @if (imageList.length === 0) {
      <swiper-slide class="item-info-item-container">
        <div class="item-info-image">
          <img [src]="'assets/imgs/environments/' + environment.tenantLocalAssetsPath + '/default-images/default-image-small-rectangle.svg'" class="w-100">
        </div>
      </swiper-slide>
    }
  </swiper-container>
  <div class="swiper-pagination"></div>

  @if (itemTypeImage) {
    <div class="item-type-container">
      <img [src]="itemTypeImage">
      <span>{{ itemTypeName }}</span>
    </div>
  }
</div>
