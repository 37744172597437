import { RadioButton } from "src/app/_generic-components-lib/__models/buttons/buttons.model";

export interface FilterGroupDTO {
  queryParameter: string;
  name: string;
  type: string;
  values: Array<FilterItemDTO>;
}

export interface FilterItemDTO {
  id: number;
  description: string;
}

export interface FilterGroup {
  queryParameter: string;
  name: string;
  type: FilterType;
  values: Array<FilterItem | RadioButton>;
  isOpen?: boolean;
  animationStarted?: boolean;
}

export interface FilterItem {
  id: number;
  description: string;
  selected: boolean;
}

export interface SelectedFilterGroup {
  queryParameter: string;
  items: Array<SelectedFilterItem>;
}

export interface SelectedFilterItem {
  name: string;
  value: number;
}

export interface FilterChip {
  queryParameter: string;
  name: string;
  value: number;
}

export enum FilterTypeEnum {
  Checkbox = 'Checkbox',
  Radio = 'Radio',
  Range = 'Range'
}

export type FilterType = keyof typeof FilterTypeEnum;
