import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormGroup } from '@angular/forms';
import { Params } from '@angular/router';
import { Observable, Subject, catchError, map } from 'rxjs';
import { DeepCopy } from 'src/app/_directives/deep-copy/deep-copy.service';
import { FiscalAddress, GenericErrorReply, ImageContent, ImageContentDTO } from 'src/app/_services/_general-service/general-service.model';
import { GeneralService } from 'src/app/_services/_general-service/general.service';
import { ApiService } from 'src/app/_services/api.service';
import { environment } from 'src/environments/environment';
import { ActivityInfo, ActivityInfoDTO, ActivityListItem, ActivityListItemDTO, Code } from '../../activities.model';
import { DynamicInput, InputType } from '../../activity-details-container/activity-details-card/activity-types/input/activity-type-input.model';

@Injectable({
  providedIn: 'root'
})
export class ActivitiesService {

  endOfScrollReached$ = new Subject<boolean>();
  infiniteScrollActive:boolean = false;

  currentPageIndex: number = 0;
  pageSize: number = 16;
  totalPages: number = 1;

  activityInfo: ActivityInfo;

  constructor(
    private apiService: ApiService,
    private generalService: GeneralService,
    private deepCopy: DeepCopy
    ){ }

  public getActivityList(pageIndex: number, params: Params = {}, pageSize?: number): Observable<Array<ActivityListItem>> {
    const currentParams: Params = {
      ...params,
      pageSize: pageSize ? pageSize : this.pageSize,
      page: pageIndex,
    };

    return this.apiService.get(`activity-management/public/activity`, currentParams, '1.0', false, environment.useMockedData.activitiesGetList).pipe(
      catchError(
        (error: HttpErrorResponse) => {
            this.generalService.isValidServerReply(error['error']);
            throw error['message'];
          }
        ),
      // map((response: GetActivityListReply | GenericErrorReply) => { TODO uncomment all comments when pagination is implemented
        map((response: Array<ActivityListItemDTO> | GenericErrorReply) => {
        if(this.generalService.isValidServerReply(response)) {
          // response = response as GetActivityListReply;
          response = (response ? response  : []) as Array<ActivityListItemDTO>;

          this.currentPageIndex = pageIndex;
          // this.totalPages = Math.ceil(response.totalItems / this.pageSize);
          this.updateInfiniteScrollState(this.currentPageIndex, this.totalPages);
          // const activityListDTO = response.items;
          const activityListDTO = response;
          const activityList = this.getActivityListFromDTO(activityListDTO);
          return activityList;
        } else {
          throw response;
        }
    }));
  }

  public getActivityListFromDTO(dto: Array<ActivityListItemDTO>): Array<ActivityListItem> {
    let activityList: Array<ActivityListItem> = [];

    dto.forEach((activityDto: ActivityListItemDTO) => {
      activityList.push({
        activityId: activityDto.activityId,
        description: activityDto.description,
        name: activityDto.name,
        type: activityDto.type,
        images: activityDto.images ? this.getActivityImageContentFromDTO(activityDto.images) : [],
        rewards: activityDto.rewards,
        partner: activityDto.partner,
        endDate: activityDto.endDate,
        totalReward: activityDto.totalReward,

        // MULTI STEP TYPE DETAILS
        totalCompletedSubActivities: activityDto.totalCompletedSubActivities,
        totalCompletedSubActivityPercentage: activityDto.totalCompletedSubActivityPercentage,
        totalSubActivities: activityDto.totalSubActivities,
        totalSubActivityReward: activityDto.totalSubActivityReward
      });
    });

    return activityList;
  }

  private getActivityImageContentFromDTO(imagesDto: Array<ImageContentDTO>): Array<ImageContent> {
    let images: Array<ImageContent> = [];

    imagesDto.forEach(dto => {
      images.push({
        href: dto.href,
        content: dto.content ? dto.content : '',
        type: dto.type,
      });
    });

    return images;
  }

  public getActivityDetails(activityId: string): Observable<ActivityInfo> {
    return this.apiService.get(`activity-management/public/activity/${activityId}`, {}, '1.0', true, environment.useMockedData.activitiesGet, activityId).pipe(
      catchError(
        (error: HttpErrorResponse) => {
            this.generalService.isValidServerReply(error['error']);
            throw error['message'];
          }
        ),
      map((response: ActivityInfoDTO | GenericErrorReply) => {
        if(this.generalService.isValidServerReply(response)) {
          const activityDetailDTO = (response as ActivityInfoDTO);
          this.activityInfo = this.getActivityDetailFromDTO(activityDetailDTO);

          return this.activityInfo;
        } else {
          throw response;
        }
      }
    ));
  }

  private getActivityDetailFromDTO(dto: ActivityInfoDTO): ActivityInfo {
    let activityDetail: ActivityInfo;

    activityDetail = {
      activityId: dto.activityId,
      description: dto.description,
      name: dto.name,
      type: dto.type,
      images: dto.images ? this.getActivityImageContentFromDTO(dto.images) : [],
      rewards: dto.rewards,
      partner: dto.partner,
      endDate: dto.endDate,
      addresses: dto.codes ? this.getAddressesFromCodes(dto.codes) : [],
      totalReward: dto.totalReward,
      canWinRewards: dto.canWinRewards,

      // INPUT TYPE DETAILS
      inputs: dto.inputs,
      userInputs: dto.userInputs,

      // MULTI STEP TYPE DETAILS
      subActivities: dto.subActivities,
      totalCompletedSubActivities: dto.totalCompletedSubActivities,
      totalCompletedSubActivityPercentage: dto.totalCompletedSubActivityPercentage,
      totalSubActivities: dto.totalSubActivities,
      totalSubActivityReward: dto.totalSubActivityReward
    }

    return activityDetail;
  }

  private getAddressesFromCodes(codes: Array<Code>): Array<FiscalAddress> {
    const addresses: Array<FiscalAddress> = [];

    codes.forEach(code => {
      if (code.address) {
        addresses.push(code.address);
      }
    });

    return addresses;
  }

  public setFormValuesToActivityInputs(activity: ActivityInfo, form: FormGroup): Array<DynamicInput> {
    const dynamicInputs = this.deepCopy.deepCopy(activity.inputs);

    dynamicInputs!.forEach(input => {
      const inputValue = form.controls[input.order].value;

      switch (input.type) {
        case InputType.PHONE_NUMBER:
          input.value = inputValue['countryCode'] + ' ' + inputValue['phoneInput'];
          break;

        case InputType.CHECKBOX:
          input.value = inputValue;
          break;

        case InputType.RADIO_GROUP:
          // TODO
          break;

        default:
          input.value = inputValue;
          break;
      }
    });

    return dynamicInputs!;
  }


  public getEndOfScrollReached() {
    return this.endOfScrollReached$;
  }

  public updateInfiniteScrollState(curPage = 0, totalPages = 0) {
    this.infiniteScrollActive = curPage < totalPages;
  }
}
