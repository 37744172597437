<div class="backdrop-container" aria-hidden="true" [@BackdropFadeIn]="animationState" (click)="closeClickOutside ? closePopUp() : null"></div>

<div class="popup-container card-radius" [@ZoomIn]="animationState">

  @if (enableXButton) {
    <div aria-hidden="true" class="close-button" (click)="closePopUp()">
      <i class="bi bi-x"></i>
    </div>
  }

  @if (preImgTitle) {
    <span class="pre-img-title mb-4"
      [ngStyle]="{
        'text-align': preImgTitle.style?.textAlign,
        'font-size': preImgTitle.style?.fontSize,
        'font-weight': preImgTitle.style?.fontWeight,
        'font-style': preImgTitle.style?.fontStyle,
        'color': preImgTitle.style?.fontColor,
        'margin-top': preImgTitle.style?.marginTop,
        'margin-bottom': preImgTitle.style?.marginBottom,
        'text-transform': preImgTitle.style?.textTransform
      }">
      {{ preImgTitle.text }}
    </span>
  }

  @if (image && !imageUsingIcon) {
    <img [src]="image" [alt]="preImgTitle != null ? preImgTitle.text : title != null ? title.text : ''">
  } @else {
    @if (iconImage) {
      <i class="iconImg mb-4" [ngClass]="iconImage.class" [ngStyle]="{'color': iconImage.style?.fontColor!}"></i>
    }
  }


  @if (title) {
    <span class="title mt-4"
      [ngClass]="{'mt-4': !title.style?.marginTop }"
      [ngStyle]="{
        'text-align': title.style?.textAlign,
        'font-size': title.style?.fontSize,
        'font-weight': title.style?.fontWeight,
        'font-style': title.style?.fontStyle,
        'color': title.style?.fontColor,
        'margin-top': title.style?.marginTop,
        'margin-bottom': title.style?.marginBottom,
        'text-transform': title.style?.textTransform
      }">{{ title.text }}</span>
  }

  <div class="w-100 description-container">
    @for (description of descriptions; track $index) {
      @if (description.innerTexts) {
        <p class="description"
          [ngClass]="{'mt-2': !description.style?.marginTop, 'mb-4': !description.style?.marginBottom }"
          [ngStyle]="{
            'text-align': description.style?.textAlign,
            'font-size': description.style?.fontSize,
            'font-weight': description.style?.fontWeight,
            'font-style': description.style?.fontStyle,
            'color': description.style?.fontColor,
            'margin-top': description.style?.marginTop,
            'margin-bottom': description.style?.marginBottom,
            'text-transform': description.style?.textTransform
          }">
          {{ description.text }}
          @for (innerText of description.innerTexts; track $index) {
            @if (innerText.action?.hasUrl) {
              <a role="button" [href]="innerText.action!.url"[ngStyle]="{
                'font-size': innerText.style?.fontSize,
                'font-weight': innerText.style?.fontWeight,
                'color': innerText.style?.fontColor
                }">
                {{innerText.text}}
              </a>
            } @else {
              <span aria-hidden="true" [ngStyle]="{
                'text-align': innerText.style?.textAlign,
                'font-size': innerText.style?.fontSize,
                'font-weight': innerText.style?.fontWeight,
                'font-style': innerText.style?.fontStyle,
                'color': innerText.style?.fontColor,
                'margin-top': innerText.style?.marginTop,
                'margin-bottom': innerText.style?.marginBottom,
                'text-transform': innerText.style?.textTransform,
                'cursor': innerText.action?.isClickable ? 'button' : ''
              }" (click)="innerText.action?.isClickable ? navigateTo() : ''">{{innerText.text}}</span>
            }
          }
        </p>
      } @else {
        <p class="description w-100"
        [ngClass]="{'mt-2': !description.style?.marginTop, 'mb-4': !description.style?.marginBottom}"
        [ngStyle]="{
          'text-align': description.style?.textAlign,
          'font-size': description.style?.fontSize,
          'font-weight': description.style?.fontWeight,
          'font-style': description.style?.fontStyle,
          'color': description.style?.fontColor,
          'margin-top': description.style?.marginTop,
          'margin-bottom': description.style?.marginBottom,
          'text-transform': description.style?.textTransform
        }">{{description.text}}</p>
      }
    }
  </div>

  @if (bottomImage) {
    <div class="bottom-image-container mb-4">
      <img alt="" [src]="bottomImage">
      <span>{{bottomDescription}}</span>
    </div>
  }

  <div class="button-area-wrapper w-100" [ngClass]="{'flex-column': buttonsVertical}">
    @for (btn of buttons; track $index) {
      <div class="w-100 mt-2 button" aria-hidden="true" [ngStyle]="{
          'background-color': btn.style?.backgroundColor,
          'color': btn.style?.fontColor,
          'font-size': btn.style?.fontSize,
          'font-weight': btn.style?.fontWeight,
          'font-style': btn.style?.fontStyle,
          'text-transform': btn.style?.textTransform,
          'border-radius': btn.style?.borderRadius,
          'padding': btn.style?.padding
        }"
        [ngClass]="{'generic-btn-shadow': btn.hasShadow, 'generic-btn': !btn.hasShadow}"
        (click)="btnAction(btn)">
        {{ btn.text }}
    </div>
    }
  </div>
</div>
